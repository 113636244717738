import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-previewdealwidget',
  templateUrl: './previewdealwidget.component.html',
  styleUrl: './previewdealwidget.component.scss'
})

export class PreviewdealwidgetComponent {
  dealPreviewImg:any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { imageSrc: string }) {
    this.dealPreviewImg = '../../../../assets/images/Preview.png'
  }
}
