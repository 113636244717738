import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-createflashdeal',
  templateUrl: './createflashdeal.component.html',
  styleUrl: './createflashdeal.component.scss'
})
export class CreateflashdealComponent implements ICellRendererAngularComp{
  params: any;

  constructor() {
  }

  refresh(): boolean {
    return true;
  }

  agInit(params: any) {
    this.params = params;
  }

  view() {
    return this.params.clicked(this.params.data);
  }
}
