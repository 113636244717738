<div (click)="view()" *ngIf="params.data.status==='A' && !params.data.flash"> <!--class="button mt-2" View-->
    <!-- <img loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/327d5d7e02368d20781ae4f8671867134f1389b5aabb39ed97b4c08663879c66?"
        class="img-28 pt-1" width="20px" /> -->
    <!-- <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 48 48">
        <path fill="#ffc107" d="M33 22h-9.4L30 5H19l-6 21h8.6L17 45z" />
    </svg> -->
    <div class="tooltip">
        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24">
            <path fill="#36849d"
                d="M11 17H4a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h12v2H4v12h7v-2l4 3l-4 3zm8 4V7H8v6H6V7a2 2 0 0 1 2-2h11a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2v-2h2v2z" />
        </svg>
        <span class="tooltiptext">Create Flash Deal</span>
    </div>

</div>
<div *ngIf="params.data.flash"> <!--class="button mt-2" View-->
    <!-- <img loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/327d5d7e02368d20781ae4f8671867134f1389b5aabb39ed97b4c08663879c66?"
        class="img-28 pt-1" width="20px" /> -->
    <div class="tooltip">
        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 48 48">
            <path fill="#ffc107" d="M33 22h-9.4L30 5H19l-6 21h8.6L17 45z" />
        </svg>
        <span class="tooltipFlashtext">Flash Deal</span>
    </div>
</div>