<nav *ngIf="pages.length!==0" aria-label="page navigation example">  
    <ul class="pagination" style="
    display: flex;
    justify-content: center;
">  <li class="page-item" (click)="onfirstPage()"><a class="page-link" href="javascript:void(0);">«  
          </a></li>  
      <li class="page-item" (click)="onPrevClickPage(activePage - 1)"><a class="page-link" href="javascript:void(0);">&lsaquo; 
          </a></li>  
      <!-- <li class="page-item" [ngClass]="{'active': activePage === item}" *ngFor="let item of pages"  
        (click)="onClickPage(item)"><a class="page-link" href="javascript:void(0);">{{item}} of {{pages.length}}</a></li>   -->
         
      <li class="page-item"><a class="page-link" href="javascript:void(0);">{{activePage}} of {{pages.length}}</a></li> 
      <li class="page-item" (click)="onNextClickPage(activePage + 1)"><a class="page-link" href="javascript:void(0);">  
        &rsaquo;</a></li>
      <li class="page-item" (click)="onLastPage(pages.length)"><a class="page-link" href="javascript:void(0);">  
            »</a></li>  
    </ul>  
    <!-- <label>Current page: {{activePage}}</label> -->
  </nav> 
