import { ChangeDetectorRef, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { HardcodedData } from '../hardcoded-data';
import { DashboardService } from 'src/app/services/dashboard.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CommonUtilService } from 'src/app/services/common-util.service';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import Validation from 'src/app/utils/validation';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrl: './company-profile.component.scss'
})
export class CompanyProfileComponent {
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders:any;
  activeStep = 1;
  companyData: any;
  allCategory: any;
  registerForm: FormGroup = new FormGroup({
    companyName: new FormControl(''),
    companyWebsite: new FormControl(''),
    companyLogo: new FormControl(''),
    registrationId: new FormControl(''),
    document: new FormControl(''),
    businessType: new FormControl(''),
    productCategories: new FormControl(''),
    addressLine: new FormControl(''),
    additionalInfo: new FormControl(''),
    country: new FormControl(''),
    state: new FormControl(''),
    city: new FormControl(''),
    zipCode: new FormControl(''),
    postbox_number:new FormControl('')
  });
  countryData: any;
  stateData: any;
  stateSelectedData: any;
  cityData: any;
  zipCode: any;
  zipID: any;
  country: any;
  macAddress: any;
  personID: any;
  allBusinessTypes: any;
  businessType: any;
  dropdownSettings: IDropdownSettings = {};
  docName: any = null;
  fData = new FormData();
  docData = new FormData();
  documents3Path: any = null;
  isFileUploaded = false;
  documentName: any = null;
  country_id: any;
  companylogo: any;
  logoName: any = null;
  imageData = new FormData();
  images3Path = null;
  isImageUploaded = false




  // Administrator Declarations

  showCreatePass = false;
  showConfirmPass = false;
  administartiveForm: FormGroup = new FormGroup({
    primaryContact: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    password: new FormControl(''),
    confirmPass: new FormControl(''),
    code: new FormControl('')
  });
  phoneEditable = true;
  updateVisibility: any = false;
  otpVisibility = false;
  // country: any;
  // macAddress: any;
  otpFieldVisible: any = false;
  updateSubmitVisible: any = true;
  data: any;
  displayTimer: boolean = false;
  resendOtp: boolean = false;
  resendOtpClicked = 1;
  display: any;
  // countryData: any;
  updateButtonVisibility:any = true;

  isAlertActive:any; 
  countryInitials:any;

  //Changepassword Declarations
  isChangePassword: boolean = false;
  // companyData = new HardcodedData();
  // showCreatePass = false;
  // showConfirmPass = false;
  showOldPass = false;
  // personID: any;
  changePasswordForm: FormGroup = new FormGroup({
    // primaryContact: new FormControl(''),
    // email: new FormControl(''),
    // phone: new FormControl(''),
    password: new FormControl(''),
    confirmPass: new FormControl(''),
  });


  constructor(
    private router: Router, private fb: FormBuilder, private service: CommonUtilService, private cStorage: ClientSideStorageService,
    private confirmationDialogService: ConfirmationDialogService,private cdr: ChangeDetectorRef, private dashboardService: DashboardService, private notifyService: NotificationAlertService,
    private route: ActivatedRoute,) {
    this.placeHolders = this.placeholders['Merchant_profile']
    this.createRegisterForm();
    this.personID = JSON.parse(this.cStorage.get("userId"));
    this.country = JSON.parse(this.cStorage.get("country"));
    this.macAddress = JSON.parse(this.cStorage.get("macAddress"));
    this.dashboardService.getCategory().subscribe((res: any) => {
      this.allCategory = res?.body.data;
    });

    this.isAlertActive = sessionStorage.getItem('isDashboardActive');
    
    // this.allCategory = this.dashboardService.getData();
    // this.allCategory = this.allCategory.source._value.data;

    // Administrator Section Call
    this.getUserData();

    this.createChangePasswordForm();
    this.changePasswordForm.get('password')?.valueChanges.subscribe(value => {
      this.service.setPasswordRulesDataData(value);
    });

    const userData = JSON.parse(this.cStorage.get("marketplaceData"));
    if (userData) {
      this.companyData = userData;
     if(parseInt(this.companyData.country) === 72){
      this.countryInitials = "B"
     }
     else if(parseInt(this.companyData.country) === 716){
      this.countryInitials = "Z"
     }
     else{
       this.countryInitials = "D"
     }


      this.route.data.subscribe((data: any) => {
        this.countryData = data.resolvedData.data
        if (this.countryData) {
          this.countryData.forEach((element: any) => {
            if ((element.country_id).toString() === this.companyData.country) {
              this.country = element.country_name;
              this.service.getBusinessTypes(this.country, this.macAddress).subscribe((res: any) => {
                this.allBusinessTypes = res.body?.data;
                if (this.allBusinessTypes) {
                  this.allBusinessTypes.forEach((element: any) => {
                    let type = this.companyData.marketplace_type * 1;
                    if (type === element.business_type_id) {
                      this.businessType = element.business_type_id;
                    }
                  });
                }
              });
              this.countrySelectionForState(element.country_id);
            }
          });
        }
      });

      // this.service.getCountry(this.country, this.macAddress).subscribe((res: any) => {
      //   this.countryData = res.body?.data;
      //   if (this.countryData) {
      //     this.countryData.forEach((element: any) => {
      //       if ((element.country_id).toString() === this.companyData.country) {
      //         this.country = element.country_name;
      //         this.countrySelectionForState(element.country_id);
      //       }
      //     });
      //   }
      // });
      //     });
      //   }
      // });
    }
    // });
  }

  

  toggleChangePassword() {
    this.isChangePassword = !this.isChangePassword;
  }

  toggleOldPasswordVisibility() {
    this.showOldPass = !this.showOldPass
  }

  createChangePasswordForm() {
    this.changePasswordForm = this.fb.group({
      oldpasswd: ['', Validators.required],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(40)
      ]],
      confirmPass: ['', Validators.required]
    },
      {
        validators: [Validation.match('password', 'confirmPass')]
      }
    );
  }

  get c(): { [key: string]: AbstractControl } {
    return this.changePasswordForm.controls;
  }

  resetChangePasword() {
    // this.confirmationDialogService
    //   .confirm("Confirmation ", "Are you sure you want to Reset? ")
    //   .then((confirmed) => {
    //     if (confirmed) {
          this.changePasswordForm.reset();
      //   }
      // });
  }

  saveChangePassword() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to save? ")
      .then((confirmed) => {
        if (confirmed) {
          let value = {
            "personId": this.personID,
            "password": this.changePasswordForm.value.password,
            "oldPassword":this.changePasswordForm.value.oldpasswd
          }
          this.service.adminPasswdUpdate(value).subscribe((res: any) => {
            if (res.status === "ERROR") {
              this.notifyService.showError(
                res.message,
                res.status
              );
            } else {
              this.notifyService.showSuccess(
                "Saved successfully !!",
                "Success"
              );
              this.router.navigate(["/dashboard"]);
            }
          });
        }
      });
  }


  // Administrator Section Call
  getUserData() {
    this.service.getUserProfileDetails().subscribe((res: any) => {
      this.data = res?.data;
      this.createForm(this.data);
    });
  }

  isValidityFormValid(): boolean  {
    const mailControl = this.administartiveForm.get('primaryContact');
    if (mailControl) {
      // Only check validity if the control is dirty or touched
      if (mailControl.dirty || mailControl.touched) {
        mailControl.updateValueAndValidity();
        return mailControl.valid;
      }
    }
    return false;
  }

  createForm(data: any) {
    let fullName = data.firstName.concat(" " + data.lastName);
    this.administartiveForm = this.fb.group({
      primaryContact: [fullName,[Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30)]],
      email: [data.email_id, [Validators.required, Validators.email]],
      phone: [data.mobile, Validators.required],
      personId: [data.personId],
      code: ['',],
      updatedPhone: [''],
      // password: ['', [
      //   Validators.required,
      //   Validators.minLength(6),
      //   Validators.maxLength(40)
      // ]],
      // confirmPass: ['', Validators.required],
      otp: ['']
    },
      {
        validators: [Validation.match('password', 'confirmPass')]
      }
    );

    if (this.a['primaryContact'].value) {
      this.a['email'].disable();
      this.a['phone'].disable();
    }
  }

  get a(): { [key: string]: AbstractControl } {
    return this.administartiveForm.controls;
  }

  reset() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to Reset? ")
      .then((confirmed) => {
        if (confirmed) {
          this.administartiveForm.reset();
        }
      });
  }

  saveAdminData() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to update the changes? ")
      .then((confirmed) => {
        if (confirmed) {
          this.proceed();
        }
      });
  }

  proceed() {
    const fullName = this.administartiveForm.value.primaryContact.split(' ');
    const lastName = fullName.pop(); // 'Panakkal'
    const firstName = fullName.join(' ')
    let payload = {
      "firstName": firstName,
      "lastName": lastName,
      "personId": this.administartiveForm.value.personId,
      "mobile": this.administartiveForm.controls['phone'].value.toString()
    }
    this.service.adminDetailsUpdate(payload).subscribe((res: any) => {
      this.notifyService.showSuccess(
        "Saved successfully !!",
        "Success"
      );
      this.updateVisibility = false;
      this.updateButtonVisibility = true;
      // this.router.navigate(["/dashboard"]);
    });
  }

  togglePasswordVisibility() {
    this.showCreatePass = !this.showCreatePass;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPass = !this.showConfirmPass;
  }

  editCompanyClick() {
    this.router.navigate(["/companyProfile"]);
  }

  changePass() {
    // this.confirmationDialogService
    //   .confirm("Confirmation ", "Are you sure you want to save this operation? ")
    //   .then((confirmed) => {
    //     if (confirmed) {
    //       this.proceed();
    //       this.router.navigate(["/changePassword"]);
    //     } else {
    this.router.navigate(["/changePassword"]);
    //   }
    // });
  }

  updatePhone() {
    this.phoneEditable = false;
    // this.administartiveForm.controls["phone"].reset();
    this.updateVisibility = true;
    this.updateSubmitVisible = false;
    this.updateButtonVisibility = false;
  }

  verifyPhone() {
    if (this.administartiveForm.controls["updatedPhone"].value !== "" && this.administartiveForm.controls["code"].value !== "") {
      let payload = {
        mobileNumber: this.administartiveForm.value?.code.toString() + this.administartiveForm.value?.updatedPhone.toString()
      }
      this.service.sendMobileOtp(payload, this.country, this.macAddress).subscribe((res: any) => {
        let value = res;
        if (res && res?.body?.status === "ERROR" || res?.body?.status === "Error") {
          this.notifyService.showError(
            res?.body?.message,
            res?.body?.status
          );
        } else {
          this.phoneEditable = true;
          this.otpFieldVisible = true;
          this.updateVisibility = true;
          this.start(3);
        }
      });
    } else {
      this.notifyService.showError(
        "Invalid Phone Number!!",
        "Invalid"
      );
    }
  }

  start(minute: any) {
    this.displayTimer = true;
    this.resendOtp = false;
    // let minute = 1;
    let seconds = minute * 60;
    let textSec: any = '0';
    let statSec = 60;
    const prefix = minute < 10 ? '0' : '';
    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      // if (statSec < 10) textSec = "0" + statSec;
      // textSec = statSec;

      if (statSec < 10) {
        // console.log('inside', statSec);
        textSec = '0' + statSec;
      } else {
        // console.log('else', statSec);
        textSec = statSec;
      }

      // this.display = prefix + Math.floor(seconds / 60) + ":" + textSec;
      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        // console.log('finished');
        clearInterval(timer);
        this.resendOtp = true;
        this.displayTimer = false;
      }
    }, 1000);
  }

  submitOtp() {
    let status: any = this.administartiveForm.controls['otp'].status;
    if (status !== 'INVALID') {

      let mobileNumber = this.administartiveForm.value?.code.toString() + this.administartiveForm.value?.updatedPhone.toString()
      let payload = {
        "phoneNumber": mobileNumber,
        "type": "mobile",
        "validationCode": this.administartiveForm.value.otp
      }
      this.service.ValidateOtp(payload, this.country, this.macAddress).subscribe((res: any) => {
        if (res.body?.status === 'Error') {
          this.notifyService.showError(
            res.body?.message,
            res.body?.status
          );
        } else {
          this.updateSubmitVisible = true;
          this.otpFieldVisible = false;
          this.notifyService.showSuccess(
            "Mobile Number Verified !!",
            "Success"
          );
          // mobileNumber = mobileNumber * 1;
          // this.administartiveForm.get('phone')?.setValue(mobileNumber);
          const fullName = this.administartiveForm.value.primaryContact.split(' ');
          const lastName = fullName.pop(); // 'Panakkal'
          const firstName = fullName.join(' ')
          let payload = {
            "firstName": firstName,
            "lastName": lastName,
            "personId": this.administartiveForm.value.personId,
            "mobile": mobileNumber
          }
          this.service.adminDetailsUpdate(payload).subscribe((res: any) => {
            this.notifyService.showSuccess(
              "Saved successfully !!",
              "Success"
            );
            this.phoneEditable = true;
            this.administartiveForm.controls["updatedPhone"].reset();
            this.administartiveForm.controls["code"].reset();
            this.updateVisibility = false;
            this.getUserData()
            this.updateButtonVisibility = true;
          });
          // this.verifyPhoneField = true;
          // this.disablePhone = true;
          // this.phoneVerified = true;
        }
      });
    } else {
      this.notifyService.showError(
        "Enter OTP !!",
        "Error"
      );
    }
  }

  onOtpChange(event: any) {
    let res = event;
    this.administartiveForm.value.otp = res;
  }

  resendOTP() {
    let clickedCOunter = this.resendOtpClicked++;
    if (clickedCOunter <= 2) {
      this.verifyPhone();
    } else {
      this.notifyService.showError("Maximum OTP sending limit reached !", "Error")
    }
  }

  cancelUpdatePhone() {
    this.phoneEditable = true;
    this.administartiveForm.controls["updatedPhone"].reset();
    this.administartiveForm.controls["code"].reset();
    this.updateVisibility = false;
    this.updateButtonVisibility = true;
    this.otpFieldVisible = false;
    // this.updateSubmitVisible = false;
  }

  ngOnInit() {
    this.dropdownSettings = {
      idField: "category_id",
      textField: "category_type",
      enableCheckAll: true,
      // selectAllText: "Select All Users From List",
      // unSelectAllText: "Unselect All Users From List",
      noDataAvailablePlaceholderText: "There is no item availabale to show",
      allowSearchFilter: true,
      // itemsShowLimit: 3
      // defaultOpen: false
    };
  }

  brandlogo:any;
  uploadedDoc:any;
  createRegisterForm() {
    if (this.companyData !== null && this.companyData !== undefined) {
      const city = (this.companyData.city) * 1;
      this.documentName = this.companyData.marketplace_document;
      this.companylogo = this.companyData?.marketplace_image !== null ? this.companyData?.marketplace_image : null;
      if (this.companyData?.marketplace_image != null) {
        this.brandlogo = environment.assetsUrl + '/assets/' + this.companyData?.marketplace_image
      }
      this.images3Path = this.companyData?.marketplace_image !== null ? this.companyData?.marketplace_image : null;
      this.documents3Path = this.companyData?.marketplace_document !== null ? this.companyData?.marketplace_document : null;
      if (this.companyData?.marketplace_document!= null) {
        this.uploadedDoc = environment.assetsUrl + '/assets/' + this.companyData?.marketplace_document
      }
      this.registerForm = this.fb.group({
        companyId: [this.companyData.marketplace_id],
        companyName: [this.companyData.marketplace_name, Validators.required],
        companyWebsite: [this.companyData.marketplace_link, [this.websiteUrlValidator()]],
        companyLogo: [this.companyData.marketplace_image, Validators.required],
        registrationId: [this.companyData.registration_id,],
        document: [this.companyData.marketplace_document],
        businessType: [this.businessType, Validators.required],
        addressLine: [this.companyData.addressLine1, Validators.required],
        additionalInfo: [this.companyData.addressLine2],
        country: [this.country, Validators.required],
        state: [this.companyData.state, Validators.required],
        city: [city, Validators.required],
        zipCode: [this.companyData.zipcode],
        productCategories: [this.companyData.categories, Validators.required],
        postbox_number:['']
      });
      if (this.f['companyName'].value) {
        this.f['companyName'].disable();
        this.f['country'].disable();
      }
      if(this.f['registrationId'].value){
        this.f['registrationId'].disable();
      }
    } else {
      this.registerForm = this.fb.group({
        companyId: [''],
        companyName: ['', Validators.required],
        companyWebsite: ['', Validators.required],
        companyLogo: ['', Validators.required],
        registrationId: ['',],
        document: ['', Validators.required],
        businessType: ['', Validators.required],
        addressLine: ['', Validators.required],
        additionalInfo: ['', Validators.required],
        country: ['', Validators.required],
        state: ['', Validators.required],
        city: ['', Validators.required],
        zipCode: [''],
        productCategories: ['', Validators.required],
        postbox_number:['']
      });
    }

  }

  get f(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  save() {
    let res = this.registerForm.value;
    let data = {
      "categories": res.productCategories,
      "marketplace_id": res.companyId,
      "marketplace_image": this.images3Path,
      "marketplace_document": this.documents3Path,
      "marketplace_link": res.companyWebsite,
      "marketplace_name": res.companyName,
      "marketplace_type": res.businessType,
      "registration_id": res.registrationId,
      "addressLine1": res.addressLine,
      "addressLine2": res.additionalInfo,
      "country": res.country,
      "state": res.state,
      "city": res.city,
      "zipcode": res.zip_code
    }
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to update the changes? ")
      .then((confirmed) => {
        if (confirmed) {
          this.service.editCompanyProfile(data, this.country, this.macAddress).subscribe((res: any) => {
            this.notifyService.showSuccess(
              "Details updated successfully, Now you can create deals navigating to dashboard!",
              "Success"
            );
            this.refreshMarketPlace();
            sessionStorage.setItem('isDashboardActive', 'true');
            let marketplaceData = res?.data;
            this.cStorage.set('marketplaceData', JSON.stringify(marketplaceData));
            // this.router.navigate(["/companyProfile/details"]);
          });
        }
      });
  }

  refreshMarketPlace() {
    this.service.getRegisterCompanyDetails().subscribe((res: any) => {
      let marketplaceData;
      if (res?.data) {
        marketplaceData = res?.data;
        this.cStorage.set('marketplaceData', JSON.stringify(marketplaceData));
      }
    });
  }

  cancel() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to cancel this operation? ")
      .then((confirmed) => {
        if (confirmed) {
          this.router.navigate(["/dashboard"]);
        }
      });
  }

  adminClick(step: number) {
    // this.router.navigate(["/companyProfile/details"]);
    this.activeStep = step;
    this.cdr.markForCheck();
  }

  countrySelectionForState(id: number) {
    this.registerForm.controls["state"].reset();
    this.registerForm.controls["city"].reset();
    this.registerForm.controls["zipCode"].reset();
    this.stateData = [];
    this.cityData = [];
    this.country_id = id;
    this.service.getState(id, this.country, this.macAddress).subscribe((res: any) => {
      this.stateData = res?.body?.data;
      // this.stateSelectedData = this.stateData?.find((element: any) => element.state_name === this.companyData.state)
      this.stateSelectedData = this.companyData.state;
      this.stateSelectionForCity(this.companyData.state);
    });
  }

  stateSelectionForCity(state: any) {
    this.registerForm.controls["city"].reset();
    this.registerForm.controls["zipCode"].reset();
    this.cityData = [];
    const selectedCountryCode = this.country_id;
    this.service.getCity(selectedCountryCode, state, this.country, this.macAddress).subscribe((res: any) => {
      this.cityData = res?.body?.data;
      this.createRegisterForm();
    });
  }

  citySelectionForZip(zip_code: any, zip_id: any) {
    this.registerForm.controls["zipCode"].reset();
    this.zipCode = zip_code;
    this.zipID = zip_id;
  }

  stateSelectForCity(state: any) {
    this.registerForm.controls["city"].reset();
    this.registerForm.controls["zipCode"].reset();
    this.cityData = [];
    const selectedCountryCode = this.country_id
    this.service.getCity(selectedCountryCode, state, this.country, this.macAddress).subscribe((res: any) => {
      this.cityData = res.body?.data;
      // this.createRegisterForm();
    });
  }

  uploadDoc(event: any) {
    let files = event.target.files[0];
    // files.type = "";
    let doctype = files.name.split('.');
    let doctypeName = doctype[doctype.length - 1];
    if (files.size <= 2097152) {
      this.docName = files.name;
      this.docData.append("file", files);
    } else {
      this.notifyService.showInfo("Only less than 2 MB docs are allowed", "Invalid File")
    }
    event.target.value = '';
  }

  removeDoc() {
    this.registerForm.value.document = null;
    this.docName = null;
    this.docData = new FormData();
    this.documents3Path = null;
    this.isFileUploaded = false;
  }

  documentUpload() {
    this.service.uploadDocRegister(this.docData).subscribe((res: any) => {
      if (res?.data?.s3Path) {
        this.documents3Path = res?.data?.s3Path;
        this.isFileUploaded = true;
        this.notifyService.showSuccess(
          "Document Successfully Uploaded",
          res?.status
        );
      } else {
        this.notifyService.showSuccess(
          res?.message,
          res?.status
        );
      }
    });
  }

  uploadImage(event: any) {
    let files = event.target.files[0];
    // files.type = "";
    let doctype = files.name.split('.');
    let doctypeName = doctype[doctype.length - 1];
    if (doctypeName && (doctypeName === "jpg" || doctypeName === "jpeg" || doctypeName === "png") &&
      files.size <= 2097152) {
      this.logoName = files.name;
      this.imageData.append("file", files);
      this.notifyService.showInfo("Image selected please upload", "")
    } else {
      this.notifyService.showInfo("Only jpg, jpeg, png file formats are allowed in less than 2 MB", "Invalid File")
    }
    // if (files.size <= 2097152) {
    //   this.logoName = files.name;
    //   this.imageData.append("file", files);
    // } else {
    //   this.notifyService.showInfo("Only less than 2 MB logo are allowed", "Invalid File")
    // }
    event.target.value = '';
  }

  removeImage() {
    this.registerForm.value.companyLogo = null;
    this.logoName = null;
    this.imageData = new FormData();
    this.images3Path = null;
    this.isImageUploaded = false;
  }

  imageUpload() {
    this.service.uploadImageRegister(this.imageData).subscribe((res: any) => {
      if (res?.data?.s3Path) {
        this.images3Path = res?.data?.s3Path;
        this.isImageUploaded = true;
        this.notifyService.showSuccess(
          "Logo Successfully Uploaded",
          res?.status
        );
      } else {
        this.notifyService.showSuccess(
          res?.message,
          res?.status
        );
      }
    });
  }

  websiteUrlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let url = control.value;
      if (url && !url.startsWith(' ') && !url.startsWith('http://') && !url.startsWith('https://')) {
        // If URL doesn't start with 'http://' or 'https://', append 'https://'
        url = '';
        control.setValue('https://' + url);
      }
      return null;
    };
  }

  onKeyDown(event: KeyboardEvent) {
    // Check if the pressed key is the spacebar (keyCode 32 or code "Space")
    if (event.keyCode === 32 || event.code === 'Space' || event.key === ' ') {
      event.preventDefault(); // Prevent default behavior (spacebar input)
    }
  }

  openLink(){
    window.open(this.uploadedDoc)
  }

}
