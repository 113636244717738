import { AfterViewInit, Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { CommonUtilService } from 'src/app/services/common-util.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-administatorpwdset',
  templateUrl: './administatorpwdset.component.html',
  styleUrl: './administatorpwdset.component.scss'
})
export class AdministatorpwdsetComponent implements AfterViewInit {
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders:any;
  registerForm: FormGroup = new FormGroup({
    companyName: new FormControl(''),
    companyWebsite: new FormControl(''),
    registrationId: new FormControl(''),
    businesscontactNo: new FormControl(''),
    addressLine: new FormControl(''),
    additionalInfo: new FormControl(''),
    country: new FormControl(''),
    state: new FormControl(''),
    city: new FormControl(''),
    adminFirstName: new FormControl(''),
    adminLastName: new FormControl(''),
    email: new FormControl(''),
    phoneNo: new FormControl('')
  });
  data: any;
  companyData: any;
  allBusinessTypes: any;
  businessType: any;
  countryData: any;
  country: any;
  country_id: any;
  stateData: any;
  cityData: any;
  macAddress: any;
  allCategory: any;
  categoryRes = [];
  companyName: any;
  adminData: any;
  @HostBinding('class.mobile') isMobile = false;
  selectedTemplate: any;

  progressPercentage: number = 0;
  timerInterval: any;
  timerValue: number = 0;

  isBotswana = false;
  countryInitials: any;

  progressTransform: string = 'rotate(0deg)';

  constructor(private route: ActivatedRoute, private router: Router, private fb: FormBuilder, private service: CommonUtilService, private cStorage: ClientSideStorageService,
    private dashboardService: DashboardService,) {
    this.placeHolders = this.placeholders['Registration_page']
    this.detectScreenWidth();
    this.country = JSON.parse(this.cStorage.get("country"));
    this.macAddress = JSON.parse(this.cStorage.get("macAddress"));
    this.adminData = this.service.getAdminData();
    this.adminData = this.adminData.source._value;
    this.data = this.service.getRegistrationData();
    this.route.queryParams.subscribe(params => {
      const id = params['id'];
      this.countryInitials = id !== undefined ? id : 'D';
    });
    // this.route.params.subscribe(params => {
    this.data = this.data.source._value;
    if (this.data) {
      this.companyData = this.data;
     
      // this.service.getCountry(this.country, this.macAddress).subscribe((res: any) => {
      //   this.countryData = res.body?.data;
      //   if (this.countryData) {
      //     this.countryData.forEach((element: any) => {
      //       if (element.country_id.toString() === this.companyData.country) {
      //         this.country = element.country_name;
      //         this.country_id = element.country_id;
      //         this.countrySelectionForState(element.country_id);
      //       }
      //     });
      //   }
      // });
    
    }

    // });
  }

  ngOnInit(): void {
  if(parseInt(this.companyData.country) === 72){
    this.isBotswana = true;
  }
  this.createRegisterForm();
  }

  ngAfterViewInit():void {
    setTimeout(() => {
    if(parseInt(this.companyData.country) === 72 || parseInt(this.companyData.country) === 716){

    }else{
      this.startTimer();
      setTimeout(() => {
        // this.router.navigate(['/login']);

        //To be Changed
        window.open("https://myuniversaldeals.com/", "_blank");
      }, 20000);
    }
  },2000 );
  }

  countrySelectionForState(id: number) {
    this.registerForm.controls["state"].reset();
    this.registerForm.controls["city"].reset();
    this.stateData = [];
    this.cityData = [];
    this.service.getState(id, this.country, this.macAddress).subscribe((res: any) => {
      this.stateData = res.body?.data;
      if (this.stateData) {
        this.stateData.forEach((element: any) => {
          if (element.state_code.toString() === this.companyData.state) {
            this.stateData = element.state_name;
            this.stateSelectionForCity(this.companyData.state);
          }
        });
      }
    });
  }

  stateSelectionForCity(state: any) {
    this.registerForm.controls["city"].reset();
    this.cityData = [];
    const selectedCountryCode = this.country_id
    this.service.getCity(selectedCountryCode, state, this.country, this.macAddress).subscribe((res: any) => {
      this.cityData = res.body?.data;
      if (this.cityData) {
        this.cityData.forEach((element: any) => {
          if (element.zip_id.toString() === this.companyData.city) {
            this.cityData = element.city_name;
          }
        });
      }
      this.createRegisterForm();
    });
  }

  createRegisterForm() {
    this.registerForm = this.fb.group({
      companyName: [this.companyData.marketplace_name],
      companyWebsite: [this.companyData.marketplace_link],
      registrationId: [this.companyData.registration_id],
      businesscontactNo: [this.companyData.business_contact_number],
      addressLine: [this.companyData.addressLine1],
      additionalInfo: [this.companyData.addressLine2],
      country: [this.companyData.country_name],
      state: [this.companyData.state_name],
      city: [this.companyData.city_name],
      adminFirstName: [this.companyData.firstName],
      adminLastName: [this.companyData.lastName],
      email: [this.companyData.email_id],
      phoneNo: [this.companyData.mobile]
    });
    this.companyName = this.companyData.marketplace_name;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.detectScreenWidth();
  }

  detectScreenWidth() {
    this.isMobile = window.innerWidth < 768; // Example breakpoint for mobile
    // this.selectedTemplate = this.isMobile ? this.isMobile : !this.isMobile;
  }

  startTimer(): void {
    this.timerInterval = setInterval(() => {
      // Calculate progress percentage based on elapsed time
      this.progressPercentage += 1;

      // If progress reaches 100, stop the timer
      if (this.progressPercentage >= 100) {
        clearInterval(this.timerInterval);
      }
    }, 200); // Update progress every 100 milliseconds

    this.timerInterval = setInterval(() => {
      this.timerValue += 1;
    }, 1000);
  }

  login(){
    // this.router.navigate(['/login']);
    //To be Changed
    window.open("https://myuniversaldeals.com/", "_self");
  }

  ngOnDestroy(): void {
    clearInterval(this.timerInterval);
  }



}
