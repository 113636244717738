import { Component, Input } from '@angular/core';
import { CommonUtilService } from 'src/app/services/common-util.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-password-rules',
  templateUrl: './password-rules.component.html',
  styleUrl: './password-rules.component.scss'
})
export class PasswordRulesComponent {
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders:any;
  @Input() passwordValue:any;

  validationRules: { rule: string; isMet: boolean }[];

  constructor( private service: CommonUtilService) {
    this.placeHolders = this.placeholders['Registration_page']
    this.validationRules = [
      { rule: this.placeHolders['LBL_PWD_RULE_8_CHARS'], isMet: false },
      { rule: this.placeHolders['LBL_PWD_RULE_UPPER_LOWER_CASE'], isMet: false },
      { rule: this.placeHolders['LBL_PWD_RULE_NUMERICAL_ONE'], isMet: false },
      { rule: this.placeHolders['LBL_PWD_RULE_SPECIAL_CHAR'], isMet: false }
    ];
    this.service.value$.subscribe(value => {
      this.passwordValue = value;
      this.validatePassword();
    });
   }

  validatePassword(): void {
    // Reset all validation rules to false initially
    this.validationRules.forEach(rule => {
      rule.isMet = false;
    });

    // Check each validation rule and update its status
    if (this.passwordValue?.length >= 8 && this.passwordValue?.length <= 30) {
      this.validationRules[0].isMet = true;
    }
    if (/[A-Z]/.test(this.passwordValue) && /[a-z]/.test(this.passwordValue)) {
      this.validationRules[1].isMet = true;
    }
    if (/[#?!@$%^&*-]/.test(this.passwordValue)) {
      this.validationRules[3].isMet = true;
    }
    if (/\d/.test(this.passwordValue)) {
      this.validationRules[2].isMet = true;
    }
  }

}
