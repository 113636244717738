<!-- 
        <div class="column-2"> -->
<form [formGroup]="messageForm">
    <div class="div-26">
        <div class="div-27">
            <div class="div-28">Birthday</div>
            <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e6079146c8ce414420f879c045e84df5272f2ea8df779809fe165383ebfc380f?"
                class="img-13" />
        </div>
        <div class="div-29">
            <!-- <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/5fadafa35acbc1732eb627e38ce08d3c8a802093e5dd77e0c449e5dbcd784a7b?"
                class="img-14" /> -->
            <div class="div-30">
                {{ getFormattedTitle() }}
                <!-- <input type="text" class=""  style="background-color: transparent;" formControlName="title"
                    placeholder="Enter title here"
                    [ngClass]="{ 'is-invalid':  f['title'].errors  && (f['title'].dirty || f['title'].touched ) }"
                    required>
                @if ( f['title'].errors) {
                <div class="invalid-feedback title-error">
                    @if (f['title'].errors['required']) {
                    <div>Title is required</div>
                    }
                    @if (f['title'].errors['minlength']) {
                    <div>Title must be at least 2 characters</div>
                    }
                    @if (f['title'].errors['maxlength']) {
                    <div>Title must not exceed 50 characters</div>
                    }
                    @if (f['title'].errors['pattern']) {
                    <div>Alphanumeric characters (letters and numbers)</div>
                    }
                </div>
                } -->
            </div>
        </div>
        <div class="div-31">
            <div class="div-32">
                <!-- <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ced4c0641f8c00e7a2b39e1ea7fb86d41381badbe2bf418d95e3fbeb4bbe07b4?"
                class="img-15" /> -->
                <label class="">Upcoming birthdays :</label>
                <div class="div-34" *ngIf="userRes?.monthlyUpcomingBirthdayCount">
                    <div class="div-35">{{userRes.monthlyUpcomingBirthdayCount}}</div>
                    <div class="div-36">users</div>
                </div>
            </div>
            <div class="pt-2">
                <label class="asterix">{{placeHolders['LBL_TITLE']}} </label>
            </div>

            <div class="div-52 col-4">
                <input type="text" class="form-control" style="background-color: transparent;" formControlName="title"
                    placeholder="Enter title"
                    [ngClass]="{ 'is-invalid':  f['title'].errors  && (f['title'].dirty || f['title'].touched ) }"
                    required>
                @if ( f['title'].errors) {
                <div class="invalid-feedback">
                    @if (f['title'].errors['required']) {
                    <div>Title is required</div>
                    }
                    @if (f['title'].errors['minlength']) {
                    <div>Title must be at least 2 characters</div>
                    }
                    @if (f['title'].errors['maxlength']) {
                    <div>Title must not exceed 50 characters</div>
                    }
                    @if (f['title'].errors['pattern']) {
                    <div>Alphanumeric characters (letters and numbers)</div>
                    }
                </div>
                }
            </div>
            <div class="div-37">{{placeHolders['LBL_COMPOSE_MESSAGE']}}</div>
            <div class="pt-2" style="display: flex">
                <label class="asterix">{{placeHolders['LBL_MESSAGE']}}
                </label>
                <div class="div-70 pt-1">
                    (Please include terms and condition, if any)
                </div>
                <!-- <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6651221268d26d5f7c2459d3d86471df8c55372891d73df0aac8e167e05b80a?"
                class="img-16" /> -->
            </div>
            <div class="div-40">
                <textarea class="form-control" maxlength="500" row="3" formControlName="message"
                    placeholder="Enter message here"
                    [ngClass]="{ 'is-invalid':  f['message'].errors  && (f['message'].dirty || f['message'].touched ) }"
                    required></textarea>
                @if ( f['message'].errors) {
                <div class="invalid-feedback">
                    @if (f['message'].errors['required']) {
                    <div>Message is required</div>
                    }
                    @if (f['message'].errors['minlength']) {
                    <div>Message must be at least 5 characters</div>
                    }
                    @if (f['message'].errors['maxlength']) {
                    <div>Message must not exceed 500 characters</div>
                    }
                    @if (f['message'].errors['pattern']) {
                    <div>Alphanumeric characters (letters and numbers)</div>
                    }
                </div>
                }
            </div>
            <div class="div-44">Maximum limit 500 characters.</div>
            <div class="div-41">
                <div class="div-42">
                    <div class="div-43">
                        <label class="asterix">{{placeHolders['LBL_DISCOUNT_CODE']}}</label>
                        <!-- <img loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
                        class="img-17" /> -->
                    </div>
                    <div class="div-45">
                        <input type="text" class="form-control" formControlName="discountCode"
                            placeholder="Enter discount code"
                            [ngClass]="{ 'is-invalid':  f['discountCode'].errors  && (f['discountCode'].dirty || f['discountCode'].touched ) }"
                            required>
                        @if ( f['discountCode'].errors) {
                        <div class="invalid-feedback">
                            @if (f['discountCode'].errors['required']) {
                            <div>Discount code is required</div>
                            }
                            @if (f['discountCode'].errors['minlength']) {
                            <div>Discount code must be at least 2 characters</div>
                            }
                            @if (f['discountCode'].errors['maxlength']) {
                            <div>Discount code must not exceed 20 characters</div>
                            }
                            @if (f['discountCode'].errors['pattern']) {
                            <div>Alphanumeric characters (letters and numbers)</div>
                            }
                        </div>
                        }
                    </div>
                </div>
                <div class="div-46">
                    <img loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ba6901714e1f81c95832a7e98cc29f08fbdc7815c5424426d7036f70cd4ba7c?"
                        class="img-18" />
                    <div class="div-47">
                        Birthday message will be sent to consumer’s 30 days
                        <br />
                        before birthday and will be valid for birthday month.
                    </div>
                </div>
            </div>
            <div class="div-48">
                <div class="div-49">
                    <div class="div-50 asterix">
                        <label class="">{{placeHolders['LBL_MSG_TO_BE_PURCHASED']}}</label>
                        <!-- <img loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
                        class="img-19" /> -->
                    </div>
                    <div class="div-52">
                        <input type="number" min="500" class="form-control" (change)="messageCost($event)"
                            formControlName="enterMssgs" placeholder="Enter here" min="500"
                            [ngClass]="{ 'is-invalid':  f['enterMssgs'].errors  && (f['enterMssgs'].dirty || f['enterMssgs'].touched ) }"
                            required>
                        @if ( f['enterMssgs'].errors) {
                        <div class="invalid-feedback">
                            @if (f['enterMssgs'].errors['required']) {
                            <div>No. of message is required</div>
                            }
                            @if (f['enterMssgs'].errors['min']) {
                            <div>No. of message must be minimum 500</div>
                            }
                        </div>
                        }
                    </div>
                </div>
                <div class="div-53">
                    <img loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/4ba6901714e1f81c95832a7e98cc29f08fbdc7815c5424426d7036f70cd4ba7c?"
                        class="img-20" />
                    <div class="div-54">
                        {{placeHolders['LBL_MIN_MSG_TO_PURCHASED']}} :
                        <span>
                            500
                        </span>
                    </div>
                </div>
            </div>
            <div class="div-55">
                <div class="div-56">
                    <div class="div-57">
                        <!-- <img loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/ced4c0641f8c00e7a2b39e1ea7fb86d41381badbe2bf418d95e3fbeb4bbe07b4?"
                        class="img-21" /> -->
                        <label class="">{{placeHolders['LBL_COST_PER_MSG']}}</label>
                    </div>
                    <div class="div-59" style="display: flex;">{{this.clickCost?.currency}}
                        <input type="number" class="" formControlName="costsPerMssg"
                            placeholder="Enter costs per messages" [readonly]="true" required>
                        <!-- <input type="text" class="" formControlName="costsPerMssg" [readonly]=true required> -->
                        <!-- <input type="number" class="" formControlName="costsPerMssg" [readonly]=true
                            placeholder="Enter Costs Per Messages"
                            [ngClass]="{ 'is-invalid':  f['costsPerMssg'].errors  && (f['costsPerMssg'].dirty || f['costsPerMssg'].touched ) }"
                            required>
                        @if ( f['costsPerMssg'].errors) {
                        <div class="invalid-feedback">
                            @if (f['costsPerMssg'].errors['required']) {
                            <div>Costs Per Message is required</div>
                            }
                        </div>
                        } -->
                    </div>
                </div>
                <div class="div-60">
                    <div class="div-61">
                        <label class="">{{placeHolders['LBL_TOTAL_COST']}}</label>
                    </div>
                    <div class="div-63">{{this.clickCost?.currency}} {{totalCost}}</div>
                </div>
            </div>
            <div class="div-64">
                <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
                <div class="div-66">
                    <button class="btn div-100" [disabled]="messageForm.invalid" (click)="save()">Save</button>
                    <button class="btn div-100" [disabled]="messageForm.invalid" (click)="submit()">{{placeHolders['LBL_BTN_MAKE_PAYMENT']}}</button>
                </div>
            </div>
        </div>
        <!-- </div> -->
    </div>
</form>