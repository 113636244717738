import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrl: './edit.component.scss'
})
export class EditComponent implements ICellRendererAngularComp {
  params: any;

  constructor() {
  }

  refresh(): boolean {
    return true;
  }

  agInit(params: any) {
    this.params = params;
  }

  edit(e:any) {
    return this.params.clicked(this.params.data);
  }
}
