// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const appcodes = {
    alignment: "spm-alignment-web",
    master: "spm-master",
  };
  
  export const appUrl = "https://azure-dev.ship2myid.com/"
  export const authUrl = "https://azure-dev.ship2myid.com/"
  export const assetsUrl = "https://pub-8d95bb7cf6ad47248bdd3f310298ddd9.r2.dev"
  export const strapiUrl = "https://layout-azuredev.ship2myid.com/api"
  
  export const environment = {
    production: false,
    placeholders: {},
    environmentName: "(DEV)",
    alignmentUrl: `${appUrl}${appcodes.alignment}`,
    commonUrl: `${appUrl}`,
    authUrl: `${authUrl}`,
    assetsUrl:`${assetsUrl}`,
    strapiUrl:`${strapiUrl}`,
    defaultLanguage: "en-US",
    supportedLanguages: ["en-US", "fr-FR"],

    baseUrl: "https://azure-appadmin.ship2myid.com/",
    awsregion: "us-east-1",
    uploadMaxFilesLimit: 20,
    uploadMaxParallelProcessingFilesLimit: 10,
    applicationTimeOut: 1000000000000, //15 min 1680 , // 28 min //180 3min
    apiRequestTimeOut: 1800000, // 30 mins in milliseconds
    apiRefreshToken: 1400000, // 23 mins in milliseconds
    dashboardComingSoonOverlay: false,
    passPhrase: "secret",
    reportUrl: "https://azure-dev.ship2myid.com/report-service/"
  };
  
