<!-- <div class="div">
    <div class="div-6">
      <div class="div-7"> -->
<!-- <div class="column-2"> -->
<!-- <div class="div-25"> -->
<div class="div-26">
  <div class="div-27">User Management</div>
  <div class="div-28">
    <button class="div-29" (click)="openDialog()">Add user</button>
    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/5714d7c1-7441-41f7-8ebf-7a9ded564b48?"
      class="img-13" />
  </div>
</div>
<div class="div-30">
  <div class="div-31">All users</div>
  <div class="div-32">
    <div class="div-33">
      <div class="div-34">
        <div class="div-35">
          <input type="search" autocomplete="off" maxlength="20" class="div-61"
            style="border:none; background-color: transparent ;" name="focus" (click)="searchClick($event)"
            (keyup)="quickSearch($event)" placeholder="  Search" />
        </div>
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/b13e6113-2bf9-4301-aa9f-797746927d14?"
          class="img-14" />
      </div>
    </div>
    <!-- <div class="div-36">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/c59a50a4-c1fd-4925-b5ba-964bd2510454?"
                    class="img-15"
                  />
                </div> -->
  </div>
</div>
<div class="pt-2 " *ngIf="userManagementData?.length!==0">
  <ag-grid-angular style="width: 100%; height: 100vh" class="ag-theme-quartz" [rowData]="userManagementData"
    [gridOptions]="gridOptions" [suppressDragLeaveHidesColumns]="true" [columnDefs]="columnDefs" [animateRows]="true" [pagination]="false"
    (gridReady)="onGridReady($event)">
  </ag-grid-angular>

  <div class="row mt-2">
    <!-- <div class="col-5 ml-auto mr-auto">
      
    </div> -->
    <div class="col-lg-12 col-md-12">
      <!-- <div class="float-right"> -->
        <app-pagination [totalRecords]="userManagementData?.length" [recordsPerPage]="10"
        [activePage]="activePage" (onPageChange)="displayActivePage($event)"></app-pagination>
      <!-- </div> -->
    </div>
  </div>

  <!-- <div class="div-38">
    <div class="div-39"></div>
  </div>
  <div class="div-40">Name</div>
  <div class="div-41">Email</div>
  <div class="div-42">Phone Number</div>
  <div class="div-43">Added on</div>
  <div class="div-44">Role Type</div>
  <div class="div-45">Status</div>
  <div class="div-46">Action</div> -->
</div>

<div class="div-Norecord" *ngIf="userManagementData.length===0">
  <div class="div-Norecord2">
    <div class="div-Norecord3">No Record Found !!</div>
  </div>
</div>


<!-- <div class="div-47">
  <div class="div-48">
    <div class="div-49"></div>
  </div>
  <div class="div-50">William Jones</div>
  <div class="div-51">w.jones$$gmail.com</div>
  <div class="div-52">+91 9889889889</div>
  <div class="div-53">11/03/2023</div>
  <div class="div-54">Administrator</div>
  <div class="div-55">
    <div class="div-56">
      <div class="div-57">Active</div>
      <div class="div-58"></div>
    </div>
  </div>
  <div class="div-59">
    <div class="div-60">Edit</div>
    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/567bd4dd-2799-47b6-976f-c9a71011b766?"
      class="img-16" />
  </div>
</div>
<div class="div-61">
  <div class="div-62">
    <div class="div-63"></div>
  </div>
  <div class="div-64">William Jones</div>
  <div class="div-65">w.jones$$gmail.com</div>
  <div class="div-66">+91 9889889889</div>
  <div class="div-67">11/03/2023</div>
  <div class="div-68">Administrator</div>
  <div class="div-69">
    <div class="div-70">
      <div class="div-71">Active</div>
      <div class="div-72"></div>
    </div>
  </div>
  <div class="div-73">
    <div class="div-74">Edit</div>
    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/85b56710-6545-48ae-a84a-5e060a7a3f44?"
      class="img-17" />
  </div>
</div>
<div class="div-75">
  <div class="div-76">
    <div class="div-77"></div>
  </div>
  <div class="div-78">William Jones</div>
  <div class="div-79">w.jones$$gmail.com</div>
  <div class="div-80">+91 9889889889</div>
  <div class="div-81">11/03/2023</div>
  <div class="div-82">Administrator</div>
  <div class="div-83">
    <div class="div-84">
      <div class="div-85">Active</div>
      <div class="div-86"></div>
    </div>
  </div>
  <div class="div-87">
    <div class="div-88">Edit</div>
    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/4b6b58c2-2bb1-42f1-8c3c-f6fc1a0cc161?"
      class="img-18" />
  </div>
</div>
<div class="div-89">
  <div class="div-90">
    <div class="div-91"></div>
  </div>
  <div class="div-92">William Jones</div>
  <div class="div-93">w.jones$$gmail.com</div>
  <div class="div-94">+91 9889889889</div>
  <div class="div-95">11/03/2023</div>
  <div class="div-96">Administrator</div>
  <div class="div-97">
    <div class="div-98">
      <div class="div-99">Active</div>
      <div class="div-100"></div>
    </div>
  </div>
  <div class="div-101">
    <div class="div-102">Edit</div>
    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/5334cb8c-a75c-4c75-af6c-24e879902ad4?"
      class="img-19" />
  </div>
</div>
<div class="div-103">
  <div class="div-104">
    <div class="div-105"></div>
  </div>
  <div class="div-106">William Jones</div>
  <div class="div-107">w.jones$$gmail.com</div>
  <div class="div-108">+91 9889889889</div>
  <div class="div-109">11/03/2023</div>
  <div class="div-110">Administrator</div>
  <div class="div-111">
    <div class="div-112">
      <div class="div-113">Active</div>
      <div class="div-114"></div>
    </div>
  </div>
  <div class="div-115">
    <div class="div-116">Edit</div>
    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/498e3888-e7ae-49d3-9d36-eec77b8976f6?"
      class="img-20" />
  </div>
</div>
<div class="div-117">
  <div class="div-118">
    <div class="div-119"></div>
  </div>
  <div class="div-120">William Jones</div>
  <div class="div-121">w.jones$$gmail.com</div>
  <div class="div-122">+91 9889889889</div>
  <div class="div-123">11/03/2023</div>
  <div class="div-124">Administrator</div>
  <div class="div-125">
    <div class="div-126">
      <div class="div-127"></div>
      <div class="div-128">Inactive</div>
    </div>
  </div>
  <div class="div-129">
    <div class="div-130">Edit</div>
    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/3da226a4-bb1e-4356-93c1-6e19c6dcf4e9?"
      class="img-21" />
  </div>
</div>
<div class="div-131">
  <div class="div-132">
    <div class="div-133"></div>
  </div>
  <div class="div-134">William Jones</div>
  <div class="div-135">w.jones$$gmail.com</div>
  <div class="div-136">+91 9889889889</div>
  <div class="div-137">11/03/2023</div>
  <div class="div-138">Administrator</div>
  <div class="div-139">
    <div class="div-140">
      <div class="div-141"></div>
      <div class="div-142">Inactive</div>
    </div>
  </div>
  <div class="div-143">
    <div class="div-144">Edit</div>
    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/8c6ad3cf-73b7-4be4-a8d8-d40c24c84581?"
      class="img-22" />
  </div>
</div>
<div class="div-145">
  <div class="div-146">
    <div class="div-147"></div>
  </div>
  <div class="div-148">William Jones</div>
  <div class="div-149">w.jones$$gmail.com</div>
  <div class="div-150">+91 9889889889</div>
  <div class="div-151">11/03/2023</div>
  <div class="div-152">Administrator</div>
  <div class="div-153">
    <div class="div-154">
      <div class="div-155"></div>
      <div class="div-156">Inactive</div>
    </div>
  </div>
  <div class="div-157">
    <div class="div-158">Edit</div>
    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/6d1944a8-17a7-4253-9dc4-6f6da15cd63e?"
      class="img-23" />
  </div>
</div>
<div class="div-159">
  <div class="div-160">
    <div class="div-161"></div>
  </div>
  <div class="div-162">William Jones</div>
  <div class="div-163">w.jones</div>
  <div class="div-164">+91 9889889889</div>
  <div class="div-165">11/03/2023</div>
  <div class="div-166">Administrator</div>
  <div class="div-167">
    <div class="div-168">
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/4db8d7b6-0d24-4d31-95fa-5e0026772396?"
        class="img-24" />
      <div class="div-169">Deleted</div>
    </div>
  </div>
  <div class="div-170">
    <div class="div-171">Edit</div>
    <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a9ed1482-c143-4122-ad6c-c5ffe9328b86?"
      class="img-25" />
  </div>
</div> -->

<!-- pagination -->
<!-- <div class="div-172">
  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/633617d2-161e-4068-9fa8-b217b5d661c0?"
    class="img-26" />
  <div class="div-173">1</div>
  <div class="div-174">2</div>
  <div class="div-175">...</div>
  <div class="div-176">9</div>
  <div class="div-177">10</div>
  <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/e4ced11f-6aa3-4c6b-9fa8-74067131973d?"
    class="img-27" />
</div> -->


<!-- </div> -->
<!-- </div> -->
<!-- </div>
    </div>
  </div> -->