import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectUsersFilterComponent } from '../select-users-filter/select-users-filter.component';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { Router } from '@angular/router';
import { CommonUtilService } from '../../../../services/common-util.service';
import { ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { HardcodedData } from 'src/app/views/hardcoded-data';
import { CheckboxComponent } from 'src/app/views/sharedComponent/checkbox/checkbox.component';
import { MessagingService } from '../../../../services/messaging.service';

@Component({
  selector: 'app-select-users-popup',
  templateUrl: './select-users-popup.component.html',
  styleUrl: './select-users-popup.component.scss'
})
export class SelectUsersPopupComponent {
  gridOptions = <GridOptions>{};
  gridApi: any;
  gridColumnApi: any;
  frameworkComponents: any;
  columnDefs: any;
  header: any;
  id: any;
  defaultColDef = {};
  paginationPageSize: any;
  currentPage: any;
  isPageChanged: any;
  types = [10, 20, 30];
  paramsSelectRecord = {
    type: "gridReady",
    api: GridApi,
    columnApi: ColumnApi,
  };
  minRowHeight = 25;
  data: any = [];
  loanFilter: any;
  userData = new HardcodedData();
  selectedUser:any=[];

  constructor(public dialog: MatDialog, private router: Router, private commonUtilService: CommonUtilService,
    private confirmationDialogService: ConfirmationDialogService, private mssgService: MessagingService) {
    // this.selectedUser = null;
    this.defaultColDef = this.commonUtilService.getDefaultColumnDef();
    this.gridOptions.defaultColDef = this.defaultColDef;
    this.createColumnDefs();
    this.gridOptions.columnDefs = this.columnDefs;
    this.gridOptions.rowHeight = 70;
  }

  onGridReady(params: any, value?: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (value && value !== null && value !== undefined) {
      this.paginationPageSize = value;
      this.ngOnInit();
    } else {
      this.paginationPageSize = 10;
    }
  }

  ngOnInit(): void {
    this.data = this.userData.selectUserData;
    this.defaultColDef = {
      editable: true,
      sortable: true,
      minWidth: 100,
      filter: true,
      resizable: false,
      tooltip: false,
      width: 170,
    };
  }

  quickSearch(e: any) {
    // if (e.target.value.length >= 3 || e.target.value.length === 0) {
    this.gridApi.setQuickFilter(e.target.value);
    // }
  }

  searchClick(event: any) {
    this.gridApi.setQuickFilter(event.target.value);
  }

  createColumnDefs() {
    this.columnDefs = [];
    let header: any;

    header = this.commonUtilService.getColumnHeader(
      "",
      "Action"
    );
    header.cellRenderer = CheckboxComponent;
    header.cellRendererParams = {
      clicked: (field: any,checked:any) => {
        if(checked===true) {
          this.selectedUser.push(field);
        } else {
          var index = this.selectedUser.findIndex(function(o:any){
            return o.userId === field.userId;
          })
          if (index !== -1) this.selectedUser.splice(index, 1);
        }
        let a = this.selectedUser;
      }
    },
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "userId",
      "User ID"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filterParams.suppressAndOrCondition = true;
    header.minWidth = 250;
    // header.wrapText= true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "age",
      "Age"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 70;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "location",
      "Geo Location"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 150;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  filterScreen() {
    this.dialog.open(SelectUsersFilterComponent, {
      // height: '460px',
      width: '500px',
      position: {
        top: '12vh',
        left: '30vw'
      },
      autoFocus: false,
      backdropClass: 'custom-dialog-overlay',  // mat-dialog css class
      disableClose: true  // If you click outside the mat-dialog box window, it will not close. 
    });
  }

  cancel() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to cancel this operation? ")
      .then((confirmed) => {
        if (confirmed) {
          this.dialog.closeAll();
        }
      });
  }

  save() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to save this operation? ")
      .then((confirmed) => {
        if (confirmed) {
          let data = {
            selectedUser: this.selectedUser,
            edited:true
          }
          this.mssgService.emit(data); 
          this.dialog.closeAll();
        }
      });
  }
}
