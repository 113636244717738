import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddinviteuserComponent } from './addinviteuser/addinviteuser.component';
import { ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { EditComponent } from '../sharedComponent/edit/edit.component';
import { SwitchToggleComponent } from '../sharedComponent/switch-toggle/switch-toggle.component';
import { CommonUtilService } from '../../services/common-util.service';
import { UserManagementService } from '../../services/user-management.service';
import * as moment from "moment";
import { HardcodedData } from '../hardcoded-data';

export enum Role {
  ADMINISTRATOR = 1,
  MARKETING = 2,
  USER = 3,
  POSTADMIN = 4,
  REPORTING = 5,
  BILLING = 6
}

// Role mapping
export const RoleMapping = {
  1: 'ADMINISTRATOR',
  2: 'MARKETING',
  3: 'USER',
  4: 'POSTADMIN',
  5: 'REPORTING',
  6: 'BILLING'
};

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrl: './user-management.component.scss'
})
export class UserManagementComponent implements OnInit {
  name = 'Angular';
  gridOptions = <GridOptions>{};
  gridApi: any;
  gridColumnApi: any;
  frameworkComponents: any;
  columnDefs: any;
  header: any;
  id: any;
  defaultColDef = {};
  paginationPageSize: any;
  currentPage: any;
  isPageChanged: any;
  types = [10, 20, 30];
  paramsSelectRecord = {
    type: "gridReady",
    api: GridApi,
    columnApi: ColumnApi,
  };
  // minRowHeight = 50;
  data: any = [];
  loanFilter: any;
  userData = new HardcodedData();
  userManagementData: any = [];
  activePage: number = 1;

  constructor(private dialog: MatDialog, private commonUtilService: CommonUtilService, private userService: UserManagementService ) {
    this.getAllUserData();
    this.defaultColDef = this.commonUtilService.getDefaultColumnDef();
    this.gridOptions.defaultColDef = this.defaultColDef;
    this.createColumnDefs();
    this.gridOptions.columnDefs = this.columnDefs;
    this.gridOptions.rowHeight = 70;
  }

  ngOnInit(): void {
    // this.userManagementData = this.userData.UserManagementData;
    this.defaultColDef = {
      editable: true,
      sortable: true,
      minWidth: 100,
      filter: true,
      resizable: false,
      tooltip: false,
      width: 170,
    };
    this.createColumnDefs();
  }

  openDialog() {    
    this.userService.setData(null);
    this.dialog.open(AddinviteuserComponent, {
      backdropClass: 'custom-dialog-overlay',  // mat-dialog css class
      disableClose: true,  // If you click outside the mat-dialog box window, it will not close. 
      height: '500px',
      width: '600px',
      position: {
        top: '12vh',
        left: '30vw'
      },
      autoFocus: false,
    });
    this.userService.getCloseEvent().subscribe((res: any) => {
      this.getAllUserData();
    });
  }

  onGridReady(params: any, value?: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (value && value !== null && value !== undefined) {
      this.paginationPageSize = value;
      this.ngOnInit();
    } else {
      this.paginationPageSize = 10;
    }
  }

  quickSearch(e: any) {
    // if (e.target.value.length >= 3 || e.target.value.length === 0) {
    this.gridApi.setQuickFilter(e.target.value);
    // }
  }

  searchClick(event: any) {
    this.gridApi.setQuickFilter(event.target.value);
  }

  createColumnDefs() {
    this.columnDefs = [];
    let header: any;

    this.frameworkComponents = {
      // toggleComponent: ToggleComponent,
      edit: EditComponent,
      // cellRendererFramework: RouterLinkRendererComponentComponent
    };

    header = this.commonUtilService.getColumnHeader(
      "userName",
      "Username"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filterParams.suppressAndOrCondition = true;
    header.minWidth = 150;
    // header.wrapText= true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "email_id",
      "Email"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 120;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    // header = this.commonUtilService.getColumnHeader(
    //   "phoneNo",
    //   "Phone Number"
    // );
    // header.filterParams = this.commonUtilService.getFilterButtons();
    // header.filter = false;
    // header.minWidth = 100;
    // header.filterParams.suppressAndOrCondition = true;
    // header.filterParams.filterOptions =
    //   this.commonUtilService.getCommaSeparatedFilter();
    // this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "createdDate",
      "Added on"
    );
    // header.pinned = "right";
    header.filter = false;
    header.minWidth = 100;
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);


    header = this.commonUtilService.getColumnHeader(
      "roleId",
      "Role type"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 120;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "status",
      "Status"
    );
    header.cellRenderer = SwitchToggleComponent;
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 30;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    // header = this.commonUtilService.getColumnHeader(
    //   "",
    //   "Action"
    // );
    // // header.cellRenderer = EditComponent;
    // header.cellRendererParams = {
    //   clicked: (field: any) => {
    //     this.userService.setData(field);
    //     // this.editOpenDialog();
    //   }
    // },
    //   header.filterParams = this.commonUtilService.getFilterButtons();
    // header.filter = false;
    // header.filterParams.suppressAndOrCondition = true;
    // header.filterParams.filterOptions =
    //   this.commonUtilService.getCommaSeparatedFilter();
    // this.columnDefs.push(header);
  }

  // editOpenDialog() {    
  //   this.dialog.open(AddinviteuserComponent, {
  //     backdropClass: 'custom-dialog-overlay',  // mat-dialog css class
  //     disableClose: true,  // If you click outside the mat-dialog box window, it will not close. 
  //     height: '500px',
  //     width: '600px',
  //     position: {
  //       top: '12vh',
  //       left: '30vw'
  //     },
  //     autoFocus: false,
  //   });
  // }

  displayActivePage(activePageNumber: number) {
    this.activePage = activePageNumber;
    // this.getTableData('11/18/2022', '11/28/2023', activePageNumber);
  }


  getAllUserData() {
    this.userManagementData = [];
    this.userService.getAllUsers().subscribe((res:any) => {
      // this.userManagementData = res?.data;
      let response = res?.data;
        response.forEach((item: any) => {
          let tableData = item;   
          let lastName ='';      
          if(tableData.lastName!==null) {
             lastName = tableData.lastName
          };
          let userName = tableData.firstName.concat(" " + lastName);
          let parsedDate = moment(tableData.createdDate, "YYYY-MM-DD");
          tableData.createdDate = parsedDate.format("DD/MM/YYYY");
          tableData.userName = userName;
          tableData.roleId = this.getRoleName(item.roleId)
          this.userManagementData.push(tableData);
        });
        
    // this.createColumnDefs();
    });
  }

  getRoleName(roleId: number): string {
    return Role[roleId] || 'Unknown'; // Using Enum
    // return RoleMapping[roleId] || 'Unknown'; // Using Mapping Object
  }


}
