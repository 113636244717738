import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Validation from 'src/app/utils/validation';
import { HardcodedData } from '../../hardcoded-data';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { CommonUtilService } from 'src/app/services/common-util.service';

@Component({
  selector: 'app-administrator-details',
  templateUrl: './administrator-details.component.html',
  styleUrl: './administrator-details.component.scss'
})
export class AdministratorDetailsComponent {
  // companyData = new HardcodedData();
  showCreatePass = false;
  showConfirmPass = false;
  administartiveForm: FormGroup = new FormGroup({
    primaryContact: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    password: new FormControl(''),
    confirmPass: new FormControl(''),
    code: new FormControl('')
  });
  phoneEditable = true;
  updateVisibility: any = false;
  otpVisibility = false;
  country: any;
  macAddress: any;
  otpFieldVisible: any = false;
  updateSubmitVisible: any = true;
  data: any;
  displayTimer: boolean = false;
  resendOtp: boolean = false;
  resendOtpClicked = 1;
  display: any;
  countryData: any;
  updateButtonVisibility:any = true;
  constructor(
    private router: Router, private fb: FormBuilder, private route: ActivatedRoute,
    private confirmationDialogService: ConfirmationDialogService, private service: CommonUtilService,
    private notifyService: NotificationAlertService, private cStorage: ClientSideStorageService) {
    this.country = JSON.parse(this.cStorage.get("country"));
    this.macAddress = JSON.parse(this.cStorage.get("macAddress"));
    this.getUserData();
  }

  getUserData() {
    this.service.getUserProfileDetails().subscribe((res: any) => {
      this.data = res?.data;
      this.createForm(this.data);
    });
  }

  ngOnInit() {
    this.service.getCountry(this.country, this.macAddress).subscribe((res: any) => {
      let countryRes = res.body?.data;
      this.countryData = countryRes.sort((a: any, b: any) => {
        var typeA = a.country_name.toLowerCase(),
          typeB = b.country_name.toLowerCase();
        if (typeA < typeB)
          //sort string ascending
          return -1;
        if (typeA > typeB) return 1;
        return 0; //default return value (no sorting)
      });
    });
  }

  isValidityFormValid(): boolean  {
    const mailControl = this.administartiveForm.get('primaryContact');
    if (mailControl) {
      // Only check validity if the control is dirty or touched
      if (mailControl.dirty || mailControl.touched) {
        mailControl.updateValueAndValidity();
        return mailControl.valid;
      }
    }
    return false;
  }

  createForm(data: any) {
    let fullName = data.firstName.concat(" " + data.lastName);
    this.administartiveForm = this.fb.group({
      primaryContact: [fullName,[Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30)]],
      email: [data.email_id, [Validators.required, Validators.email]],
      phone: [data.mobile, Validators.required],
      personId: [data.personId],
      code: ['',],
      updatedPhone: [''],
      // password: ['', [
      //   Validators.required,
      //   Validators.minLength(6),
      //   Validators.maxLength(40)
      // ]],
      // confirmPass: ['', Validators.required],
      otp: ['']
    },
      {
        validators: [Validation.match('password', 'confirmPass')]
      }
    );

    if (this.f['primaryContact'].value) {
      this.f['email'].disable();
      this.f['phone'].disable();
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.administartiveForm.controls;
  }

  reset() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to Reset? ")
      .then((confirmed) => {
        if (confirmed) {
          this.administartiveForm.reset();
        }
      });
  }

  save() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to save? ")
      .then((confirmed) => {
        if (confirmed) {
          this.proceed();
        }
      });
  }

  proceed() {
    const fullName = this.administartiveForm.value.primaryContact.split(' ');
    const lastName = fullName.pop(); // 'Panakkal'
    const firstName = fullName.join(' ')
    let payload = {
      "firstName": firstName,
      "lastName": lastName,
      "personId": this.administartiveForm.value.personId,
      "mobile": this.administartiveForm.controls['phone'].value.toString()
    }
    this.service.adminDetailsUpdate(payload).subscribe((res: any) => {
      this.notifyService.showSuccess(
        "Saved successfully !!",
        "Success"
      );
      this.updateVisibility = false;
      this.updateButtonVisibility = true;
      // this.router.navigate(["/dashboard"]);
    });
  }

  togglePasswordVisibility() {
    this.showCreatePass = !this.showCreatePass;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPass = !this.showConfirmPass;
  }

  editCompanyClick() {
    this.router.navigate(["/companyProfile"]);
  }

  changePass() {
    // this.confirmationDialogService
    //   .confirm("Confirmation ", "Are you sure you want to save this operation? ")
    //   .then((confirmed) => {
    //     if (confirmed) {
    //       this.proceed();
    //       this.router.navigate(["/changePassword"]);
    //     } else {
    this.router.navigate(["/changePassword"]);
    //   }
    // });
  }

  updatePhone() {
    this.phoneEditable = false;
    // this.administartiveForm.controls["phone"].reset();
    this.updateVisibility = true;
    this.updateSubmitVisible = false;
    this.updateButtonVisibility = false;
  }

  verifyPhone() {
    if (this.administartiveForm.controls["updatedPhone"].value !== "" && this.administartiveForm.controls["code"].value !== "") {
      let payload = {
        mobileNumber: this.administartiveForm.value?.code.toString() + this.administartiveForm.value?.updatedPhone.toString()
      }
      this.service.sendMobileOtp(payload, this.country, this.macAddress).subscribe((res: any) => {
        let value = res;
        if (res && res?.body?.status === "ERROR" || res?.body?.status === "Error") {
          this.notifyService.showError(
            res?.body?.message,
            res?.body?.status
          );
        } else {
          this.phoneEditable = true;
          this.otpFieldVisible = true
          this.updateVisibility = true;
          this.start(3);
        }
      });
    } else {
      this.notifyService.showError(
        "Enter Mobile Number!!",
        "Invalid"
      );
    }
  }

  start(minute: any) {
    this.displayTimer = true;
    this.resendOtp = false;
    // let minute = 1;
    let seconds = minute * 60;
    let textSec: any = '0';
    let statSec = 60;
    const prefix = minute < 10 ? '0' : '';
    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      // if (statSec < 10) textSec = "0" + statSec;
      // textSec = statSec;

      if (statSec < 10) {
        // console.log('inside', statSec);
        textSec = '0' + statSec;
      } else {
        // console.log('else', statSec);
        textSec = statSec;
      }

      // this.display = prefix + Math.floor(seconds / 60) + ":" + textSec;
      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        // console.log('finished');
        clearInterval(timer);
        this.resendOtp = true;
        this.displayTimer = false;
      }
    }, 1000);
  }

  submitOtp() {
    let status: any = this.administartiveForm.controls['otp'].status;
    if (status !== 'INVALID') {

      let mobileNumber = this.administartiveForm.value?.code.toString() + this.administartiveForm.value?.updatedPhone.toString()
      let payload = {
        "phoneNumber": mobileNumber,
        "type": "mobile",
        "validationCode": this.administartiveForm.value.otp
      }
      this.service.ValidateOtp(payload, this.country, this.macAddress).subscribe((res: any) => {
        if (res.body?.status === 'Error') {
          this.notifyService.showError(
            res.body?.message,
            res.body?.status
          );
        } else {
          this.updateSubmitVisible = true;
          this.otpFieldVisible = false;
          this.notifyService.showSuccess(
            "Mobile Number Verified !!",
            "Success"
          );
          // mobileNumber = mobileNumber * 1;
          // this.administartiveForm.get('phone')?.setValue(mobileNumber);
          const fullName = this.administartiveForm.value.primaryContact.split(' ');
          const lastName = fullName.pop(); // 'Panakkal'
          const firstName = fullName.join(' ')
          let payload = {
            "firstName": firstName,
            "lastName": lastName,
            "personId": this.administartiveForm.value.personId,
            "mobile": mobileNumber
          }
          this.service.adminDetailsUpdate(payload).subscribe((res: any) => {
            this.notifyService.showSuccess(
              "Saved successfully !!",
              "Success"
            );
            this.phoneEditable = true;
            this.administartiveForm.controls["updatedPhone"].reset();
            this.administartiveForm.controls["code"].reset();
            this.updateVisibility = false;
            this.getUserData()
            this.updateButtonVisibility = true;
          });
          // this.verifyPhoneField = true;
          // this.disablePhone = true;
          // this.phoneVerified = true;
        }
      });
    } else {
      this.notifyService.showError(
        "Enter OTP !!",
        "Error"
      );
    }
  }

  onOtpChange(event: any) {
    let res = event;
    this.administartiveForm.value.otp = res;
  }

  resendOTP() {
    let clickedCOunter = this.resendOtpClicked++;
    if (clickedCOunter <= 2) {
      this.verifyPhone();
    } else {
      this.notifyService.showError("OTP resend limit exceeded !", "Error")
    }
  }

  cancelUpdatePhone() {
    this.phoneEditable = true;
    this.administartiveForm.controls["updatedPhone"].reset();
    this.administartiveForm.controls["code"].reset();
    this.updateVisibility = false;
    this.updateButtonVisibility = true;
    this.otpFieldVisible = false;
    // this.updateSubmitVisible = false;
  }

}
