import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClientSideStorageService } from './client-side-storage.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {
  closeEvent: EventEmitter<any> = new EventEmitter();
  private favoriteMssgData = new BehaviorSubject<any>([]);
  private birthdayMssgData = new BehaviorSubject<any>([]);
  private festiveMssgData = new BehaviorSubject<any>([]);
  userId: any;
  brandId: any;

  constructor(private http: HttpClient, private cStorage: ClientSideStorageService) {
    let marketplaceData = JSON.parse(this.cStorage.get("marketplaceData"));    
    this.userId = JSON.parse(this.cStorage.get("userId")); 
    this.brandId = marketplaceData.marketplace_id;
  }

  emit(data: any) {
    this.closeEvent.emit(data);
  }

  getCloseEvent() {
    return this.closeEvent;
  }

  setData(param: any): void {
    this.favoriteMssgData.next(param);
  }

  getData(): Observable<any> {
    return this.favoriteMssgData.asObservable();
  }

  setBirthdayData(param: any): void {
    this.birthdayMssgData.next(param);
  }

  getBirthdayData(): Observable<any> {
    return this.birthdayMssgData.asObservable();
  }

  setFestiveData(param: any): void {
    this.festiveMssgData.next(param);
  }

  getFestiveData(): Observable<any> {
    return this.festiveMssgData.asObservable();
  }

  getBirthdayMessage(pageNo:any): Observable<any> {
    const url = environment.authUrl+'orchestration-service/admin/brand/birthday/message?brandId=' + this.brandId + '&pageNo='+ pageNo + '&pageSize=10';
    return this.http.get(url, { observe: 'response' });
  }

  addBirthdayMessage(data: any): Observable<any> {
    let url = environment.authUrl+'orchestration-service/admin/deal/birthday/add';

    return this.http.post(url, data, { observe: 'response' });
  }

  updateBirthdayMessage(data: any): Observable<any> {
    let url = environment.authUrl+'orchestration-service/admin/deal/birthday/update';

    return this.http.post(url, data, { observe: 'response' });
  }


  // getRegisterCompanyDetails () {
  //   this.userId = JSON.parse(this.cStorage.get("userId")); 
  //   const url = 'https://dev.ship2myid.com/orchestration-service/admin/user/marketplace/' + this.userId;
  //   return this.http.get(url);
  // }

  getFestiveMessage(pageNo:any): Observable<any> {
    const url = environment.authUrl+'orchestration-service/admin/brand/festive/message?brandId=' + this.brandId + '&pageNo='+ pageNo + '&pageSize=10';
    return this.http.get(url, { observe: 'response' });
  }

  addFestiveMessage(data: any): Observable<any> {
    let url = environment.authUrl+'orchestration-service/admin/deal/festive/add';

    return this.http.post(url, data, { observe: 'response' });
  }

  updateFestiveMessage(data: any): Observable<any> {
    let url = environment.authUrl+'orchestration-service/admin/deal/festive/update';

    return this.http.post(url, data, { observe: 'response' });
  }

  getFavAllList(pageNo:any,search:any): Observable<any> {
    let payload = {
      "keywords": []
    }
    const url = environment.authUrl+'orchestration-service/admin/deal/favorites?userId='+ this.userId +'&brandId=' + this.brandId + '&pageNo=' + pageNo  + '&pageSize=10&status=' + '' + '&search='+ search ;
    return this.http.post(url, payload, { observe: 'response' })
  }

  getFavAllListwithFilter(pageNo:any, status:any, keywords:any, fromDate:any, toDate:any): Observable<any> {
    let url:any ;
    let payload = {
      keywords: []
    }
    if (keywords?.length === 0) {
      payload.keywords = [];
    } else {
      payload.keywords = keywords;
    }

    if(fromDate !== null && toDate !== null ) {
      url = environment.authUrl+'orchestration-service/admin/deal/favorites?userId='+ this.userId +'&brandId=' + this.brandId + '&pageNo=' + pageNo  + '&pageSize=10&status=' + status + '&from='+ fromDate +'&to='+ toDate ;
    } 
    else {
      url = environment.authUrl+'orchestration-service/admin/deal/favorites?userId='+ this.userId +'&brandId=' + this.brandId + '&pageNo=' + pageNo  + '&pageSize=10&status=' + status 
    }
    return this.http.post(url, payload, { observe: 'response' })
  }

  addFavMessage(data: any): Observable<any> {
    let url = environment.authUrl+'orchestration-service/admin/deal/favorite-message/add';

    return this.http.post(url, data, { observe: 'response' });
  }

  getFavMessage(): Observable<any> {
    const url = environment.authUrl+'orchestration-service/admin/brand/favorite/message?brandId=' + this.brandId + '&pageNo=1&pageSize=10';
    return this.http.get(url, { observe: 'response' });
  }
  
  getUpcomingBirthday(): Observable<any> {
    const url = environment.authUrl+'orchestration-service/admin/user/upcoming/birthday/' + this.brandId ;
    return this.http.get(url, { observe: 'response' });
  }

  getFestiveUser(): Observable<any> {
    const url = environment.authUrl+'orchestration-service/admin/deal/festive?userId='+ this.userId +'&brandId=' + this.brandId ;
    return this.http.get(url, { observe: 'response' });
  }
}
