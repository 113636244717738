import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClientSideStorageService } from './client-side-storage.service';
import { Params } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  private reportData = new BehaviorSubject<any>([]);
  userId: any;
  closeEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private cStorage: ClientSideStorageService) {
    this.userId = JSON.parse(this.cStorage.get("userId"));
  }

  setData(param: any): void {
    this.reportData.next(param);
  }

  getData(): Observable<any> {
    return this.reportData.asObservable();
  }


  getDealEventCounts(): Observable<any> {
    const url = environment.reportUrl + `/report/getDealEventCounts?startDate=2024-06-01&endDate=2024-06-30&brandId=5750`;
    return this.http.get(url, { observe: 'response' });
  };

  getDealsCounts(fromDate: string, toDate: string): Observable<any> {
    const url = `${environment.reportUrl}report/getdealeventcounts?startDate=${fromDate}&endDate=${toDate}`;
    return this.http.get(url, { observe: 'response' });
  }

  getAllReportData(page: number, pageSize: number, status: any, category: any): Observable<any> {

    let url = environment.reportUrl + `report/getdealreport?pageNo=${page}&pageSize=${pageSize}&status=${status}&category=${category}`;
    return this.http.get(url, { observe: 'response' });
  };

  // getAllReportDataFilter(page: number, pageSize: number, status: any, category: any): Observable<any> {

  //   let url = environment.reportUrl + `report/getdealreport?startDate=2024-01-01&endDate=2024-06-30&pageNo=${page}&pageSize=${pageSize}& status=${status}&category=${category}`;
  //   return this.http.get(url, { observe: 'response' });
  // };

  getReportByID(id: any): Observable<any> {
    let url = environment.reportUrl + 'report/getdealdetailsCount?dealId=' + id;
    return this.http.get(url, { observe: 'response' });
  }



  getDealUserListByEvent(id: number, eventType: string, page: number, pageSize: number): Observable<any> {
    const url = `${environment.reportUrl}report/getdealuserlistbyevent?dealId=${id}&eventType=${eventType}&pageNo=${page}&pageSize=${pageSize}`;
    return this.http.get<any>(url);
  }

  getDealDetails(id: any): Observable<any> {
    return this.http.get<any>(`${environment.reportUrl}report/getdealdetails?dealId=${id}`)
  }

  getDealDetailsChart(id: any): Observable<any> {
    return this.http.get<any>(`${environment.reportUrl}report/getdealdetailscharts?dealId=${id}`)
  }
  getDealDetailsGenderChart(id: any): Observable<any> {
    return this.http.get<any>(`${environment.reportUrl}report/getdealdetailsgenderchart?dealId=${id}`)
  }

  getDealDetailsAgeChart(id: any): Observable<any> {
    return this.http.get<any>(`${environment.reportUrl}report/getdealdetailsagechart?dealId=${id}`)
  }

  emit(data: any) {
    this.closeEvent.emit(data);
  }

  getCloseEvent() {
    return this.closeEvent;
  }
}
