import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ClientSideStorageService } from '../services/client-side-storage.service';

@Injectable({
    providedIn: 'root'
})


export class DataResolver implements Resolve<any> {
    country: any;
    macAddress: any;

    constructor(private http: HttpClient, private cStorage: ClientSideStorageService) {
        // this.macAddress = Math.random();
        // this.cStorage.set('country', JSON.stringify([]));
        // this.cStorage.set('macAddress', JSON.stringify(this.macAddress));
        this.country = JSON.parse(this.cStorage.get("country"));
        this.macAddress = JSON.parse(this.cStorage.get("macAddress"));
    }

    // resolve(): Observable<any> {
    //     const url = environment.authUrl + 'orchestration-service/event/master/countries';
    //     const httpOptions = {
    //         // 'userName': 'satheshjkv@gmail.com',
    //         'macAddress': this.macAddress,
    //         'country': this.country
    //     };
    //     return this.http.get(url, { headers: httpOptions });
    //     // Make your API call here and return the observable
    //     // return this.http.get('https://api.example.com/data');
    // }
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        const { endpoint, service } = route.data;
        // Extract endpoint or service method from route data


        if (endpoint) {
            // Call API endpoint
            return this.fetchData(endpoint);
        } else if (service && typeof service === 'function') {
            // Call service method
            return service();
        } else {
            throw new Error('Invalid resolver configuration. Specify endpoint or service method in route data.');
        }
    }

    private fetchData(endpoint: string): Observable<any> {
        // Implement logic to fetch data from API endpoint
        const httpOptions = {
            // 'userName': 'satheshjkv@gmail.com',
            'macAddress': this.macAddress,
            'country': this.country
        };
        return this.http.get(endpoint, { headers: httpOptions,withCredentials:true });; // Replace with actual API call using HttpClient
    }
}



// import { Injectable } from '@angular/core';
// import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
// import { Observable } from 'rxjs';

// @Injectable({
//   providedIn: 'root'
// })

