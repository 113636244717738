<form [formGroup]="administartiveForm">
    <div class="div-6">Company Profile</div>
    <!-- <div class="div-7">
        Please provide your email address to begin the registration
        process. The email you provide will be used as your login
        username.
        <br />
        Creating an account through this form will assign you as the
        Primary Contact for your company.
    </div> -->
    <div class="div-8">
        <nav>
            <button style="border: none;" (click)="editCompanyClick()" class="button">
                <div class="div-9">
                    <div class="div-10" style="text-align: left;">Business Details</div>
                    <div class="div-11">Brand/ Retailer/ Businesses/ Government/ Others</div>
                </div>
            </button>
        </nav>
        <div class="div-12">
            <div class="div-13">Administrator Details</div>
            <div class="div-14">Administrator’s details of the brand</div>
        </div>
    </div>

    <!-- <div class="div-17">Company Registration</div>
    <div class="div-18">
        Please provide your email address to begin the registration
        process. The email you provide will be used as your login
        username.
        <br />
        Creating an account through this form will assign you as the
        Primary Contact for your company.
    </div>
    <span class="span-10">
        <div class="div-19">Administrator Details</div>
        <div class="div-20">Administrator’s details of the brand</div>
    </span> -->
    <span class="span-11">
        <!-- <span class="span-12">
            <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/1dcb1ef744cae731986e62216d917800a97e7f706c63b43bf5f9cf0db0fea805?"
                class="img-13" />
            <a class="div-21" routerLink="/companyProfile">Back to Company Details</a>
        </span> -->
        <div class="div-22 col-7">
            <div class="div-23">
                <span class="span-13">
                    <label class=" asterix ">Primary contact person</label>
                    <!-- <img loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
                    class="img-14" /> -->
                </span>
                <span class="span-14">
                    <input type="text" class="form-control" formControlName="primaryContact"
                        placeholder="Enter primary contact name" pattern="^[A-Za-z ]+$" maxlength="30" (keydown.space)="$event.preventDefault();"
                        [ngClass]="{ 'is-invalid': f['primaryContact'].errors && (f['primaryContact'].dirty || f['primaryContact'].touched )}"
                        required />
                    @if ( f['primaryContact'].errors ) {
                    <div class="invalid-feedback">
                        @if (f['primaryContact'].errors['required']) {
                        <div>Primary contact name is required</div>
                        }
                        @if (f['primaryContact'].errors['minlength']) {
                        <div>Primary contact name must be at least 2 characters</div>
                        }
                        @if (f['primaryContact'].errors['maxlength']) {
                        <div>Primary contact name must not exceed 30 characters</div>
                        }
                        @if (f['primaryContact'].errors['pattern']) {
                        <div>Only characters allowed</div>
                        }
                    </div>
                    }
                </span>
            </div>
            <!-- <div class="div-25">
            <span class="span-15">
                <div class="div-26">Country</div>
                <img loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
                    class="img-15" />
            </span>
            <span class="span-16">
                <div class="div-27">ITALY</div>
                <img loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/717590e7b5540a948d7067906f8675c9b629ac930b6d7ffdcb0d7579a4380246?"
                    class="img-16" />
            </span>
        </div> -->


            <div class="div-29">
                <span class="span-17">
                    <label class=" asterix">Email</label>
                    <!-- <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
                class="img-17" /> -->
                </span>
                <span class="span-18">
                    <div class="div-31">
                        <input class="form-control" type="text" placeholder="Enter your email" autocomplete="email"
                            formControlName="email" [readonly]="true"
                            [ngClass]="{ 'is-invalid': f['email'].errors && (f['email'].dirty || f['email'].touched ) }"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required />
                        @if ( f['email'].errors) {
                        <div class="invalid-feedback">
                            @if (f['email'].errors['required']) {
                            <div>Email is required</div>
                            }
                            @if (f['email'].errors['pattern']) {
                            <div>Invalid email !</div>
                            }
                        </div>
                        }
                    </div>
                    <!-- <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/042d48b1970056ebc0aefc6a98f2a22627250c74f011516202f3bacde9b9b5a9?"
                class="img-18" /> -->
                </span>
            </div>


        </div>
        <div class="div-28 ">
            <div class="col-3 form-group ml-12" *ngIf="updateVisibility===true">
                <div class="custom-select">
                    <label class="asterix" for="countrySelect">Country Code</label>
                    <select class="mt-2 form-control" formControlName="code" id="code">
                        <option *ngFor="let option of countryData" [value]="option.phone_country_prefix">
                            {{option.phonePrefixWithCountryName}}</option>
                    </select>
                </div>
            </div>

            <div class="div-32">
                <span class="span-19">
                    <label class=" asterix">Contact number</label>
                    <!-- <img loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
                    class="img-19" /> -->
                </span>
                <span class="span-20" *ngIf="updateVisibility===false">
                    <div class="div-34">
                        <input class="form-control" type="text" placeholder="Enter mobile number" formControlName="phone"
                            pattern="^[0-9]{10}$"
                            [ngClass]="{ 'is-invalid': f['phone'].errors && (f['phone'].dirty || f['phone'].touched ) }"
                            required />
                        @if ( f['phone'].errors) {
                        <div class="invalid-feedback">
                            @if (f['phone'].errors['required']) {
                            <div>Phone number is required</div>
                            }
                            @if (f['phone'].errors['pattern']) {
                            <div>Invalid number !</div>
                            }
                            <!-- @if (f['phone'].errors['pattern']) {
                        <div>Invalid Email!!</div>
                        } -->
                        </div>
                        }
                    </div>


                    <!-- <img loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/042d48b1970056ebc0aefc6a98f2a22627250c74f011516202f3bacde9b9b5a9?"
                    class="img-20" /> -->
                </span>


                <span class="span-20" *ngIf="updateVisibility===true">
                    <div class="div-34">
                        <input class="form-control" type="number" placeholder="Enter mobile number"                        
                        onkeydown="if(event.key==='.' ||event.key==='+' || event.key==='e' || event.key==='E'){event.preventDefault();}"
                            formControlName="updatedPhone" pattern="^[0-9]{10}$" onKeyPress="if(this.value.length==10) return false;"
                            [ngClass]="{ 'is-invalid': f['updatedPhone'].errors && (f['updatedPhone'].dirty || f['updatedPhone'].touched ) }"
                             />
                        @if ( f['updatedPhone'].errors) {
                        <div class="invalid-feedback">
                            @if (f['updatedPhone'].errors['required']) {
                            <div>Phone number is required</div>
                            }
                            @if (f['updatedPhone'].errors['pattern']) {
                            <div>Invalid number !</div>
                            }
                        </div>
                        }
                    </div>
                </span>
            </div>

            <span style="
            margin-top: 30px;
        ">
                <button *ngIf="updateVisibility===false" class="btn div-1000" (click)="updatePhone()">
                    Edit<img loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/567bd4dd-2799-47b6-976f-c9a71011b766?"
                        class="px-1" width="20px" /> </button>
                <button *ngIf="updateVisibility===true" class="btn div-1000" (click)="verifyPhone()">Verify </button>
                <button *ngIf="updateVisibility===true" class="mx-1 btn div-1000" (click)="cancelUpdatePhone()">Cancel </button>
            </span>
        </div>

        <div *ngIf="otpFieldVisible" style="display:flex">
            <div class="div-32">
                <span class="span-19">
                    <div class="div-33 "></div>
                </span>
                <span class="span-20">
                    <div class="div-34">
                        <div class="">Enter OTP</div>
                        <ng-otp-input class="otp p-0" (onInputChange)="onOtpChange($event)"
                            [config]="{length:6}"></ng-otp-input>
                    </div>

                    <div class="pt-1 px-4" style="text-align: left;color: gray;">

                        <p *ngIf="displayTimer">Expiring in <span style="color: red;">{{ display }}</span></p>
                        <a *ngIf="resendOtp" style="color:rgb(0, 0, 238);text-decoration: underline;cursor: pointer;"
                            (click)="resendOTP()">Resend
                            OTP</a>

                        <!-- <div>Expiring in <span id="timer">
                          </span>
                        </div> -->
                    </div>

                    <!-- <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/042d48b1970056ebc0aefc6a98f2a22627250c74f011516202f3bacde9b9b5a9?"
                class="img-20" /> -->
                </span>

            </div>

            <div class=" mx-4" style="
            margin-top: 35px;
            ">
                <button class="div-1000" (click)="submitOtp()">Submit </button>
            </div>
        </div>
        <div class="changePass" (click)="changePass()">Change password</div>

        <div class="div-44" *ngIf="updateButtonVisibility===true">
            <!-- <button class="span-24" (click)="reset()">Reset</button> -->
            <button class="btn span-25" [disabled]="!isValidityFormValid()" (click)="save()">Update changes</button>
        </div>
    </span>
</form>