<!-- <div class="div">
    <div class="div-6">
      <div class="div-7"> -->
<!-- <div class="column-2"> -->
<!-- <div class="div-26"> -->
<form [formGroup]="messageForm">
  <div class="div-27">{{placeHolders['LBL_TITLE_MESSAGING']}}</div>
  <div class="div-28">
    <div class="div-29">
      <div class="div-30">{{placeHolders['LBL_TAB_FAVOURITES']}}</div>
      <div class="div-31">
        {{placeHolders['LBL_LIST_DEALS_FAVOURITED']}}
      </div>
    </div>
    <span>
      <a routerLink="/messaging/birthday" class="button" routerLinkActive="active" ariaCurrentWhenActive="page">
        <div class="div-32">
          <div class="div-33">{{placeHolders['LBL_TAB_BIRTHDAY']}}</div>
          <div class="div-34">{{placeHolders['LBL_BIRTHDAY_MESSAGES_FOR']}}</div>
        </div>
      </a>
    </span>
    <span>
      <a routerLink="/messaging/festive" class="button" routerLinkActive="active" ariaCurrentWhenActive="page">

        <div class="div-35">
          <div class="div-36">{{placeHolders['LBL_TAB_FESTIVE']}}</div>
          <div class="div-37">{{placeHolders['LBL_FESTIVAL_MESSAGES_FOR']}}</div>
        </div>
      </a>
    </span>
  </div>
  <div class="div-38">
    <div class="div-39">{{placeHolders['LBL_FAVOUTIRED_DEALS']}}</div>
    <div class="div-40">
      <div class="div-41">
        <div class="div-42">
          <div class="div-43">
            <input type="search" autocomplete="off" maxlength="20" class=""
              style="border:none; background-color: transparent;" name="focus" [(ngModel)]="searchValue"
              (search)="search()" formControlName="search" placeholder=" Search on deal name" />
          </div>
          <img loading="lazy" 
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/960e3587b102d32ae26b055144ae7805805584175a9f8744d348d759c2ad8f5e?"
            class="img-13" (click)="search()" />
        </div>
      </div>
      <div class="div-44">
        <img loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/67410ecd28e542fc77d1ec58dd6b5b5bad9785f1a076cde8b71bcc022f28a5b1?"
          class="img-14" (click)="filterScreen()" />
      </div>
    </div>
  </div>


  <div class="row pb-1" *ngIf="filterDataForChip">
    <span>
      <label *ngIf="filterDataForChip.status!==''">
        <b> Applied filters: </b>
      </label>
      <button (click)="clearAll()" *ngIf="filterDataForChip.status!==''" class="div-filterbtn">
        Clear all x</button>
      <label *ngIf="filterDataForChip.status!==''" class="div-chip">
        <b>Status</b>: {{filterDataForChip.status}} </label>
      <label class="div-chip" *ngIf="filterDataForChip.toDate!==''">
        <b>To date</b>: {{filterDataForChip.toDate}}</label>
      <label class="div-chip" *ngIf="filterDataForChip.fromDate!==''">
        <b>From date</b>: {{filterDataForChip.fromDate}}</label>
      <span *ngIf="filterDataForChip.tag!==[]">
        @for (data of filterDataForChip.tag; track data) {
        <label class="div-chip">{{data}}</label>
        }
      </span>
    </span>
  </div>


  <div class="col-12" *ngIf="data.length!==0">

    <ag-grid-angular style="width: 100%; " class="ag-theme-quartz" [rowData]="data" domLayout='autoHeight'
      [gridOptions]="gridOptions" [suppressDragLeaveHidesColumns]="true" [columnDefs]="columnDefs" [animateRows]="true"
      [pagination]="false" (gridReady)="onGridReady($event)">
    </ag-grid-angular>
   
  </div>
  

  <div class="div-Norecord" *ngIf="data.length==0">
    <div class="div-Norecord2">
      <div class="div-Norecord3">No record found !</div>
    </div>
  </div>

  

  <div class="row mt-2" *ngIf="data.length!==0">
    <!-- <div class="col-5 ml-auto mr-auto">
        
      </div> -->
    <div class="col-lg-12 col-md-12" >
      <!-- <div class="float-right"> -->
      <app-pagination  *ngIf="recordTotalCount!==0" [totalRecords]="recordTotalCount" [recordsPerPage]="10" [activePage]="activePage"
        (onPageChange)="displayActivePage($event)"></app-pagination>
      <!-- </div> -->
    </div>
  </div>
</form>