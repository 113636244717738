import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { LoginService } from 'src/app/services/login.service';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrl: './forget-password.component.scss'
})
export class ForgetPasswordComponent implements OnInit {
  forgotPassForm: FormGroup = new FormGroup({
    email_id: new FormControl(''),
  });
  otpForm!: FormGroup;
  submitted = false;
  resetLink = false;
  display: any;
  resendOtp: boolean = false;
  displayTimer: boolean = false;
  country: any;
  macAddress: any;
  resendOtpClicked = 1;
  @ViewChild('ngOtpInput') ngOtpInputRef:any;
  constructor(private fb: FormBuilder,
    private router: Router, private cStorage: ClientSideStorageService,
    private notifyService: NotificationAlertService, private loginService: LoginService) {
    this.createforgotForm();
  }

  ngOnInit() {
    this.submitted = false;
    this.country = JSON.parse(this.cStorage.get("country"));
    this.macAddress = JSON.parse(this.cStorage.get("macAddress"));
  }

  createforgotForm() {
    this.forgotPassForm = this.fb.group({
      email_id: ['', [Validators.required, Validators.email]],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.forgotPassForm.controls;
  }

  // f() {
  //   return this.forgotPassForm.controls;
  // }

  createOtpForm() {
    this.otpForm = this.fb.group({
      otp: ["", [Validators.required]],
    });
  }

  get o(): { [key: string]: AbstractControl } {
    return this.otpForm.controls;
  }

  /** This Method Submits sends mail with OTP  */
  onSubmit() {
    // this.submitted = true;
    if (this.forgotPassForm.invalid) {
      if (this.forgotPassForm.controls['email_id'].touched !== true) {
        this.notifyService.showError("Email cannot be Empty", "Invalid");
      } else if (this.forgotPassForm.controls['email_id'].invalid) {
        this.notifyService.showError("Invalid Email", "Invalid");
      }
    } else {
      this.sendOtp();
      // let res = this.forgotPassForm.value;
      // this.loginService.sendOtpForForgetPass(res, this.country, this.macAddress).subscribe((res: any) => {
      //   this.notifyService.showSuccess(
      //     "Your Otp has been sent to your Email Id !!",
      //     "Success"
      //   );
      //   this.resetLink = true;
      //   this.createOtpForm();
      //   this.start(1);
      // });
    }
  }

  /** This method validates OTP */
  validateOtp() {
    if (this.otpForm.invalid) {
      this.notifyService.showError("Invalid Fields !!", "Invalid");
    } else if (this.otpForm.value.otp.length !== 6) {
      this.notifyService.showError("Invalid", "Error");
    } else {
      // API not available
      let email_id = this.forgotPassForm.value.email_id;
      let data = {
        token: this.otpForm.value.otp
      }
      this.loginService.validateOtp(data, email_id, this.country, this.macAddress).subscribe((res: any) => {
        if (res.body?.status === "SUCCESS") {
          this.cStorage.set('userName', JSON.stringify(this.forgotPassForm.value.email_id));
          this.cStorage.set('passwordToken', JSON.stringify(res));
          this.notifyService.showSuccess(
            res.body?.message,
            res.body?.status
          );
          this.router.navigate(["./createPassword"]);
        } else if (res.body?.status === "ERROR") {
          this.notifyService.showError(
            res.body?.message,
            res.body?.status
          );
          // clearInterval(this.timer);
          // this.o['otp'].setValue("")
          // this.onOtpChange("")
          this.ngOtpInputRef.setValue("");
          // this.forgotPassForm.reset();
          // this.resetLink = false;
          // this.router.navigate(["./forgetPassword"]);
        }
      });

    }
  }

  sendOtp() {
    let res = this.forgotPassForm.value;
    this.loginService.sendOtpForForgetPass(res, this.country, this.macAddress).subscribe((res: any) => {
      if (res.body?.status !== "ERROR") {
        this.notifyService.showSuccess(
          "OTP has been sent to your Email Id !",
          "Success"
        );
        this.resetLink = true;
        this.createOtpForm();
        this.start(2);
      } else if (res.body?.status === "ERROR") {
        this.notifyService.showError("Invalid user!", res.body?.status);
      }
    }, (error) => {
      this.notifyService.showError("Invalid Email", error);
    }
    );
  }

  resendOTP() {
    let clickedCOunter = this.resendOtpClicked++;
    if (clickedCOunter <= 2) {
      this.sendOtp();
    } else {
      this.notifyService.showError("OTP resend limit exceeded !", "Error")
    }
  }

  timer:any;
  start(minute: any) {
    this.displayTimer = true;
    this.resendOtp = false;
    // let minute = 1;
    let seconds = minute * 60;
    let textSec: any = '0';
    let statSec = 60;
    const prefix = minute < 10 ? '0' : '';
    this.timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      // if (statSec < 10) textSec = "0" + statSec;
      // textSec = statSec;

      if (statSec < 10) {
        // console.log('inside', statSec);
        textSec = '0' + statSec;
      } else {
        // console.log('else', statSec);
        textSec = statSec;
      }

      // this.display = prefix + Math.floor(seconds / 60) + ":" + textSec;
      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        // console.log('finished');
        clearInterval(this.timer);
        this.resendOtp = true;
        this.displayTimer = false;
      }
    }, 1000);
  }

  onOtpChange(event: any) {
    let res = event;
    this.otpForm = this.fb.group({
      otp: [res, [Validators.required]],
    });
  }

}
