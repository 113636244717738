import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessagingService } from 'src/app/services/messaging.service';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { CampaignService } from 'src/app/services/campaign.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-compose-message-festive',
  templateUrl: './compose-message-festive.component.html',
  styleUrl: './compose-message-festive.component.scss'
})
export class ComposeMessageFestiveComponent implements OnInit {
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders: any;
  messageForm!: FormGroup;
  companyData: any;
  editData: any;
  favId: any;
  userData: any;
  clickCost: any;
  presentDate: any;
  afterPresentDate: any;
  userRes: any;
  totalCost: any = 0;

  constructor(private router: Router, private formBuilder: FormBuilder, private service: MessagingService, private cStorage: ClientSideStorageService,
    private confirmationDialogService: ConfirmationDialogService, private notifyService: NotificationAlertService,
    private campaignService: CampaignService,) {
    this.placeHolders = this.placeholders['Messaging_Page']
    this.createForm();
    const curDate = new Date();
    this.presentDate =
      curDate.getFullYear() +
      "-" +
      ("0" + (curDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + curDate.getDate()).slice(-2);
    this.afterPresentDate = curDate.getFullYear() +
      "-" +
      ("0" + (curDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (curDate.getDate() + 1)).slice(-2);
    this.editData = this.service.getFestiveData();
    this.editData = this.editData.source._value;

    this.getCurrentUsers();
  }

  ngOnInit(): void {

    this.campaignService.price().subscribe((res: any) => {
      if (res?.body?.data) {
        res?.body?.data.forEach((item: any) => {
          if (item.event_type === "favorite_dm") {
            this.favId = item.event_id;
          }
        });
      }

      this.userData = JSON.parse(this.cStorage.get("marketplaceData"));
      // this.service.getRegisterCompanyDetails().subscribe((res: any) => {
      if (this.userData) {
        //     let userData = res?.data;
        this.campaignService.eventCostByCountryCosts(this.userData['country_name']).subscribe((response: any) => {
          if (response?.body?.data?.countryEventsCostsList) {
            let event = response?.body?.data?.countryEventsCostsList;
            this.clickCost = event.find((el: any) => el.event_type === 'DEAL_FAVORITE_DM');
            if (this.editData === null) {
              this.createForm();
              this.companyData = JSON.parse(this.cStorage.get("marketplaceData"));
            } else {
              this.editForm(this.editData);
            }
          }
        });
      }
      // });
    });
  }

  getFormattedTitle() {
    return this.messageForm.get('title')?.value || 'Create festive message';
  }

  createForm() {
    this.messageForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      upcomingBirthday: [''],
      message: ['', [Validators.required, Validators.minLength(5),
      Validators.maxLength(500)]], //Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')
      discountCode: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      enterMssgs: ['', Validators.required],
      costsPerMssg: [this.clickCost?.cost, Validators.required],
      totalSpent: [''],
      validFrom: ['', Validators.required],
      validTo: ['', Validators.required],
      currency: [this.clickCost?.currency]
    });
  }

  editForm(data: any) {
    this.totalCost = data.msgToBePurhcased * this.clickCost?.cost;
    // let parsedDate = moment(data.endDate, "YYYY-MM-DD");
    // data.endDate = parsedDate.format("YYYY-MM-DD");

    // let parseDate = moment(data.startDate, "YYYY-MM-DD");
    // data.startDate = parseDate.format("YYYY-MM-DD");
    let available_from = new Date(data?.startDate)
    let parsedDate = moment(available_from, "YYYY-MM-DD");
    data.startDate = parsedDate.format("YYYY-MM-DD");

    let available_to = new Date(data?.endDate)
    let parseDate = moment(available_to, "YYYY-MM-DD");
    data.endDate = parseDate.format("YYYY-MM-DD");

    this.messageForm = this.formBuilder.group({
      marketplaceId: [data.marketplace_id],
      title: [data.title, [Validators.required, Validators.minLength(2),
      Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      upcomingBirthday: [''],
      message: [data.composeMessage, [Validators.required, Validators.minLength(5),
      Validators.maxLength(500)]],
      discountCode: [data.discountCode, [Validators.required, Validators.minLength(2),
      Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      enterMssgs: [data.msgToBePurhcased, Validators.required],
      costsPerMssg: [this.clickCost?.cost, Validators.required],
      totalSpent: [''],
      validFrom: [data.startDate, Validators.required],
      validTo: [data.endDate, Validators.required],
      currency: [this.clickCost?.currency]
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.messageForm.controls;
  }

  getCurrentUsers() {
    this.service.getFestiveUser().subscribe((res: any) => {
      if (res?.body?.data) {
        this.userRes = res?.body?.data;
      }
    });
  }

  cancel() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to cancel? ")
      .then((confirmed) => {
        if (confirmed) {
          this.router.navigate(["/messaging/festive"]);
        }
      });
  }

  save() {
    if (this.editData === null) {
      let values = this.messageForm.value;

      let fromDate = moment(this.messageForm.value.validFrom).hours(moment().hours()).minutes(moment().minutes()).seconds(moment().seconds()).utc();
      let toDate = moment(this.messageForm.value.validTo).hours(moment().hours()).minutes(moment().minutes()).seconds(moment().seconds()).utc();

      values.validFrom = fromDate.format();
      values.validTo = toDate.format();

      let value = {
        "dealType": "2",
        "title": this.messageForm.value.title,
        "marketplace_id": this.companyData.marketplace_id,
        "composeMessage": this.messageForm.value.message,
        "discountCode": this.messageForm.value.discountCode,
        // "budget":1000,
        // "amountSpent":0,
        "msgToBePurhcased": this.messageForm.value.enterMssgs.toString(),
        "paymentMade": false,
        "startDate": this.messageForm.value.validFrom,
        "endDate": this.messageForm.value.validTo,
      }
      this.confirmationDialogService
        .confirm("Confirmation ", "Are you sure you want to save? ")
        .then((confirmed) => {
          if (confirmed) {
            this.service.addFestiveMessage(value).subscribe((res: any) => {
              this.notifyService.showSuccess(
                "Record successfully added !!",
                "Success"
              );
              this.router.navigate(["/messaging/festive"]);
            });
          }
        });
    } else {
      this.confirmationDialogService
        .confirm("Confirmation ", "Are you sure you want to save? ")
        .then((confirmed) => {
          if (confirmed) {
            let values = this.messageForm.value;

            let fromDate = moment(this.messageForm.value.validFrom).hours(moment().hours()).minutes(moment().minutes()).seconds(moment().seconds()).utc();
            let toDate = moment(this.messageForm.value.validTo).hours(moment().hours()).minutes(moment().minutes()).seconds(moment().seconds()).utc();

            values.validFrom = fromDate.format();
            values.validTo = toDate.format();
            let value = {
              "dealId": this.editData.dealId,
              "dealType": "2",
              "title": this.messageForm.value.title,
              "marketplace_id": this.messageForm.value.marketplaceId,
              "composeMessage": this.messageForm.value.message,
              "discountCode": this.messageForm.value.discountCode,
              // "budget":1000,
              // "amountSpent":0,
              "msgToBePurhcased": this.messageForm.value.enterMssgs.toString(),
              "paymentMade": this.editData.paymentMade,
              "startDate": this.messageForm.value.validFrom,
              "endDate": this.messageForm.value.validTo,
            }
            this.service.updateFestiveMessage(value).subscribe((res: any) => {
              this.notifyService.showSuccess(
                "Record successfully updates !!",
                "Success"
              );
              this.router.navigate(["/messaging/festive"]);
            });
          }
        });
    }
  }

  submit() {
    let values = this.messageForm.value;

    let fromDate = moment(this.messageForm.value.validFrom).hours(moment().hours()).minutes(moment().minutes()).seconds(moment().seconds()).utc();
    let toDate = moment(this.messageForm.value.validTo).hours(moment().hours()).minutes(moment().minutes()).seconds(moment().seconds()).utc();

    values.validFrom = fromDate.format();
    values.validTo = toDate.format();

    if (this.editData === null) {
      let value = {
        "dealType": "2",
        "title": this.messageForm.value.title,
        "marketplace_id": this.companyData.marketplace_id,
        "composeMessage": this.messageForm.value.message,
        "discountCode": this.messageForm.value.discountCode,
        // "budget":1000,
        "amountSpent": this.totalCost,
        "msgToBePurhcased": this.messageForm.value.enterMssgs.toString(),
        "paymentMade": true,
        "startDate": this.messageForm.value.validFrom,
        "endDate": this.messageForm.value.validTo,
      }
      this.confirmationDialogService
        .confirm("Confirmation ", "Are you sure you want to save? ")
        .then((confirmed) => {
          if (confirmed) {
            this.service.addFestiveMessage(value).subscribe((res: any) => {
              this.notifyService.showSuccess(
                "Record successfully added !!",
                "Success"
              );
              this.router.navigate(["/messaging/festive"]);
            });
          }
        });
    } else {
      this.confirmationDialogService
        .confirm("Confirmation ", "Are you sure you want to save? ")
        .then((confirmed) => {
          if (confirmed) {
            let values = this.messageForm.value;

            let fromDate = moment(this.messageForm.value.validFrom).hours(moment().hours()).minutes(moment().minutes()).seconds(moment().seconds()).utc();
            let toDate = moment(this.messageForm.value.validTo).hours(moment().hours()).minutes(moment().minutes()).seconds(moment().seconds()).utc();

            values.validFrom = fromDate.format();
            values.validTo = toDate.format();

            let value = {
              "dealId": this.editData.dealId,
              "dealType": "2",
              "title": this.messageForm.value.title,
              "marketplace_id": this.messageForm.value.marketplaceId,
              "composeMessage": this.messageForm.value.message,
              "discountCode": this.messageForm.value.discountCode,
              // "budget":1000,        
              "amountSpent": this.totalCost,
              "msgToBePurhcased": this.messageForm.value.enterMssgs.toString(),
              "paymentMade": true,
              "startDate": this.messageForm.value.validFrom,
              "endDate": this.messageForm.value.validTo,
            }
            this.service.updateFestiveMessage(value).subscribe((res: any) => {
              this.notifyService.showSuccess(
                "Record successfully updates !!",
                "Success"
              );
              this.router.navigate(["/messaging/festive"]);
            });
          }
        });
    }
  }

  messageCost(event: any) {
    let value = 0;
    this.totalCost = event.target.value * this.messageForm.value.costsPerMssg;

  }
}
