import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessagingService } from 'src/app/services/messaging.service';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { CampaignService } from 'src/app/services/campaign.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-compose-message',
  templateUrl: './compose-message.component.html',
  styleUrl: './compose-message.component.scss'
})
export class ComposeMessageComponent implements OnInit {
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders: any;
  messageForm!: FormGroup;
  companyData: any;
  editData: any;
  favId: any;
  userData: any;
  clickCost: any;
  userRes: any;
  totalCost: any = 0;

  constructor(private router: Router, private formBuilder: FormBuilder, private service: MessagingService, private cStorage: ClientSideStorageService,
    private confirmationDialogService: ConfirmationDialogService, private notifyService: NotificationAlertService,
    private campaignService: CampaignService,) {
    this.placeHolders = this.placeholders['Messaging_Page']
    this.createForm();
    this.getUpcomingBirthday();
    this.editData = this.service.getBirthdayData();
    this.editData = this.editData.source._value;

  }

  ngOnInit(): void {
    this.campaignService.price().subscribe((res: any) => {
      if (res?.body?.data) {
        res?.body?.data.forEach((item: any) => {
          if (item.event_type === "favorite_dm") {
            this.favId = item.event_id;
          }
        });
      }

      this.userData = JSON.parse(this.cStorage.get("marketplaceData"));
      // this.service.getRegisterCompanyDetails().subscribe((res: any) => {
      if (this.userData) {
        //     let userData = res?.data;
        this.campaignService.eventCostByCountryCosts(this.userData['country_name']).subscribe((response: any) => {
          if (response?.body?.data?.countryEventsCostsList) {
            let event = response?.body?.data?.countryEventsCostsList;
            this.clickCost = event.find((el: any) => el.event_type === 'DEAL_FAVORITE_DM');
            if (this.editData === null) {
              this.createForm();
              this.companyData = JSON.parse(this.cStorage.get("marketplaceData"));
            } else {
              this.editForm(this.editData);
            }
          }
        });
      }
      // });
    });
  }

  getFormattedTitle() {
    return this.messageForm.get('title')?.value || 'Create birthday message';
  }

  createForm() {
    this.messageForm = this.formBuilder.group({
      title: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      upcomingBirthday: [''],
      message: ['', [Validators.required, Validators.minLength(5),
      Validators.maxLength(500)]],//Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')
      discountCode: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      enterMssgs: ['', Validators.required],
      costsPerMssg: [this.clickCost?.cost],
      totalSpent: [''],
      currency: [this.clickCost?.currency]
    });
  }

  editForm(data: any) {
    this.totalCost = data.msgToBePurhcased * this.clickCost?.cost;
    this.messageForm = this.formBuilder.group({
      marketplaceId: [data.marketplace_id],
      title: [data.title, [Validators.required, Validators.minLength(2),
      Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      upcomingBirthday: [''],
      message: [data.composeMessage, [Validators.required, Validators.minLength(5),
      Validators.maxLength(500)]],
      discountCode: [data.discountCode, [Validators.required, Validators.minLength(2),
      Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      enterMssgs: [data.msgToBePurhcased, Validators.required],
      costsPerMssg: [this.clickCost?.cost, Validators.required],
      totalSpent: [''],
      currency: [this.clickCost?.currency]
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.messageForm.controls;
  }

  cancel() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to cancel? ")
      .then((confirmed) => {
        if (confirmed) {
          this.router.navigate(["/messaging/birthday"]);
        }
      });
  }

  getUpcomingBirthday() {
    this.service.getUpcomingBirthday().subscribe((res: any) => {
      if (res?.body?.data) {
        this.userRes = res?.body?.data;
      }
    });
  }

  save() {
    if (this.editData === null) {
      let value = {
        "dealType": "1",
        "title": this.messageForm.value.title,
        "marketplace_id": this.companyData.marketplace_id,
        "composeMessage": this.messageForm.value.message,
        "discountCode": this.messageForm.value.discountCode,
        // "budget":1000,
        // "amountSpent":0,
        "msgToBePurhcased": this.messageForm.value.enterMssgs.toString(),
        "paymentMade": false
      }
      this.confirmationDialogService
        .confirm("Confirmation ", "Are you sure you want to save? ")
        .then((confirmed) => {
          if (confirmed) {
            this.service.addBirthdayMessage(value).subscribe((res: any) => {
              this.notifyService.showSuccess(
                "Record successfully added !!",
                "Success"
              );
              this.router.navigate(["/messaging/birthday"]);
            });
          }
        });
    } else {
      this.confirmationDialogService
        .confirm("Confirmation ", "Are you sure you want to save ? ")
        .then((confirmed) => {
          if (confirmed) {
            let value = {
              "dealId": this.editData.dealId,
              "title": this.messageForm.value.title,
              "dealType": "1",
              // "marketplace_id": this.companyData.marketplace_id,
              "composeMessage": this.messageForm.value.message,
              "discountCode": this.messageForm.value.discountCode,
              // "budget":1000,
              // "amountSpent":0,
              "msgToBePurhcased": this.messageForm.value.enterMssgs.toString(),
              "paymentMade": this.editData.paymentMade
            }
            this.service.updateBirthdayMessage(value).subscribe((res: any) => {
              this.notifyService.showSuccess(
                "Record successfully updates !!",
                "Success"
              );
              this.router.navigate(["/messaging/birthday"]);
            });
          }
        });
    }
  }

  submit() {
    if (this.editData === null) {
      let value = {
        "dealType": "1",
        "title": this.messageForm.value.title,
        "marketplace_id": this.companyData.marketplace_id,
        "composeMessage": this.messageForm.value.message,
        "discountCode": this.messageForm.value.discountCode,
        // "budget":1000,
        "amountSpent": this.totalCost,
        "msgToBePurhcased": this.messageForm.value.enterMssgs.toString(),
        "paymentMade": true
      }
      this.confirmationDialogService
        .confirm("Confirmation ", "Are you sure you want to save? ")
        .then((confirmed) => {
          if (confirmed) {
            this.service.addBirthdayMessage(value).subscribe((res: any) => {
              this.notifyService.showSuccess(
                "Record successfully added !!",
                "Success"
              );
              this.router.navigate(["/messaging/birthday"]);
            });
          }
        });
    } else {
      this.confirmationDialogService.confirm("Confirmation ", "Are you sure you want to save? ").then((confirmed:any) => {
          if (confirmed) {
            let value = {
              "dealId": this.editData.dealId,
              "dealType": "1",
              "title": this.messageForm.value.title,
              // "marketplace_id": this.companyData.marketplace_id,
              "composeMessage": this.messageForm.value.message,
              "discountCode": this.messageForm.value.discountCode,
              "amountSpent": this.totalCost,
              "msgToBePurhcased": this.messageForm.value.enterMssgs.toString(),
              "paymentMade": true
            }
            this.service.updateBirthdayMessage(value).subscribe((res: any) => {
              this.notifyService.showSuccess(
                "Record successfully updates !!",
                "Success"
              );
              this.router.navigate(["/messaging/birthday"]);
            });
          }
        });
    }
  }

  messageCost(event: any) {
    this.totalCost = event.target.value * this.messageForm.value.costsPerMssg;

  }
}
