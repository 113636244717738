import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from './services/auth.service';
import { ClientSideStorageService } from './services/client-side-storage.service';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router:Router,private authService: AuthService, private cStorage: ClientSideStorageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.authService.getToken();

    if (authToken) {
      request = this.addAuthToken(request, authToken);
    }
    // else{
    //   this.router.navigate(["/login"]);
    // }

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403 && authToken) {
          return this.handleUnauthorizedError(request, next);
        }
        return throwError(error);
      })
    );
  }

  private addAuthToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    // const token = JSON.parse(this.cStorage.get("token"));
    if(!request.withCredentials){
      const refreshToken = JSON.parse(this.cStorage.get("refreshToken"));
      const userName = JSON.parse(this.cStorage.get("userName"));
      const country = JSON.parse(this.cStorage.get("country"));
      const macAddress = JSON.parse(this.cStorage.get("macAddress"));
      const loggedIn = JSON.parse(this.cStorage.get("loggedIn"));
        return request.clone({
          headers:  new HttpHeaders({
            'auth-token':  token,
            'userName': userName,
            'macAddress': macAddress,
            'country': country
          })
         });
    }else{
      return request.clone(request);
    }
    
   
  }

  private handleUnauthorizedError(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.refreshToken().pipe(
      switchMap(() => {
        const newAuthToken = this.authService.getToken();
        if (newAuthToken) {
          request = this.addAuthToken(request, newAuthToken);
          return next.handle(request);
        } else {
          this.authService.logout();
          return throwError('Unable to refresh token.');
        }
      })
    );
  }
}
