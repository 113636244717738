<!-- <div class="div">
  <div class="div-6">
    <div class="div-7">
      <div class="column-2"> -->

<form [formGroup]="selectForm" class="">
  <div class="div-26">
    <div class="div-27">
      <div class="div-28">Favourite</div>
      <img loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/b157c88e56e73617a9ec1527d8af889885fb67bf7e7dd7efe4da19edca1c503c?"
        class="img-13" />
    </div>
    <!-- <div class="div-29">       -->
    <input type="text" class="div-29" style="background-color: transparent;" formControlName="title"
      [readonly]="true" />
    <!-- </div> -->
    <div class="div-30">
      <div class="div-31">
        <!-- <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/ced4c0641f8c00e7a2b39e1ea7fb86d41381badbe2bf418d95e3fbeb4bbe07b4?"
                class="img-14"
              /> -->
        <label class="div-35">{{placeHolders['LBL_NO_USER_AVAILABLE']}} : </label>
        
      <span class="div-33">
        <input type="text" class="div-34 col-1" style="background-color: transparent;" formControlName="totalFav"
          [readonly]="true" />
        <div class="div-35">users</div>
      </span>
      </div>
      <!-- <div class="div-36">Target Audience</div>
      <div class="div-37">
        <div class="div-38">
            <input type="text" class="div-34 col-1" style="background-color: transparent;" formControlName="totalFav"
          [readonly]="true" />
          <div class="div-40">users</div>
        </div>
      </div> -->
      <div class="div-43">{{placeHolders['LBL_COMPOSE_MESSAGE']}}</div>
      <div class="pt-2" style="display: flex;">
        <label class=" asterix">{{placeHolders['LBL_MESSAGE']}}</label>
        
      <div class="div-48 pt-1">
        (Please include terms and condition, if any)
      </div>
        <!-- <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6651221268d26d5f7c2459d3d86471df8c55372891d73df0aac8e167e05b80a?"
                class="img-16"
              /> -->
      </div>
      <div class="">
        <textarea class="form-control" row="5" formControlName="message" placeholder="Enter Message Here"
          [ngClass]="{ 'is-invalid':  f['message'].errors  && (f['message'].dirty || f['message'].touched ) }"
          required></textarea>
        @if ( f['message'].errors) {
        <div class="invalid-feedback">
          @if (f['message'].errors['required']) {
          <div>Message is required</div>
          }
          @if (f['message'].errors['minlength']) {
          <div>Message must be at least 5 characters</div>
          }
          @if (f['message'].errors['maxlength']) {
          <div>Message must not exceed 500 characters</div>
          }
        </div>
        }
      </div>
      <div class="div-47">Maximum limit 500 characters.</div>
      <div class="div-49">
        <div class="div-50">
          <label class="asterix">
            <!-- <img
                    loading="lazy"
                    srcset="..."
                    class="img-17"
                  /> -->
                  {{placeHolders['LBL_DISCOUNT_CODE']}}
            </label>
          <div class="">
            <input type="text" class="form-control" formControlName="discountCode" placeholder="Enter Discount Code"
              [ngClass]="{ 'is-invalid':  f['discountCode'].errors  && (f['discountCode'].dirty || f['discountCode'].touched ) }"
              required>
            @if ( f['discountCode'].errors) {
            <div class="invalid-feedback">
              @if (f['discountCode'].errors['required']) {
                <div>Discount code is required</div>
                }
                @if (f['discountCode'].errors['minlength']) {
                <div>Discount code must be at least 2 characters</div>
                }
                @if (f['discountCode'].errors['maxlength']) {
                <div>Discount code must not exceed 20 characters</div>
                }
                @if (f['discountCode'].errors['pattern']) {
                <div>Alphanumeric characters (letters and numbers)</div>
                }
            </div>
            }
          </div>
        </div>
        <div class="div-54">
          <label class="asterix">
            <!-- <img
                    loading="lazy"
                    srcset="..."
                    class="img-18"
                  /> -->
                  {{placeHolders['LBL_VALIDITY_FROM']}}
            </label>
          <div class="">
            <div class="">
              <input type="date"  formControlName="validFrom" min="{{ presentDate }}" onkeydown="return false" placeholder="Select Date"
                style="border: 0px solid transparent;" class="col-8 form-control" required ngDefaultControl />
            </div>
            <!-- <img loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/608313657a294b01b8987895510b39792526a83598dcb1284f7a6e778732f532?"
              class="img-19" /> -->
          </div>
        </div>
        <div class="div-59">
          <label class="asterix">
            <!-- <img
                    loading="lazy"
                    srcset="..."
                    class="img-20"
                  /> -->
                  {{placeHolders['LBL_VALIDITY_TO']}}
            </label>
          <div class="">
            <div class="">
              <input type="date"  formControlName="validTo" min="{{ afterPresentDate }}" onkeydown="return false" placeholder="Select Date"
                style="border: 0px solid transparent;" class="col-8 form-control" required ngDefaultControl />
            </div>
            <!-- <img loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/608313657a294b01b8987895510b39792526a83598dcb1284f7a6e778732f532?"
              class="img-21" /> -->
          </div>
        </div>
      </div>
      <div class="div-64">
        <div class="div-65">
          <div class="div-66">
            <!-- <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/ced4c0641f8c00e7a2b39e1ea7fb86d41381badbe2bf418d95e3fbeb4bbe07b4?"
                    class="img-22"
                  /> -->
            <label class="div-67">{{placeHolders['LBL_COST_PER_MSG']}}</label>
          </div>
          <div class="div-68" style="display: flex;">{{this.clickCost?.currency}}
            <input type="number" class="" formControlName="costsPerMssg" placeholder="Enter Costs Per Messages"
              [readonly]="true" required>
          </div>
        </div>
        <div class="div-69">
          <div class="div-70">
            <label class="div-71">{{placeHolders['LBL_TOTAL_COST']}}</label>
          </div>
          <div class="div-72">
            {{this.clickCost?.currency}} {{totalcost}}</div>
        </div>
      </div>
      <div class="div-73">
        <button class="btn btn-secondary" (click)="cancel()">Cancel</button>
        <div class="div-75">
          <button class="btn div-63" [disabled]="selectForm.invalid" (click)="save()">Save</button>
          <button class="btn div-63" [disabled]="selectForm.invalid" (click)="submit()">{{placeHolders['LBL_BTN_MAKE_PAYMENT']}}</button>
        </div>
      </div>
    </div>
  </div>
</form>
<!-- </div>
    </div>
  </div>
</div> -->