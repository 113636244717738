
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ClientSideStorageService } from "./client-side-storage.service";
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient, private cStorage: ClientSideStorageService) { }

  getToken(): string | null {
    // Implement logic to get the token from localStorage, cookies, or wherever it's stored
    return JSON.parse(this.cStorage.get("token"));
  }

  refreshToken(): Observable<any> {
    const refreshToken = JSON.parse(this.cStorage.get("refreshToken"));
    const userName = JSON.parse(this.cStorage.get("userName"));
    const country = JSON.parse(this.cStorage.get("country"));
    const macAddress = JSON.parse(this.cStorage.get("macAddress"));
    const url = environment.authUrl + 'orchestration-service/event/generate-token'
    const httpOptions = {
      'refresh-token': refreshToken,
      'userName': userName,
      'macAddress': macAddress,
      'country': country
    };
    // Implement logic to refresh the token by making an HTTP request to your backend
    return this.http.get<any>(url, { headers: httpOptions,withCredentials:true,observe: 'response'}).pipe(
      tap((response: HttpResponse<any>) => {
        const newAuthToken = response.headers.get('auth-token');
        if (newAuthToken) {
          this.cStorage.set('token', JSON.stringify(newAuthToken));
        }
      })
    );
  }

  logout(): void {
    // Implement logic to clear the token and log out the user
    localStorage.removeItem('token');
    // Other logout logic
  }
}

