import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnApi, GridApi, GridOptions } from 'ag-grid-community';
import { EditComponent } from '../../sharedComponent/edit/edit.component';
import { CommonUtilService } from 'src/app/services/common-util.service';
import { MessagingService } from 'src/app/services/messaging.service';
import * as moment from 'moment';
import { SwitchToggleComponent } from '../../sharedComponent/switch-toggle/switch-toggle.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-festive',
  templateUrl: './festive.component.html',
  styleUrl: './festive.component.scss'
})
export class FestiveComponent implements OnInit {
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders:any;
  gridOptions = <GridOptions>{};
  gridApi: any;
  gridColumnApi: any;
  frameworkComponents: any;
  columnDefs: any;
  header: any;
  id: any;
  defaultColDef = {};
  paginationPageSize: any;
  currentPage: any;
  isPageChanged: any;
  types = [10, 20, 30];
  paramsSelectRecord = {
    type: "gridReady",
    api: GridApi,
    columnApi: ColumnApi,
  };
  minRowHeight = 25;
  data: any = [];
  activePage: number = 1;
  recordTotalCount: any;

  constructor(
    private router: Router, private service: MessagingService, private commonUtilService: CommonUtilService, private ngZone: NgZone
  ) {
    this.placeHolders = this.placeholders['Messaging_Page']
    this.getMessageList(this.activePage);
    this.defaultColDef = this.commonUtilService.getDefaultColumnDef();
    this.gridOptions.defaultColDef = this.defaultColDef;
    this.createColumnDefs();
    this.gridOptions.columnDefs = this.columnDefs;
    this.gridOptions.rowHeight = 70;
  }

  ngOnInit(): void {
    this.defaultColDef = {
      editable: true,
      sortable: true,
      minWidth: 100,
      filter: true,
      resizable: false,
      tooltip: false,
      width: 170,

    };

  }

  getMessageList(pageNo: any) {
    this.service.getFestiveMessage(pageNo).subscribe(res => {
      this.data = [];
      if (res?.body?.data) {
        this.recordTotalCount = res?.body?.data?.totalMessages;
        let response = res?.body?.data.campaignMessages;
        response.forEach((item: any) => {
          let tableData = item;
          let parsedDate = moment(tableData.createdDate, "YYYY-MM-DD");
          tableData.createdDate = parsedDate.format("DD/MM/YYYY");
          this.data.push(tableData);
        });
      }
    });
  }

  composeMessage() {
    this.service.setFestiveData(null);
    this.router.navigate(["/messaging/festive/composeMessage"]);
  }

  onGridReady(params: any, value?: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (value && value !== null && value !== undefined) {
      this.paginationPageSize = value;
      this.ngOnInit();
    } else {
      this.paginationPageSize = 10;
    }
  }

  createColumnDefs() {
    this.columnDefs = [];
    let header: any;

    this.frameworkComponents = {
      // toggleComponent: ToggleComponent,
      edit: EditComponent,
      // cellRendererFramework: RouterLinkRendererComponentComponent
    };


    header = this.commonUtilService.getColumnHeader(
      "title",
      "Title"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filterParams.suppressAndOrCondition = true;
    header.minWidth = 100;
    // header.wrapText= true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "composeMessage",
      "Message"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filterParams.suppressAndOrCondition = true;
    header.minWidth = 250;
    // header.wrapText= true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "createdDate",
      "Date created"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 120;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "status",
      "Status"
    );
    header.cellRenderer = SwitchToggleComponent;
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 50;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "budget",
      "Budget"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 100;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "amountSpent",
      "Actual spent"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 150;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "",
      "Action"
    );
    header.cellRenderer = EditComponent;
    header.cellRendererParams = {
      clicked: (field: any) => {
        this.service.setFestiveData(field);
        this.ngZone.run(() => {
          this.router.navigate(["/messaging/festive/composeMessage"]);
        });
      }
    },
      header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);
  }

  displayActivePage(activePageNumber: number) {
    this.activePage = activePageNumber;
    this.getMessageList(activePageNumber);
  }

}
