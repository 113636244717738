export class HardcodedData {
    AllCampaignTestData= [
        {
          "available_from": "2023-07-19T08:38:46.469+00:00",
          "available_to": "2023-09-15T08:38:46.469+00:00",
          "category_id": 4,
          "coupon_code": "bxcdjdc",
          "created_by": "clmcore",
          "created_date": "2023-07-19T08:38:46.469+00:00",
          "deal_report": null,
          "digital_offer": "N",
          "discountPercentage": 0,
          "gender": "female",
          "location": "USA",
          "lower_age": 16,
          "merchant_id": 4,
          "modified_by": "clmcore",
          "modified_date": "2023-07-19T08:38:46.469+00:00",
          "physical_sample": "Y",
          "printed_offer": "Y",
          "product_active": "Y",
          "product_description": "M.A.C Matte Lipstick - Velvet Teddy (3gms)",
          "product_eligibility_score": 0,
          "product_entry_criteria": "",
          "product_id": 39,
          "product_image": "category/brand/deals/MAC_Velvet_Teddys_Party_Crew_Vault.png",
          "product_name": "test 1 M.A.C Velvet Teddy's Party Crew Vault, 15 gm",
          "status": "A",
          "sub_category_id": 9,
          "target": 600,
          "total_spent": 8,
          "trending": false,
          "upper_age": 80,
          "currency": "$",
          "url": "kjdhkhed.com",
          "ageGroup": "0-10"
        },
        {
          "available_from": "2023-07-19T08:38:46.469+00:00",
          "available_to": "2023-09-15T08:38:46.469+00:00",
          "category_id": 5,
          "coupon_code": "ksdlcdnlk",
          "created_by": "clmcore",
          "created_date": "2023-07-19T08:38:46.469+00:00",
          "deal_report": null,
          "digital_offer": "N",
          "discountPercentage": 0,
          "gender": "female",
          "location": "USA",
          "lower_age": 16,
          "merchant_id": 5,
          "modified_by": "clmcore",
          "modified_date": "2023-07-19T08:38:46.469+00:00",
          "physical_sample": "Y",
          "printed_offer": "Y",
          "product_active": "Y",
          "product_description": "M.A.C Matte Lipstick - Velvet Teddy (3gms)",
          "product_eligibility_score": 0,
          "product_entry_criteria": "",
          "product_id": 40,
          "product_image": "category/brand/deals/MAC_Velvet_Teddys_Party_Crew_Vault.png",
          "product_name": "test 2 M.A.C Velvet Teddy's Party Crew Vault, 15 gm",
          "status": "A",
          "sub_category_id": 9,
          "target": 600,
          "total_spent": 8,
          "trending": false,
          "upper_age": 80,
          "currency": "$",
          "url": "kjdhkhed.com",
          "ageGroup": "0-10"
        },
        {
          "available_from": "2023-07-19T08:38:46.469+00:00",
          "available_to": "2023-09-15T08:38:46.469+00:00",
          "category_id": 6,
          "coupon_code": "jkdbcjkd",
          "created_by": "clmcore",
          "created_date": "2023-07-19T08:38:46.469+00:00",
          "deal_report": null,
          "digital_offer": "N",
          "discountPercentage": 0,
          "gender": "female",
          "location": "USA",
          "lower_age": 16,
          "merchant_id": 6,
          "modified_by": "clmcore",
          "modified_date": "2023-07-19T08:38:46.469+00:00",
          "physical_sample": "Y",
          "printed_offer": "Y",
          "product_active": "Y",
          "product_description": "M.A.C Matte Lipstick - Velvet Teddy (3gms)",
          "product_eligibility_score": 0,
          "product_entry_criteria": "",
          "product_id": 41,
          "product_image": "category/brand/deals/MAC_Velvet_Teddys_Party_Crew_Vault.png",
          "product_name": "test 3 M.A.C Velvet Teddy's Party Crew Vault, 15 gm",
          "status": "A",
          "sub_category_id": 9,
          "target": 600,
          "total_spent": 8,
          "trending": false,
          "upper_age": 80,
          "currency": "$",
          "url": "kjdhkhed.com",
          "ageGroup": "0-10"
        },
        {
          "available_from": "2023-07-19T08:38:46.469+00:00",
          "available_to": "2023-09-15T08:38:46.469+00:00",
          "category_id": 7,
          "coupon_code": "kdncjnd",
          "created_by": "clmcore",
          "created_date": "2023-07-19T08:38:46.469+00:00",
          "deal_report": null,
          "digital_offer": "N",
          "discountPercentage": 0,
          "gender": "female",
          "location": "USA",
          "lower_age": 16,
          "merchant_id": 7,
          "modified_by": "clmcore",
          "modified_date": "2023-07-19T08:38:46.469+00:00",
          "physical_sample": "Y",
          "printed_offer": "Y",
          "product_active": "Y",
          "product_description": "M.A.C Matte Lipstick - Velvet Teddy (3gms)",
          "product_eligibility_score": 0,
          "product_entry_criteria": "",
          "product_id": 42,
          "product_image": "category/brand/deals/MAC_Velvet_Teddys_Party_Crew_Vault.png",
          "product_name": "test 4 M.A.C Velvet Teddy's Party Crew Vault, 15 gm",
          "status": "A",
          "sub_category_id": 9,
          "target": 600,
          "total_spent": 8,
          "trending": false,
          "upper_age": 80,
          "currency": "$",
          "url": "kjdhkhed.com",
          "ageGroup": "0-10"
        }
      ];
      CompanyData = 
        {
         "id": "",
         "companyName": "Nestle",
         "companyWebsite": "www.Nestle.com",
         "companyLogo": "Nestle.png",
         "registrationID": "123456",
         "businessType": "Retailer",
         "category_id": "1",
         "category_type": "Dine Out",
         "addressLine": "Mumbai",
         "additionalInfo": "None",
         "primaryContact": "John Doe",
         "email": "hello@nestle.com",
         "phoneNo": "1234567899",
         "password": "123456",
         "document" : "hcdjdj"

        }
        UserManagementData =  [
        {
         "id": "1",
         "userName": "Harry",
         "roleType": "Administrator",
         "email": "Harry@nestle.com",
         "phoneNo": "1234567899",
         "createdDate": "30/01/2024",
         "status": "A",
         "role": "A",
         "message": " Hello, this is my description",
         "country": ""
        },
        {
          "id": "2",
          "userName": "Max",
          "roleType": "Administrator",
          "email": "Max@nestle.com",
          "phoneNo": "1234567899",
          "createdDate": "30/01/2024",
          "status": "A",
          "role": "A",
          "message": " Hello, this is my description",
          "country": ""
         },
         {
           "id": "3",
           "userName": "Jack",
           "roleType": "Administrator",
           "email": "Jack@nestle.com",
           "phoneNo": "1234567899",
           "createdDate": "30/01/2024",
           "status": "A",
           "role": "A",
           "message": " Hello, this is my description",
           "country": ""
          },
          {
            "id": "4",
            "userName": "John",
            "roleType": "Administrator",
            "email": "John@nestle.com",
            "phoneNo": "1234567899",
            "createdDate": "30/01/2024",
            "status": "I",
            "role": "A",
            "message": " Hello, this is my description",
            "country": ""
           },
           {
             "id": "5",
             "userName": "William",
             "roleType": "Administrator",
             "email": "hello@nestle.com",
             "phoneNo": "1234567899",
             "createdDate": "30/01/2024",
             "status": "I",
             "role": "A",
             "message": " Hello, this is my description",
             "country": ""
            }
      ];
      ReportData =  [
        {
          "campaignName": "Winter: new season sale",
          "campaignID": "CPGN4684",
          "startDate": "11/03/2023",
          "endDate": "11/03/2023",
          "status": "A",
          "budget": "$1000.00",
          "actualSpent": "$600.00",
          "totalTransactions": "1250",
          "totalSales": "25,853",
          "transactionFees": "775.89",
          "data": [
            {
              "title":"Number of Notifications sent",
              "counts": "45382",
              "costs": "-",
              "total": "-"
            },
            {
              "title":"Number of of Impressions",
              "counts": "21245",
              "costs": "0.09",
              "total": "$1,912.28"
            },
            {
              "title":"Number of of Clicks",
              "counts": "8150",
              "costs": "0.17",
              "total": "$1,385.55"
            },
            {
              "title":"Number of Copy and Claim",
              "counts": "6302",
              "costs": "0.24",
              "total": "$1,512.48"
            }
          ]
        },
        {
          "campaignName": "Puma new season sale",
          "campaignID": "CPGN4685",
          "startDate": "11/03/2023",
          "endDate": "11/03/2023",
          "status": "A",
          "budget": "$1000.00",
          "actualSpent": "$600.00",
          "totalTransactions": "1250",
          "totalSales": "25,853",
          "transactionFees": "775.89",
          "data": [
            {
              "title":"Number of Notifications sent",
              "counts": "45382",
              "costs": "-",
              "total": "-"
            },
            {
              "title":"Number of of Impressions",
              "counts": "21245",
              "costs": "0.09",
              "total": "$1,912.28"
            },
            {
              "title":"Number of of Clicks",
              "counts": "8150",
              "costs": "0.17",
              "total": "$1,385.55"
            },
            {
              "title":"Number of Copy and Claim",
              "counts": "6302",
              "costs": "0.24",
              "total": "$1,512.48"
            }
          ]
        },
        {
          "campaignName": "Nykaa new season sale",
          "campaignID": "CPGN4686",
          "startDate": "11/03/2023",
          "endDate": "11/03/2023",
          "status": "A",
          "budget": "$1000.00",
          "actualSpent": "$600.00",
          "totalTransactions": "1250",
          "totalSales": "25,853",
          "transactionFees": "775.89",
          "data": [
            {
              "title":"Number of Notifications sent",
              "counts": "45382",
              "costs": "-",
              "total": "-"
            },
            {
              "title":"Number of of Impressions",
              "counts": "21245",
              "costs": "0.09",
              "total": "$1,912.28"
            },
            {
              "title":"Number of of Clicks",
              "counts": "8150",
              "costs": "0.17",
              "total": "$1,385.55"
            },
            {
              "title":"Number of Copy and Claim",
              "counts": "6302",
              "costs": "0.24",
              "total": "$1,512.48"
            }
          ]
        },
        {
          "campaignName": "Myntra new season sale",
          "campaignID": "CPGN4687",
          "startDate": "11/03/2023",
          "endDate": "11/03/2023",
          "status": "A",
          "budget": "$1000.00",
          "actualSpent": "$600.00",
          "totalTransactions": "1250",
          "totalSales": "25,853",
          "transactionFees": "775.89",
          "data": [
            {
              "title":"Number of Notifications sent",
              "counts": "45382",
              "costs": "-",
              "total": "-"
            },
            {
              "title":"Number of of Impressions",
              "counts": "21245",
              "costs": "0.09",
              "total": "$1,912.28"
            },
            {
              "title":"Number of of Clicks",
              "counts": "8150",
              "costs": "0.17",
              "total": "$1,385.55"
            },
            {
              "title":"Number of Copy and Claim",
              "counts": "6302",
              "costs": "0.24",
              "total": "$1,512.48"
            }
          ]
        },
        {
          "campaignName": "Summer season sale",
          "campaignID": "CPGN4688",
          "startDate": "11/03/2023",
          "endDate": "11/03/2023",
          "status": "A",
          "budget": "$1000.00",
          "actualSpent": "$600.00",
          "totalTransactions": "1250",
          "totalSales": "25,853",
          "transactionFees": "775.89",
          "data": [
            {
              "title":"Number of Notifications sent",
              "counts": "45382",
              "costs": "-",
              "total": "-"
            },
            {
              "title":"Number of of Impressions",
              "counts": "21245",
              "costs": "0.09",
              "total": "$1,912.28"
            },
            {
              "title":"Number of of Clicks",
              "counts": "8150",
              "costs": "0.17",
              "total": "$1,385.55"
            },
            {
              "title":"Number of Copy and Claim",
              "counts": "6302",
              "costs": "0.24",
              "total": "$1,512.48"
            }
          ]
        }
      ];
      FavMessageData =  [
        {
          "campaignName": "Deal_00NewYear 2024_lakme",
          "campaignID": "CPGN4684",
          "endDate": "11/03/2023",
          "status": "A",
          "noOfFavourites": "445",
          "noOfUserClaim": "234",          
          "noOfOffer": "30",          
          "spendOnfavourites": "$230",          
          "successfulTransaction": "40"
        },
        {
          "campaignName": "Deal_01NewYear 2024_lakme",
          "campaignID": "CPGN4685",
          "endDate": "11/03/2023",
          "status": "A",
          "noOfFavourites": "445",
          "noOfUserClaim": "234",          
          "noOfOffer": "30",          
          "spendOnfavourites": "$230",          
          "successfulTransaction": "40"
        },
        {
          "campaignName": "Deal_02NewYear 2024_lakme",
          "campaignID": "CPGN4686",
          "endDate": "11/03/2023",
          "status": "P",
          "noOfFavourites": "445",
          "noOfUserClaim": "234",          
          "noOfOffer": "30",          
          "spendOnfavourites": "$230",          
          "successfulTransaction": "40"
        },
        {
          "campaignName": "Deal_03NewYear 2024_lakme",
          "campaignID": "CPGN4687",
          "endDate": "11/03/2023",
          "status": "I",
          "noOfFavourites": "445",
          "noOfUserClaim": "234",          
          "noOfOffer": "30",          
          "spendOnfavourites": "$230",          
          "successfulTransaction": "40"
        },
        {
          "campaignName": "Deal_04NewYear 2024_lakme",
          "campaignID": "CPGN4688",
          "endDate": "11/03/2023",
          "status": "E",
          "noOfFavourites": "445",
          "noOfUserClaim": "234",          
          "noOfOffer": "30",          
          "spendOnfavourites": "$230",          
          "successfulTransaction": "40"
        }
      ];
      selectUserData =  [
        {
          "userId": "William01",
          "age": "30-40",
          "location": "Delhi",
        },
        {
          "userId": "William02",
          "age": "30-40",
          "location": "Delhi"
        },
        {
          "userId": "William03",
          "age": "30-40",
          "location": "Delhi"
        },
        {
          "userId": "William04",
          "age": "30-40",
          "location": "Delhi"
        },
        {
          "userId": "William05",
          "age": "30-40",
          "location": "Delhi"
        }
      ];

}
