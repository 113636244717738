import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { CommonUtilService } from 'src/app/services/common-util.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { UserManagementService } from 'src/app/services/user-management.service';

@Component({
  selector: 'app-addinviteuser',
  templateUrl: './addinviteuser.component.html',
  styleUrl: './addinviteuser.component.scss'
})
export class AddinviteuserComponent {
  countryData: any;
  editData: any;
  dropdownSettings: IDropdownSettings = {};
  country: any;
  macAddress: any;
  personID: any;
  showAdminData: boolean = false;
  showMarketingData: boolean = false;
  showReportingData: boolean = false;
  showBillingData: boolean = false;
  addInviteUserForm: FormGroup = new FormGroup({
    fullName: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    role: new FormControl(''),
    // country: new FormControl(''),
    message: new FormControl(''),
  });

  constructor(private dialog: MatDialog, private router: Router, private fb: FormBuilder, private service: CommonUtilService,
    private confirmationDialogService: ConfirmationDialogService, private notifyService: NotificationAlertService, private cStorage: ClientSideStorageService,
    private userService: UserManagementService) {
    this.personID = JSON.parse(this.cStorage.get("userId"));
    this.country = JSON.parse(this.cStorage.get("country"));
    this.macAddress = JSON.parse(this.cStorage.get("macAddress"));
    this.editData = this.userService.getData();
    this.editData = this.editData.source._value;
    this.dropdownSettings = {
      idField: "country_id",
      textField: "country_name",
      enableCheckAll: true,
      // selectAllText: "Select All Users From List",
      // unSelectAllText: "Unselect All Users From List",
      noDataAvailablePlaceholderText: "There is no item availabale to show",
      allowSearchFilter: true,
      // itemsShowLimit: 3
      // defaultOpen: false
    };
    if (this.editData !== null && this.editData !== undefined) {
      this.editAddInviteUserForm();
    } else {
      this.createAddInviteUserForm();
    }
    // this.service.getCountry(this.country, this.macAddress).subscribe((res: any) => {
    //   this.countryData = res.body?.data;
    // });

    this.service.getCountry(this.country, this.macAddress).subscribe((res: any) => {
      let countryRes = res.body?.data;
      this.countryData = countryRes.sort((a: any, b: any) => {
        var typeA = a.country_name.toLowerCase(),
          typeB = b.country_name.toLowerCase();
        if (typeA < typeB)
          //sort string ascending
          return -1;
        if (typeA > typeB) return 1;
        return 0; //default return value (no sorting)
      });
    });
  }

  createAddInviteUserForm() {
    this.addInviteUserForm = this.fb.group({
      fullName: ['', [Validators.required,Validators.maxLength(50),Validators.pattern('^[a-zA-Z]+( [a-zA-Z0-9]+)*$')]],
      email: ['', [Validators.required, Validators.email]],
      code: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      role: ['1', Validators.required],
      // country: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  editAddInviteUserForm() {
    let res = this.editData;
    this.addInviteUserForm = this.fb.group({
      fullName: [res.userName, Validators.required],
      email: [res.email, [Validators.required, Validators.email]],
      phone: [res.phoneNo],
      role: [res.role, Validators.required],
      // country: ['', Validators.required],
      message: [res.message, Validators.required],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.addInviteUserForm.controls;
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  cancel() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to cancel this operation? ")
      .then((confirmed) => {
        if (confirmed) {
          this.dialog.closeAll();
        }
      });
  }

  add() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to invite this user? ")
      .then((confirmed) => {
        if (confirmed) {
          let res = this.addInviteUserForm.value;
          let data = {
            "firstName": res.fullName,
            "email_id": res.email,
            "roleId": res.role,
            "country": res.country,
            "message": res.message,
            "mobile": res.code + res.phone,
            "password":"password@123"
          }
          this.userService.addUser(data).subscribe((res: any) => {
            if (res?.status === "ERROR") {
              this.notifyService.showError(res.message, res.status);
            } else {
              this.notifyService.showSuccess(
                "User successfully added !!",
                "Success"
              );              
              this.dialog.closeAll();
              this.router.navigate(["/usermanagement"]);
              this.userService.emit(data);
            }
          });
        }
      });
  }


}
