<div class="div-29">
  <div class="div-30">Campaign Title</div>
  <div class="div-31">
    <div class="div-32">
      <div class="div-33">Basic Details</div>
      <div class="div-34">Enter Campaign Details</div>
    </div>
    <nav>
      <button style="border: none;" (click)="targetClick()" class="button">
        <div class="div-35">
          <div class="div-36" style="text-align: left;">Target Audience</div>
          <div class="div-37">Select the Target Audience</div>
        </div>
      </button>
      <!-- <a routerLink="/campaign/target" class="button" routerLinkActive="active" ariaCurrentWhenActive="page">
        <div class="div-35">
          <div class="div-36">Target Audience</div>
          <div class="div-37">Select the Target Audience</div>
        </div>
      </a> -->
    </nav>
    <nav>
      <button style="border: none;" (click)="budgetDetailClick()" class="button">
        <div class="div-38">
          <div class="div-39">Budget Details</div>
          <div class="div-40" style="text-align: left;">Set Budget</div>
        </div>
      </button>
      <!-- <a routerLink="/campaign/budget" class="button" routerLinkActive="active" ariaCurrentWhenActive="page">
        <div class="div-38">
          <div class="div-39">Budget Details</div>
          <div class="div-40">Set Budget</div>
        </div>
      </a> -->
    </nav>
    <nav>
      <button class="button" style="border: none;" (click)="ValidityClick()">
        <div class="div-41">
          <div class="div-42" style="text-align: left;">Validity & Status</div>
          <div class="div-43" style="text-align: left;">Enter Validity</div>
        </div>
      </button>
      <!-- <a  class="button" routerLinkActive="active" ariaCurrentWhenActive="page">
        <div class="div-41">
          <div class="div-42">Validity & Status</div>
          <div class="div-43">Enter Validity</div>
        </div>
      </a> -->
    </nav>
  </div>
  <form [formGroup]="campaignBasicDetailForm" class="form-group">
    <div class="div-44">
      <div class="div-45">Basic Information</div>
      <div class="div-46">
        <div class="div-47">
          <div class="div-48">
            <div class="div-49 asterix">Campaign Title</div>
            <!-- <img loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/c545d9af-9d38-4a0f-aaef-b507e2f23ed0?"
              class="img-15" /> -->
          </div>
          <div class="div-50">
            <input type="text" class=" col-4 form-control" formControlName="title" placeholder="Enter Campaign Title"
              [ngClass]="{ 'is-invalid': f['title'].errors  && (f['title'].dirty || f['title'].touched ) }" required />
            @if (f['title'].errors) {
            <div class="invalid-feedback">
              @if (f['title'].errors['required']) {
              <div>Title is required</div>
              }
              @if (f['title'].errors['minlength']) {
              <div>Title must be at least 2 characters</div>
              }
              @if (f['title'].errors['maxlength']) {
              <div>Title must not exceed 30 characters</div>
              }
            </div>
            }
            <!-- <div class="div-50">Enter Campaign Title</div> -->
          </div>
        </div>
        <div class="div-51">
          <div class="div-52">
            <div class="div-53 asterix">Campaign Categories</div>
            <!-- <img loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/fb58bd0f-3e6f-4ab0-8c17-29223a475ddb?"
              class="img-16" /> -->
          </div>
          <div class="div-select">
            <!-- <div class=" "> -->
            <!-- <ng-select  [clearable]="true" [searchable]="false" [dropdownPosition]="'bottom'"
                [items]="allCategory" bindLabel="category_type" bindValue="category_id" formControlName="category"
                [ngClass]="{ 'is-invalid': submitted && f['category'].errors }" required>
              </ng-select> -->
            <!-- <mat-form-field class="col-12">
              <mat-label>Select Category</mat-label> -->

            <mat-form-field class="col-12">
              <mat-label>Select Category</mat-label>
              <mat-select formControlName="category" class=" col-4 ">
                @for (category of allCategory; track category) {
                <mat-option [value]="category.category_id">{{category.category_type}}
                </mat-option>
                }
              </mat-select>
              <mat-error
                *ngIf="{ 'is-invalid':  f['category'].errors && (f['category'].dirty || f['category'].touched )}">
                Category is required
              </mat-error>
            </mat-form-field>

            <!-- <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Select Category'"
              [data]="allCategory" bindLabel="category_type" bindValue="category_id" formControlName="category"
              required>
            </ng-multiselect-dropdown> -->
            <!-- <mat-select formControlName="category" class="div-55 col-4 campaignSelect"
                 [ngClass]="{ 'is-invalid':  f['category'].errors && (f['category'].dirty || f['category'].touched ) }" required>
                  @for (category of allCategory; track category) {
                    <mat-option [value]="category.category_id">{{category.category_type}}
                    </mat-option>
                  }
                </mat-select> -->

            <!-- </mat-form-field> -->
            <!-- </div> -->
            <!-- <img loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/6dae1dc3-9a1d-417c-839b-12ae528e2fc6?"
              class="img-17" />  -->
          </div>
        </div>
        <div class="div-56">
          <div class="div-57">
            <div class="div-58 asterix">Offer Code</div>
            <!-- <img loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/cca85226-b33d-4e3d-9790-4816768c39ab?"
              class="img-18" /> -->
          </div>

          <div class="div-50">
            <input type="text" class=" col-4 form-control" formControlName="offerCode" placeholder="Enter Offer Code"
              [ngClass]="{ 'is-invalid':  f['offerCode'].errors  && (f['offerCode'].dirty || f['offerCode'].touched ) }"
              required />
            @if ( f['offerCode'].errors) {
            <div class="invalid-feedback">
              @if (f['offerCode'].errors['required']) {
              <div>Offer Code is required</div>
              }
              @if (f['offerCode'].errors['minlength']) {
              <div>Offer Code must be at least 2 characters</div>
              }
              @if (f['offerCode'].errors['maxlength']) {
              <div>Offer Code must not exceed 20 characters</div>
              }
            </div>
            }
          </div>
          <!-- <div class="div-59">Enter Offer Code</div> -->
        </div>
      </div>
      <div class="div-60">
        <div class="div-61 asterix">Deal Description</div>
        <!-- <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/b642367d-1f44-4fb6-8be1-fbfb494d1f15?"
          class="img-19" /> -->
      </div>
      <div class="div-62">
        <textarea class=" form-control" rows="3" formControlName="description" placeholder="Description goes here"
          [ngClass]="{ 'is-invalid':  f['description'].errors  && (f['description'].dirty || f['description'].touched ) }"
          required></textarea>
        @if ( f['description'].errors) {
        <div class="invalid-feedback">
          @if (f['description'].errors['required']) {
          <div>Description is required</div>
          }
          @if (f['description'].errors['minlength']) {
          <div>Description must be at least 5 characters</div>
          }
          @if (f['description'].errors['maxlength']) {
          <div>Description must not exceed 500 characters</div>
          }
        </div>
        }
      </div>
      <!-- <div class="div-62">Description goes here</div> -->
      <div class="div-63">
        <div class="div-64">
          <div class="column-3">
            <div class="div-65">
              <div class="div-66">
                <div class="div-67">
                  <div class="div-68">
                    <div class="div-69 asterix">Upload Image(s)</div>
                    <!-- <img loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/3e494fe9-5ecd-450e-a9ac-b1d19da525ea?"
                      class="img-20" /> -->
                  </div>
                  <div class="div-70" *ngIf="fileName===null">
                    <input class="div-71" type="file" (change)="uploadDoc($event)" formControlName="image"
                      title="Upload"
                      [ngClass]="{ 'is-invalid':  f['image'].errors  && (f['image'].dirty || f['image'].touched )}"
                      required />

                    @if ( f['image'].errors) {
                    <div class="invalid-feedback">
                      @if (f['image'].errors['required']) {
                      <div>Image is required</div>
                      }
                    </div>
                    }
                    <!-- <div class="div-71">Upload</div> -->
                    <!-- <img loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/3daf9256-a330-4b33-80fc-6c9fc31b41c5?"
                      class="img-21" /> -->
                  </div>

                  <div class="div-70" *ngIf="fileName!==null">
                    <div class="div-71" type="text" disabled>{{fileName}}</div>
                    <div class="div-711" (click)="removeImage()" style="cursor: pointer;">Remove</div>
                  </div>
                </div>
                <button style="cursor: pointer;" class="div-72 mt-4" (click)="imageUpload()">Upload</button>
              </div>
              <!-- <div class="div-73">Image size and instructions</div> -->

            </div>
          </div>
          <div class="column-4">
            <div class="div-74">
              <div class="div-75">
                <div class="div-76 asterix">Landing Page URL</div>
                <!-- <img loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/6cc23266-450b-4982-9f2a-d7ec09c42d10?"
                  class="img-22" /> -->
              </div>

              <div class="div-62">
                <input type="text" class="form-control" formControlName="url" placeholder="Enter URL here"
                  [ngClass]="{ 'is-invalid':  f['url'].errors  && (f['url'].dirty || f['url'].touched ) }" required />
                @if ( f['url'].errors) {
                <div class="invalid-feedback">
                  @if (f['url'].errors['required']) {
                  <div>Url is required</div>
                  }
                  @if (f['url'].errors['minlength']) {
                  <div>Url must be at least 5 characters</div>
                  }
                </div>
                }
                <!-- <div class="div-77">Enter URL here</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="div-60">
        <div class="div-61 asterix">Add Tags/Keywords</div>
      </div>
      <div class="div-62">
        <ng-multiselect-dropdown [settings]="tagsDropdownSettings" [placeholder]="'Select Tags/Keywords'"
          [data]="allTagsKewords" bindLabel="tag" bindValue="tag" formControlName="tag"
          [class.is-invalid]="{ 'is-invalid': f['tag'].errors  && (f['tag'].dirty || f['tag'].touched ) }" required>
        </ng-multiselect-dropdown>
        @if ( f['tag'].errors) {
        <div class="invalid-feedback">
          @if (f['tag'].errors['required']) {
          <div>Tags/Keywords is required</div>
          }
        </div>
        }
      </div>
      <div class="div-78">
        <button class="div-79" (click)="cancel()">Cancel</button>
        <div class="div-80">
          <!-- <div class="div-81">Preview</div> -->
          <!-- <nav>
                    <a routerLink="campgain/target" class="button"  routerLinkActive="active" ariaCurrentWhenActive="page"> -->
          <button class="div-82" (click)="saveAndNext()">Save & Next</button>
          <!-- </a></nav> -->
        </div>
      </div>
    </div>
  </form>
</div>