import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationDialogService } from "../../services/confirmation-dialog.service";
import { CampaignService } from "../../services/campaign.service";
import { DashboardService } from 'src/app/services/dashboard.service';
import { HardcodedData } from '../hardcoded-data';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';


@Component({
  selector: 'app-campaign',
  templateUrl: './campaign.component.html',
  styleUrl: './campaign.component.scss'
})
export class CampaignComponent {
  editData: any = null;
  allCategory: any;
  submitted = false;
  fileName: any = null;
  data = Array<HardcodedData>();
  dropdownSettings: IDropdownSettings = {};
  tagsDropdownSettings: IDropdownSettings = {};
  images3Path: any = null;
  fData = new FormData();
  editResponse: any;
  allTagsKewords: any = [];
  tagKeywords: any = [];

  campaignBasicDetailForm: FormGroup = new FormGroup({
    title: new FormControl(''),
    category: new FormControl(''),
    offerCode: new FormControl(''),
    description: new FormControl(''),
    image: new FormControl(''),
    url: new FormControl(''),
  });

  constructor(private fb: FormBuilder, private router: Router,
    private confirmationDialogService: ConfirmationDialogService, private campaignService: CampaignService,
    private dashboardService: DashboardService,
    private notifyService: NotificationAlertService) {
    this.allTagsKewords = this.campaignService.getAllTagsKeyword().subscribe((res: any) => {
      if (res?.body?.data) {
        this.allTagsKewords = res.body.data;
      }

    });

    this.campaignBasicDetailForm.reset();
    this.dropdownSettings = {
      idField: "category_id",
      textField: "category_type",
      enableCheckAll: true,
      // selectAllText: "Select All Users From List",
      // unSelectAllText: "Unselect All Users From List",
      noDataAvailablePlaceholderText: "There is no item availabale to show",
      allowSearchFilter: true,
      // itemsShowLimit: 3
      // defaultOpen: false
    };
    this.tagsDropdownSettings = {
      idField: "tag",
      textField: "tag",
      enableCheckAll: true,
      // selectAllText: "Select All Users From List",
      // unSelectAllText: "Unselect All Users From List",
      noDataAvailablePlaceholderText: "There is no item availabale to show",
      allowSearchFilter: true,
      // itemsShowLimit: 3
      // defaultOpen: false
    };
    this.submitted = false;
    this.editData = this.campaignService.getData();
    this.editData = this.editData.source._value;
    this.editResponse = this.campaignService.returnCreateAndEditRes();
    this.allCategory = this.dashboardService.getData();
    this.allCategory = this.allCategory.source._value.data;

    if (this.editResponse === false) {
      this.createForm();
    } else {
      this.editForm();
    }
  }

  createForm() {
    this.fileName = null;
    this.campaignBasicDetailForm = this.fb.group({
      id: [null],
      title: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(30)]],
      category: ['', Validators.required],
      offerCode: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(20)]],
      description: ['', [Validators.required, Validators.minLength(5),
      Validators.maxLength(500)]],
      image: ['', Validators.required],
      url: ['', [Validators.required, Validators.minLength(5)]],
      tag: ['', Validators.required]
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.campaignBasicDetailForm.controls;
  }

  editForm() {
    let res = this.editData;
    // let tagKeyword = [];
    // res.
    this.campaignBasicDetailForm = this.fb.group({
      id: [res.product_id],
      title: [res.product_name, [Validators.required, Validators.minLength(2),
      Validators.maxLength(30)]],
      category: [res.category_id, Validators.required],
      offerCode: [res.coupon_code, [Validators.required, Validators.minLength(2),
      Validators.maxLength(20)]],
      description: [res.product_description, [Validators.required, Validators.minLength(5),
      Validators.maxLength(500)]],
      image: [res.product_image, Validators.required],
      url: [res.product_url, [Validators.required, Validators.minLength(5)]],
      tag: [res.keywords, Validators.required]
    });
    this.fileName = res.product_image;
  }

  uploadDoc(event: any) {
    let files = event.target.files[0];
    let doctype = files.name.split('.');
    let doctypeName = doctype[doctype.length - 1];
    if (doctypeName && (doctypeName === "jpg" || doctypeName === "jpeg" || doctypeName === "png")) {
      this.fileName = files.name;
      this.fData.append("file", files);
    } else {
      this.notifyService.showInfo("Only jpg, jpeg, png file formats are allowed", "Invalid File")
    }
    event.target.value = '';
  }

  removeImage() {
    this.campaignBasicDetailForm.value.image = null;
    this.fileName = null;
    this.fData = new FormData();
    this.images3Path = null;
  }

  imageUpload() {
    this.campaignService.uploadImage(this.fData).subscribe((res: any) => {
      if (res?.data?.s3Path) {
        this.images3Path = res?.data?.s3Path;
        this.notifyService.showSuccess(
          "Image Successfully Uploaded",
          res?.status
        );
      } else {
        this.notifyService.showSuccess(
          res?.message,
          res?.status
        );
      }
    });
  }

  targetClick() {
    if (this.campaignBasicDetailForm.invalid) {
      this.notifyService.showError("Please fill required details", "Invalid Form");
      return;
    } else {
      this.confirmationDialogService
        .navigateConfirm("Confirmation ", "Are you sure you want to save this operation? ")
        .then((confirmed) => {
          if (confirmed) {
            let values = this.campaignBasicDetailForm.value;
            this.saveData(values);
            this.router.navigate(["/campaign/target"]);
          } else {
            this.router.navigate(["/campaign/target"]);
          }
        });
    }
  }

  budgetDetailClick() {
    if (this.campaignBasicDetailForm.invalid) {
      this.notifyService.showError("Please fill required details", "Invalid Form");
      // return;
    } else {
      this.confirmationDialogService
        .navigateConfirm("Confirmation ", "Are you sure you want to save this operation? ")
        .then((confirmed) => {
          if (confirmed) {
            let values = this.campaignBasicDetailForm.value;
            this.saveData(values);
            this.router.navigate(["/campaign/budget"]);
          } else {
            this.router.navigate(["/campaign/budget"]);
          }
        });
    }
  }

  ValidityClick() {
    if (this.campaignBasicDetailForm.invalid) {
      this.notifyService.showError("Please fill required details", "Invalid Form");
      return;
    } else {
      this.confirmationDialogService
        .navigateConfirm("Confirmation ", "Are you sure you want to save this operation? ")
        .then((confirmed) => {
          if (confirmed) {
            let values = this.campaignBasicDetailForm.value;
            this.saveData(values);
            this.router.navigate(["/campaign/validatstatus"]);
          } else {
            this.router.navigate(["/campaign/validatstatus"]);
          }
        });
    }
  }

  saveAndNext() {
    // this.submitted = true;
    if (this.campaignBasicDetailForm.invalid) {
      this.notifyService.showError("Please fill required details", "Invalid Form");
      return;
    } else {
      this.confirmationDialogService
        .confirm("Confirmation ", "Are you sure you want to Save? ")
        .then((confirmed) => {
          if (confirmed) {
             let values = this.campaignBasicDetailForm.value;
            if (this.campaignBasicDetailForm.value.tag.length !== 0) {
              this.campaignBasicDetailForm.value.tag.forEach((item: any) => {
                this.tagKeywords.push(item.tag);
              });
            }
            this.saveData(values);
            this.router.navigate(["/campaign/target"]);
          }
        });
    }
  }

  saveData(data: any) {
    if (this.editResponse === true) {
      this.campaignService.setEditBasicDetails(data, this.images3Path, this.tagKeywords);
    } else {
      this.campaignService.setBasicDetails(data, this.images3Path, this.tagKeywords);
      // this.data.push(values);
    }
  }

  cancel() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to cancel this operation? ")
      .then((confirmed) => {
        if (confirmed) {
          this.router.navigate(["/dashboard"]);
        }
      });
  }


}
