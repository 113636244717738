<div class="div-30">Puma Season Sale</div>
<div class="div-31">
  <nav>
    <button style="border: none;" (click)="campaignBasicDetailClick()" class="button">

      <div class="div-32">
        <div class="div-33" style="text-align: left;">Basic Details</div>
        <div class="div-34">Enter Campaign Details</div>
      </div>
    </button>
  </nav>
  <nav>
    <button style="border: none;" (click)="targetClick()" class="button">
      <div class="div-35">
        <div class="div-36" style="text-align: left;">Target Audience</div>
        <div class="div-37">Select the Target Audience</div>
      </div>
    </button>
  </nav>
  <nav>
    <button style="border: none;" (click)="budgetDetailClick()" class="button">
      <div class="div-38">
        <div class="div-39" style="text-align: left;">Budget Details</div>
        <div class="div-40">Set Budget</div>
      </div>
    </button>
  </nav>
  <div class="div-41">
    <div class="div-42">Validity & Status</div>
    <div class="div-43">Enter Validity</div>
  </div>
</div>

<form [formGroup]="validityStatusForm">
  <div class="div-44">
    <div class="div-45">Campaign validity</div>
    <div class="div-46">
      <div class="div-47">
        <div class="div-48">
          <div class="div-49 asterix">From</div>
          <!-- <img loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/93b381d4-8237-45ae-b550-b7305ed99686?"
            class="img-15" /> -->
        </div>
        <div class="div-50">
          <Input type="date" formControlName="from" min="{{ presentDate }}" onkeydown="return false" placeholder="Select Date"
            style="border: 0px solid transparent;" class="col-12" required ngDefaultControl />
          <!-- <div class="div-51">20 Oct 2023</div>
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a11df8c5-f527-4ad0-b1d3-15a8bbea6391?"
          class="img-16" /> -->
        </div>
      </div>
      <div class="div-52">
        <div class="div-53">
          <div class="div-54 asterix">To</div>
          <!-- <img loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/17ade54a-c7ed-4c94-a402-517a4d153350?"
            class="img-17" /> -->
        </div>
        <div class="div-55">
          <Input type="date" formControlName="to" min="{{ afterPresentDate }}" onkeydown="return false" placeholder="Select Date"
            style="border: 0px solid transparent;" class="col-12" required ngDefaultControl />
          <!-- <div class="div-56">20 Oct 2023</div>
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/aff62646-61d5-46eb-9615-43587119c748?"
          class="img-18" /> -->
        </div>
      </div>
    </div>
    <div class="div-57">
      <!-- <div class="div-53"> -->
      <div class="div-54 asterix"  *ngIf="createCampaign===false">Campaigns Status</div>
      <!-- <div class="div-56" *ngIf="createCampaign===true">
        <mat-radio-group aria-label="Select an option" formControlName="status" required>
          <mat-radio-button value="D">Draft</mat-radio-button>
          <mat-radio-button value="A">Live</mat-radio-button>
        </mat-radio-group>
      </div> -->
      <div class="div-56" *ngIf="createCampaign===false">
        <mat-radio-group aria-label="Select an option" formControlName="status" required>
          <!-- <mat-radio-button value="D">Draft</mat-radio-button> -->
          <mat-radio-button value="A">Live</mat-radio-button>
          <mat-radio-button value="P">Paused</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
    <div class="div-58">
      <button class="div-59" (click)="cancel()">Cancel</button>
      <div class="div-60">
        <!-- <div class="div-61">Preview</div> -->
        <button class="div-61" (click)="saveDraft()">Save</button>
        <button class="div-62" (click)="submit()">Go Live</button>
      </div>
    </div>
  </div>
</form>