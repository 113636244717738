import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilterFavouriteCampaignsComponent } from './favourites/filter-favourite-campaigns/filter-favourite-campaigns.component';
import * as moment from "moment";
import { Router } from '@angular/router';
import { CommonUtilService } from '../../services/common-util.service';
import { ColumnApi, GridApi, GridOptions } from "ag-grid-community";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HardcodedData } from '../hardcoded-data';
import { SwitchToggleComponent } from '../sharedComponent/switch-toggle/switch-toggle.component';
import { MessagingService } from 'src/app/services/messaging.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrl: './messaging.component.scss'
})
export class MessagingComponent implements OnInit {
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders:any;
  gridOptions = <GridOptions>{};
  gridApi: any;
  gridColumnApi: any;
  frameworkComponents: any;
  columnDefs: any;
  header: any;
  id: any;
  defaultColDef = {};
  paginationPageSize: any;
  currentPage: any;
  isPageChanged: any;
  types = [10, 20, 30];
  paramsSelectRecord = {
    type: "gridReady",
    api: GridApi,
    columnApi: ColumnApi,
  };
  minRowHeight = 25;
  data: any = [];
  loanFilter: any;
  activePage: number = 1;
  pageNo: number = 1;
  status:any;
  keywords= [];
  fromDate:any;
  toDate:any;
  searchValue: any;
  favMessagingData = new HardcodedData();
  searchField:any;
  messageForm: FormGroup = new FormGroup({});
  filterDataForChip:any;
  recordTotalCount :any = 0;

  constructor(private router: Router, public dialog: MatDialog,
    private commonUtilService: CommonUtilService, private fb: FormBuilder, private mssgService: MessagingService) {
      this.placeHolders = this.placeholders['Messaging_Page']
      this.createMessageForm();
  }

  ngOnInit(): void {
    this.getFavData();
    // this.data = this.favMessagingData.FavMessageData;
    this.defaultColDef = {
      editable: true,
      sortable: true,
      minWidth: 100,
      filter: true,
      resizable: false,
      tooltip: false,
      width: 170,
    };
  }

  getFavData() {    
    this.data = [];
    this.recordTotalCount = 0;
    this.mssgService.getFavAllList(this.pageNo, '').subscribe((res: any) => {
      if (res?.body?.data) {
        this.recordTotalCount = res?.body?.data?.TOTAL_DEALS;
        res?.body?.data?.FAVORITED_DEALS.forEach((item: any) => {
            let tableData = item;
            let parsedDate = moment(tableData.deal.available_to, "YYYY-MM-DD");
            tableData.deal.available_to = parsedDate.format("DD/MM/YYYY");
            this.data.push(tableData);
        });
      }
      this.defaultColDef = this.commonUtilService.getDefaultColumnDef();
      this.gridOptions.defaultColDef = this.defaultColDef;
      this.createColumnDefs();
      this.gridOptions.columnDefs = this.columnDefs;
      this.gridOptions.rowHeight = 70;
    });
  }

  createMessageForm() {
    this.messageForm = this.fb.group({
      pagination: [this.activePage],
      search: [''],
      filtertoDate: [this.toDate],
      filterfromDate: [this.fromDate],
      filterStatus: [''],
      tags: ['']
    });
  }

  onGridReady(params: any, value?: any) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (value && value !== null && value !== undefined) {
      this.paginationPageSize = value;
      this.ngOnInit();
    } else {
      this.paginationPageSize = 10;
    }
  }

  quickSearch(e: any) {
    // if (e.target.value.length >= 3 || e.target.value.length === 0) {
    this.gridApi.setQuickFilter(e.target.value);
    // }
  }

  searchClick(event: any) {
    this.gridApi.setQuickFilter(event.target.value);
  }

  createColumnDefs() {
    this.columnDefs = [];
    let header: any;

    header = this.commonUtilService.getColumnHeader(
      "deal.product_name",
      "Deal name"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filterParams.suppressAndOrCondition = true;
    header.minWidth = 220;
    // header.wrapText= true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    header.cellRenderer = (params: any) => {
      var link = document.createElement("a");
      link.href = "#";
      link.innerText = params.value;
      if (params.data.id < 5) {
        link.style.color = "darkblue";
      }
      link.addEventListener("click", (e) => {
        e.preventDefault();
        this.onEdit(params);
      });
      return link;
    };
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "deal.product_id",
      "Deal ID"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 30;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "deal.available_to",
      "Valid upto"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 70;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    // header = this.commonUtilService.getColumnHeader(
    //   "deal.status",
    //   "Status"
    // );
    // header.cellRenderer = SwitchToggleComponent;
    // header.filterParams = this.commonUtilService.getFilterButtons();
    // header.filter = false;
    // header.minWidth = 70;
    // header.filterParams.suppressAndOrCondition = true;
    // header.filterParams.filterOptions =
    //   this.commonUtilService.getCommaSeparatedFilter();
    // this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "details.totalFavorited",
      "No. of favourites"
    );
    // header.pinned = "right";
    header.filter = false;
    header.minWidth = 70;
    header.wrapText = true;
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);


    header = this.commonUtilService.getColumnHeader(
      "details.totalSpend",
      "Spend on favourites"
    );
    // header.pinned = "right";
    header.filter = false;
    header.minWidth = 70;
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "details.totalClaimed",
      "No. of user’s claimed"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 70;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    header = this.commonUtilService.getColumnHeader(
      "details.totalCustomerOfferMade",
      "No. of custom offer made"
    );
    header.filterParams = this.commonUtilService.getFilterButtons();
    header.filter = false;
    header.minWidth = 70;
    header.filterParams.suppressAndOrCondition = true;
    header.filterParams.filterOptions =
      this.commonUtilService.getCommaSeparatedFilter();
    this.columnDefs.push(header);

    // header = this.commonUtilService.getColumnHeader(
    //   "successfulTransaction",
    //   "No. of successfull Transaction"
    // );
    // header.filterParams = this.commonUtilService.getFilterButtons();
    // header.filter = false;
    // header.minWidth = 70;
    // header.filterParams.suppressAndOrCondition = true;
    // header.filterParams.filterOptions =
    //   this.commonUtilService.getCommaSeparatedFilter();
    // this.columnDefs.push(header);

  }

  onEdit(data: any) {    
    let parsedDate = moment(data.data.deal.available_to, "DD/MM/YYYY");
    data.data.deal.available_to = parsedDate.format("YYYY-MM-DD");
    this.mssgService.setData(data);
    this.router.navigate(["/messaging/favourites/selectUsers"]);
  }

  displayActivePage(activePageNumber: number) {
    this.activePage = activePageNumber;
    this.pageNo = activePageNumber;
     this.getFavData();
  }

  filterScreen() {
    this.filterDataForChip = null;
    this.dialog.open(FilterFavouriteCampaignsComponent, {
      height: 'auto',
      width: '500px',
      position: {
        top: '12vh',
        left: '30vw'
      },
      autoFocus: false,
      backdropClass: 'custom-dialog-overlay',  // mat-dialog css class
      disableClose: true  // If you click outside the mat-dialog box window, it will not close. 
    });
    this.mssgService.getCloseEvent().subscribe((res: any) => {
      this.filterDataForChip = [];
      let filterData = res;
      if (filterData !== null && filterData !== undefined) {
        this.keywords = [];
        let tagArray: any = [];
        if (filterData.tag && filterData.tag.length !== 0) {
          filterData.tag.forEach((item: any) => {
            tagArray.push(item);
          });
        }
        this.keywords = tagArray;
        this.status = filterData.status;
        this.fromDate = filterData.fromDate;
        this.toDate = filterData.toDate;
        this.getAllFitersData();        
        this.filterDataForChip = filterData.chipData;
      }
    });
  }

  getAllData() {
    this.recordTotalCount = 0;
    this.mssgService.getFavAllList(this.pageNo, this.messageForm.value.search).subscribe((res: any) => {
      if (res?.body?.data) {
        this.data = [];        
        this.recordTotalCount = res?.body?.data?.TOTAL_DEALS;
        res?.body?.data?.FAVORITED_DEALS.forEach((item: any) => {
          // if (item?.deal) {
            let tableData = item;
            let parsedDate = moment(tableData.deal.available_to, "YYYY-MM-DD");
            tableData.deal.available_to = parsedDate.format("DD/MM/YYYY");
            this.data.push(tableData);
          // }
        });
      }
      this.defaultColDef = this.commonUtilService.getDefaultColumnDef();
      this.gridOptions.defaultColDef = this.defaultColDef;
      this.createColumnDefs();
      this.gridOptions.columnDefs = this.columnDefs;
      this.gridOptions.rowHeight = 70;
    })
  }

  getAllFitersData() {
    let currentDate = new Date();  
    let parsedDate = moment(currentDate, "YYYY-MM-DD");
    let presentDate = parsedDate.format("MM/DD/YYYY");
    
    let past15Days = new Date(
      new Date().getTime() - 15 * 24 * 60 * 60 * 1000
    );
    let parsedpast15DaysDate = moment(past15Days, "YYYY-MM-DD");
    let past15Day = parsedpast15DaysDate.format("MM/DD/YYYY");
    this.recordTotalCount = 0;

    if(this.fromDate !== "" ) {
      this.toDate = this.toDate !== "" ? this.toDate : presentDate;
      this.filterDataForChip.toDate = this.toDate;
    } else if (this.toDate !== ""){      
      this.fromDate = this.fromDate !== "" ? this.fromDate : past15Day;
      this.filterDataForChip.fromDate = this.fromDate;
    } else if (this.fromDate === "" && this.toDate === "") {
      this.toDate = null;
      this.fromDate = null;
    }
    this.mssgService.getFavAllListwithFilter(this.pageNo, this.status, this.keywords, this.fromDate, this.toDate).subscribe((res: any) => {
      if (res?.body?.data) {
        this.data = [];        
        this.recordTotalCount = res?.body?.data?.TOTAL_DEALS;
        res?.body?.data?.FAVORITED_DEALS.forEach((item: any) => {
          // if (item?.deal) {
            let tableData = item;
            let parsedDate = moment(tableData.deal.available_to, "YYYY-MM-DD");
            tableData.deal.available_to = parsedDate.format("DD/MM/YYYY");
            this.data.push(tableData);
          // }
        });
      }
      this.defaultColDef = this.commonUtilService.getDefaultColumnDef();
      this.gridOptions.defaultColDef = this.defaultColDef;
      this.createColumnDefs();
      this.gridOptions.columnDefs = this.columnDefs;
      this.gridOptions.rowHeight = 70;
    })
  }

  search() {
    this.getAllData();
  }

  clearAll() {
    this.filterDataForChip = null;
    this.messageForm.reset();
    this.getFavData();
  }

}