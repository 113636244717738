import { Component, Input, OnChanges, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrl: './pagination.component.scss'
})
export class PaginationComponent implements OnChanges {

  @Input() totalRecords = 0;
  @Input() recordsPerPage = 0;
  @Input() activePage = 1;
  totalPage: any = 0;
  pageCount: any;
  // visiblepages:number[] =[];

  @Output() onPageChange: EventEmitter<number> = new EventEmitter();

  public pages: number[] = [];
  // activePage: any=1;  

  ngOnChanges(): any {
    this.pageCount = this.getPageCount();
    this.pages = this.getArrayOfPage(this.pageCount);

    // this.activePage = 1;  
    // this.onPageChange.emit(1);  
  }

  private getPageCount(): number {
    // let totalPage = 0;

    if (this.totalRecords > 0 && this.recordsPerPage > 0) {
      const pageCount = this.totalRecords / this.recordsPerPage;
      const roundedPageCount = Math.floor(pageCount);

      this.totalPage = roundedPageCount < pageCount ? roundedPageCount + 1 : roundedPageCount;
    }

    return this.totalPage;
  }

  private getArrayOfPage(pageCount: number): number[] {
    // if (pageCount === 1) {   // If total pages are 10 or fewer, return all pages
    //   this.pages = [];
    // } else if (pageCount <= 10) {
      this.pages = [];
      this.pages = Array(pageCount).fill(0).map((x, i) => i + 1);
    // }
    //  else { // If total pages are more than 10
    //   this.pages = [];
    //   const totalVisiblePages = 3;
    //   const halfVisblePages = Math.floor(totalVisiblePages / 2);
    //   const endPage = this.totalPage;

    //   // Return the first 5 pages, an ellipsis, and the last page
    //   this.pages = Array(totalVisiblePages).fill(0).map((x, i) => i + 1).concat(["...", endPage - 1, endPage])
    // }
    return this.pages;
  }

  onClickPage(pageNumber: number): void {
    if (pageNumber !== this.activePage) {
      if (pageNumber >= 1 && pageNumber <= this.pages[this.pages.length - 1]) {
        this.activePage = pageNumber;
        this.onPageChange.emit(this.activePage);
      } else if (pageNumber !== 0 && pageNumber <= this.pages[this.pages.length]) {
        const pageArray = [];

        if (this.pageCount > 0) {
          for (let i = 1; i <= this.pageCount; i++) {
            pageArray.push(i);
          }
        }

        this.pages = pageArray;
      }




      // if (pageNumber >= 1) {
      //   this.activePage = pageNumber;
      //   this.onPageChange.emit(this.activePage);
      // }
      //  else {
      //   const pageArray = [];

      //   if (this.pageCount > 0) {
      //     for (let i = 1; i <= this.pageCount; i++) {
      //       pageArray.push(i);
      //     }
      //   }

      //   this.pages = pageArray;
      // }
    }
  }

  onfirstPage(): void {
    let pageNumber = 1;
    if (pageNumber !== this.activePage) {
      this.activePage = pageNumber;
      this.onPageChange.emit(this.activePage);
    }
  }

  onLastPage(pageNumber: number): void {
    if (pageNumber !== this.activePage) {
      this.activePage = pageNumber;
      this.onPageChange.emit(this.activePage);
    }
  }

  onNextClickPage(pageNumber: number): void {
    if (pageNumber >= this.activePage && pageNumber !== this.activePage) {
      if (pageNumber >= 1 && pageNumber <= this.pages[this.pages.length - 1]) {
        this.activePage = pageNumber;
        this.onPageChange.emit(this.activePage);
      } 
    }
  }

  onPrevClickPage(pageNumber: number): void {
    if (pageNumber <= this.pages[this.pages.length - 1]) {
      if (pageNumber >= 1 && pageNumber <= this.pages[this.pages.length - 1]) {
        this.activePage = pageNumber;
        this.onPageChange.emit(this.activePage);
      }
    }
  }
}

