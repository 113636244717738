<!-- <div>
    <label
      class="switch switch-pill switch-secondary"
    >
      <input
        checked=""
        class="switch-input"
        type="checkbox"
        [checked]="getChecked(active)"
      />
  
      <span class="switch-slider"></span> 
    </label>
  </div> -->


<div class="active mt-2" *ngIf="active===true">
  <div class="div-104">Active</div>
  <!-- <div class="div-105"></div> -->
</div>
<div class="active-c mt-2" *ngIf="completed===true">
  <div class="completed">Completed</div>
  <!-- <div class="div-105"></div> -->
</div>


<div class="draft mt-2" *ngIf="draft===true">
  <div class="draft-text">Draft</div>
  <!-- <div class="div-305"></div> -->
</div>

<div class="paused mt-2" *ngIf="pending===true">
  <!-- <img
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/348a751f501739e889992dbb240e6ce9e2b9e47b5cce62142e4d1b86145b17f4?"
      class="img-38"
    /> -->
  <div class="div-304">Pending</div>
</div>
<div class="paused mt-2" *ngIf="paused===true">
  <!-- <img
      loading="lazy"
      src="https://cdn.builder.io/api/v1/image/assets/TEMP/348a751f501739e889992dbb240e6ce9e2b9e47b5cce62142e4d1b86145b17f4?"
      class="img-38"
    /> -->
  <div class="div-304">Paused</div>
</div>
<div class="div-303 mt-2" *ngIf="upcoming===true">
  <div class="upcoming-text ">Upcoming</div>
</div>

<div class="div-202 mt-2" *ngIf="expired===true">
  <img loading="lazy"
    src="https://cdn.builder.io/api/v1/image/assets/TEMP/8585a121130b4f4f34f4fff7d08e79aebc09031ba8a2a261276e4fd619bfc037?"
    class="img-44" />
  <div class="div-203">Expired</div>
</div>
<div class="div-202 mt-2" *ngIf="rejected===true">
  <img loading="lazy"
    src="https://cdn.builder.io/api/v1/image/assets/TEMP/8585a121130b4f4f34f4fff7d08e79aebc09031ba8a2a261276e4fd619bfc037?"
    class="img-44" />
  <div class="div-203">Rejected</div>
</div>
<div class="draft mt-2" *ngIf="new===true">
  <div class="draft-text">New</div>
  <!-- <div class="div-305"></div> -->
</div>
<div class="div-185 mt-2" *ngIf="inactive===true">
  <div class="div-186"></div>
  <div class="div-187">Inactive</div>
</div>