import { Component, EventEmitter, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { DashboardService } from 'src/app/services/dashboard.service';
import * as moment from "moment";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-filter-campaign',
  templateUrl: './filter-campaign.component.html',
  styleUrl: './filter-campaign.component.scss'
})
export class FilterCampaignComponent implements OnInit {
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders:any;
  data: any;
  allCategory: any;
  dropdownSettings: IDropdownSettings = {};
  fromDate: any;
  toDate: any;
  addForm!: FormGroup;
  categoryValue: any;
  categoryArray: any = [];
  categoryTypeArray: any = [];
  // onSubmitFilter = new EventEmitter();
  closeEvent: EventEmitter<any> = new EventEmitter();
  currency:any;

  constructor(public dialog: MatDialog, private dashboardService: DashboardService,
    private formBuilder: FormBuilder,@Inject('COUNTRY_CONFIG') private countryConfig: any
  ) {
    this.currency = this.countryConfig.currency;
    this.dropdownSettings = {
      idField: "category_id",
      textField: "category_type",
      enableCheckAll: true,
      // selectAllText: "Select All Users From List",
      // unSelectAllText: "Unselect All Users From List",
      noDataAvailablePlaceholderText: "There is no item availabale to show",
      allowSearchFilter: true,
      // itemsShowLimit: 3
      // defaultOpen: false
    };
    this.placeHolders = this.placeholders['dashboard_page']
  }

  ngOnInit(): void {
    this.data = this.dashboardService.getData();
    this.allCategory = this.data.source._value.data;
    this.createForm();
  }

  createForm() {
    this.addForm = this.formBuilder.group({
      category: [''],
      budget: [''],
      actualSpent: [''],
      status: ['All'],
      // toDate: [null,[Validators.required]],
      // active: new FormControl(this.roleDetails.active),
    });
  }

  editForm() {
    this.addForm = this.formBuilder.group({
      category: [],
      budget: [null],
      actualSpent: [null],
      status: ['All'],
    });
  }


  closeDialog() {
    this.addForm.reset();
    this.addForm.controls["budget"].reset();
    this.addForm.controls["actualSpent"].reset();
    this.addForm.controls["category"].reset();
    this.createForm();
    this.addForm.value.status = "All";

    // let data = {
    //   actualSpent: '',
    //   budget: '',
    //   category: [],
    //   status: '',
    //   chipData: {
    //     category: [],
    //     status: '',
    //     actualSpent: '',
    //     budget: '',
    //   }
    // }

    // this.dashboardService.emit(data);
    this.dialog.closeAll();
    // this.addForm.reset();
    // this.addForm.controls["budget"].reset();
    // this.addForm.controls["actualSpent"].reset();
    // this.addForm.controls["category"].reset();
    // this.createForm();
    // this.addForm.value.status = "All";
    // this.dashboardService.emit(null);
    // this.dialog.closeAll();
  }

  setCategoryValue(e: any) {
    this.categoryValue = e;
  }

  onSubmitFilter() {
    let value = this.addForm.value;
    let data = {
      actualSpent: value.actualSpent,
      budget: value.budget,
      category: [],
      status: '',
      chipData: {
        category: [],
        status: '',
        actualSpent: value.budget,
        budget: value.actualSpent,
      }
    }

    if (value.category !== "") {
      value.category.forEach((item: any) => {
        this.categoryTypeArray.push(item.category_type);
      });
    }
    if (value.status === 'All') {
      data.chipData.status = 'All';
    } else if (value.status === 'A') {
      data.chipData.status = 'Active';
    } else if (value.status === 'S') {
      data.chipData.status = 'Paused';
    } else if (value.status === 'E') {
      data.chipData.status = 'Expired';
    }else if (value.status === 'D') {
      data.chipData.status = 'Draft';
    }else if (value.status === 'P') {
      data.chipData.status = 'Pending';
    }
    data.chipData.category = this.categoryTypeArray;
    if (value.budget === '0') {
      data.chipData.budget = '$ 0-1000';
    } else if (value.budget === '1') {
      data.chipData.budget = '$ 1001-9999';
    } else if (value.budget === '2') {
      data.chipData.budget = 'Above $ 9999';
    } else {
      data.chipData.budget = '';
    }

    if (value.actualSpent === '0') {
      data.chipData.actualSpent = '$ 0-1000';
    } else if (value.actualSpent === '1') {
      data.chipData.actualSpent = '$ 1001-9999';
    } else if (value.actualSpent === '2') {
      data.chipData.actualSpent = 'Above $ 9999';
    } else {
      data.chipData.actualSpent = '';
    }

    if (value.category !== "") {
      value.category.forEach((item: any) => {
        this.categoryArray.push(item.category_id);
      });
    }
    if (value.status === 'All') {
      data.status = '';
    } else {
      data.status = value.status;
    }
    data.category = this.categoryArray;
    this.dashboardService.emit(data);
    this.dialog.closeAll();
    //this.dashboardService.getBrandSpecificDeals(value.fromDate, value.toDate, "1", value.status)
  }


  onReset() {
    this.categoryValue = null;
    // this.addForm.reset();
    // this.addForm.controls["status"].reset();
    this.addForm.controls["budget"].reset();
    this.addForm.controls["actualSpent"].reset();
    this.addForm.controls["category"].reset();
    this.createForm();
    this.addForm.value.status = "All";
  }
}
