<div class="div">
    <div class="div-2" style="justify-content: space-between;">Filter Users
    <mat-icon (click)="closeDialog()">close</mat-icon>
</div>
    <div class="div-3">
      <div class="div-4">
        <div class="div-5">
          <div class="div-6">Country</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6651221268d26d5f7c2459d3d86471df8c55372891d73df0aac8e167e05b80a?"
            class="img"
          />
        </div>
        <div class="div-7">
          <div class="div-8">USA</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6cac818db78e270d69547e74f2d65eb2a298b1ea7db0d8a1e69734a2144b579f?"
            class="img-2"
          />
        </div>
      </div>
      <div class="div-9">
        <div class="div-10">
          <div class="div-11">State</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6651221268d26d5f7c2459d3d86471df8c55372891d73df0aac8e167e05b80a?"
            class="img"
          />
        </div>
        <div class="div-12">
          <div class="div-13">California</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6cac818db78e270d69547e74f2d65eb2a298b1ea7db0d8a1e69734a2144b579f?"
            class="img-3"
          />
        </div>
      </div>
      <div class="div-14">
        <div class="div-15">
          <div class="div-16">City</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6651221268d26d5f7c2459d3d86471df8c55372891d73df0aac8e167e05b80a?"
            class="img"
          />
        </div>
        <div class="div-17">
          <div class="div-18">All</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/6cac818db78e270d69547e74f2d65eb2a298b1ea7db0d8a1e69734a2144b579f?"
            class="img-4"
          />
        </div>
      </div>
    </div>
    <div class="div-19">
      <div class="div-20">
        <div class="div-21">
          <div class="div-22">Gender</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6651221268d26d5f7c2459d3d86471df8c55372891d73df0aac8e167e05b80a?"
            class="img"
          />
        </div>
        <div class="div-23">
          <div class="div-24">Male</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7c9cb226b99e8185b5b0a8f2f8fe316c5f1ae9c857f4c692d34a13823fa14477?"
            class="img-5"
          />
        </div>
      </div>
      <div class="div-25">
        <div class="div-26">
          <div class="div-27">Age</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6651221268d26d5f7c2459d3d86471df8c55372891d73df0aac8e167e05b80a?"
            class="img-6"
          />
        </div>
        <div class="div-28">
          <div class="div-29">
            <div class="div-30">
              <div class="div-31">18-40</div>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/0b928e438cbc449794446fee71e59d9d8ebba3d13a5b8c6bcd0feaa3c7636dbb?"
                class="img-7"
              />
            </div>
            <div class="div-32">
              <div class="div-33">41-60</div>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/0b928e438cbc449794446fee71e59d9d8ebba3d13a5b8c6bcd0feaa3c7636dbb?"
                class="img-8"
              />
            </div>
          </div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f231dafc1b3fef336bbad007ade9614aba7f4b8bebd006e84c57ee6d4b1289a7?"
            class="img-9"
          />
        </div>
      </div>
    </div>
  </div>