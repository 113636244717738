import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ClassToggleService, HeaderComponent } from '@coreui/angular';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { TranslationService } from 'src/app/services/translation.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  styleUrls: ['./default-header.component.scss'],
})
export class DefaultHeaderComponent extends HeaderComponent {

  @Input() sidebarId: string = "sidebar";
  @Input() userFullName = '';
  @Input() userRoleType = '';
  
  readonly colorModes = [
    { name: 'en', text: 'English', icon: 'cilLanguage' },
    { name: 'fr', text: 'French', icon: 'cilLanguage' },
    // { name: 'auto', text: 'Auto', icon: 'cilContrast' }
  ];

  public newMessages = new Array(4)
  public newTasks = new Array(5)
  public newNotifications = new Array(5)

  merchantName:any;

  constructor(private classToggler: ClassToggleService,private cStorage: ClientSideStorageService, private translationService:TranslationService) {
    super();
    let marketplaceData = JSON.parse(this.cStorage.get("marketplaceData"));
    this.merchantName = marketplaceData.marketplace_name
  }

  colorMode(param:any){
    debugger
    this.translationService.changeLocale(param)
  }

}
