<div class="div">
  <div class="div-2">
    <div class="div-3">
      <!-- <img
          loading="lazy"
          srcset="..."
          class="img"
        /> -->
      <img loading="lazy" style="cursor: pointer;" routerLink="/login" src="https://cdn.builder.io/api/v1/image/assets/TEMP/a47714be-3217-4ffe-a745-a8e5e12fe2a1?"
        class="img-2" />
    </div>
    <div class="div-4">
      <div class="div-5">
        <!-- <div class="div-6">Quick Help</div>
        <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/fc0670d5-2dde-4318-8acd-255e7b8c768f?"
          class="img-3" /> -->
      </div>
      <div class="div-7">
        <div class="div-8">
          <img loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/298675b0-c2d5-415d-bfe8-dc2a4931ccf7?" class="img-4" />
          <div class="div-9">Existing user?</div>
        </div>

        <nav>
          <a routerLink="/login" class="button" routerLinkActive="active" ariaCurrentWhenActive="page">
            <div class="div-10">Login here</div>
          </a>
        </nav>
      </div>
    </div>
  </div>
  <form [formGroup]="registerForm">
    <div class="div-11">
      <div class="div-12">Company Registration</div>
      <div class="div-13">
        Please provide your email address to begin the registration process.
        The email you provide will be used as your login username.
        <br />
        Creating an account through this form will assign you as the Primary
        Contact for your company.
      </div>
      <div class="div-14">
        <div class="div-15">Company Details</div>
        <div class="div-16">
          Brand/ Retailer/ Businesses/ Government/ Others
        </div>
        <div class="div-17">
          <div class="div-18"></div>
        </div>
      </div>
      <div class="div-19">
        <!-- <div class="div-21">
            <img loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/c8f4f44b-f8c1-44b2-bffd-f3f58fdbb53f?"
              class="img-5" />
            <img loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/163293d9-3eb1-4d54-a3bc-34d88981fc6d?"
              class="img-6" />
          </div>
          <div class="div-25">Basic Information</div> -->
        <div class="container mt-4">
          <div class="row">
            <div class="row col-12">
              <div class="col-1 form-group">
                <img loading="lazy" style="margin-top: -50px;
                  margin-left: -18px;"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/c8f4f44b-f8c1-44b2-bffd-f3f58fdbb53f?"
                  class="img-5" />
              </div>
              <div class="col-4 form-group">
                <div class="div-25">Basic Information</div>
              </div>
            </div>
            <div class="row col-12">
              <div class="col-4 form-group">
                <label class="asterix" for="CompanyName">Company name</label>
                <input type="text" class="form-control" formControlName="companyName" placeholder="Enter company name"
                 pattern="^[a-zA-Z_ ]*$" [ngClass]="{ 'is-invalid':  f['companyName'].errors && (f['companyName'].dirty || f['companyName'].touched )}"
                  required />
                @if ( f['companyName'].errors ) {
                <div class="invalid-feedback">
                  @if (f['companyName'].errors['required']) {
                  <div>Company name is required</div>
                  }
                  @if (f['companyName'].errors['minlength']) {
                  <div>Company name must be at least 2 characters</div>
                  }
                  @if (f['companyName'].errors['maxlength']) {
                  <div>Company name must not exceed 50 characters</div>
                  }
                  @if (f['companyName'].errors['pattern']) {
                    <div>Only characters allowed</div>
                    }
                </div>
                }
              </div>
              <div class="col-4 form-group">
                <label for="companyWebsite" class="asterix" style="margin-left: 6px;">Company website</label>
                <input type="text" class="form-control" formControlName="companyWebsite"
                  placeholder="Enter company website"
                  [ngClass]="{ 'is-invalid':  f['companyWebsite'].errors && (f['companyWebsite'].dirty || f['companyWebsite'].touched )}"
                  required />
                @if ( f['companyWebsite'].errors) {
                <div class="invalid-feedback">
                  @if (f['companyWebsite'].errors['required']) {
                  <div>Company website is required</div>
                  }
                  @if (f['companyWebsite'].errors['minlength']) {
                  <div>Company website must be at least 2 characters</div>
                  }
                </div>
                }
              </div>
              <!-- <div class="col-4 form-group">
                  <label for="SelectCountry" class="asterix" style="margin-left: 6px;">Select Country</label>
                  <input type="text" class="form-control" />
                </div> -->
            </div>
            <div class="row col-12 pt-3">
              <div class="col-3 form-group" *ngIf="fileName ==null">
                <label for="CompanyLogo" class="asterix" style="margin-top: 7px;margin-left: 6px;">Company logo</label>
                <input class="form-control" type="file" formControlName="companyLogo" (change)="uploadImage($event)"
                  title="Upload"
                  [ngClass]="{ 'is-invalid':  f['companyLogo'].errors && (f['companyLogo'].dirty || f['companyLogo'].touched )}"
                  required />
                  @if ( f['companyLogo'].errors) {
                  <div class="invalid-feedback">
                    @if (f['companyLogo'].errors['required']) {
                    <div>Image is required</div>
                    }
                  </div>
                  }
                <small class="form-text text-muted" style="font-size: 10px;">
                  JPG, PNG or JPEG format & not exceed 2MB in size.
                </small>

              </div>
              <!-- <div class="col-1 form-group">
                  <button class="btn div-37" (click)="imageUpload()" style="cursor: pointer; margin-top: 25px;">Upload</button>
                </div> -->
              <div class="col-3 form-group" *ngIf="fileName!==null">
                <label for="CompanyLogo" class="asterix" style="margin-top: 7px;margin-left: 6px;">Company logo</label>
                <div class="form-control" type="text" disabled>{{fileName}}</div>
                <img *ngIf="fileName!==null" src="../../../assets/images/delete-icon.png" style="width: 12px;
                margin-left: 215px;
                margin-top: -60px;cursor: pointer;" (click)="removeImage()" />
              </div>
              <div class="col-1 form-group" *ngIf="!isImgUploaded">
                <button class="btn div-37"  (click)="imageUpload()"
                  style="cursor: pointer; margin-top: 33px;margin-left:-16px">Upload</button>
              </div>
              <div class="col-1 form-group" *ngIf="isImgUploaded">
                <!-- <button class="btn div-37" disabled
                  style="cursor: pointer; margin-top: 33px;">Uploaded</button> -->
                <img src="../../../assets/images/tickmark-icon.png" style="width: 23px; margin-top: 40px;" />
              </div>
              <!-- <div class="col-1 form-group" *ngIf="fileName!==null">
                 <button class="btn remove-btn" (click)="removeImage()"
                  style="cursor: pointer; margin-top: 33px;">Remove</button>
                  <img  *ngIf="fileName!==null" src="../../../assets/images/delete-icon.png" style="width: 12px;
                  margin-left: -133px;
                  margin-top: 42px;" (click)="removeDoc()" />
              </div> -->
              <div class="col-4 form-group">
                <label for="businessType" class="asterix" style="margin-left: 6px;"> Business type (retailers,
                  government)</label>
                <mat-form-field class="col-12">
                  <!-- <mat-label>Select Business Type</mat-label> -->
                  <mat-select placeholder="Select business type" formControlName="businessType" class="form-control"
                    style="margin-top: -7px; margin-left: -15px;width: 110%;" required>
                    @for (BusinessType of allBusinessTypes; track BusinessType) {
                    <mat-option [value]="BusinessType.business_type_id">{{BusinessType.business_type}}
                    </mat-option>
                    }
                  </mat-select>
                  <mat-error style="margin-top: -18px; margin-left: -15px; font-size: 14px;"
                    *ngIf="{ 'is-invalid': f['businessType'].errors && (f['businessType'].dirty || f['businessType'].touched )}">
                    Business type is required
                  </mat-error>
                </mat-form-field>
              </div>
              <!-- <div class="div-42" *ngIf="fileName!==null">
                  <div class="div-71" type="text" disabled>{{fileName}}</div>
                  <div class="div-43" (click)="removeImage()" style="cursor: pointer;">Remove</div>
                </div> -->


            </div>
            <div class="row col-12 pt-3">

              <div class="col-4 form-group" *ngIf="docName ==null">
                <label for="companyregistration">Company registration document(optional)</label>
                <input class="form-control" style="width: 240px;" type="file" (change)="uploadDoc($event)"
                  formControlName="document" title="Upload" />
                <small class="form-text text-muted" style="font-size: 10px;">
                  Document should not exceed 2MB in size.
                </small>
              </div>
              <div class="col-4 form-group" *ngIf="docName!==null">
                <label for="companyregistration">Company registration document(optional)</label>
                <div class="form-control" style="width: 240px;" type="text" disabled>{{docName}}</div>
                <img *ngIf="docName!==null" src="../../../assets/images/delete-icon.png" style="width: 12px;
                margin-left: 215px;
                margin-top: -60px;cursor: pointer;" (click)="removeDoc()" />
              </div>
              <div class="col-1 form-group" *ngIf="!isFileUploaded">
                <button class="btn div-37" (click)="documentUpload()"
                  style="cursor: pointer; margin-top: 27px;margin-left: -105px;">Upload</button>
              </div>
              <div class="col-1 form-group" *ngIf="isFileUploaded">
                <img src="../../../assets/images/tickmark-icon.png"
                  style="width:22px; margin-top: 27px;margin-left: -84px;" />
              </div>
              <!-- <div class="col-1 form-group" *ngIf="docName!==null">
                <button class="btn remove-btn" (click)="removeDoc()"
                  style="cursor: pointer; margin-top: 25px;margin-left: -84px;">Remove</button>
              </div> -->
              <div class="col-4 form-group">
                <label for="registrationId" class="asterix" style="margin-left: 6px;margin-left: -84px;">Registration
                  id</label>
                <input type="text" class="form-control" formControlName="registrationId"
                  placeholder="Enter registration id" style="margin-left: -84px;"
                  pattern="^[^\s]+$"
                  [ngClass]="{ 'is-invalid':  f['registrationId'].errors && (f['registrationId'].dirty || f['registrationId'].touched )}"
                  required />
                @if ( f['registrationId'].errors) {
                <div class="invalid-feedback" style="margin-left: -84px;">
                  @if (f['registrationId'].errors['required']) {
                  <div>Registration id is required</div>
                  }
                  @if (f['registrationId'].errors['minlength']) {
                  <div>Registration id must be at least 5 characters</div>
                  }
                  @if (f['registrationId'].errors['maxlength']) {
                  <div>Registration id must not exceed 20 characters</div>
                  }
                  @if (f['registrationId'].errors['pattern']) {
                  <div>Invalid id </div>
                  }
                </div>
                }
              </div>

            </div>
            <div class="row col-12 pt-3">
              <div class="col-6 form-group">
                <label class="asterix" for="Productcategories">Product categories</label>

                <ng-multiselect-dropdown class=" p-0 category" [settings]="dropdownSettings" [placeholder]="'Select categories'"
                  [data]="allCategory" bindLabel="category_type" bindValue="category_id" formControlName="productCategories"
                  [ngClass]="{ 'is-invalid':  f['productCategories'].errors && (f['productCategories'].dirty || f['productCategories'].touched )}" required>
                </ng-multiselect-dropdown>                
                @if ( f['productCategories'].errors) {
                  <div class="invalid-feedback">
                    @if (f['productCategories'].errors['required']) {
                    <div>Categories is required</div>
                    }
                  </div>
                  }

                <!-- <mat-form-field class="col-12">
                  <mat-select formControlName="productCategories" style="margin-top: -15px;margin-left: -15px;"
                    class="form-control">
                    @for (category of allCategory; track category) {
                    <mat-option [value]="category.category_id">{{category.category_type}}
                    </mat-option>
                    }
                  </mat-select>
                  <mat-error style="margin-top: -15px;margin-left: -15px;"
                    *ngIf="{ 'is-invalid':  f['productCategories'].errors && (f['productCategories'].dirty || f['productCategories'].touched )}">
                    Category is required
                  </mat-error>
                </mat-form-field> -->

              </div>
            </div>
            <div class="row col-12 pt-5">
              <div class="col-1 form-group">
                <img loading="lazy" style="margin-top: -50px;
                  margin-left: -18px;"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/c8f4f44b-f8c1-44b2-bffd-f3f58fdbb53f?"
                  class="img-5" />
              </div>
              <div class="col-4 form-group">
                <div class="div-25">Company address</div>
              </div>
            </div>
            <div class="row col-12">
              <div class="col-12 form-group">
                <label class="asterix" for="CompanyName">Address line</label>
                <textarea class="form-control" formControlName="addressLine" placeholder="Enter address"
                  [ngClass]="{ 'is-invalid':  f['addressLine'].errors && (f['addressLine'].dirty || f['addressLine'].touched )}"
                  required>
                </textarea>
                @if ( f['addressLine'].errors) {
                <div class="invalid-feedback">
                  @if (f['addressLine'].errors['required']) {
                  <div>Address is required</div>
                  }
                </div>
                }

              </div>
            </div>
            <div class="row col-12 pt-3">
              <div class="col-12 form-group">
                <label for="additionalInfo">Additional information</label>
                <textarea class="form-control" formControlName="additionalInfo"
                  placeholder="Landmark"> </textarea>
              </div>
            </div>
            <div class="row col-12 pt-3">
              <div class="col-3 form-group">
                <label class="asterix" for="CompanyName">Country</label>
                <mat-form-field class="col-12">
                  <mat-select formControlName="country" class="form-control"
                    style="margin-top: -15px;margin-left: -15px;" required>
                    @for (country of countryData; track country) {
                    <mat-option (click)="countrySelectionForState(country.country_id)"
                      [value]="country.country_id">{{country.country_name}}
                    </mat-option>
                    }
                  </mat-select>
                  <mat-error style="margin-top: -15px;margin-left: -15px;"
                    *ngIf="{ 'is-invalid':  f['country'].errors && (f['country'].dirty || f['country'].touched )}">
                    Country is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-3 form-group">
                <label class="asterix" for="CompanyName">State</label>
                <mat-form-field class="col-12">
                  <mat-select formControlName="state" class="form-control" style="margin-top: -15px;margin-left: -15px;"
                    required>
                    @for (state of stateData; track state) {
                    <mat-option (click)="stateSelectionForCity(state.state_code)"
                      [value]="state.state_code">{{state.state_name}}
                    </mat-option>
                    }
                  </mat-select>
                  <mat-error style="margin-top: -15px;margin-left: -15px;"
                    *ngIf="{ 'is-invalid':  f['state'].errors && (f['state'].dirty || f['state'].touched )}">
                    State is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-3 form-group">
                <label class="asterix" for="CompanyName">City</label>
                <mat-form-field class="col-12">
                  <mat-select formControlName="city" class="form-control" style="margin-top: -15px;margin-left: -15px;"
                    required>
                    @for (city of cityData; track city) {
                    <mat-option (click)="citySelectionForZip(city.zip_code,city.zip_id)"
                      [value]="city.zip_id">{{city.city_name}}
                    </mat-option>
                    }
                  </mat-select>
                  <mat-error style="margin-top: -15px;margin-left: -15px;"
                    *ngIf="{ 'is-invalid':  f['city'].errors && (f['city'].dirty || f['city'].touched )}">
                    city is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-3 form-group" *ngIf="zipCode!==undefined">
                <label class="asterix" for="CompanyName">Zip code</label>
                <input type="number" class="form-control" formControlName="zipCode" placeholder="Enter zIP code"
                  [ngClass]="{ 'is-invalid':  f['zipCode'].errors && (f['zipCode'].dirty || f['zipCode'].touched )}"
                  required style="margin-top: 8px;" />
                @if ( f['zipCode'].errors ) {
                <div class="invalid-feedback">
                  @if (f['zipCode'].errors['required']) {
                  <div>Zipcode is required</div>
                  }
                </div>
                }
              </div>
            </div>
            <div class="row col-12 pt-3">
              <div class="text-right">
                <button (click)="next()" class="btn div-63" style="cursor: pointer;float: right;">Save</button>
                <button (click)="reset()" class="btn btn-secondary"
                  style="float: right;margin-right: 5px;">Reset</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
<ngx-ui-loader fgsColor="#93d847" pbColor="#93d847" text="Please Wait..."></ngx-ui-loader>