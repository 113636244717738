import { Injectable } from "@angular/core";

const TOKEN_KEY = "AuthToken";
const ALTER_KEY = "AuthAlterToken";
const REF_KEY = "REFERESHToken";
const EXP_KEY = "EXP";
@Injectable()
export class TokenStorage {
  username:any;

  constructor() {}

  signOut() {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.clear();
  }

  public saveToken(token: string) {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public saveAlterToken(token: string) {
    window.sessionStorage.removeItem(ALTER_KEY);
    window.sessionStorage.setItem(ALTER_KEY, token);
  }

  public saveREFToken(token: string) {
    window.sessionStorage.removeItem(REF_KEY);
    window.sessionStorage.setItem(REF_KEY, token);
  }

  public saveEXPToken(exp: any) {
    window.sessionStorage.removeItem(EXP_KEY);
    window.sessionStorage.setItem(EXP_KEY, exp);
  }

  public saveUser(username: string) {
    window.sessionStorage.removeItem(username);
    window.sessionStorage.setItem(this.username, username);
  }

  public getToken(): any {
    return sessionStorage.getItem(TOKEN_KEY);
  }

  public getALterToken(): any {
    return sessionStorage.getItem(ALTER_KEY);
  }

  public getREFToken(): any {
    return sessionStorage.getItem(REF_KEY);
  }

  public getEXPToken(): any {
    return sessionStorage.getItem(EXP_KEY);
  }
  
  public getUser(): any {
    return sessionStorage.getItem(this.username);
  }

  public clearStorage() {
    window.sessionStorage.removeItem(TOKEN_KEY);
  }
}
