import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NotificationAlertService } from '../services/notification-alert.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,private notifyService:NotificationAlertService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree {
    const isMenuactive = sessionStorage.getItem('isDashboardActive');
    if (isMenuactive === 'true') {
      return true; // Allow navigation
    } else {
      // Redirect to a different route or show an error message
      this.notifyService.showError("Please complete your profile", "ERROR");
      return false//// Example: Redirect to login page
    }
  }
}
