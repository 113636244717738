import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CampaignService } from 'src/app/services/campaign.service';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { CommonUtilService } from 'src/app/services/common-util.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';

@Component({
  selector: 'app-budgetdetails',
  templateUrl: './budgetdetails.component.html',
  styleUrl: './budgetdetails.component.scss'
})
export class BudgetdetailsComponent {
  editData: any;
  editResponse: any;
  favId: any;
  clickId: any;
  clickCost: any;
  favCost: any;
  userData: any;
  BudgetDetailForm: FormGroup = new FormGroup({
    cpc: new FormControl(''),
    cpf: new FormControl(''),
    currency: new FormControl(''),
    budget: new FormControl(''),
  });

  constructor(private router: Router, private confirmationDialogService: ConfirmationDialogService, private cStorage: ClientSideStorageService,
    private campaignService: CampaignService, private fb: FormBuilder, private service: CommonUtilService,
    private notifyService: NotificationAlertService) {
    this.BudgetDetailForm.reset();
    this.editData = this.campaignService.getData();
    this.editData = this.editData.source._value;
    this.editResponse = this.campaignService.returnCreateAndEditRes();

    this.campaignService.price().subscribe((res: any) => {
      if (res?.body?.data) {
        res?.body?.data.forEach((item: any) => {
          if (item.event_type === "click") {
            this.clickId = item.event_id;
          } else if (item.event_type === "favorite") {
            this.favId = item.event_id;
          }
        });
      }

      this.userData = JSON.parse(this.cStorage.get("marketplaceData"));
      // this.service.getRegisterCompanyDetails().subscribe((res: any) => {
      if (this.userData) {
        //     let userData = res?.data;
        this.campaignService.eventCostByCountry(this.userData['country']).subscribe((response: any) => {
          if (response?.body?.data?.countryEventsCostList) {
            let event = response?.body?.data?.countryEventsCostList;
            this.clickCost = event.find((el: any) => el.event_id === this.clickId);
            this.favCost = event.find((el: any) => el.event_id === this.favId);
            if (this.editResponse === false) {
              this.createForm();
            } else {
              this.editForm();
            }
          }
        });
      }
      // });
    });
  }

  createForm() {
    this.BudgetDetailForm = this.fb.group({
      cpc: [this.clickCost.cost],
      cpf: [this.favCost.cost],
      currency: [this.clickCost.currency],
      budget: ['', Validators.required],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.BudgetDetailForm.controls;
  }

  editForm() {
    let res = this.editData;
    this.BudgetDetailForm = this.fb.group({
      cpc: [this.clickCost.cost],
      cpf: [this.favCost.cost],
      currency: [this.clickCost.currency],
      budget: [res.budget, Validators.required],
    });
  }

  saveAndNext() {
    if (this.BudgetDetailForm.invalid) {
      this.notifyService.showError("Please fill required details", "Invalid Form");
      return;
    } else {
      this.confirmationDialogService
        .confirm("Confirmation ", "Are you sure you want to Save? ")
        .then((confirmed) => {
          if (confirmed) {
            let values = this.BudgetDetailForm.value;
            this.saveData(values);
            this.router.navigate(["/campaign/validatstatus"]);
          }
        });
    }
  }

  saveData(data: any) {
    if (this.editResponse === true) {
      this.campaignService.setEditBudgetDetail(data, this.userData['marketplace_id']);
    } else {
      this.campaignService.setBudgetDetail(data, this.userData['marketplace_id']);
      // this.data.push(values);
    }
  }

  cancel() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to cancel this operation? ")
      .then((confirmed) => {
        if (confirmed) {
          this.router.navigate(["/dashboard"]);
        }
      });
  }

  targetClick() {
    if (this.BudgetDetailForm.invalid) {
      this.notifyService.showError("Please fill required details", "Invalid Form");
      return;
    } else {
      this.confirmationDialogService
        .navigateConfirm("Confirmation ", "Are you sure you want to save this operation? ")
        .then((confirmed) => {
          if (confirmed) {
            let values = this.BudgetDetailForm.value;
            this.saveData(values);
            this.router.navigate(["/campaign/target"]);
          } else {
            this.router.navigate(["/campaign/target"]);
          }
        });
    }
  }

  campaignBasicDetailClick() {
    if (this.BudgetDetailForm.invalid) {
      this.notifyService.showError("Please fill required details", "Invalid Form");
      return;
    } else {
      this.confirmationDialogService
        .navigateConfirm("Confirmation ", "Are you sure you want to save this operation? ")
        .then((confirmed) => {
          if (confirmed) {
            let values = this.BudgetDetailForm.value;
            this.saveData(values);
            this.router.navigate(["/campaign"]);
          } else {
            this.router.navigate(["/campaign"]);
          }
        });
    }
  }

  ValidityClick() {
    if (this.BudgetDetailForm.invalid) {
      this.notifyService.showError("Please fill required details", "Invalid Form");
      return;
    } else {
      this.confirmationDialogService
        .navigateConfirm("Confirmation ", "Are you sure you want to save this operation? ")
        .then((confirmed) => {
          if (confirmed) {
            let values = this.BudgetDetailForm.value;
            this.saveData(values);
            this.router.navigate(["/campaign/validatstatus"]);
          } else {
            this.router.navigate(["/campaign/validatstatus"]);
          }
        });
    }
  }
}
