<a routerLink="/messaging" class="div-26">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" fill="rgba(135,138,155,1)">
        <path
            d="M12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48 22 2 17.52 2 12C2 6.48 6.48 2 12 2ZM12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12C4 16.42 7.58 20 12 20ZM12 11H16V13H12V16L8 12L12 8V11Z">
        </path>
    </svg>
    Back</a>
<mat-card style="padding: 5px;">
    <div>
        <div class="row">
            <div class="col-md-8">
                <p class="mt-2 mb-1"><b>Deal Title:</b>
                    <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i> {{dealData.deal.product_name}}
                </p>
            </div>
            <div class="col-md-4">
                <p class="mt-2 mb-1"><b>Coupoun Code:</b>
                    <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>{{dealData.deal.coupon_code}}
                </p>
            </div>
        </div> <!-- end row -->
        <div class="row">
            <div class="col-md-8">
                <p class="mt-2 mb-1 deal-description">
                    <span class="label"><b>Deal Description :</b></span>
                    <span class="content">
                        <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>
                        {{dealData.deal.product_description}}
                    </span>
                </p>
            </div>
            <div class="col-md-4">
                <p class="mt-2 mb-1"><b>Discount type:</b>
                    <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i> {{dealData.deal.discountDescription}}
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <label><b>Target audience:</b></label>
            </div>
            <div class="col-md-4">
                <p class="mt-2 mb-1"><b>Valid from:</b>
                    <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>
                    {{dealData.deal.available_from | date: 'dd-MM-yyyy' }}
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-8 row">
                <div class="col-md-2"><b>Gender: </b><i>{{dealData.deal.gender}}</i>
                </div>
                <div class="col-md-3"><b>Age Range: </b><i>{{ageRangeLabel}}</i>
                </div>
                <div class="col-md-3"><b>Location: </b><i>{{dealData.deal.countryName}}</i>
                </div>
            </div>
            <div class="col-md-4" style="margin-left: 24px;">
                <p class="mt-2 mb-1"><b>Valid to:</b>
                    <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i> {{dealData.deal.available_to |
                    date: 'dd-MM-yyyy'}}
                </p>
            </div>
        </div>
    </div>
</mat-card>
<div class="tabs pt-2">
    <button class="btn-nav" (click)="activeTab = 'messaging'" [class.active]="activeTab === 'messaging'"
        [class.bg-messaging]="activeTab === 'messaging'" [disabled]="dealData.deal.status !== 'A'">Messaging</button>
    <button class="btn-nav" style="margin-left: 1px;" (click)="activeTab = 'history'"
        [class.active]="activeTab === 'history'" [class.bg-history]="activeTab === 'history'">History</button>
</div>
<mat-card style="padding: 5px; margin-top: 6px;">


    <div [ngSwitch]="activeTab">
        <ng-container *ngSwitchCase="'messaging'">
            <div class="p-2">
                <div class="row">
                    <div class="col-md-3">
                        <mat-chip class="chip-color">
                            <p class="mt-2 mb-1">#High prospects:
                                <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>
                                {{dealData.details.totalUniqueProspects}}
                            </p>
                        </mat-chip>
                    </div>
                    <div class="col-md-3">
                        <mat-chip class="chip-color">
                            <p class="mt-2 mb-1">Retargeted:
                                <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>
                                {{dealData.details.totalCustomerOfferMade}}
                            </p>
                        </mat-chip>
                    </div>
                    <div class="col-md-3">
                        <mat-chip class="chip-color">
                            <p class="mt-2 mb-1">Pending Prospects:
                                <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>
                                {{dealData.details.totalPendingProspects}}
                            </p>
                        </mat-chip>
                    </div>
                </div> <!-- end row -->
                <div class="row pt-2">

                    <div class="col-md-3">
                        <mat-chip class="chip-color">
                            <p class="mt-2 mb-1">Cost per Message:
                                <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>
                                {{msgSendCost?.cost}}
                            </p>
                        </mat-chip>
                    </div>
                    <div class="col-md-3">
                        <mat-chip class="chip-color">
                            <p class="mt-2 mb-1">Total Spent:
                                <i class='mdi mdi-ticket font-18 text-success me-1 align-middle'></i>
                                {{dealData.details.totalSpend}}
                            </p>
                        </mat-chip>
                    </div>

                </div> <!-- end row -->
                <div class="row">

                </div> <!-- end row -->
            </div>
            <mat-card style="background-color: #36849d78;padding: 16px;">
                <div class="filter-controls">
                    <div class="control-group">
                        <label for="gender">Gender:</label>
                        <select id="gender" [(ngModel)]="selectedGender">
                            <option value="">Select Gender</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="">All</option>
                        </select>
                    </div>

                    <div class="control-group">
                        <label for="ageRange">Age range:</label>
                        <select id="ageRange" [(ngModel)]="selectedAgeRange">
                            <option value="">Select Age Range</option>
                            <option value="">All</option>
                            <option *ngFor="let range of filteredAgeRanges" [value]="range.minAge + '-' + range.maxAge">
                                {{ range.label }}
                            </option>
                            <!-- <option value="1-100">All</option>
                            <option value="13-17">13-17</option>
                            <option value="18-25">18-25</option>
                            <option value="26-40">26-40</option>
                            <option value="41-60">41-60</option>
                            <option value="61-Above">61+</option> -->
                        </select>
                    </div>

                    <div class="control-group">
                        <label for="state">State:</label>
                        <select id="state" [(ngModel)]="selectedState" (change)="stateSelectionForCity($event)">
                            <option value="">Select State</option>
                            <option *ngFor="let state of stateData" [value]="state.state_id">
                                {{ state.state_name }}
                            </option>
                        </select>
                    </div>

                    <div class="control-group">
                        <label for="city">City:</label>
                        <select id="city" [(ngModel)]="selectedCity">
                            <option value="">Select City</option>
                            <option *ngFor="let city of cityData" [value]="city.city_name">
                                {{ city.city_name }}
                            </option>
                            <!-- Add city options here -->
                        </select>
                    </div>

                    <button (click)="getFilteredData()" class="filter-button">Apply Filter</button>
                </div>
            </mat-card>
            <form [formGroup]="messageForm" (ngSubmit)="onSubmit()">
                <div style="padding: 20px;">
                    <div class="row">
                        <div class="col-md-6">
                            <p class="mt-2 mb-1">Target Prospects :
                                <input style="background-color: #80808063;" class="inputstyle" matInput placeholder="Target"
                                    formControlName="targetProspectus" [readonly]="true">
                                <!-- <mat-error
                                    *ngIf="messageForm?.get('targetProspectus')?.invalid && messageForm?.get('targetProspectus')?.touched">
                                    Please enter a valid target (minimum 1)
                                </mat-error> -->
                            </p>
                        </div>
                        <div class="col-md-6">
                            <p class="mt-2 mb-1"> New Coupon Code :
                                <input style="width: 46%;" class="inputstyle" matInput placeholder="Coupon code"
                                    formControlName="couponCode" maxlength="20">
                                <!-- <mat-error
                                    *ngIf="messageForm?.get('couponCode')?.invalid && messageForm?.get('couponCode')?.touched">
                                    Please enter a valid coupon code (minimum 3 characters)
                                </mat-error> -->
                            </p>
                        </div>
                    </div> <!-- end row -->
                    <div class="row">
                        <div class="col-md-12">
                            <p class="mt-2 mb-1">Message Description :
                                <textarea style="width: 88%; border-radius: 5px;" matInput rows="5" maxlength="300"
                                    placeholder="Enter deal description"
                                    formControlName="messageDescription"></textarea>
                                    <span style="float: inline-end; font-size: small;margin-right: 125px;"> Character Count: {{
                                        charCount}}/300</span>
                                <!-- <mat-error
                                    *ngIf="messageForm?.get('messageDescription')?.invalid && messageForm?.get('messageDescription')?.touched">
                                    Please enter a message description (maximum 300 characters)
                                </mat-error> -->
                            </p>
                           
                        </div>
                    </div> <!-- end row -->
                    <div class="row mt-2">
                        <div class="col-md-9">
                            <p class="mt-2 mb-1">Total Cost :
                                <input style="background-color: #80808063;" class="inputstyle" matInput placeholder="Total" formControlName="totalCost"
                                    [readonly]="true">
                                <!-- <mat-error
                                    *ngIf="messageForm?.get('totalCost')?.invalid && messageForm?.get('totalCost')?.touched">
                                    Please enter a valid total cost (minimum 0)
                                </mat-error> -->
                            </p>
                        </div>
                        <div class="col-md-2">
                            <!--  -->
                            <button type="submit" class="btn div-63" [disabled]="messageForm.invalid">Send
                                Message</button>
                        </div>
                    </div> <!-- end row -->
                </div>
            </form>
            <!-- <div style="padding: 20px;">
                <div class="row">
                    <div class="col-md-6">
                        <p class="mt-2 mb-1">Target Prospectus :
                            <input class="inputstyle" matInput placeholder="Target">
                        </p>
                    </div>
                    <div class="col-md-6">
                        <p class="mt-2 mb-1"> New Coupon Code :
                            <input class="inputstyle" matInput placeholder="Coupon code">
                        </p>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-md-12">
                        <p class="mt-2 mb-1">Message Description :
                            <textarea style="width: 88%; border-radius: 5px;" matInput rows="5" maxlength="300"
                                placeholder="Enter deal description"></textarea>
                        </p>
                    </div>
                </div> 
                <div class="row">
                    <div class="col-md-8">
                        <p class="mt-2 mb-1">Total Cost :
                            <input class="inputstyle" matInput placeholder="Total">
                        </p>
                    </div>
                    <div class="col-md-2">
                        <button class="btn div-63">Send Message</button>
                    </div>

                </div> 
            </div> -->
        </ng-container>
        <ng-container *ngSwitchCase="'history'">
            <div class="col-md-2 pt-3">
                <mat-chip class="chip-color">
                    <p class="mt-2 mb-1">Total Spent: <span>{{totalAmountSpent}}</span></p>
                </mat-chip>
            </div>
            <table class="mt-2">
                <thead>
                    <tr>
                        <th>Messages sent on</th>
                        <th>Message Description</th>
                        <th>Coupon code</th>
                        <th>Messages Send</th>
                        <th>Messages Viewed</th>
                        <th>Total Spent</th>
                        <th>Applied Filter</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of messageData">
                        <td>{{ data.sentOn | date:'short' }}</td>
                        <td>{{ data.description }}</td>
                        <td>{{ data.couponCode }}</td>
                        <td>{{ data.messagesSent }}</td>
                        <td>{{ data.messagesViewed }}</td>
                        <td>{{ data.totalSpent | currency }}</td>
                        <td>{{ data.appliedFilter}}</td>
                    </tr>
                </tbody>
            </table>
        </ng-container>
    </div>
</mat-card>