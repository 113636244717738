<!-- <div class="div">
    <div class="div-6">
      <div class="div-7"> -->
<!-- <div class="column-2"> -->
<!-- <div class="div-29"> -->
<div class="div-30">Puma Season Sale</div>
<div class="div-31">
  <nav>
    <button style="border: none;" (click)="campaignBasicDetailClick()" class="button">
      <div class="div-32">
        <div class="div-33" style="text-align: left;">Basic Details</div>
        <div class="div-34">Enter Campaign Details</div>
      </div>
    </button>
  </nav>
  <div class="div-35">
    <div class="div-36">Target Audience</div>
    <div class="div-37">Select the Target Audience</div>
  </div>
  <nav>
    <button style="border: none;" (click)="budgetDetailClick()" class="button">
      <div class="div-38">
        <div class="div-39">Budget Details</div>
        <div class="div-40" style="text-align: left;">Set Budget</div>
      </div>
    </button>
  </nav>
  <nav>
    <button class="button" style="border: none;" (click)="ValidityClick()">
      <div class="div-41">
        <div class="div-42">Validity & Status</div>
        <div class="div-43" style="text-align: left;">Enter Validity</div>
      </div>
    </button>
  </nav>
</div>
<form [formGroup]="targetForm">
  <div class="div-44">
    <div class="div-45">Target Country</div>
    <div class="div-46">Select country you want to target .</div>
    <div class="div-47">
      <div class="div-48">Country</div>
      <!-- <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/2d49f5c8-7fa1-4f7b-acc5-b7923f915739?"
        class="img-15" /> -->
    </div>
    <div class="div-1000">
      <div class="div-50">
        <input type="text" [readonly]="true" formControlName="country" class=" col-4 " />
      </div>
      <!-- <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/af95113f-c3f2-4246-a1d4-457397f2272c?"
        class="img-16" /> -->
    </div>
    <div class="div mt-5">Target Audience</div>
    <div class="div-51">
      <mat-radio-group aria-label="Select an option" formControlName="select">
        <mat-radio-button class="div-53" (change)="selectClick()" value="EC">Entire country</mat-radio-button>
        <mat-radio-button class="div-55 mx-5" (change)="customClick()" value="C">Custom</mat-radio-button>
      </mat-radio-group>
      <!-- <div class="div-52">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/072b5f88-e8cf-4c67-80bc-cbda517a8f80?"
                    class="img-17"
                  />
                  <div class="div-53">Entire country</div>
                </div>
                <div class="div-54">
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/e9b34829-3efb-4423-95e6-e08fad6575a9?"
                    class="img-18"
                  />
                  <div class="div-55">Custom</div>
                </div> -->
    </div>


    <span class="span pt-3" *ngIf="targetAudience!==false">

      <!-- <div class=""> -->
      <div class="col-3 pt-4">
        <span class="">
          <div class="div-4 pb-2 asterix">Gender</div>
          <!-- <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
              class="img"
            /> -->
        </span>
        <span class="div-49 col-4">

          <mat-form-field class="col-12">
            <mat-label>Select Gender</mat-label>
            <mat-select formControlName="gender" class=" col-4 " required>
              @for (gen of gender; track gen) {
              <mat-option [value]="gen.id">{{gen.name}}
              </mat-option>
              }
            </mat-select>            
            <mat-error 
            *ngIf="{ 'is-invalid': f['gender'].errors && (f['gender'].dirty || f['gender'].touched )}">
            Gender is required
          </mat-error>
          </mat-form-field>

          <!-- <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Select Gender'" [data]="gender"
              bindLabel="name" bindValue="name" formControlName="gender" required>
            </ng-multiselect-dropdown> -->
          <!-- <div class="div-5">Male</div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/717590e7b5540a948d7067906f8675c9b629ac930b6d7ffdcb0d7579a4380246?"
              class="img-2"
            /> -->
        </span>
      </div>
      <div class="pt-2" style="display: flex;">
        <span class="col-6">
          <div class="div-7 pt-2 pb-2 asterix">Lower Age</div>
          <!-- <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6651221268d26d5f7c2459d3d86471df8c55372891d73df0aac8e167e05b80a?"
              class="img-3"
            /> -->
          <!-- <div class=""> -->
          <div class="col-6 div-49">
            <!-- <span class="col-4"> -->

            <mat-form-field class="col-12">
              <mat-label>Select Lower Age</mat-label>
              <mat-select formControlName="lowerAge" class=" col-4 " required>
                @for (age of lowerAgeGroup; track age) {
                <mat-option [value]="age.id">{{age.lower_age}}
                </mat-option>
                }
              </mat-select>
              <mat-error 
            *ngIf="{ 'is-invalid':  f['lowerAge'].errors && (f['lowerAge'].dirty || f['lowerAge'].touched )}">
            Lower Age is required
          </mat-error>
            </mat-form-field>

            <!-- <ng-multiselect-dropdown [settings]="agedropdownSettings" [placeholder]="'Select Lower Age'" [data]="lowerAgeGroup"
                  bindLabel="age" bindValue="age" formControlName="lowerAge" required>
                </ng-multiselect-dropdown> -->

            <!-- <div class="div-10">18-40</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/57f6728b9abb08ca48a3c5a44dfce8050541257cf72d76f742f37dba5d9c5bc3?"
                  class="img-4" 
                />-->
            <!-- </span> -->
            <!-- <span class="span-6">
                <div class="div-11">41-60</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/57f6728b9abb08ca48a3c5a44dfce8050541257cf72d76f742f37dba5d9c5bc3?"
                  class="img-5"
                />
              </span> -->
          </div>
          <!-- <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f231dafc1b3fef336bbad007ade9614aba7f4b8bebd006e84c57ee6d4b1289a7?"
              class="img-6"
            /> -->
          <!-- </div> -->
        </span>


        <span class="col-6">
          <div class="div-7 pt-2 pb-2 asterix">Upper Age</div>
          <!-- <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6651221268d26d5f7c2459d3d86471df8c55372891d73df0aac8e167e05b80a?"
              class="img-3"
            /> -->
          <!-- <div class=""> -->
          <div class="col-6 div-49">
            <!-- <span class="col-4"> -->

            <mat-form-field class="col-12">
              <mat-label>Select Upper Age</mat-label>
              <mat-select formControlName="upperAge" class="col-6 " required>
                @for (age of upperAgeGroup; track age) {
                <mat-option [value]="age.id">{{age.upper_age}}
                </mat-option>
                }
              </mat-select>
              <mat-error 
            *ngIf="{ 'is-invalid':  f['upperAge'].errors && (f['upperAge'].dirty || f['upperAge'].touched )}">
            Upper Age is required
          </mat-error>
            </mat-form-field>

            <!-- <ng-multiselect-dropdown [settings]="agedropdownSettings" [placeholder]="'Select Upper Age'" [data]="upperAgeGroup"
                  bindLabel="age" bindValue="age" formControlName="upperAge" required>
                </ng-multiselect-dropdown> -->

            <!-- <div class="div-10">18-40</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/57f6728b9abb08ca48a3c5a44dfce8050541257cf72d76f742f37dba5d9c5bc3?"
                  class="img-4" 
                />-->
            <!-- </span> -->
            <!-- <span class="span-6">
                <div class="div-11">41-60</div>
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/57f6728b9abb08ca48a3c5a44dfce8050541257cf72d76f742f37dba5d9c5bc3?"
                  class="img-5"
                />
              </span> -->
          </div>
          <!-- <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f231dafc1b3fef336bbad007ade9614aba7f4b8bebd006e84c57ee6d4b1289a7?"
              class="img-6"
            /> -->
          <!-- </div> -->

        </span>



      </div>
      <!-- </div> -->
    </span>

    <div class="div-56">
      <button class="div-57" (click)="cancel()">Cancel</button>
      <div class="div-58">
        <!-- <div class="div-59">Preview</div> -->
        <button class="div-60" (click)="saveAndNext()">Save & Next</button>
      </div>
    </div>
  </div>
</form>
<!-- </div> -->
<!-- </div> -->
<!-- </div>
    </div>
  </div> -->