import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders:any;
  @Input()
  title!: string;
  @Input()
  message!: string;
  @Input()
  data!: any;
  @Input()
  btnOkText!: string;
  @Input()
  btnCancelText!: string;
  constructor(private activeModal: NgbActiveModal) {
    this.placeHolders = this.placeholders['Registration_page']
   }

  ngOnInit(): void {
  }
  public decline() {
    this.activeModal.close(false);
  }

  public accept() {
    this.activeModal.close(true);
  }

  public dismiss() {
    this.activeModal.dismiss();
  }
}
