<div class="div-25">{{placeHolders['LBL_TITLE_MESSAGING']}}</div>
<div class="div-26">
  <span>
    <a routerLink="/messaging" class="button" routerLinkActive="active" ariaCurrentWhenActive="page">
      <div class="div-27">
        <div class="div-28">{{placeHolders['LBL_TAB_FAVOURITES']}}</div>
        <div class="div-29">
          {{placeHolders['LBL_LIST_DEALS_FAVOURITED']}}
        </div>
      </div>
    </a>
  </span>
  <div class="div-30">
    <div class="div-31">{{placeHolders['LBL_TAB_BIRTHDAY']}}</div>
    <div class="div-32">{{placeHolders['LBL_BIRTHDAY_MESSAGES_FOR']}}</div>
  </div>
  <span>
    <a routerLink="/messaging/festive" class="button" routerLinkActive="active" ariaCurrentWhenActive="page">
      <div class="div-33">
        <div class="div-34">{{placeHolders['LBL_TAB_FESTIVE']}}</div>
        <div class="div-35">{{placeHolders['LBL_FESTIVAL_MESSAGES_FOR']}}</div>
      </div>
    </a>
  </span>
</div>
<div class="div-36">
  <div class="div-37">
    <div class="div-38">{{placeHolders['LBL_BIRTHDAY_MESSAGES']}}</div>
    <div class="div-39">
      <div class="div-40" *ngIf="userRes">
        <div class="div-41">{{userRes.userCount}}</div>
        <div class="div-42">Current user’s</div>
      </div>
      <div class="div-43" *ngIf="userRes">
        <div class="div-44">{{userRes.monthlyUpcomingBirthdayCount}}</div>
        <div class="div-45">Upcoming birthday’s</div>
      </div>

      <div class="div-50 mx-2">
        <div class="div-51" style="cursor: pointer;" (click)="composeMessage()">{{placeHolders['LBL_CREATE_NEW_MSG']}}</div>
        <img loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/862bfa063408c274fe5cb1f1741baf3baa7ab670148862f07274b8cea7a6d4e6?"
          class="img-13" />
      </div>
    </div>
  </div>
  <div class="div-46">
    <div *ngIf="data.length!==0">

      <ag-grid-angular style="width: 100%; " class="ag-theme-quartz" [rowData]="data" domLayout='autoHeight'
        [gridOptions]="gridOptions" [suppressDragLeaveHidesColumns]="true" [columnDefs]="columnDefs" [animateRows]="true" [pagination]="false"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
      <!-- <div class="div-48">
        <img loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/4a99c5f3c628d567eeb2198dd7569df69562042cfaad0c4599b9a5da1c1e54eb?"
          class="img-12" />
        <div class="div-49">
          Create offer message for the upcoming birthday’s of the
          users
        </div>
        <div class="div-50">
          <div class="div-51" style="cursor: pointer;" (click)="composeMessage()">Create New Message</div>
          <img loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/862bfa063408c274fe5cb1f1741baf3baa7ab670148862f07274b8cea7a6d4e6?"
            class="img-13" />
        </div>
      </div> -->
    </div>
    <div class="div-Norecord"  *ngIf="data.length==0">
      <div class="div-Norecord2">
        <div class="div-Norecord3">No record found !</div>
      </div>
    </div>

    <div class="row mt-2" *ngIf="data.length!==0">
      <div class="col-lg-12 col-md-12 ml-auto mr-auto">
        <div class="float-right">
          <app-pagination *ngIf="recordTotalCount!==0" [totalRecords]="recordTotalCount" [recordsPerPage]="10"
            [activePage]="activePage" (onPageChange)="displayActivePage($event)"></app-pagination>
        </div>
      </div>
    </div>
  </div>
</div>