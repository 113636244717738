import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClientSideStorageService } from './client-side-storage.service';
import {environment} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  private editCampaignData = new BehaviorSubject<any>([]);
  // private createCampaignData = new BehaviorSubject<any>([]);
  userId: any;
  createEdit: any = {
    edit: false
  };
  createCampaignPayload = {
    // "category_id": null,
    "sub_category_id": null,
    "product_name": null,
    "budget":null,
    "product_description": null,
    "merchant_id": null,
    "product_active": null,
    "product_eligibility_score": null,
    "status": null,
    "exclusive": null,
    "sample": null,
    "product_entry_criteria": null,
    "physical_sample": null,
    "printed_offer": null,
    "digital_offer": null,
    "location": null,
    "coupon_code": null,
    "available_from": null,
    "available_to": null,
    "target": null,
    "lower_age": null,
    "upper_age": null,
    "gender": null,
    "product_image": null,
    "total_spent": null,
    "product_url": null,
    "discountType" : null,
    "discountPercentage" : null,
    "keywords":[],
    "paymentDone":null
  }
  editCampaignPayload = {
    // "category_id": null,
    "sub_category_id": null,
    "product_name": null,
    "product_description": null,
    "merchant_id": null,
    "product_active": null,
    "product_eligibility_score": null,
    "status": null,
    "exclusive": null,
    "sample": null,
    "budget":null,
    "product_entry_criteria": null,
    "physical_sample": null,
    "printed_offer": null,
    "digital_offer": null,
    "location": null,
    "coupon_code": null,
    "available_from": null,
    "available_to": null,
    "target": null,
    "lower_age": null,
    "upper_age": null,
    "gender": null,
    "product_image": null,
    "total_spent": null,
    "product_url": null,
    "product_id": null,
    "discountType" : null,
    "discountPercentage" : null,
    "keywords":[],
    "paymentDone":null
  }

  constructor(private http: HttpClient, private cStorage: ClientSideStorageService) { }

  setData(param: any): void {
    this.editCampaignData.next(param);
    if (param === null) {
      this.createEdit.edit = false;
    } else {
      this.createEdit.edit = true;
      this.editCampaignPayload.merchant_id = param?.merchant_id;
      // this.editCampaignPayload.category_id = param?.category_id;
      this.editCampaignPayload.product_description = param?.product_description;
      this.editCampaignPayload.product_image = param?.product_image;
      this.editCampaignPayload.coupon_code = param?.coupon_code;
      this.editCampaignPayload.product_name = param?.product_name;
      this.editCampaignPayload.lower_age = param?.lower_age;
      this.editCampaignPayload.upper_age = param?.upper_age;
      this.editCampaignPayload.gender = param?.gender;
      this.editCampaignPayload.budget = param?.budget;
      this.editCampaignPayload.available_from = param?.available_from;
      this.editCampaignPayload.available_to = param?.available_to;
      this.editCampaignPayload.status = param?.status;
      this.editCampaignPayload.product_url = param?.product_url;
      this.editCampaignPayload.product_id = param?.product_id;
      this.editCampaignPayload.sub_category_id = param?.sub_category_id;
      this.editCampaignPayload.location = param?.location;
      this.editCampaignPayload.product_active = param?.product_active;
      this.editCampaignPayload.total_spent = param?.total_spent;
      this.editCampaignPayload.digital_offer = param?.digital_offer;
      this.editCampaignPayload.exclusive = param?.exclusive;
      this.editCampaignPayload.physical_sample = param?.physical_sample;
      this.editCampaignPayload.printed_offer = param?.printed_offer;
      this.editCampaignPayload.product_eligibility_score = param?.product_eligibility_score;
      this.editCampaignPayload.product_entry_criteria = param?.product_entry_criteria;
      this.editCampaignPayload.sample = param?.sample;
      this.editCampaignPayload.discountPercentage = param?.discountPercentage
      this.editCampaignPayload.discountType = param?.discountType
      this.editCampaignPayload.keywords = param?.keywords;
      this.editCampaignPayload.paymentDone = param?.paymentDone      
    }
  }

  returnCreateAndEditRes() {
    return this.createEdit.edit;
  }

  // setCreateAndEditRes(value:any) {
  //   this.createEdit.edit= value;
  // }

  getData(): Observable<any> {
    return this.editCampaignData.asObservable();
  }

  // setCreateData(param: any): void {
  //   this.createCampaignData.next(param);
  // }

  // getCreateData(): Observable<any> {
  //   return this.createCampaignData.asObservable();
  // }

  setBasicDetails(data: any, images3Path?: any, tag?:any) {
    let payload: any = this.createCampaignPayload;
    payload.category_id = data.category;
    payload.product_description = data.description;
    if (images3Path !== null && images3Path !== undefined) {
      payload.product_image = images3Path;
    }
    payload.product_url = data.url;
    payload.coupon_code = data.offerCode;
    payload.product_name = data.title;
    payload.discountType = data.discountType,
    payload.discountPercentage = data.discountPercentage,
    payload.keywords = tag;
    // this.setCreateData(payload);
  }

  setTargetDetails(data: any) {
    let payload: any = this.createCampaignPayload;
    payload.lower_age = data.lowerAge;
    payload.upper_age = data.upperAge;
    payload.gender = data.gender;
    payload.location = payload.country;  
  }

  setBudgetDetail(data: any, merchantid: any) {
    let payload: any = this.createCampaignPayload;
    payload.budget = data.budget;
    payload.merchant_id = merchantid;
  }

  setValidityStatus(data: any) {
    let payload: any = this.createCampaignPayload;
    payload.available_from = data.from;
    payload.available_to = data.to;
    payload.status = data.status;
  }

  createCampaignPayloadReturn() {
    return this.createCampaignPayload;
  }


  addCampaign(data: any) {
    let url: any;
    url = environment.authUrl +'orchestration-service/admin/deal/add';

    const httpOptions = {
      // 'userName': username,
      // 'macAddress': macAddress,
      // 'country': country
    };

    return this.http.post(url, data, { headers: httpOptions, observe: 'response' });
  }

  updateCampaign(data: any) {
    let url: any;
    url = environment.authUrl +'orchestration-service/admin/deal/update';

    const httpOptions = {
      // 'userName': username,
      // 'macAddress': macAddress,
      // 'country': country
    };

    return this.http.post(url, data, { headers: httpOptions, observe: 'response' });
  }

  setEditBasicDetails(data: any, images3Path?: any, tag?:any) {
    let payload: any = this.editCampaignPayload;
    payload.category_id = data.category;
    payload.product_description = data.description;
    if (images3Path !== null && images3Path !== undefined) {
      payload.product_image = images3Path;
    }
    payload.product_url = data.url;
    payload.coupon_code = data.offerCode;
    payload.product_name = data.title;
    payload.product_id = data.id;
    payload.discountType = data.discountType,
    payload.discountPercentage = data.discountPercentage,
    payload.keywords = tag;
  }

  setEditTargetDetails(data: any) {
    let payload: any = this.editCampaignPayload;
    payload.lower_age = data.lowerAge;
    payload.upper_age = data.upperAge;
    payload.gender = data.gender;
    payload.location = payload.country;    

  }

  setEditBudgetDetail(data: any, merchantid: any) {
    let payload: any = this.editCampaignPayload;
    payload.budget = data.budget;
    payload.merchant_id = merchantid;
  }

  setEditValidityStatus(data: any) {
    let payload: any = this.editCampaignPayload;
    payload.available_from = data.from;
    payload.available_to = data.to;
    payload.status = data.status;
  }

  editCampaignPayloadReturn() {
    return this.editCampaignPayload;
  }

  eventCostByCountry(id: any) {
    const url = environment.authUrl +'orchestration-service/admin/country-events-cost?country=' + id;
    return this.http.get(url, { observe: 'response' });
  }
  eventCostByCountryCosts(name: any) {
    const url = environment.authUrl +'orchestration-service/admin/country-events-costs?country=' + name;
    return this.http.get(url, { observe: 'response' });
  }

  price() {
    this.userId = JSON.parse(this.cStorage.get("userId"));
    const url = environment.authUrl +'orchestration-service/admin/price-events?userId=' + this.userId;
    return this.http.get(url, { observe: 'response' });
  }

  uploadImage(data: any) {
    const url = environment.authUrl +'orchestration-service/admin/image/upload?module=deal&fileType=jpg';
    // const httpOptions = {
    //   'userName': 'satheshjkv@gmail.com',
    //   'macAddress': macAddress,
    //   'country': country
    // };

    return this.http.post(url, data);
  }

  getAllTagsKeyword() {
    const url = environment.authUrl +'orchestration-service/admin/tag/all'
    return this.http.get(url, { observe: 'response' });
  }
  

}
