import { Component, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import * as moment from "moment";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReportService } from 'src/app/services/report.service';

@Component({
  selector: 'app-filter-report',
  templateUrl: './filter-report.component.html',
  styleUrl: './filter-report.component.scss'
})
export class FilterReportComponent {
  data:any;
  fromDate:any;
  toDate:any;
  addForm!: FormGroup;
  categoryValue:any;
  // onSubmitFilter = new EventEmitter();
  closeEvent: EventEmitter<any> = new EventEmitter();

  constructor(public dialog: MatDialog,private service: ReportService,
    private formBuilder: FormBuilder,
  ) {
    
    this.createForm();
  }

  createForm() { 
    this.addForm = this.formBuilder.group({
      tag: [],
      budget1: [null],
      budget2: [null],
      budget3: [null],
      actualSpent1: [null],
      actualSpent2: [null],
      actualSpent3: [null],
      // toDate: [null,[Validators.required]],
      // active: new FormControl(this.roleDetails.active),
      status: ['All'],
    });
  }
  

  closeDialog() {
    this.dialog.closeAll();
  }

  setCategoryValue(e:any) {
    this.categoryValue = e;
  }

  onSubmitFilter() {    
    let value = this.addForm.value;    
    let parsedToDate = moment(value.toDate,"YYYY-MM-DD");
    value.toDate = parsedToDate.format("DD/MM/YYYY");    
    let parsedFromDate = moment(value.fromDate,"YYYY-MM-DD");
    value.fromDate = parsedFromDate.format("DD/MM/YYYY");
    // value.category = this.categoryValue;
    this.service.emit(this.addForm.value);    
    this.dialog.closeAll();
    //this.dashboardService.getBrandSpecificDeals(value.fromDate, value.toDate, "1", value.status)
  }


  onReset() {
    this.categoryValue =  null;
    this.addForm.reset();
  }

  filter() {
    this.dialog.closeAll();
    // this.router.navigate(["/reports/detailedReport"]);
  }

  reset() {

  }
}
