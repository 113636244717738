import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment'; 
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ClientSideStorageService } from './client-side-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {
  private userData = new BehaviorSubject<any>([]);
  closeEvent: EventEmitter<any> = new EventEmitter();
  marketplaceData:any;
  constructor(private http: HttpClient,private cStorage: ClientSideStorageService) {
    this.marketplaceData = JSON.parse(this.cStorage.get("marketplaceData"));
   }

  setData(param: any): void {
    this.userData.next(param);
  }

  getData(): Observable<any> {
    return this.userData.asObservable();
  }

  getAllUsers () { 
    const url = environment.authUrl+'orchestration-service/admin/brand/users/all?brandId='+ this.marketplaceData.marketplace_id;
    return this.http.get(url);
  }

  addUser(data:any) {
    const url = environment.authUrl+'orchestration-service/admin/brand/add-user/'+ this.marketplaceData.marketplace_id;
    // const httpOptions = {
    //   'userName': 'satheshjkv@gmail.com',
    //   'macAddress': macAddress,
    //   'country': country
    // };

    return this.http.post(url, data);
  }

  emit(data: any) {
    this.closeEvent.emit(data);
  }

  getCloseEvent() {
    return this.closeEvent;
  }
}
