import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import * as moment from 'moment';

@Component({
  selector: 'app-switch-toggle',
  templateUrl: './switch-toggle.component.html',
  styleUrl: './switch-toggle.component.scss'
})
export class SwitchToggleComponent implements ICellRendererAngularComp {
  inputCheckbox: any;
  switchId = 'customToggle1';
  private params: any;
  active: any;
  paused: any;
  expired: any;
  inactive: any;
  draft: any;
  upcoming: any;
  pending: any;
  rejected: any;
  completed:any;
  new: any;
  presentDateTime: any;
  combinedFromDateTime: any;

  ngOnInit(): void {

  }
  agInit(params: ICellRendererParams): void {
    const curDate = new Date();
    this.presentDateTime = curDate
    this.params = params;
    if (params.data.status === "A") {
      this.active = true;
    } else if (params.data.status === "P") {
      this.pending = true;
    } else if (params.data.status === "E") {
      this.expired = true;
    } else if (params.data.status === "I") {
      this.inactive = true;
    } else if (params.data.status === "D") {
      this.draft = true;
    } else if (params.data.status === "U") {
      this.upcoming = true;
    } else if (params.data.status === "S") {
      this.paused = true;
    } else if (params.data.status === "R") {
      this.rejected = true;
    } else if (params.data.status === "N") {
      this.new = true;
    } else if (params.data.status === "C") {
      this.completed = true;
    }
    
    // if (params.data.available_from) {
    //   const date = params.data.available_from.split('T')
    //   const dateParts = date[0].split('-'); // Split the date into year, month, and day
    //   const fromtimeParts = date[1].split(':');

    //   if (params.data.flash === true && params.data.status === "U") {
    //     this.combinedFromDateTime = new Date(Date.UTC(
    //       +dateParts[0], // Year
    //       +dateParts[1] - 1, // Month (JavaScript months are 0-indexed)
    //       +dateParts[2], // Day
    //       +fromtimeParts[0], // Hours
    //       +fromtimeParts[1] // Minutes
    //     ));
    //     const dateTimeString = this.combinedFromDateTime.toISOString().slice(0, 19); // "2024-04-15 10:30:00"
    //     this.combinedFromDateTime = dateTimeString;
    //   }
    //   debugger
    //   const fromDateCompare = new Date(this.combinedFromDateTime)
    //   let fromCompare = moment(fromDateCompare, "YYYY-MM-DD hh:mm:ss").local();
    //   let FromValue = fromCompare.format("YYYY-MM-DD hh:mm:ss");
    //   const dateCompare = this.compareDates(FromValue, this.presentDateTime);
    //   if (dateCompare === -1 && params.data.flash === true && params.data.status === "U") {
    //     this.active = true;
    //     this.pending = false;
    //     this.upcoming = false;
    //   }
    // }

  }
  getChecked(active: boolean) {
    if (active) {
      return true;
    } else {
      return false;
    }
  }

  refresh(): boolean {
    return true;
  }

  btnClickedHandler(params: any) {
    this.params.clicked(this.params.data, params.target.checked);
  }

  compareDates(fromDateStr: string, toDateStr: string): number {
    const fromDate = new Date(fromDateStr);
    const toDate = new Date(toDateStr);

    // Compare the dates using getTime() method of Date
    if (fromDate.getTime() > toDate.getTime()) {
      return 1; // From date is greater than to date
    } else if (fromDate.getTime() < toDate.getTime()) {
      return -1; // From date is less than to date
    } else {
      return 0; // Dates are equal
    }
  }
}
