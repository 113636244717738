import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { SessionStorageService } from "ngx-webstorage";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private systemUsers = new BehaviorSubject([]);
  // JSON.parse(this.storageService.retrieve("user"))
  private basicDetails = new BehaviorSubject([]);

  constructor(private http: HttpClient, private storageService: SessionStorageService) { }

  setSystemUser(systemUser: any) {
    this.systemUsers.next(systemUser);
  }

  getSystemUser(): Observable<any> {
    return this.systemUsers.asObservable();
  }

  sendOtpForForgetPass(email: any, country: any, macAddress: any): Observable<any> {
    let url: any;
    url = environment.authUrl+'orchestration-service/event/admin/forgot-password';
    // this.details = this.getDetails();
    // this.details = this.details.source._value;
    let obj = {email_id:email.email_id.toLowerCase()}
    const httpOptions = {
      'userName': email.email_id,
      'macAddress': macAddress,
      'country': country
    };

    return this.http.post(url, obj, { headers: httpOptions, observe: 'response' });
  }


  logIn(data: any, country: any, macAddress: any): Observable<any> {
    // let httpHeaders = new HttpHeaders();
    // httpHeaders.append('userName', data.email_id);
    // httpHeaders.append('Content-Type', 'application/json');
    // httpHeaders.append("Authorization", "Basic " + btoa("00000:magical"));
    // ,macAddress:any, country:any
    const httpOptions = {
      'userName': data.email_id,
      'macAddress': macAddress,
      'country': country
    };

    let url: any;
    url = environment.authUrl+'orchestration-service/admin/login-user';

    return this.http.post(url, data, { headers: httpOptions, observe: 'response' });
  }

  saveUpdatePassword(data: any, country: any, macAddress: any) {
    let url: any;
    url = environment.authUrl+'orchestration-service/event/admin/update-password';

    const httpOptions = {
      'userName': data.email_id,
      'macAddress': macAddress,
      'country': country
    };

    return this.http.post(url, data, { headers: httpOptions, observe: 'response' });
  }

  validateOtp(data: any, username: any, country: any, macAddress: any) {
    let url: any;
    url = environment.authUrl+'orchestration-service/event/admin/validate-token';

    const httpOptions = {
      'userName': username,
      'macAddress': macAddress,
      'country': country
    };

    return this.http.post(url, data, { headers: httpOptions, observe: 'response' });
  }

  logoutUser() {
    const url = environment.authUrl+'orchestration-service/admin/logout-user';
    return this.http.get(url, { observe: 'response' });
  }
}
