<form [formGroup]="changePasswordForm">
    <div class="div-6">Change Password</div>
    <div class="div-7">
        Passwords must have at least 8 characters and contain at least
        two of the following: uppercase letters, lowercase letters,
        numbers, and symbols.
    </div>


    <span class="span-11">
        <span class="span-12">
            <img loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/1dcb1ef744cae731986e62216d917800a97e7f706c63b43bf5f9cf0db0fea805?"
                class="img-13" />
            <a class="div-21" routerLink="/companyProfile/details">Back to Administrator Details</a>
        </span>
        <div class="div-35">Set Password</div>
        <!-- <div class="div-36">
            Passwords must have at least 8 characters and contain at least
            two of the following: uppercase letters, lowercase letters,
            numbers, and symbols.
        </div> -->
        <div class="div-37">
            <div class="div-38">
                <span class="span-21">
                    <label class="asterix">Old password</label>
                    <!-- <img loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
                    class="img-21" /> -->
                </span>
                <div class="div-40">
                    <span>
                        <input matInput [type]="showOldPass ? 'text' : 'password'" class="col-11 form-control"
                            placeholder="Enter old password" formControlName="oldpasswd" style="width: 240px; "
                            pattern="^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,30}$"
                            [ngClass]="{ 'is-invalid': f['oldpasswd'].errors && (f['oldpasswd'].dirty || f['oldpasswd'].touched )  }"
                            required />
                        @if (f['oldpasswd'].errors) {
                        <div class="invalid-feedback">
                            @if (f['oldpasswd'].errors['required']) {
                            <div>Old password is required</div>
                            }
                        </div>
                        }
                    </span>
                    <mat-icon matSuffix style="overflow: visible;margin-top: 8px;margin-left: -72px" class="col-1"
                        (click)="toggleOldPasswordVisibility()">{{showOldPass?'visibility_off':'visibility'}}</mat-icon>
                </div>
            </div>
        </div>
        <div class="div-37">
            <div class="div-38">
                <span class="span-21">
                    <label class="asterix">New password</label>
                    <!-- <img loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
                    class="img-21" /> -->
                </span>
                <div class="div-40">
                    <div class="col-4">
                        <input matInput [type]="showCreatePass ? 'text' : 'password'" class="col-11 form-control"
                            placeholder="Create password" formControlName="password"
                            pattern="^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,30}$"
                            [ngClass]="{ 'is-invalid': f['password'].errors && (f['password'].dirty || f['password'].touched )  }"
                            required />
                        @if (f['password'].errors) {
                        <div class="invalid-feedback">
                            @if (f['password'].errors['required']) {
                            <div>Password is required</div>
                            }
                            @if (f['password'].errors['minlength']) {
                            <div>Password must be at least 8 characters</div>
                            }
                            @if (f['password'].errors['maxlength']) {
                            <div>Password must not exceed 30 characters</div>
                            }
                            @if (f['password'].errors['pattern']) {
                            <div>
                                Enter correct pattern
                            </div>
                            }
                        </div>
                        }
                    </div>
                    <mat-icon matSuffix style="overflow: visible;margin-top: 8px;margin-left: -72px" class="col-1"
                        (click)="togglePasswordVisibility()">{{showCreatePass?'visibility_off':'visibility'}}</mat-icon>

                    <app-password-rules></app-password-rules>
                </div>
            </div>
        </div>
        <div class="div-38">
            <span class="span-22">
                <label class="asterix">Confirm password</label>
            </span>
            <div class="div-40">
                <span>
                    <input matInput [type]="showConfirmPass ? 'text' : 'password'" class="form-control"
                        placeholder="Re-enter password" formControlName="confirmPass" style="width: 240px;"
                        [ngClass]="{ 'is-invalid': !f['password'].errors && f['confirmPass'].errors && (f['confirmPass'].dirty || f['confirmPass'].touched ) }"
                        required />
                    @if ( f['confirmPass'].errors) {
                    <div class="invalid-feedback">
                        @if (f['confirmPass'].errors['required']) {
                        <div>Confirm password is required</div>
                        }
                        @if (f['confirmPass'].errors['matching']) {
                        <div>Confirm password does not match</div>
                        }
                    </div>
                    }
                </span>
                <mat-icon matSuffix style="overflow: visible;margin-top: 8px;margin-left: -72px" class="col-1"
                    (click)="toggleConfirmPasswordVisibility()">{{showConfirmPass?'visibility_off':'visibility'}}</mat-icon>
            </div>
        </div>

        <div class="div-44">
            <button class="btn btn-secondary" (click)="reset()">Reset</button>
            <button class="btn span-25"[disabled]="changePasswordForm.invalid" (click)="save()">Update changes</button>
        </div>
    </span>
</form>