import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Validation from 'src/app/utils/validation';
import { HardcodedData } from '../../hardcoded-data';
import { Router } from '@angular/router';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { CommonUtilService } from 'src/app/services/common-util.service';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent {
  companyData = new HardcodedData();
  showCreatePass = false;
  showConfirmPass = false;
  showOldPass = false;
  personID: any;
  changePasswordForm: FormGroup = new FormGroup({
    // primaryContact: new FormControl(''),
    // email: new FormControl(''),
    // phone: new FormControl(''),
    password: new FormControl(''),
    confirmPass: new FormControl(''),
  });

  constructor(
    private router: Router, private fb: FormBuilder, private service: CommonUtilService,
    private confirmationDialogService: ConfirmationDialogService, private cStorage: ClientSideStorageService,
    private notifyService: NotificationAlertService) {
    this.personID = JSON.parse(this.cStorage.get("userId"));
    this.createForm();
    this.changePasswordForm.get('password')?.valueChanges.subscribe(value => {
      this.service.setPasswordRulesDataData(value);
    });
  }

  createForm() {
    this.changePasswordForm = this.fb.group({
      oldpasswd: ['', Validators.required],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(40)
      ]],
      confirmPass: ['', Validators.required]
    },
      {
        validators: [Validation.match('password', 'confirmPass')]
      }
    );
  }

  get f(): { [key: string]: AbstractControl } {
    return this.changePasswordForm.controls;
  }

  reset() {
    // this.confirmationDialogService
    //   .confirm("Confirmation ", "Are you sure you want to Reset? ")
    //   .then((confirmed) => {
    //     if (confirmed) {
          this.changePasswordForm.reset();
      //   }
      // });
  }

  save() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to save? ")
      .then((confirmed) => {
        if (confirmed) {
          let value = {
            "personId": this.personID,
            "password": this.changePasswordForm.value.password,
            "oldPassword":this.changePasswordForm.value.oldpasswd
          }
          this.service.adminPasswdUpdate(value).subscribe((res: any) => {
            if (res.status === "ERROR") {
              this.notifyService.showError(
                res.message,
                res.status
              );
            } else {
              this.notifyService.showSuccess(
                "Saved successfully !!",
                "Success"
              );
              this.router.navigate(["/dashboard"]);
            }
          });
        }
      });
  }

  togglePasswordVisibility() {
    this.showCreatePass = !this.showCreatePass;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPass = !this.showConfirmPass;
  }

  toggleOldPasswordVisibility() {
    this.showOldPass = !this.showOldPass
  }

  editCompanyClick() {
    this.router.navigate(["/companyProfile"]);
  }

  changePass() {
    this.router.navigate(["/companyProfile/changePassword"]);
  }

}
