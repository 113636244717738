import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SelectUsersPopupComponent } from '../select-users-popup/select-users-popup.component';
import { DashboardService } from 'src/app/services/dashboard.service';
import { MessagingService } from 'src/app/services/messaging.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { Router } from '@angular/router';
import { CampaignService } from 'src/app/services/campaign.service';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-select-users',
  templateUrl: './select-users.component.html',
  styleUrl: './select-users.component.scss'
})
export class SelectUsersComponent {
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders:any;
  selectedUserCount: any;
  data: any;
  selectForm!: FormGroup;
  favId: any;
  userData: any;
  clickCost: any;
  presentDate: any;
  afterPresentDate: any;
  totalcost: any;
  rowItem: any;
  constructor(private router: Router, public dialog: MatDialog, private dashboardService: DashboardService, private formBuilder: FormBuilder, private cStorage: ClientSideStorageService,
    private confirmationDialogService: ConfirmationDialogService, private notifyService: NotificationAlertService, private campaignService: CampaignService,
    private mssgService: MessagingService) {
    this.placeHolders = this.placeholders['Messaging_Page']
    const curDate = new Date();
    this.presentDate =
      curDate.getFullYear() +
      "-" +
      ("0" + (curDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + curDate.getDate()).slice(-2);
    this.afterPresentDate = curDate.getFullYear() +
      "-" +
      ("0" + (curDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (curDate.getDate() + 1)).slice(-2);
    this.data = this.mssgService.getData();
    this.rowItem = this.data.source._value.rowIndex
    this.data = this.data.source._value.data;
    this.createForm();

    this.campaignService.price().subscribe((res: any) => {
      if (res?.body?.data) {
        res?.body?.data.forEach((item: any) => {
          if (item.event_type === "favorite_dm") {
            this.favId = item.event_id;
          }
        });
      }

      this.userData = JSON.parse(this.cStorage.get("marketplaceData"));
      // this.service.getRegisterCompanyDetails().subscribe((res: any) => {
      if (this.userData) {
        //     let userData = res?.data;
        this.campaignService.eventCostByCountryCosts(this.userData['country_name']).subscribe((response: any) => {
          if (response?.body?.data?.countryEventsCostsList) {
            let event = response?.body?.data?.countryEventsCostsList;
            this.clickCost = event.find((el: any) => el.event_type === 'DEAL_FAVORITE_DM');
            this.mssgService.getFavMessage().subscribe(res => {
              if (res?.body?.data && res?.body?.data?.campaignMessages.length !== 0) {
                let messages = res?.body?.data?.campaignMessages.find(
                  (i: any) => i.favoritedDealId === this.data.deal.product_id
                );
                if (messages !== null && messages !== undefined) {
                  this.editForm(messages)
                } else {
                  this.createForm();
                }
              } else {
                this.createForm();
              }
            });
          }
        });
      }
      // });
    });

  }

  createForm() {
    let res = this.data;
    // let parsedDate = moment(res?.deal?.available_from, "YYYY-MM-DD");
    // res.deal.available_from = parsedDate.format("YYYY-MM-DD");

    res.available_from = new Date(res?.deal?.available_from)
    let parsedDate = moment(res.available_from, "YYYY-MM-DD");
    res.deal.available_from = parsedDate.format("YYYY-MM-DD");

    res.available_to = new Date(res?.deal?.available_to)
    let parsedToDate = moment(res.available_to, "YYYY-MM-DD");
    res.deal.available_to = parsedToDate.format("YYYY-MM-DD");

    // let parseDate = moment(res?.deal?.available_to, "YYYY-MM-DD");
    // res.deal.available_to = parseDate.format("YYYY-MM-DD");

    this.selectForm = this.formBuilder.group({
      title: [res?.deal?.product_name, [Validators.required, Validators.minLength(2),
      Validators.maxLength(50)]],
      totalFav: [res?.details?.totalFavorited, Validators.required],
      message: [res?.deal?.product_description, [Validators.required, Validators.minLength(5),
      Validators.maxLength(500)]],
      discountCode: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      enterMssgs: [''],
      costsPerMssg: [this.clickCost?.cost],
      totalSpent: [''],
      validFrom: [res.deal.available_from, Validators.required],
      validTo: [res.deal.available_to, Validators.required],
      currency: [this.clickCost?.currency]
    });

    if (this.f['title'].value) {
      this.f['message'].disable();
      this.f['validFrom'].disable();
      this.f['validTo'].disable();
    }

    this.totalcost = this.clickCost?.cost * res?.details?.totalFavorited;
  }

  editForm(data: any) {
    let res = this.data;
    res.available_from = new Date(data?.startDate)
    let parsedDate = moment(res.available_from, "YYYY-MM-DD");
    data.startDate = parsedDate.format("YYYY-MM-DD");

    res.available_to = new Date(data?.endDate)
    let parseDate = moment(res.available_to, "YYYY-MM-DD");
    data.endDate = parseDate.format("YYYY-MM-DD");

    this.totalcost = this.clickCost?.cost * res?.details.totalFavorited;

    this.selectForm = this.formBuilder.group({
      title: [res?.deal.product_name, [Validators.required, Validators.minLength(2),
      Validators.maxLength(50), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      totalFav: [res?.details.totalFavorited, Validators.required],
      message: [res?.deal?.product_description, [Validators.required, Validators.minLength(5),
      Validators.maxLength(500)]],
      discountCode: [data?.discountCode, [Validators.required, Validators.minLength(2),
      Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      enterMssgs: [''],
      costsPerMssg: [this.clickCost?.cost],
      totalSpent: [''],
      validFrom: [data?.startDate, Validators.required],
      validTo: [data?.endDate, Validators.required],
      currency: [this.clickCost?.currency],
      dealId: [data?.dealId]
    });

    if (this.f['title'].value) {
      this.f['message'].disable();
      this.f['validFrom'].disable();
      this.f['validTo'].disable();
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.selectForm.controls;
  }

  openDialoge() {
    this.dialog.open(SelectUsersPopupComponent, {
      height: '500px',
      width: '600px',
      position: {
        top: '12vh',
        left: '30vw'
      },
      autoFocus: false,
      backdropClass: 'custom-dialog-overlay',  // mat-dialog css class
      disableClose: true  // If you click outside the mat-dialog box window, it will not close. 
    });
    this.mssgService.getCloseEvent().subscribe((res: any) => {
      this.selectedUserCount = res.selectedUser.length;
    });
  }

  cancel() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to cancel? ")
      .then((confirmed) => {
        if (confirmed) {
          this.router.navigate(["/messaging"]);
        }
      });
  }

  save() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to save? ")
      .then((confirmed) => {
        if (confirmed) {
          debugger
          let values = this.selectForm.value;
          // let parsedDate = moment(values.validTo, "YYYY-MM-DD");
          // values.validTo = parsedDate.format("YYYY-MM-DD");
          // let parsedFromDate = moment(values.validFrom, "YYYY-MM-DD");
          // values.validFrom = parsedFromDate.format("YYYY-MM-DD");

          let fromDate = moment(this.f['validFrom'].value).hours(moment().hours()).minutes(moment().minutes()).seconds(moment().seconds()).utc();
          let toDate = moment(this.f['validTo'].value).hours(moment().hours()).minutes(moment().minutes()).seconds(moment().seconds()).utc();

          values.validFrom = fromDate.format();
          values.validTo = toDate.format();
          let data = {
            "sendMessageTo": "all",//values will be "all" or "selected" 
            "userIdList": [],//if "sendMessageTo":"all" then "userIdList":[], if "sendMessageTo":"selected" then "userIdList":[<userId>,<userId>,...] - "userIdList":[25750,25776]
            "favoritedDealId": this.data.deal.product_id,
            "occasionDeal": {
              "dealType": 3,
              "amountSpent": this.data.deal.total_spent,
              "budget": this.data.deal.budget,
              "composeMessage": this.f['message'].value,
              "discountCode": this.f['discountCode'].value,
              "marketplace_id": this.userData.marketplace_id,
              "status": 1,
              "startDate": values.validFrom,
              "endDate": values.validTo,
              "msgToBePurhcased": this.f['totalFav'].value,
              "paymentMade": false,
              "favoritedDealId": this.data.deal.product_id
            }
          };
          this.mssgService.addFavMessage(data).subscribe((res: any) => {
            this.notifyService.showSuccess(
              "Message successfully added !!",
              "Success"
            );
            this.router.navigate(["/messaging"]);
          });
        }
      });
  }

  submit() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to save? ")
      .then((confirmed) => {
        if (confirmed) {
          let values = this.selectForm.value;
          // let parsedDate = moment(values.validTo, "YYYY-MM-DD");
          // values.validTo = parsedDate.format("YYYY-MM-DD");
          // let parsedFromDate = moment(values.validFrom, "YYYY-MM-DD");
          // values.validFrom = parsedFromDate.format("YYYY-MM-DD");
          let fromDate = moment(this.f['validFrom'].value).hours(moment().hours()).minutes(moment().minutes()).seconds(moment().seconds()).utc();
          let toDate = moment(this.f['validTo'].value).hours(moment().hours()).minutes(moment().minutes()).seconds(moment().seconds()).utc();

          values.validFrom = fromDate.format();
          values.validTo = toDate.format();
          let data = {
            "sendMessageTo": "all",//values will be "all" or "selected" 
            "userIdList": [],//if "sendMessageTo":"all" then "userIdList":[], if "sendMessageTo":"selected" then "userIdList":[<userId>,<userId>,...] - "userIdList":[25750,25776]
            "favoritedDealId": this.data.deal.product_id,
            "occasionDeal": {
              "dealType": 3,
              "amountSpent": this.totalcost,
              "budget": this.data.deal.budget,
              "composeMessage": this.f['message'].value,
              "discountCode": this.f['discountCode'].value,
              "marketplace_id": this.userData.marketplace_id,
              "status": 1,
              "startDate": values.validFrom,
              "endDate": values.validTo,
              "msgToBePurhcased": this.f['totalFav'].value,
              "paymentMade": true,
              "favoritedDealId": this.data.deal.product_id
            }
          };
          this.mssgService.addFavMessage(data).subscribe((res: any) => {
            this.notifyService.showSuccess(
              "Message successfully added !!",
              "Success"
            );
            this.router.navigate(["/messaging"]);
          });
        }
      });
  }

}
