import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CampaignService } from 'src/app/services/campaign.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';

@Component({
  selector: 'app-validstatus',
  templateUrl: './validstatus.component.html',
  styleUrl: './validstatus.component.scss'
})
export class ValidstatusComponent {
  editData: any;
  editResponse: any;
  createCampaign = true;
  presentDate: any;
  afterPresentDate:any;
  validityStatusForm: FormGroup = new FormGroup({
    to: new FormControl(''),
    from: new FormControl(''),
    status: new FormControl('A'),
  });

  constructor(private fb: FormBuilder, private router: Router,
    private notifyService: NotificationAlertService,
    private confirmationDialogService: ConfirmationDialogService, private campaignService: CampaignService) {
    const curDate = new Date();
    this.presentDate =
      curDate.getFullYear() +
      "-" +
      ("0" + (curDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + curDate.getDate()).slice(-2);
    this.afterPresentDate = curDate.getFullYear() +
    "-" +
    ("0" + (curDate.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + (curDate.getDate() + 1)).slice(-2);
    this.validityStatusForm.reset();
    this.createCampaign = true;
    this.editData = this.campaignService.getData();
    this.editData = this.editData.source._value;
    this.editResponse = this.campaignService.returnCreateAndEditRes();
    if (this.editResponse === false) {
      this.createForm();
    } else {
      this.editForm();
    }
  }

  createForm() {
    this.createCampaign = true;
    this.validityStatusForm = this.fb.group({
      to: [''],
      from: [''],
      status: ['']
    });

  }

  editForm() {
    this.createCampaign = false;
    let res = this.editData;
    let parsedDate = moment(res.available_from, "YYYY-MM-DD");
    res.available_from = parsedDate.format("YYYY-MM-DD");

    let parseDate = moment(res.available_to, "YYYY-MM-DD");
    res.available_to = parseDate.format("YYYY-MM-DD");

    this.validityStatusForm = this.fb.group({
      to: [res.available_to],
      from: [res.available_from],
      status: [res.status]
    });
  }

  cancel() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to cancel this operation? ")
      .then((confirmed) => {
        if (confirmed) {
          this.router.navigate(["/dashboard"]);
        }
      });
  }

  submit() {
    if (this.validityStatusForm.invalid) {
      this.notifyService.showError("Please fill required details", "Invalid Form");
      return;
    } else {
      this.confirmationDialogService
        .confirm("Confirmation ", "Are you sure you want to Save? ")
        .then((confirmed) => {
          if (confirmed) {
            let values = this.validityStatusForm.value;
            let parsedDate = moment(values.to, "YYYY-MM-DD");
            values.to = parsedDate.format("YYYY-MM-DD");
            let parsedFromDate = moment(values.from, "YYYY-MM-DD");
            values.from = parsedFromDate.format("YYYY-MM-DD");
            let payload: any;

            if (this.editResponse === false) {
              values.status = "A";
              this.campaignService.setValidityStatus(values);
              payload = this.campaignService.createCampaignPayloadReturn();
              // this.data.push(values);

              this.campaignService.addCampaign(payload).subscribe((res: any) => {
                if (res.body?.status === 'Error') {
                  this.notifyService.showError(
                    res.body?.message,
                    res.body?.status
                  );
                } else {
                  this.notifyService.showSuccess(
                    "New Deal Added Successfully",
                    res.body?.status
                  );
                  this.router.navigate(["/dashboard"]);
                }
              });
            } else {
              this.campaignService.setEditValidityStatus(values);
              payload = this.campaignService.editCampaignPayloadReturn();

              this.campaignService.updateCampaign(payload).subscribe((res: any) => {
                if (res.body?.status === 'Error') {
                  this.notifyService.showError(
                    res.body?.message,
                    res.body?.status
                  );
                } else {
                  this.notifyService.showSuccess(
                    "Deal Updated Successfully",
                    res.body?.status
                  );
                  this.campaignService.setData(null);
                  this.router.navigate(["/dashboard"]);
                }
              });
            }
          }
        });
    }
  }

  targetClick() {
    if (this.validityStatusForm.invalid) {
      this.notifyService.showError("Please fill required details", "Invalid Form");
      return;
    } else {
      this.confirmationDialogService
        .navigateConfirm("Confirmation ", "Are you sure you want to save this operation? ")
        .then((confirmed) => {
          if (confirmed) {
            this.router.navigate(["/campaign/target"]);
          } else {
            this.router.navigate(["/campaign/target"]);
          }
        });
    }
  }

  budgetDetailClick() {
    if (this.validityStatusForm.invalid) {
      this.notifyService.showError("Please fill required details", "Invalid Form");
      // return;
    } else {
      this.confirmationDialogService
        .navigateConfirm("Confirmation ", "Are you sure you want to save this operation? ")
        .then((confirmed) => {
          if (confirmed) {
            this.router.navigate(["/campaign/budget"]);
          } else {
            this.router.navigate(["/campaign/budget"]);
          }
        });
    }
  }

  campaignBasicDetailClick() {
    if (this.validityStatusForm.invalid) {
      this.notifyService.showError("Please fill required details", "Invalid Form");
      return;
    } else {
      this.confirmationDialogService
        .navigateConfirm("Confirmation ", "Are you sure you want to save this operation? ")
        .then((confirmed) => {
          if (confirmed) {
            this.router.navigate(["/campaign"]);
          } else {
            this.router.navigate(["/campaign"]);
          }
        });
    }
  }

  saveDraft() {
    if (this.validityStatusForm.invalid) {
      this.notifyService.showError("Please fill required details", "Invalid Form");
      return;
    } else {
      this.confirmationDialogService
        .confirm("Confirmation ", "Are you sure you want to Save? ")
        .then((confirmed) => {
          if (confirmed) {
            let values = this.validityStatusForm.value;
            let parsedDate = moment(values.to, "YYYY-MM-DD");
            values.to = parsedDate.format("YYYY-MM-DD");
            let parsedFromDate = moment(values.from, "YYYY-MM-DD");
            values.from = parsedFromDate.format("YYYY-MM-DD");
            let payload: any;

            if (this.editResponse === false) {
              values.status = "D";
              this.campaignService.setValidityStatus(values);
              payload = this.campaignService.createCampaignPayloadReturn();
              // this.data.push(values);

              this.campaignService.addCampaign(payload).subscribe((res: any) => {
                if (res.body?.status === 'Error') {
                  this.notifyService.showError(
                    res.body?.message,
                    res.body?.status
                  );
                } else {
                  this.notifyService.showSuccess(
                    "New Deal Added Successfully",
                    res.body?.status
                  );
                  this.router.navigate(["/dashboard"]);
                }
              });
            } else {
              this.campaignService.setEditValidityStatus(values);
              payload = this.campaignService.editCampaignPayloadReturn();

              this.campaignService.updateCampaign(payload).subscribe((res: any) => {
                if (res.body?.status === 'Error') {
                  this.notifyService.showError(
                    res.body?.message,
                    res.body?.status
                  );
                } else {
                  this.notifyService.showSuccess(
                    "Deal Updated Successfully",
                    res.body?.status
                  );
                  this.campaignService.setData(null);
                  this.router.navigate(["/dashboard"]);
                }
              });
            }
          }
        });
    }
  }

}
