<form [formGroup]="addForm" class="">
  <div class="div">
    <div class="div-2">
      <div class="div-3">Filter favourite deals</div>
      <mat-icon (click)="closeDialog()">close</mat-icon>
    </div>

    <div class="div-4">
      <div class="div-5">Select tags</div>
      <!-- <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6651221268d26d5f7c2459d3d86471df8c55372891d73df0aac8e167e05b80a?"
        class="img-2"
      /> -->
    </div>
    <div class="div-6">
      <div class="div-7 col-12">
        <!-- <div class="div-8"> -->

        <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Select tags/keywords'"
          [data]="allTagsKewords" bindLabel="tag" bindValue="tag" formControlName="tag">
        </ng-multiselect-dropdown>

        <!-- <div class="div-9">Puma</div>
          <img loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/57f6728b9abb08ca48a3c5a44dfce8050541257cf72d76f742f37dba5d9c5bc3?"
            class="img-3" />
        </div>
        <div class="div-10">
          <div class="div-11">Season</div>
          <img loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/57f6728b9abb08ca48a3c5a44dfce8050541257cf72d76f742f37dba5d9c5bc3?"
            class="img-4" />
        </div>
        <div class="div-12">
          <div class="div-13">Sale</div>
          <img loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/57f6728b9abb08ca48a3c5a44dfce8050541257cf72d76f742f37dba5d9c5bc3?"
            class="img-5" />
        </div>-->
      </div>
      <!-- <img loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/f231dafc1b3fef336bbad007ade9614aba7f4b8bebd006e84c57ee6d4b1289a7?"
        class="img-6" />  -->
      </div>
      <div class="div-14">
        <div class="div-15">
          <div class="div-16">
            <div class="div-17">From</div>
            <!-- <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
            class="img-7"
          /> -->
          </div>
          <div class="div-18">
            <div class="div-19">
              <input type="date" max="{{ todaysDate }}" formControlName="fromDate" onkeydown="return false" placeholder="Select date"
                style="border: 0px solid transparent;" class="col-12" ngDefaultControl />
            </div>
            <!-- <img loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/658be46e7d2e2afd6dd528db8e99e208e0977d4c8a3dab57e94b07bea6e45296?"
            class="img-8" /> -->
          </div>
        </div>
        <div class="div-20">
          <div class="div-21">
            <div class="div-22">To</div>
            <!-- <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7ed5e5b41e4208efcad5291278fcc6a5d320da4b646dec769aa8dcc08b65947a?"
            class="img-9"
          /> -->
          </div>
          <div class="div-23">
            <div class="div-24">

              <input type="date" max="{{ todaysDate }}" formControlName="toDate" onkeydown="return false" placeholder="Select date"
                style="border: 0px solid transparent;" class="col-12" ngDefaultControl />
            </div>
            <!-- <img loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/0569b5ae6744fc630ea42b617a2aa705299e14b8d30ee927b76cd9faf59e12e5?"
            class="img-10" /> -->
          </div>
        </div>
      </div>
      <div class="div-25">
        <div class="div-26">Status</div>
        <!-- <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
        class="img-11"
      /> -->
      </div>
      <div class="div-27">
        <mat-radio-group aria-label="Select an option" formControlName="status">
          <mat-radio-button value="All">All</mat-radio-button>
          <mat-radio-button value="A">Active</mat-radio-button>
          <mat-radio-button value="P">Paused</mat-radio-button>
          <mat-radio-button value="E">Expired</mat-radio-button>
        </mat-radio-group>
        <!-- <div class="div-28">
        <div class="div-29"></div>
        <div class="div-30">All</div>
      </div>
      <div class="div-31">
        <img
          loading="lazy"
          srcset="..."
          class="img-12"
        />
        <div class="div-32">Active</div>
      </div>
      <div class="div-33">
        <div class="div-34"></div>
        <div class="div-35">Paused</div>
      </div>
      <div class="div-36">
        <div class="div-37"></div>
        <div class="div-38">Expired</div>
      </div> -->
      </div>
      <div class="div-39">
        <button class="div-40" (click)="onReset()">Reset</button>
        <button class="div-41" (click)="onSubmitFilter()">Filter</button>
      </div>
    </div>
</form>