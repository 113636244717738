<span class="span ">
  <div>
  <mat-icon style="float:right !important" (click)="closeDialog()">close</mat-icon>
</div>
  <div class="card p-2">
  <div class="divFilter ">Filter Users  
</div>
  <div class="divFilter-2 ">
    <div class="divFilter-3">
      <span class="span-2">
        <div class="divFilter-4">Country</div>
        <!-- <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6651221268d26d5f7c2459d3d86471df8c55372891d73df0aac8e167e05b80a?"
          class="imgFilter"
        /> -->
      </span>
      <span class="span-3">
        <div class="divFilter-5">USA</div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/6cac818db78e270d69547e74f2d65eb2a298b1ea7db0d8a1e69734a2144b579f?"
          class="imgFilter-2"
        />
      </span>
    </div>
    <div class="divFilter-6">
      <span class="span-4">
        <div class="divFilter-7">State</div>
        <!-- <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6651221268d26d5f7c2459d3d86471df8c55372891d73df0aac8e167e05b80a?"
          class="imgFilter"
        /> -->
      </span>
      <span class="span-5">
        <div class="divFilter-8">All</div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/6cac818db78e270d69547e74f2d65eb2a298b1ea7db0d8a1e69734a2144b579f?"
          class="imgFilter-3"
        />
      </span>
    </div>
    <div class="divFilter-9">
      <span class="span-6">
        <div class="divFilter-10">City</div>
        <!-- <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6651221268d26d5f7c2459d3d86471df8c55372891d73df0aac8e167e05b80a?"
          class="img"
        /> -->
      </span>
      <span class="span-7">
        <div class="divFilter-11">All</div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/6cac818db78e270d69547e74f2d65eb2a298b1ea7db0d8a1e69734a2144b579f?"
          class="imgFilter-4"
        />
      </span>
    </div>
  </div>
  <div class="divFilter-12">
    <div class="divFilter-13">
      <span class="span-8">
        <div class="divFilter-14">Gender</div>
        <!-- <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6651221268d26d5f7c2459d3d86471df8c55372891d73df0aac8e167e05b80a?"
          class="imgFilter"
        /> -->
      </span>
      <span class="span-9">
        <div class="divFilter-15">Male</div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7c9cb226b99e8185b5b0a8f2f8fe316c5f1ae9c857f4c692d34a13823fa14477?"
          class="imgFilter-5"
        />
      </span>
    </div>
    <div class="divFilter-16">
      <span class="span-10">
        <div class="divFilter-17">Age</div>
        <!-- <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6651221268d26d5f7c2459d3d86471df8c55372891d73df0aac8e167e05b80a?"
          class="imgFilter-6"
        /> -->
      </span>
      <div class="divFilter-18">
        <div class="divFilter-19">
          <span class="span-11">
            <div class="divFilter-20">18-40</div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/0b928e438cbc449794446fee71e59d9d8ebba3d13a5b8c6bcd0feaa3c7636dbb?"
              class="imgFilter-7"
            />
          </span>
          <span class="span-12">
            <div class="divFilter-21">41-60</div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/0b928e438cbc449794446fee71e59d9d8ebba3d13a5b8c6bcd0feaa3c7636dbb?"
              class="imgFilter-8"
            />
          </span>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/f231dafc1b3fef336bbad007ade9614aba7f4b8bebd006e84c57ee6d4b1289a7?"
          class="imgFilter-9"
        />
      </div>

      <div class="div-button">
        <button class="buttonReset" >Reset</button>
        <button class="buttonFilter">Filter</button>
      </div>

    </div>
  </div>
</div>
</span>

<div class="div">
    <div class="div-2">
      <div class="div-3">
        <div class="div-4">Select Users</div>
        <div class="div-5">Select user for special offer message</div>
      </div>
    </div>
    <div class="div-6">
      <div class="div-7">
        <div class="div-8">
          <!-- <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/ced4c0641f8c00e7a2b39e1ea7fb86d41381badbe2bf418d95e3fbeb4bbe07b4?"
            class="img-2"
          /> -->
          <div class="div-9">No. of current favourites :</div>
        </div>
        <div class="div-10">
          <div class="div-11">137</div>
          <div class="div-12">users</div>
        </div>
      </div>
      <!-- <div class="div-13">
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/33e08d69431f0345ba12d7c05be18d8fe4fca6bcbc871ed1d712747d098570bd?"
          class="img-3"
          (click) = "filterScreen()"
        />
      </div> -->
    </div>
    <div class="div-14">
      <ag-grid-angular style="width: 100%; height: 80vh" class="ag-theme-quartz" [rowData]="data"
        [gridOptions]="gridOptions" [suppressDragLeaveHidesColumns]="true" [columnDefs]="columnDefs" [animateRows]="true" [pagination]="false"
        (gridReady)="onGridReady($event)">
      </ag-grid-angular>
      <!-- <div class="div-15">
        <div class="div-16">
        </div>
      </div>
      <div class="div-17">
        <div class="div-18">User ID</div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d5428d81ff7db48fc52aded520c232fae1145dd3a3f2f1acb6a6363880c09a2?"
          class="img-4"
        />
      </div>
      <div class="div-19">
        <div class="div-20">Age range</div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d5428d81ff7db48fc52aded520c232fae1145dd3a3f2f1acb6a6363880c09a2?"
          class="img-5"
        />
      </div>
      <div class="div-21">
        <div class="div-22">Geo Location</div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d5428d81ff7db48fc52aded520c232fae1145dd3a3f2f1acb6a6363880c09a2?"
          class="img-6"
        />
      </div> -->
    </div>
    <!-- <div class="div-23">
      <div class="div-24"><div class="div-25"></div></div>
      <div class="div-26">NishaKadamtets01</div>
      <div class="div-27">30-40</div>
      <div class="div-28">Pune</div>
    </div>
    <div class="div-29">
      <div class="div-30">
        <img
          loading="lazy"
          srcset="..."
          class="img-7"
        />
      </div>
      <div class="div-31">NishaKadamtets01</div>
      <div class="div-32">30-40</div>
      <div class="div-33">
        <div class="div-34">Pune</div>
        <div class="div-35"></div>
      </div>
    </div>
    <div class="div-36">
      <div class="div-37">
        <img
          loading="lazy"
          srcset="..."
          class="img-8"
        />
      </div>
      <div class="div-38">NishaKadamtets01</div>
      <div class="div-39">30-40</div>
      <div class="div-40">Pune</div>
    </div>
    <div class="div-41">
      <div class="div-42">
        <img
          loading="lazy"
          srcset="..."
          class="img-9"
        />
      </div>
      <div class="div-43">NishaKadamtets01</div>
      <div class="div-44">30-40</div>
      <div class="div-45">Pune</div>
    </div>
    <div class="div-46">
      <div class="div-47"><div class="div-48"></div></div>
      <div class="div-49">NishaKadamtets01</div>
      <div class="div-50">30-40</div>
      <div class="div-51">Pune</div>
    </div>
    <div class="div-52">
      <div class="div-53"><div class="div-54"></div></div>
      <div class="div-55">NishaKadamtets01</div>
      <div class="div-56">30-40</div>
      <div class="div-57">Pune</div>
    </div>
    <div class="div-58">
      <div class="div-59"><div class="div-60"></div></div>
      <div class="div-61">NishaKadamtets01</div>
      <div class="div-62">30-40</div>
      <div class="div-63">Pune</div>
    </div>
    <div class="div-64">
      <div class="div-65"><div class="div-66"></div></div>
      <div class="div-67">NishaKadamtets01</div>
      <div class="div-68">30-40</div>
      <div class="div-69">Pune</div>
    </div>
    <div class="div-70">
      <div class="div-71"><div class="div-72"></div></div>
      <div class="div-73">NishaKadamtets01</div>
      <div class="div-74">30-40</div>
      <div class="div-75">Pune</div>
    </div>
    <div class="div-76">
      <div class="div-77"><div class="div-78"></div></div>
      <div class="div-79">NishaKadamtets01</div>
      <div class="div-80">30-40</div>
      <div class="div-81">Pune</div>
    </div>
    <div class="div-82">
      <div class="div-83"><div class="div-84"></div></div>
      <div class="div-85">NishaKadamtets01</div>
      <div class="div-86">30-40</div>
      <div class="div-87">Pune</div>
    </div> -->
    <div class="div-88">
      <div class="div-89"  *ngIf="selectedUser&&selectedUser.length">
        
        <div class="div-90">
          <span
            style="
                  font-family: Manrope, sans-serif;
                  font-weight: 400;
                "
          >
            Selected :
          </span>
          <span
            style="
                  font-family: Manrope, sans-serif;
                  font-weight: 500;
                "
          ></span>
        </div>
        <div class="div-91">
          <!-- <div class="div-92" *ngIf="selectedUser=== null">00</div> -->
          <div class="div-92">{{selectedUser.length}}</div>
          <div class="div-93">users</div>
        </div>
      </div>
      <div class="div-94 pb-2">
        <button class="div-95" (click)="cancel()">Cancel</button>
        <button class="div-96" (click)="save()">Save and proceed</button>
      </div>
    </div>
  </div>