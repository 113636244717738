<div class="div">
  <div class="div-2">
    <div class="div-3">
      <div class="column"> </div>
      <div class="column-2">
        <div class="div-13">

          <form [formGroup]="createPassForm" >
            <div class="div-17">
              <img style="cursor: pointer;" routerLink="/login" src="https://cdn.builder.io/api/v1/image/assets/TEMP/9498edba-5ab7-4231-9132-36334e2e7c6d?"
                class="img-2" />
              <div class="div-18">Reset password</div>
              <div style="
              text-align: left;
          ">
                <div class="div-19 asterix">Create password</div>
                <div class="">
                  <input matInput [type]="showCreatePass ? 'text' : 'password'" class="col-12 div-22"
                    placeholder="Create password" formControlName="password"
                    [ngClass]="{ 'is-invalid': f['password'].errors && (f['password'].dirty || f['password'].touched )  }"
                    pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_=+-]).{8,30}$"
                    required />
                  <mat-icon matSuffix style="margin-left: -30px;" class=""
                    (click)="togglePasswordVisibility()">{{showCreatePass?'visibility_off':'visibility'}}</mat-icon>
                  @if (f['password'].errors) {
                  <div class="invalid-feedback">
                    @if (f['password'].errors['required']) {
                    <div>Password is required</div>
                    }
                    @if (f['password'].errors['minlength']) {
                    <div>Password must be at least 8 characters</div>
                    }
                    @if (f['password'].errors['maxlength']) {
                    <div>Username must not exceed 30 characters</div>
                    }
                    @if (f['password'].errors['pattern']) {
                      <div><ul>
                        <li>At least 8 characters in length  (up to 30 characters)</li>
                        <li>Contain both upper and lowercase alphabetic characters (e.g. A-Z, a-z)</li>
                        <li>Must have at least one numerical character (0-9)</li>
                        <li>Must have at least one special character (~!&#64;#$%^&*()_-+=)</li>
                      </ul></div>
                      }
                  </div>
                  }
                </div>
              </div>
              <div style="
              text-align: left;
          ">
                <div class="div-19 asterix">Confirm password</div>
                <div class="">
                  <input matInput [type]="showConfirmPass ? 'text' : 'password'" class=" div-22 col-12"
                    placeholder="Re-enter password" formControlName="confirmPass"
                    [ngClass]="{ 'is-invalid': !f['password'].errors && f['confirmPass'].errors && (f['confirmPass'].dirty || f['confirmPass'].touched ) }"
                    required />
                  <mat-icon matSuffix style="margin-left: -30px;" class=""
                    (click)="toggleConfirmPasswordVisibility()">{{showConfirmPass?'visibility_off':'visibility'}}</mat-icon>
                  @if ( f['confirmPass'].errors) {
                  <div class="invalid-feedback">
                    <!-- @if (f['password'].touched) {
                    <div>Create Password is required</div>
                    } -->
                    @if (f['confirmPass'].errors['required']) {
                    <div>Confirm Password is required</div>
                    }
                    @if (f['confirmPass'].errors['matching']) {
                    <div>Confirm Password does not match</div>
                    }
                  </div>
                  }
                </div>
              </div>
              <nav>
                <button class="div-24" (click)="onSubmit()">Save</button>
              </nav>
            </div>
            <div></div>

          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-ui-loader fgsColor="#93d847" pbColor="#93d847" text="Please Wait..."></ngx-ui-loader>