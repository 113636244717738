import { ChangeDetectorRef, Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Observable } from 'rxjs';
import { CampaignService } from 'src/app/services/campaign.service';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { CommonUtilService } from 'src/app/services/common-util.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ElementRef, ViewChild, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
// import {Observable} from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { AsyncPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { PreviewdealwidgetComponent } from '../sharedComponent/previewdealwidget/previewdealwidget.component';
import { MatDialog } from '@angular/material/dialog';

interface FormData {
  field1: string;
  field2: string;
  field3: string;
  // Add more fields as needed
}
export interface AgeRange {
  label: string;
  minAge: number;
  maxAge: number;
}


@Component({
  selector: 'app-addcampaign',
  templateUrl: './addcampaign.component.html',
  styleUrl: './addcampaign.component.scss'
})

export class AddcampaignComponent {
  placeholders = environment.placeholders as { [key: string]: string };
  placeHolders:any;
  activeStep = 1;
  date = Date;
  fileName: any = null;
  allCategory: any;
  editResponse: any;
  brandimage: any;
  editData: any = null;
  submitted = false;
  images3Path: any = null;
  isFileUploaded = false;
  country: any;
  countryid: any;
  targetAudience = false;
  targetCity = false;
  macAddress: any;
  personID: any;
  countryData: any;
  userData: any;
  tagsDropdownSettings: IDropdownSettings = {};

  fData = new FormData();
  allTagsKewords: any = [];
  tagKeywords: any = [];

  favId: any;
  clickId: any;
  clickCost: any;
  favCost: any;
  claimCost: any;
  successCost: any;

  stateData: any;
  cityData: any;
  charCount: number = 0;
  dealTitlechar: number = 0;

  todate: any;
  maxDate: any;
  presentDate: any;
  presentDateTime: any;
  afterPresentDate: any;
  createCampaign = true;
  rejectedDealinfo: string | any;

  isSaveDraft = false;
  savedDraft = false;
  isValidStatus = false;
  isGoLive = false;
  isFlash = false;
  createFlash = false;
  btnLabel: any;
  btnMsg: any = "";
  isDraftRadiobtnVisible = true;
  isPauseRadiobtnVisible = true;
  isdiscountpercentenable = true;
  isdiscountdescription = false;

  hoursOptions: number[] = [4, 6, 8, 12];
  selectedHour: number = 0;

  storeInfo = 'Address/location where deal can be reedemed'
  tagsInfo = 'These tags and keywords will be helpful for deal search.'
  gender = [{ id: "male", name: "Male" }, { id: "female", name: "Female" }, { id: "All", name: "All" }];
  ageRanges: AgeRange[] = [
    // { label: '0-12', minAge: 0, maxAge: 12 },
    { label: 'All', minAge: 1, maxAge: 100 },
    { label: '13-17', minAge: 13, maxAge: 17 },
    { label: '18-25', minAge: 18, maxAge: 25 },
    { label: '26-40', minAge: 26, maxAge: 40 },
    { label: '41-60', minAge: 41, maxAge: 60 },
    { label: '61-Above', minAge: 61, maxAge: 100 },
  ];
  selectedAgeRanges: AgeRange[] = [];
  lowerAgeGroup = [{ id: 0, lower_age: "0" }, { id: 10, lower_age: "10" }, { id: 20, lower_age: "20" }, { id: 30, lower_age: "30" }, { id: 40, lower_age: "40" },
  { id: 50, lower_age: "50" }, { id: 60, lower_age: "60" }, { id: 70, lower_age: "70" }, { id: 80, lower_age: "80" }];

  upperAgeGroup = [{ id: 10, upper_age: "10" }, { id: 20, upper_age: "20" }, { id: 30, upper_age: "30" }, { id: 40, upper_age: "40" },
  { id: 50, upper_age: "50" }, { id: 60, upper_age: "60" }, { id: 70, upper_age: "70" }, { id: 80, upper_age: "80" }];

  separatorKeysCodes: number[] = [ENTER, COMMA];
  // tagCtrl = new FormControl('');
  filteredTags: Observable<string[]> | undefined;
  tagsKeywords: string[] = [];
  allTags: string[] = ['Greeting', 'Home', 'Dineout', 'Food', 'Bag'];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement> | any;

  announcer = inject(LiveAnnouncer);


  constructor(private notifyService: NotificationAlertService, private campaignService: CampaignService,
    private dashboardService: DashboardService, private fb: FormBuilder, private cdr: ChangeDetectorRef, private route: ActivatedRoute,
    private cStorage: ClientSideStorageService, private service: CommonUtilService, private commonUtilService: CommonUtilService,
    private confirmationDialogService: ConfirmationDialogService, private router: Router,private dialog: MatDialog) {
    this.placeHolders = this.placeholders['Create_Deal']
    this.route.queryParams.subscribe(params => {
      console.log(params)
      if (params["createFlash"]) {
        this.activeStep = 1
        this.showSection2 = true
        this.showSection1 = false
        this.createFlash = true;
      }
    });
    this.personID = JSON.parse(this.cStorage.get("userId"));
    // this.country = JSON.parse(this.cStorage.get("country"));
    this.macAddress = JSON.parse(this.cStorage.get("macAddress"));
    this.userData = JSON.parse(this.cStorage.get("marketplaceData"));
    this.allCategory = this.dashboardService.getData();
    this.allCategory = this.allCategory.source._value.data;
    // this.allTagsKewords = this.campaignService.getAllTagsKeyword().subscribe((res: any) => {
    //   if (res?.body?.data) {
    //     this.allTagsKewords = res.body.data;
    //   }

    // });
    this.tagsDropdownSettings = {
      idField: "tag",
      textField: "tag",
      enableCheckAll: true,
      // selectAllText: "Select All Users From List",
      // unSelectAllText: "Unselect All Users From List",
      noDataAvailablePlaceholderText: "There is no item availabale to show",
      allowSearchFilter: true,
      // itemsShowLimit: 3
      // defaultOpen: false
    };
    this.country = JSON.parse(this.cStorage.get("country"));
    this.macAddress = JSON.parse(this.cStorage.get("macAddress"));
    const curDate = new Date();
    this.presentDateTime = curDate
    this.createCampaign = true;
    this.presentDate =
      curDate.getFullYear() +
      "-" +
      ("0" + (curDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + curDate.getDate()).slice(-2);
    this.afterPresentDate = curDate.getFullYear() +
      "-" +
      ("0" + (curDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + (curDate.getDate() + 1)).slice(-2);


    this.submitted = false;
    this.editData = this.campaignService.getData();
    this.editData = this.editData.source._value;
    this.editResponse = this.campaignService.returnCreateAndEditRes();
    this.todate = new Date(this.editData?.available_to)
    this.maxDate = this.todate.getFullYear() +
      "-" +
      ("0" + (this.todate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + this.todate.getDate()).slice(-2);

    if (this.editResponse === false) {
      this.createForm();
    } else {
      this.editForm();
    }
    this.filteredTags = this.campaignBasicDetailForm.get('tagCtrl')?.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => (item ? this._filter(item) : this.allTags.slice())),
    );


    // this.campaignService.price().subscribe((res: any) => {
    //   if (res?.body?.data) {
    //     res?.body?.data.forEach((item: any) => {
    //       if (item.event_type === "DEAL_CLICK_EVENT") {
    //         this.clickId = item.event_id;
    //       } else if (item.event_type === "DEAL_FAVOURITE_EVENT") {
    //         this.favId = item.event_id;
    //       } else if (item.event_type === "DEAL_COUPON_CODE_VIEW_EVENT") {
    //         this.claimCost = item.event_id;
    //       } else if (item.event_type === "success_fee") {
    //         this.successCost = item.event_id;
    //       }


    //     });
    //   }

    //   this.userData = JSON.parse(this.cStorage.get("marketplaceData"));
    //   if (this.userData) {
    //     this.campaignService.eventCostByCountry(this.userData['country']).subscribe((response: any) => {
    //       if (response?.body?.data?.countryEventsCostList) {
    //         let event = response?.body?.data?.countryEventsCostList;
    //         this.clickCost = event.find((el: any) => el.event_id === this.clickId);
    //         this.favCost = event.find((el: any) => el.event_id === this.favId);
    //         this.claimCost = event.find((el: any) => el.event_id === this.claimCost);
    //         this.successCost = event.find((el: any) => el.event_id === this.successCost);
    //         if (this.editResponse === false) {
    //           this.createbudgetForm();
    //         } else {
    //           this.editbudgetForm();
    //         }
    //       }
    //     });
    //   }
    // });

    if (this.userData) {
      this.route.data.subscribe((data: any) => {
        this.countryData = data.resolvedData.data
        if (this.countryData) {
          let countryId = parseInt(this.userData.country);
          let CountryValues = this.countryData.filter((country: any) => country.country_id === countryId);//this.countryData.filter((option:any) => option.country_id === this.userData.country);
          this.country = CountryValues[0].country_name;
          this.countryid = CountryValues[0].country_id
          // this.countryData.forEach((element: any) => {
          //   if ((element.country_id).toString() === this.userData.country) {
          //     this.country = element.country_name;
          //     this.countryid = element.country_id
          //   }
          // });
          this.campaignService.eventCostByCountryCosts(this.country).subscribe((response: any) => {
            if (response?.body?.data?.countryEventsCostsList) {
              let event = response?.body?.data?.countryEventsCostsList;
              this.clickCost = event.find((el: any) => el.event_type === "DEAL_CLICK_EVENT");
              this.favCost = event.find((el: any) => el.event_type === "DEAL_FAVOURITE_EVENT");
              this.claimCost = event.find((el: any) => el.event_type === "DEAL_COUPON_CODE_VIEW_EVENT");
              this.successCost = event.find((el: any) => el.event_type === "SUCCESS_FEE");
              if (this.editResponse === false) {
                this.createbudgetForm();
              } else {
                this.editbudgetForm();
              }
            }
          });
        }


        if (this.editResponse === false) {
          this.createtargetForm();
          this.createValidityForm();
          // this.createbudgetForm();
        } else {
          this.editValidityForm();
          this.edittargetForm();
          // this.editbudgetForm();
        }
      });
      // this.service.getCountry(this.country, this.macAddress).subscribe((res: any) => {
      //   this.countryData = res.body?.data;
      //   if (this.countryData) {
      //     this.countryData.forEach((element: any) => {
      //       if ((element.country_id).toString() === this.userData.country) {
      //         this.country = element.country_name;
      //       }
      //     });
      //   }
      //   if (this.editResponse === false) {
      //     this.createtargetForm();
      //     this.createValidityForm();
      //     this.createbudgetForm();
      //   } else {
      //     this.editValidityForm();
      //     this.edittargetForm();
      //     this.editbudgetForm();
      //   }
      // });
    }
  }

  add(event: MatChipInputEvent): void {
    // const value = (event.value || '').trim();
    const value = typeof event === 'string' ? event : (event.value || '').trim();

    // Add our tag
    if (value) {
      this.tagsKeywords.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.campaignBasicDetailForm.get('tagCtrl')?.setValue(null);
  }

  remove(item: string): void {
    const index = this.tagsKeywords.indexOf(item);

    if (index >= 0) {
      this.tagsKeywords.splice(index, 1);

      this.announcer.announce(`Removed ${item}`);
    }

    this.campaignBasicDetailForm.get('tagCtrl')?.updateValueAndValidity();
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tagsKeywords.push(event.option.viewValue);
    this.tagInput.nativeElement.value = '';
    this.campaignBasicDetailForm.get('tagCtrl')?.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(item => item.toLowerCase().includes(filterValue));
  }

  private tagsArrayValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      return this.tagsKeywords.length === 0 ? { 'tagsRequired': true } : null;
    };
  }

  isFormValid = false;
  isValidityFormValid = false;
  ngOnInit(): void {
    this.countrySelectionForState();
    this.getBrandSpecificCategory();
    this.selectedHour = this.hoursOptions[0];
    this.campaignBasicDetailForm.valueChanges.subscribe(() => {
      // this.isFormValid = this.campaignBasicDetailForm.valid;
      this.validateForm()
    });
    this.validityStatusForm.valueChanges.subscribe(() => {
      this.isValidityFormValid = this.validityStatusForm.valid;

      console.log(this.validityStatusForm.get('to')?.errors?.['dateRangeError'])
      if (this.validityStatusForm.value.from === 'Invalid date' || this.validityStatusForm.value.from === null || this.validityStatusForm.value.to === 'Invalid date') {
        // this.notifyService.showError("Please Select Dates", "Invalid Form");
        this.isValidityFormValid = this.validityStatusForm.invalid;
      }
    });

    this.campaignBasicDetailForm.get('discountPercentage')?.valueChanges.subscribe((value) => {
      if (this.f['discountType'].value === 'fixedDiscount') {
        let discountValue = value
        this.campaignBasicDetailForm.get('discountDescription')?.setValue(`Get ${discountValue}% Off`);
      }
    });

    this.campaignBasicDetailForm.get('discountType')?.valueChanges.subscribe((value) => {

      // if (value === 'fixedDiscount') {
      //   this.campaignBasicDetailForm.get('discountPercentage')?.enable();
      //   this.campaignBasicDetailForm.get('discountDescription')?.disable();
      //   this.isdiscountpercentenable = true;
      //   this.isdiscountdescription = false;
      // } else {
      //   this.campaignBasicDetailForm.get('discountPercentage')?.disable();
      //   this.campaignBasicDetailForm.get('discountDescription')?.enable();
      //   this.isdiscountpercentenable = false;
      //   this.isdiscountdescription = true;
      // }
      if (value === 'fixedDiscount') {
        this.campaignBasicDetailForm.get('discountPercentage')?.enable();
        this.campaignBasicDetailForm.get('discountPercentage')?.setValidators(Validators.required);
        this.campaignBasicDetailForm.get('discountPercentage')?.updateValueAndValidity();


        this.campaignBasicDetailForm.get('discountDescription')?.disable();
        this.campaignBasicDetailForm.get('discountDescription')?.clearValidators();
        this.campaignBasicDetailForm.get('discountDescription')?.updateValueAndValidity();

        this.isdiscountpercentenable = true;
        this.isdiscountdescription = false;
      } else {
        this.campaignBasicDetailForm.get('discountPercentage')?.disable();
        this.campaignBasicDetailForm.get('discountPercentage')?.clearValidators();
        this.campaignBasicDetailForm.get('discountPercentage')?.updateValueAndValidity();
        this.campaignBasicDetailForm.get('discountDescription')?.setValue('');
        this.campaignBasicDetailForm.get('discountDescription')?.enable();
        this.campaignBasicDetailForm.get('discountDescription')?.setValidators(Validators.required);
        this.campaignBasicDetailForm.get('discountDescription')?.updateValueAndValidity();

        this.isdiscountpercentenable = false;
        this.isdiscountdescription = true;
      }
    });

    this.campaignBasicDetailForm.get('description')?.valueChanges.subscribe(value => {
      this.charCount = value.length;
    });
    this.campaignBasicDetailForm.get('title')?.valueChanges.subscribe(value => {
      this.dealTitlechar = value.length;
    });
  }

  validateForm() {
    this.isFormValid = this.campaignBasicDetailForm.valid && this.isFileUploaded;
  }

  getCategory() {
    this.dashboardService.getCategory().subscribe((response: any) => {
      this.commonUtilService.tokenSet(response);
      this.allCategory = response?.body.data;
      this.dashboardService.setData(response?.body);
    });
  }

  getBrandSpecificCategory() {
    this.dashboardService.getBrandCategory(this.userData['marketplace_id']).subscribe((response: any) => {
      // this.commonUtilService.tokenSet(response);
      this.allCategory = response?.body.data;
      this.allCategory.push({
        "category_id": 0,
        "category_type": "All",
        "status": "A",
        "image_url": "category/DineOut.png",
        "created_by": "clmcore",
        "created_date": "2023-07-19T08:38:46.469+00:00",
        "modified_by": "clmcore",
        "modified_date": "2023-07-19T08:38:46.469+00:00"
      })
      this.dashboardService.setData(response?.body);

      if (response && this.editResponse) {
        this.detectCategories()
      }
    });

  }


  campaignBasicDetailForm: FormGroup = new FormGroup({
    title: new FormControl(''),
    category: new FormControl(''),
    offerCode: new FormControl(''),
    description: new FormControl(''),
    image: new FormControl(''),
    url: new FormControl(''),
    country: new FormControl(''),
    discountType: new FormControl(''),
    discountPercentage: new FormControl(''),
    discountDescription: new FormControl('')
  });
  targetForm: FormGroup = new FormGroup({
    country: new FormControl(''),
    select: new FormControl(''),
    state: new FormControl(''),
    city: new FormControl('')
  });
  BudgetDetailForm: FormGroup = new FormGroup({
    cpc: new FormControl(''),
    cpf: new FormControl(''),
    currency: new FormControl(''),
    budget: new FormControl(''),
  });
  validityStatusForm: FormGroup = new FormGroup({
    to: new FormControl(''),
    from: new FormControl(''),
    status: new FormControl(''),
    flashdate: new FormControl(''),
    selectedHour: new FormControl('')
  });

  createForm() {
    this.fileName = null;
    this.campaignBasicDetailForm = this.fb.group({
      id: [null],
      title: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(50)]], //Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')
      category: ['', Validators.required],
      offerCode: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      description: ['', [Validators.required, Validators.minLength(5),
      Validators.maxLength(500)]],
      image: ['', [Validators.required]],
      url: ['', [this.websiteUrlValidator()]],
      discountType: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      discountPercentage: [{ value: '', disabled: true }, [Validators.required]],
      tag: ['',],
      tagCtrl: [null, [this.tagsArrayValidator()]],
      location: ['', [Validators.minLength(5),
      Validators.maxLength(200)]],
      discountDescription: [{ value: '', disabled: true }, [Validators.minLength(5),
      Validators.maxLength(50)]]
    },{
      validators: this.atLeastOneRequiredValidator('url', 'location')
    });
  }

  selectedCategory: any;
  isCategoriesChanged: boolean = false;
  editForm() {
    let res = this.editData;
    // let tagKeyword = [];
    // res.
    this.charCount = res.product_description.length;
    this.dealTitlechar = res.product_name.length;
    this.tagsKeywords = res.keywords;
    if (res.categories.length > 1) {
      this.selectedCategory = 0
    } else {
      this.selectedCategory = res.categories[0]
    }
    this.campaignBasicDetailForm = this.fb.group({
      id: [res.product_id],
      title: [res.product_name, [Validators.required, Validators.minLength(2),
      Validators.maxLength(50)]],//Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')
      category: [this.selectedCategory, Validators.required],
      offerCode: [res.coupon_code, [Validators.required, Validators.minLength(2),
      Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      description: [res.product_description, [Validators.required, Validators.minLength(5),
      Validators.maxLength(500)]],
      image: [res.product_image, Validators.required],
      url: [res.product_url, [ this.websiteUrlValidator()]],
      discountType: [res.discountType, [Validators.required, Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      discountPercentage: [res.discountPercentage, [Validators.required]],
      tag: [res.keywords],
      tagCtrl: [],
      location: [res.location, [ Validators.minLength(5),
      Validators.maxLength(200)]],
      discountDescription: [res.discountDescription, [Validators.minLength(5),
      Validators.maxLength(50)]]
    },
    {
      validators: this.atLeastOneRequiredValidator('url', 'location')
    });

    if (res.discountType === 'fixedDiscount') {
      this.campaignBasicDetailForm.get('discountPercentage')?.disable();
      this.isdiscountpercentenable = true;
      this.isdiscountdescription = false;
    } else {
      this.campaignBasicDetailForm.get('discountDescription')?.disable();
      this.isdiscountpercentenable = false;
      this.isdiscountdescription = true;
    }
    this.fileName = res.product_image;
    if (res.product_image != null) {
      this.isFileUploaded = true;
      this.brandimage = environment.assetsUrl + '/assets/' + res.product_image
    }
  }

  createtargetForm() {
    this.targetAudience = true;
    this.targetForm = this.fb.group({
      country: [this.country],
      select: ['EC'],
      state: [''],
      city: [''],
      gender: ['All'],
      lowerAge: [1],
      upperAge: [100]
    });
    this.selectedAgeRanges = this.ageRanges.filter(
      (range) => range.minAge === this.t['lowerAge'].value && range.maxAge === this.t['upperAge'].value!
    );
  }
  edittargetForm() {
    let res = this.editData;
    this.rejectedDealinfo = res.rejectedDealComments
    // this.stateSelectionForCity( res?.state);
    // this.service.getCity(this.countryid, res?.state, this.country, this.macAddress).subscribe((res: any) => {
    //   this.cityData = res.body?.data;
    // });
    // if (res.gender !== null && res.gender !== undefined && res.gender !== "" && res.state === null && res.state === undefined && res.state === "") {
    //   this.targetAudience = true;
    //   this.targetForm = this.fb.group({
    //     //lower_age
    //     country: [this.country],
    //     select: ['C'],
    //     gender: [res?.gender, Validators.required],
    //     lowerAge: [res?.lower_age, Validators.required],
    //     upperAge: [res?.upper_age, Validators.required],
    //     state: [''],
    //     city: ['']
    //   });
    //   if(res?.lower_age === 0 && res?.upper_age === 100 ){
    //     this.selectedAgeRanges = this.ageRanges.filter(
    //       (range) => range.minAge === res?.lower_age! && range.maxAge === res?.upper_age!
    //     );
    //   }else{
    //     this.selectedAgeRanges = this.ageRanges.filter(
    //       (range) => range.minAge >= res?.lower_age! && range.maxAge <= res?.upper_age!
    //     );
    //   }

    // } else 

    if (res.state !== null && res.state !== undefined && res.state !== "" && res.state !== "all") {
      // let stateId = parseInt(res.state);
      // let setStateId = this.stateData.filter((state: any) => state.state_id === stateId);
      // let statecode = this.editResponse ? setStateId[0].state_code:this.stateData[0]?.state_code;

      this.targetAudience = true;
      this.targetCity = true;
      this.targetForm = this.fb.group({
        //lower_age
        country: [this.country],
        select: ['AC'],
        gender: [res?.gender, Validators.required],
        lowerAge: [res?.lower_age, Validators.required],
        upperAge: [res?.upper_age, Validators.required],
        state: [res?.state],
        city: [res?.cities]
      });
      if (res?.lower_age === 1 && res?.upper_age === 100) {
        this.selectedAgeRanges = this.ageRanges.filter(
          (range) => range.minAge === res?.lower_age! && range.maxAge === res?.upper_age!
        );
      } else {
        this.selectedAgeRanges = this.ageRanges.filter(
          (range) => range.minAge >= res?.lower_age! && range.maxAge <= res?.upper_age!
        );
      }
    }
    else {
      this.targetAudience = true;
      this.targetForm = this.fb.group({
        country: [this.country],
        select: ['EC'],
        state: [res?.state],
        city: [res?.cities],
        gender: [res?.gender],
        lowerAge: [res?.lower_age],
        upperAge: [res?.upper_age]
      });
      if (res?.lower_age === 1 && res?.upper_age === 100) {
        this.selectedAgeRanges = this.ageRanges.filter(
          (range) => range.minAge === res?.lower_age! && range.maxAge === res?.upper_age!
        );
      } else {
        this.selectedAgeRanges = this.ageRanges.filter(
          (range) => range.minAge >= res?.lower_age! && range.maxAge <= res?.upper_age!
        );
      }
    }
  }
  createbudgetForm() {
    this.BudgetDetailForm = this.fb.group({
      cpc: [this.clickCost.cost],
      cpf: [this.favCost.cost],
      currency: [this.clickCost.currency],
      budget: ['', Validators.required],
    });
  }
  editbudgetForm() {
    let res = this.editData;
    let setBudget = this.createFlash ? 0 :res.budget
    this.BudgetDetailForm = this.fb.group({
      cpc: [this.clickCost.cost],
      cpf: [this.favCost.cost],
      currency: [this.clickCost.currency],
      budget: [setBudget, Validators.required],
    });
  }

  createValidityForm() {
    this.createCampaign = true;
    this.validityStatusForm = this.fb.group({
      to: ['', [Validators.required, this.dateRangeValidator.bind(this)]],
      from: ['', [Validators.required, this.dateRangeFromValidator.bind(this)]],
      status: [''],
      flashdate: [''],
      selectedHour: ['']
      // flashofferCode: ['', [Validators.minLength(2),
      // Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      // flashbudget: [''],
    });

    this.btnLabel = "Save As Draft"
    this.btnMsg = "Are you sure you want to Save this deal as draft?"
  }


  dateRangeValidator(control: AbstractControl): ValidationErrors | null {
    const fromValue = new Date(this.validityStatusForm.get('from')?.value);
    const toValue = new Date(control.value);

    if (fromValue > toValue) {
      return { dateRangeError: true };
    }

    return null;
  }

  dateRangeFromValidator(control: AbstractControl): ValidationErrors | null {
    const fromValue = new Date(control.value);
    const toValue = new Date(this.validityStatusForm.get('to')?.value);

    if (fromValue > toValue) {
      return { dateRangeError: true };
    }

    return null;
  }

  calculateTimeDifference(fromTime: string, toTime: string): string {
    const fromDate = new Date(fromTime);
    const toDate = new Date(toTime);

    const diffInMs = toDate.getTime() - fromDate.getTime();

    const diffInSeconds = Math.floor(diffInMs / 1000);
    const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
    const diffInHours = Math.floor(diffInMs / (1000 * 60 * 60));

    return `${diffInHours}`; //hours, ${diffInMinutes % 60} minutes, ${diffInSeconds % 60} seconds
  }

  timeDifference: string = "";
  selectedDuration: any;
  flashDealDay: any;
  editValidityForm() {
    this.createCampaign = false;
    let res = this.editData;

    if (res.flash) {
      this.isFlash = res.flash;
      this.showSection2 = true;
      this.showSection1 = false;
      this.timeDifference = this.calculateTimeDifference(res.available_from, res.available_to);
      this.selectedDuration = parseInt(this.timeDifference)//this.hoursOptions.indexOf(parseInt(this.timeDifference));
      // this.selectedDuration = this.hoursOptions.filter(option => option === parseInt(this.timeDifference));
      res.available_from = new Date(res.available_from)
      let parsedDate = moment(res.available_from, "YYYY-MM-DD hh:mm:ss").local();
      this.flashDealDay = parsedDate.format("YYYY-MM-DD");
      res.available_from = parsedDate.format("HH:mm:ss");
      res.available_to = new Date(res.available_to)
      let parseDate = moment(res.available_to, "YYYY-MM-DD hh:mm:ss").local();
      res.available_to = parseDate.format("HH:mm:ss");


    } else {
      res.available_from = new Date(res.available_from)
      let parsedDate = moment(res.available_from, "YYYY-MM-DD");
      res.available_from = parsedDate.format("YYYY-MM-DD");
      res.available_to = new Date(res.available_to)
      let parseDate = moment(res.available_to, "YYYY-MM-DD");
      res.available_to = parseDate.format("YYYY-MM-DD");

      if (this.createFlash) {
        this.flashDealDay = this.presentDate;
        res.status = ''
      }
    }




    this.validityStatusForm = this.fb.group({
      to: [res.available_to, [Validators.required, this.dateRangeValidator.bind(this)]],
      from: [res.available_from, [Validators.required, this.dateRangeFromValidator.bind(this)]],
      status: [res.status],
      flashdate: [this.flashDealDay],
      selectedHour: [this.selectedDuration]
      // flashofferCode: [res.coupon_code, [Validators.required, Validators.minLength(2),
      // Validators.maxLength(20), Validators.pattern('^[a-zA-Z0-9]+( [a-zA-Z0-9]+)*$')]],
      // flashbudget: [res.budget, Validators.required],
    });

    if (this.v['status'].value === 'E' || this.v['status'].value === 'C') {
      this.f['offerCode'].disable();
      this.v['from'].disable();
      this.f['category'].disable();
      this.f['url'].disable();
      this.f['discountType'].disable();
      this.f['discountPercentage'].disable();
      this.f['discountDescription'].disable();
      this.f['title'].disable();
      this.f['description'].disable();
      this.f['location'].disable();
      if (res.flash) {
        this.v['to'].disable();
        this.v['flashdate'].disable();
        this.v['selectedHour'].disable();
      }
    }

    if (this.v['status'].value === 'A' || this.v['status'].value === 'S' || this.v['status'].value === 'U') {
      if (!this.createFlash) {
        this.f['offerCode'].disable();
        this.v['from'].disable();
        this.f['category'].disable();
        this.f['url'].disable();
        this.f['discountType'].disable();
        this.f['discountPercentage'].disable();
        this.f['discountDescription'].disable();
        this.f['title'].disable();
        this.f['description'].disable();
        this.f['location'].disable();
      }
      if (res.flash) {
        this.v['to'].disable();
        this.v['flashdate'].disable();
        this.v['selectedHour'].disable();
      }
    }

    if (this.v['status'].value === 'A' || this.v['status'].value === 'S') {
      this.isDraftRadiobtnVisible = false;
    }

    if (this.v['status'].value === 'D' || this.v['status'].value === 'P') {
      this.isPauseRadiobtnVisible = false
    }

    if (this.v['status'].value === 'A') {
      this.btnLabel = "Save"
      this.btnMsg = "Are you sure you want to Save this deal?"
    }
    else if (this.v['status'].value === 'D') {
      this.btnLabel = "Save As Draft"
      this.btnMsg = "Are you sure you want to Save this deal as draft?"
    }
    else {
      this.btnLabel = "Save"
      this.btnMsg = "Are you sure you want to Save this deal?"
    }


  }

  get b(): { [key: string]: AbstractControl } {
    return this.BudgetDetailForm.controls;
  }


  get f(): { [key: string]: AbstractControl } {
    return this.campaignBasicDetailForm.controls;
  }
  get t(): { [key: string]: AbstractControl } {
    return this.targetForm.controls;
  }
  get v(): { [key: string]: AbstractControl } {
    return this.validityStatusForm.controls;
  }


  websiteUrlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const url = control.value;
      if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
        // If URL doesn't start with 'http://' or 'https://', append 'https://'
        control.setValue('https://' + url);
      }
      return null;
    };
  }


  selectClick() {
    this.targetAudience = true;
    this.targetCity = false;
    let res = this.editData;
    if (res?.gender !== null && res?.gender !== undefined) {
      this.targetForm = this.fb.group({
        country: [this.country],
        select: ['EC', Validators.required],
        gender: [res?.gender, Validators.required],
        lowerAge: [res?.lower_age, Validators.required],
        upperAge: [res?.upper_age, Validators.required]
      });
    } else {

      this.targetForm = this.fb.group({
        country: [this.country],
        select: ['EC', Validators.required],
        gender: ['All', Validators.required],
        lowerAge: [1, Validators.required],
        upperAge: [100, Validators.required]
      });
      this.selectedAgeRanges = this.ageRanges.filter(
        (range) => range.minAge === this.t['lowerAge'].value && range.maxAge === this.t['upperAge'].value!
      );
    }
  }
  selectClickCity() {
    this.targetAudience = true;
    this.targetCity = true;
    let res = this.editData;
    this.countrySelectionForState()
    if (res?.state !== null && res?.state !== undefined && res?.state !== '') {
      this.targetForm = this.fb.group({
        country: [this.country],
        state: [res?.state],
        city: [res?.cities],
        select: ['AC', Validators.required],
        gender: [res?.gender, Validators.required],
        lowerAge: [res?.lower_age, Validators.required],
        upperAge: [res?.upper_age, Validators.required]
      });
    } else {

      this.targetForm = this.fb.group({
        country: [this.country],
        select: ['AC', Validators.required],
        state: [this.stateData[0]?.state_code],
        city: ['All'],
        gender: ['All', Validators.required],
        lowerAge: [1, Validators.required],
        upperAge: [100, Validators.required]
      });
      this.selectedAgeRanges = this.ageRanges.filter(
        (range) => range.minAge === this.t['lowerAge'].value && range.maxAge === this.t['upperAge'].value!
      );
    }

  }
  customClick() {
    this.targetAudience = true;
    this.targetCity = false;
    let res = this.editData;
    if (res?.gender !== null && res?.gender !== undefined) {
      this.targetForm = this.fb.group({
        country: [this.country],
        select: ['C', Validators.required],
        gender: [res?.gender, Validators.required],
        lowerAge: ['', Validators.required],
        upperAge: ['', Validators.required]
      });
    } else {
      this.targetForm = this.fb.group({
        country: [this.country],
        select: ['C', Validators.required],
        gender: ['', Validators.required],
        lowerAge: ['', Validators.required],
        upperAge: ['', Validators.required]
      });
    }

  }

  onSelectionChange(event: any) {
    const allOption = this.ageRanges.find(range => range.label === 'All');
    if (allOption) {
      if (event.value.includes(allOption)) {
        this.selectedAgeRanges = [allOption];
      } else {
        // this.selectedAgeRanges = event.value.filter((range: AgeRange) => range.label !== 'All');
        this.selectedAgeRanges = event.value.filter((range: AgeRange) => range.label !== 'All');
      }
    }
  }

  removeImage() {
    this.campaignBasicDetailForm.value.image = null;
    this.fileName = null;
    this.fData = new FormData();
    this.images3Path = null;
    const imageControl = this.campaignBasicDetailForm.get('image');
    this.imagePreview = null;

    // Reset the file input
    const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
    if (imageControl) {
      const customError = { invalidImage: true }; // Custom validation error object
      imageControl.setErrors(customError);
      imageControl.markAsDirty(); // Mark control as touched to trigger validation messages
      this.campaignBasicDetailForm.updateValueAndValidity();
      this.isFormValid = this.campaignBasicDetailForm.valid;
      this.isFileUploaded = false;
    }

  }

  // Method to manually set validation state to true for a control
  setValidationTrue(controlName: string): void {
    const control = this.campaignBasicDetailForm.get(controlName);
    if (control) {
      control.setErrors(null); // Clear any existing errors
      control.markAsDirty(); // Mark control as touched to trigger validation
    }
  }

  updateValidators(controlName: string): void {
    const control = this.targetForm.get(controlName);
    const otherControlName = controlName === 'upperAge' ? 'lowerAge' : 'upperAge';
    const otherControl = this.targetForm.get(otherControlName);

    if (control && otherControl) {
      const selectedValue = control.value;
      const otherValue = otherControl.value;

      if (controlName === 'upperAge') {
        control.setValidators([Validators.required, Validators.min(+otherValue + 1)]);
        otherControl.setValidators([Validators.required, Validators.max(+selectedValue - 1)]);
      } else {
        control.setValidators([Validators.required, Validators.max(+otherValue - 1)]);
        otherControl.setValidators([Validators.required, Validators.min(+selectedValue + 1)]);
      }
      control.updateValueAndValidity();
      otherControl.updateValueAndValidity();
    }
  }


  imageUpload() {
    this.campaignService.uploadImage(this.fData).subscribe((res: any) => {
      if (res?.data?.s3Path) {
        this.images3Path = res?.data?.s3Path;
        this.notifyService.showSuccess(
          "Image Successfully Uploaded",
          res?.status
        );
        this.isFormValid = this.campaignBasicDetailForm.valid;
        this.isFileUploaded = true;
      } else {
        this.notifyService.showSuccess(
          res?.message,
          res?.status
        );
      }
    });
  }

  imagePreview: string | ArrayBuffer | null = null;
  uploadDoc(event: any) {
    let files = event.target.files[0];
    let doctype = files.name.split('.');
    let doctypeName = doctype[doctype.length - 1];
    if (doctypeName && (doctypeName === "jpg" || doctypeName === "jpeg" || doctypeName === "png") &&
      files.size <= 2097152) {
      this.fileName = files.name;
      this.fData.append("file", files);
      const imageControl = this.campaignBasicDetailForm.get('image');
      if (imageControl) {
        imageControl.setErrors(null); // Clear custom validation errors
        imageControl.markAsPristine(); // Mark control as pristine
        this.campaignBasicDetailForm.updateValueAndValidity();
        // this.isFormValid = this.campaignBasicDetailForm.valid;
      }
      const file = (event.target as HTMLInputElement).files?.[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.imagePreview = reader.result;
        };
        reader.readAsDataURL(file);
      }
      this.notifyService.showInfo("Image selected please upload", "")
    } else {
      this.notifyService.showInfo("Only jpg, jpeg, png file formats are allowed in less than 2 MB", "Invalid File")
    }
    event.target.value = '';
  }

  formData: FormData = {
    field1: '',
    field2: '',
    field3: '',
    // Initialize other fields as needed
  };




  saveData1(data: any) {
    if (this.editResponse === true) {
      this.campaignService.setEditBasicDetails(data, this.images3Path, this.tagKeywords);
    } else {
      this.campaignService.setBasicDetails(data, this.images3Path, this.tagKeywords);
      // this.data.push(values);
    }
  }
  saveData2(data: any) {
    if (this.editResponse === true) {
      this.campaignService.setEditTargetDetails(data);
    } else {
      this.campaignService.setTargetDetails(data);
      // this.data.push(values);
    }
  }
  saveData3(data: any) {
    if (this.editResponse === true) {
      this.campaignService.setEditBudgetDetail(data, this.userData['marketplace_id']);
    } else {
      this.campaignService.setBudgetDetail(data, this.userData['marketplace_id']);
      // this.data.push(values);
    }
  }



  nextStep1(form: any) {
    if (form.valid) {
      // this.confirmationDialogService
      //   .confirm("Confirmation ", "Are you sure you want to Save? ")
      //   .then((confirmed) => {
      //     if (confirmed) {
      let values = this.campaignBasicDetailForm.value;
      // if (this.campaignBasicDetailForm.value.tag.length !== 0) {
      //   this.campaignBasicDetailForm.value.tag.forEach((item: any) => {
      //     if (!this.tagKeywords.includes(item.tag)) {
      //       this.tagKeywords.push(item.tag);
      //     }
      //   });
      // }
      // if (this.campaignBasicDetailForm.value.tag.length !== 0) {
      //   const existingTags = this.tagKeywords.map((tag: any) => tag); // Convert existing tags to lowercase for comparison
      //   const newTags = this.campaignBasicDetailForm.value.tag.map((item: any) => item.tag); // Convert new tags to lowercase for comparison


      //   newTags.forEach((tag: any) => {
      //     if (!existingTags.includes(tag)) {
      //       this.tagKeywords.push(tag);
      //     }
      //   });


      //   this.tagKeywords = this.tagKeywords.filter((tag: any) => newTags.includes(tag));
      //   // this.tagKeywords = this.tagKeywords.map((tag:any) => tag.charAt(0).toUpperCase() + tag.slice(1));
      // }


      // if (this.campaignBasicDetailForm.value.tag.length !== 0) {
      //   let newTags: any;
      //   const existingTags = this.tagKeywords.map((tag: any) => tag); // Convert existing tags to lowercase for comparison
      //   const isArray1InObjectFormat = this.isArrayInObjectFormat(this.campaignBasicDetailForm.value.tag);
      //   if (isArray1InObjectFormat) {
      //     newTags = this.campaignBasicDetailForm.value.tag.map((item: any) => item.tag); // Convert new tags to lowercase for comparison
      //   }
      //   else {
      //     newTags = this.campaignBasicDetailForm.value.tag.map((tag: any) => ({ tag }));
      //   }

      //   newTags.forEach((tag: any) => {
      //     if (!existingTags.includes(tag)) {
      //       this.tagKeywords.push(tag);
      //     }
      //   });

      //   this.tagKeywords = isArray1InObjectFormat ? this.tagKeywords.filter((tag: any) => newTags.includes(tag)) : this.tagKeywords.map((obj: any) => obj.tag);
      //   // const tagKeywords = this.tagKeywords.map((obj:any) => obj.tag);
      //   // this.tagKeywords = this.tagKeywords.map((tag:any) => tag.charAt(0).toUpperCase() + tag.slice(1));
      // }

      // this.saveData1(values);
      if (this.isFileUploaded) {
        ++this.activeStep
        this.cdr.markForCheck();
      } else {
        this.notifyService.showError("Please upload the image", "Invalid Form");
        return;
      }

      // this.router.navigate(["/campaign/target"]);
      //   }
      // });
    }
    else {
      this.notifyService.showError("Please provide mandatory details", "Invalid Form");
      return;
    }
  }

  nextStep2(form: any) {
    const lowerAge = Math.min(...this.selectedAgeRanges.map(range => range.minAge));
    const upperAge = Math.max(...this.selectedAgeRanges.map(range => range.maxAge));
    this.targetForm.get('lowerAge')?.setValue(lowerAge);
    this.targetForm.get('upperAge')?.setValue(upperAge);

    if (form.valid) {
      if (this.targetForm.invalid) {
        this.notifyService.showError("Please provide mandatory details", "Invalid Form");
        return;
      } else {
        // this.confirmationDialogService
        //   .confirm("Confirmation ", "Are you sure you want to Save? ")
        //   .then((confirmed) => {
        //     if (confirmed) {
        let values = this.targetForm.value;
        this.saveData2(values);
        ++this.activeStep
        this.cdr.markForCheck();
        // this.router.navigate(["/campaign/budget"]);
        //   }
        // });
      }
    }
    else {
      this.notifyService.showError("Please provide mandatory details", "Invalid Form");
      return;
    }
  }



  nextStep3(form: any) {



    if (form.valid) {
      if (this.BudgetDetailForm.invalid) {
        this.notifyService.showError("Please provide mandatory details", "Invalid Form");
        return;
      } else {
        // this.confirmationDialogService
        //   .confirm("Confirmation ", "Are you sure you want to Save? ")
        //   .then((confirmed) => {
        //     if (confirmed) {
        let values = this.BudgetDetailForm.value;
        this.saveData3(values);
        ++this.activeStep
        this.cdr.markForCheck();
        // this.router.navigate(["/campaign/validatstatus"]);
        //   }
        // });
      }
    }
    else {
      this.notifyService.showError("Please provide mandatory details", "Invalid Form");
      return;
    }
  }

  nextStep(form: any) {
    this.isGoLive = true;
    if (this.validityStatusForm.value.from > this.presentDate) {
      this.notifyService.showInfo("Your Deal will be in 'Upcoming' state till the Mentioned Date Hits", "");
    }
    this.btnMsg = `Are you sure you want to send this deal for approval?<br><br> <b>Note:</b> Once the deal is approved then only it will be visible to consumer.`
    this.saveCampaignDraft()
    // if (form.valid) {
    //   // this.confirmationDialogService
    //   // .confirm("Confirmation ", "Are you sure you want to Save? ")
    //   // .then((confirmed) => {
    //   //   if (confirmed) {
    //   // let values = this.campaignBasicDetailForm.value;
    //   // if (this.campaignBasicDetailForm.value.tag.length !== 0) {
    //   //   this.campaignBasicDetailForm.value.tag.forEach((item: any) => {
    //   //     this.tagKeywords.push(item.tag);
    //   //   });
    //   // }
    //   this.submit()
    //   //   }
    //   // });
    // }
    // else {
    //   this.notifyService.showError("Please fill required details", "Invalid Form");
    //   return;
    // }
  }

  submit() {
    if (this.validityStatusForm.invalid) {
      this.notifyService.showError("Please provide mandatory details", "Invalid Form");
      return;
    } else {
      this.confirmationDialogService
        .confirm("Confirmation ", "Are you sure you want to save and go live? ")
        .then((confirmed) => {
          if (confirmed) {
            let values = this.validityStatusForm.value;
            // let parsedDate = moment(values.to);
            // values.to = parsedDate.format();
            // let parsedFromDate = moment(values.from);
            // values.from = parsedFromDate.format();

            let fromDate = moment(values.from).hours(0).minutes(0).seconds(1).utc();
            let toDate = moment(values.to).hours(23).minutes(59).seconds(59).utc();

            values.from = fromDate.format();
            values.to = toDate.format();

            let payload: any;

            if (this.editResponse === false) {
              values.status = "A";
              this.campaignService.setValidityStatus(values);
              payload = this.campaignService.createCampaignPayloadReturn();
              payload.product_name = this.f['title'].value;
              // this.data.push(values);

              this.campaignService.addCampaign(payload).subscribe((res: any) => {
                if (res.body?.status === 'Error') {
                  this.notifyService.showError(
                    res.body?.message,
                    res.body?.status
                  );
                } else {
                  this.notifyService.showSuccess(
                    "New Deal Added Successfully",
                    res.body?.status
                  );
                  this.router.navigate(["/dashboard"]);
                }
              });
            } else {
              this.campaignService.setEditValidityStatus(values);
              payload = this.campaignService.editCampaignPayloadReturn();


              values.status = "A";
              this.campaignService.setEditValidityStatus(values);
              payload.product_name = this.f['title'].value;
              // payload = this.campaignService.editCampaignPayloadReturn();


              this.campaignService.updateCampaign(payload).subscribe((res: any) => {
                if (res.body?.status === 'Error') {
                  this.notifyService.showError(
                    res.body?.message,
                    res.body?.status
                  );
                } else {
                  this.notifyService.showSuccess(
                    "Deal Updated Successfully",
                    res.body?.status
                  );
                  this.campaignService.setData(null);
                  this.router.navigate(["/dashboard"]);
                }
              });
            }
          }
        });
    }
  }


  saveDeal() {
    let values = this.validityStatusForm.value;
    if (this.validityStatusForm.value.from === 'Invalid date' || this.validityStatusForm.value.from === null || this.validityStatusForm.value.to === 'Invalid date') {
      this.notifyService.showError("Please Select Dates", "Invalid Form");
    } else {
      this.saveCampaignDraft()
    }

  }

  resultArray: string[] = [];
  saveCampaignDraft() {
    if (this.campaignBasicDetailForm.valid) {
      let values = this.validityStatusForm.value;
      debugger
      // let parsedDate = moment(values.to);
      // values.to = parsedDate.format();
      // let parsedFromDate = moment(values.from);
      // values.from = parsedFromDate.format();

      let fromDate = moment(values.from).hours(0).minutes(0).seconds(1).utc();
      let toDate = moment(values.to).hours(23).minutes(59).seconds(59).utc();

      values.from = fromDate.format();
      values.to = toDate.format();





      values.status = "D";
      this.campaignService.setValidityStatus(values);
      this.saveData1(this.campaignBasicDetailForm.value)
      const dialogmsg = this.btnMsg !== "" ? this.btnMsg : "Are you sure you want to Save?"
      this.confirmationDialogService
        .confirm("Confirmation ", dialogmsg) //This Deal will go for approval once support team approves it will go 'LIVE'
        .then((confirmed) => {
          if (confirmed) {
            let payload: any;
            payload = this.campaignService.editCampaignPayloadReturn();

            // this.resultArray = this.tagKeywords.filter((item:any) => !this.campaignBasicDetailForm.value.tag.includes(item));
            // this.tagKeywords.push(...this.resultArray);
            // payload.category_id = this.f['category'].value;
            payload.categories = this.selectedCategories//[this.f['category'].value];
            payload.product_description = this.f['description'].value;
            if (this.images3Path !== null && this.images3Path !== undefined) {
              payload.product_image = this.images3Path;
            }
            payload.product_url = this.f['url'].value;
            payload.coupon_code = this.f['offerCode'].value;
            payload.product_name = this.f['title'].value;
            payload.product_id = this.campaignBasicDetailForm.value.id;
            payload.discountPercentage = this.f['discountPercentage'].value;
            payload.discountType = this.f['discountType'].value;
            payload.discountDescription = this.f['discountDescription'].value;

            payload.keywords = this.tagsKeywords//this.tagKeywords;

            payload.merchant_id = this.userData['marketplace_id'];

            payload.location = this.f['location'].value !== undefined ? this.f['location'].value : null;
            payload.cities = this.t['city'] !== undefined ? this.t['city'].value : 'All';
            // debugger
            // let stateValue = this.t['state'] !== undefined ? this.t['state'].value : '';
            // let setStateId = this.stateData.filter((state: any) => state.state_code === stateValue);
            // let stateID = setStateId[0].state_id.toString();
            payload.state = this.t['state'] !== undefined ? this.t['state'].value : '';
            payload.country = this.countryid;

            if (this.t['gender'] !== null && this.t['gender'] !== undefined) {
              if (this.selectedAgeRanges.length > 0) {
                const lowerAge = Math.min(...this.selectedAgeRanges.map(range => range.minAge));
                const upperAge = Math.max(...this.selectedAgeRanges.map(range => range.maxAge));
                this.targetForm.get('lowerAge')?.setValue(lowerAge);
                this.targetForm.get('upperAge')?.setValue(upperAge);
              }

              payload.gender = this.t['gender'].value;
              payload.lower_age = this.t['lowerAge'].value;
              payload.upper_age = this.t['upperAge'].value;
            } else {

              payload.gender = null;
              payload.lower_age = null;
              payload.upper_age = null;
            }
      

            // const curDate = new Date();

            // const presentDateCompare = moment.utc({
            //   year: curDate.getFullYear(),
            //   month: curDate.getMonth(), // Note: months are 0-indexed
            //   day: curDate.getDate(),
            //   hour: 0,
            //   minute: 0,
            //   second: 1
            // }).format('YYYY-MM-DDTHH:mm:ss[Z]');

            // const fromDateCompare = moment(this.validityStatusForm.value.from, "YYYY-MM-DD hh:mm:ss").local();
            const fromDateCompare = new Date(this.validityStatusForm.value.from)
            let fromCompare = moment(fromDateCompare, "YYYY-MM-DD hh:mm:ss").local();
            let FromValue = fromCompare.format("YYYY-MM-DD");

            payload.budget = this.b['budget'].value != undefined ? this.b['budget'].value : null;
            if(!this.editResponse){
              payload.total_spent = this.editResponse? this.editData.total_spent :null;
            }
            if(this.createFlash){
              payload.total_spent = !this.createFlash? this.editData.total_spent :null;
            }
          
            if (FromValue > this.presentDate && this.isGoLive) {
              payload.status = "P" //U
              payload.paymentDone = true;
            } else if (FromValue === this.presentDate && this.isGoLive) {
              payload.status = "P" //A
              payload.paymentDone = true;
            } else if (this.v['status'].value === 'R') {
              payload.status = "P"
            } else {
              payload.status = this.v['status'].value != undefined ? this.v['status'].value : "D";
            }

            if (this.isFlash) {
              payload.available_from = this.combinedFromDateTime !== 'Invalid date' ? this.combinedFromDateTime : null;
              payload.available_to = this.combinedToDateTime !== 'Invalid date' ? this.combinedToDateTime : null;
              payload.flash = this.isFlash;
              payload.noOfHours = this.v['selectedHour'].value
              if(this.editResponse){
              payload.timezone = this.editData.timezone
              }

              if (this.combinedFromDateTime > this.presentDateTime && this.isGoLive) {
                payload.status = "P" //U
              } else if (this.v['status'].value === 'R') {
                payload.status = "P"
              }
              else {
                payload.status = this.v['status'].value != undefined ? this.v['status'].value : "D";
              }
            }
            else {
              payload.flash = this.isFlash;
              payload.available_from = this.validityStatusForm.value.from !== 'Invalid date' || undefined ? this.validityStatusForm.value.from : null;
              payload.available_to = this.validityStatusForm.value.to !== 'Invalid date' ? this.validityStatusForm.value.to : null;
            }


            if (this.campaignBasicDetailForm.value.id !== null || this.f['id'].value !== null || undefined) {
              this.campaignService.updateCampaign(payload).subscribe((res: any) => {
                if (res.body?.status === 'Error') {
                  this.notifyService.showError(
                    res.body?.message,
                    res.body?.status
                  );
                } else {
                  this.notifyService.showSuccess(
                    "Saved Successfully",
                    res.body?.status
                  );
                  // this.campaignBasicDetailForm.value.id = res.body?.data.product_id !== undefined ? res.body?.data.product_id : this.f['id'].value
                  // if (this.activeStep !== 4) {
                  //   ++this.activeStep
                  //   this.cdr.markForCheck();
                  // }
                  // else {
                  this.router.navigate(["/dashboard"]);
                  // }

                }
              })
            }
            else {
              this.campaignService.addCampaign(payload).subscribe((res: any) => {
                if (res.body?.status === 'Error') {
                  this.notifyService.showError(
                    res.body?.message,
                    res.body?.status
                  );
                } else {
                  this.notifyService.showSuccess(
                    "New Deal Added Successfully",
                    res.body?.status
                  );
                  // if (this.activeStep !== 4) {
                  //   ++this.activeStep
                  //   this.cdr.markForCheck();
                  // }
                  // else {
                  this.router.navigate(["/dashboard"]);
                  // }
                  this.isSaveDraft = true;

                }
              });
            }
          }
          else {
            this.btnMsg = ""
          }
        });
    }
    else {
      this.notifyService.showError("Please provide mandatory details", "Invalid Form");
      return;
    }

  }




  private isArrayInObjectFormat(arr: any[]): boolean {
    // Check if every element in the array is an object with a 'tag' property
    return arr.every(item => typeof item === 'object' && item !== null && 'tag' in item);
  }

  onCardClick(step: number) {
    if (this.campaignBasicDetailForm.valid && this.isFileUploaded && this.BudgetDetailForm.valid) {
      this.activeStep = step;
    }
    else {
      this.notifyService.showError("Please provide mandatory details", "Invalid Form");
      return;
    }
  }

  onPrevClick(step: number) {
    if (this.campaignBasicDetailForm.valid) {
      this.activeStep = step;
    }
    else {
      this.notifyService.showError("Please provide mandatory details", "Invalid Form");
      return;
    }
  }



  getFormattedTitle() {
    return this.campaignBasicDetailForm.get('title')?.value || this.placeHolders['LBL_DEAL_TITLE'];
  }
  
  reset() {

    if (this.activeStep === 3) {
      this.BudgetDetailForm.controls["budget"].reset();
    }
    if (this.activeStep === 2) {
      this.targetForm.reset()
      this.targetForm.get('select')?.setValue('EC');
      this.selectClick();

    }

  }

  cancel() {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to cancel this operation? ")
      .then((confirmed) => {
        if (confirmed) {
          this.router.navigate(["/dashboard"]);
        }
      });
  }


  countrySelectionForState() {
    // this.administartiveForm.controls["state"].reset();
    // this.administartiveForm.controls["city"].reset();
    // this.registerForm.controls["zipCode"].reset();
    const selectedCountryCode = this.countryid//this.targetForm.get('country')?.value;
    this.stateData = [];
    this.cityData = [];
    this.service.getState(selectedCountryCode, this.country, this.macAddress).subscribe((res: any) => {
      this.stateData = res.body?.data;

      let stateId = parseInt(this.editData?.state);
      let setStateId = this.stateData.filter((state: any) => state.state_id === stateId);
      let stateid = this.editResponse && !Number.isNaN(stateId) ? setStateId[0].state_id : this.stateData[0]?.state_id;

      this.stateSelectionForCity(stateid);
      this.cityData.push({
        "zip_id": 0,
        "city_name": "All",
        "state_code": "All",
        "zip_code": 0,
        "area_code": 0,
        "country_fips": 0,
        "country_name": "",
        "time_zone": null,
        "dst": null,
        "market_area": 0,
        "zip_code_type": null,
        "country_iso_code": "",
        "country_id": 0
      })
    });
  }

  stateSelectionForCity(event: any) {
    // this.targetForm.controls["city"].reset();
    // this.registerForm.controls["zipCode"].reset();
    this.cityData = [];
    // let stateId = parseInt(this.editData.state);
    const selectedStateCode = event.target !== undefined ? parseInt(event.target.value) : event;
    let setStateId = this.stateData.filter((state: any) => state.state_id === selectedStateCode);
    let statecode = setStateId[0].state_code

    //this.targetForm.get('state')?.value;
    const selectedCountryCode = this.countryid//this.targetForm.get('country')?.value;
    this.service.getCity(selectedCountryCode, statecode, this.country, this.macAddress).subscribe((res: any) => {
      this.cityData = [{
        "zip_id": null,
        "city_name": "All",
        "state_code": "All",
        "zip_code": 0,
        "area_code": 0,
        "country_fips": 0,
        "country_name": "",
        "time_zone": null,
        "dst": null,
        "market_area": 0,
        "zip_code_type": null,
        "country_iso_code": "",
        "country_id": 0
      }, ...res.body?.data]//res.body?.data;
      // this.cityData.push({
      //   "zip_id": null,
      //   "city_name": "All",
      //   "state_code": "All",
      //   "zip_code": 0,
      //   "area_code": 0,
      //   "country_fips": 0,
      //   "country_name": "",
      //   "time_zone": null,
      //   "dst": null,
      //   "market_area": 0,
      //   "zip_code_type": null,
      //   "country_iso_code": "",
      //   "country_id": 0
      // })
    });
  }

  selectLiveAction(selectedStatus: string) {
    if (selectedStatus === 'A') {
      // this.confirmationDialogService
      //   .confirm("Confirmation ", "Once status changed to Live cannot be changed")
      //   .then((confirmed) => {
      //     if (confirmed) {
      this.saveAction(selectedStatus)
      //   }
      //   else {
      //     this.validityStatusForm.get("status")?.patchValue(this.editData.status);
      //   }
      // });
    }
    else if (selectedStatus === 'S') {
      // this.validityStatusForm.get('status')?.value !== 'P'
      // this.confirmationDialogService
      //   .confirm("Confirmation ", "Are you sure you want to pause the deal")
      //   .then((confirmed) => {
      //     if (confirmed) {
      this.saveAction(selectedStatus)
      //   }
      //   else {

      //     this.validityStatusForm.get("status")?.patchValue(this.editData.status);
      //   }
      // });
    }
  }


  saveAction(selectedStatus: string) {
    if (this.editData) {
      this.createCampaign = false;
      this.isValidStatus = selectedStatus === 'A' ? true : false;
      let res = this.editData;
      let parsedDate = moment(res.available_from, "YYYY-MM-DD");
      res.available_from = parsedDate.format("YYYY-MM-DD");

      let parseDate = moment(res.available_to, "YYYY-MM-DD");
      res.available_to = parseDate.format("YYYY-MM-DD");

      this.validityStatusForm.get("status")?.patchValue(selectedStatus);

      // this.validityStatusForm = this.fb.group({
      //   // to: [res.available_to,[Validators.required, this.dateRangeValidator.bind(this)]],
      //   // from: [res.available_from],
      //   status: [selectedStatus]
      // });

    } else {
      this.isValidStatus = true;
      this.validityStatusForm.get("status")?.patchValue(selectedStatus);
    }
  }


  showSection1 = true; // Initial state
  showSection2 = false;

  toggleSections() {
    this.showSection1 = !this.showSection1;
    this.showSection2 = !this.showSection2;
  }


  combinedFromDateTime: any
  combinedToDateTime: any
  datesave() {
    this.presentDate
    this.validityStatusForm.value.from
    this.validityStatusForm.value.to

    if (this.presentDate && this.validityStatusForm.value.from && this.v['to'].value) {
      const dateParts = this.presentDate.split('-'); // Split the date into year, month, and day
      const fromtimeParts = this.v['from'].value.split(':');
      const totimeParts = this.v['to'].value.split(':'); // Split the time into hours and minutes

      if (this.validityStatusForm.value.from) {
        this.combinedFromDateTime = new Date(Date.UTC(
          +dateParts[0], // Year
          +dateParts[1] - 1, // Month (JavaScript months are 0-indexed)
          +dateParts[2], // Day
          +fromtimeParts[0], // Hours
          +fromtimeParts[1] // Minutes
        ));
        const dateTimeString = this.combinedFromDateTime.toISOString().slice(0, 19); // "2024-04-15 10:30:00"
        this.combinedFromDateTime = new Date(dateTimeString);
      }
      // Create a new Date object using the parts and combine date and time

      if (this.v['to'].value) {
        this.combinedToDateTime = new Date(Date.UTC(
          +dateParts[0], // Year
          +dateParts[1] - 1, // Month (JavaScript months are 0-indexed)
          +dateParts[2], // Day
          +totimeParts[0], // Hours
          +totimeParts[1] // Minutes
        ));
        const dateTimeString = this.combinedToDateTime.toISOString().slice(0, 19);
        this.combinedToDateTime = dateTimeString;

      }
      this.combinedToDateTime = this.incrementDateTime(this.combinedFromDateTime, parseInt(this.v['selectedHour'].value));
      this.combinedToDateTime = new Date(this.combinedToDateTime);
      this.isFlash = true;
      this.btnMsg = 'Are you sure you want to Save?'
      this.saveFlashDeal()
    } else {
      this.combinedFromDateTime = null; // Reset combinedDateTime if either date or time is missing
    }

  }


  saveFlashDeal() {
    if (this.campaignBasicDetailForm.valid) {

      // if (this.campaignBasicDetailForm.value.tag.length !== 0) {
      //   let newTags: any;
      //   const existingTags = this.tagKeywords.map((tag: any) => tag); // Convert existing tags to lowercase for comparison
      //   const isArray1InObjectFormat = this.isArrayInObjectFormat(this.campaignBasicDetailForm.value.tag);
      //   if (isArray1InObjectFormat) {
      //     newTags = this.campaignBasicDetailForm.value.tag.map((item: any) => item.tag); // Convert new tags to lowercase for comparison
      //   }
      //   else {
      //     newTags = this.campaignBasicDetailForm.value.tag.map((tag: any) => ({ tag }));
      //   }

      //   newTags.forEach((tag: any) => {
      //     if (!existingTags.includes(tag)) {
      //       this.tagKeywords.push(tag);
      //     }
      //   });

      //   this.tagKeywords = isArray1InObjectFormat ? this.tagKeywords.filter((tag: any) => newTags.includes(tag)) : this.tagKeywords.map((obj: any) => obj.tag);
      //   // const tagKeywords = this.tagKeywords.map((obj:any) => obj.tag);
      //   // this.tagKeywords = this.tagKeywords.map((tag:any) => tag.charAt(0).toUpperCase() + tag.slice(1));
      // }
      const dialogmsg = this.btnMsg !== "" ? this.btnMsg : "Are you sure you want to Save?"
      this.confirmationDialogService
        .confirm("Confirmation ", dialogmsg)
        .then((confirmed) => {
          if (confirmed) {
            let payload: any;
            payload = this.campaignService.editCampaignPayloadReturn();


            // payload.category_id = this.f['category'].value;
            payload.categories = this.selectedCategories//[this.f['category'].value]
            payload.product_description = this.f['description'].value;
            if (this.images3Path !== null && this.images3Path !== undefined) {
              payload.product_image = this.images3Path;
            }
            payload.product_url = this.f['url'].value;
            payload.coupon_code = this.f['offerCode'].value;
            payload.product_name = this.f['title'].value;
            payload.product_id = this.campaignBasicDetailForm.value.id;
            payload.discountPercentage = this.f['discountPercentage'].value;
            payload.discountType = this.f['discountType'].value;
            payload.discountDescription = this.f['discountDescription'].value;

            payload.keywords = this.tagsKeywords//this.tagKeywords;

            payload.merchant_id = this.userData['marketplace_id'];

            payload.location = this.f['location'].value !== undefined ? this.f['location'].value : null;
            payload.cities = this.t['city'] !== undefined ? this.t['city'].value : 'All';
            payload.state = this.t['state'] !== undefined ? this.t['state'].value : '';
            payload.country = this.countryid;

            if (this.t['gender'] !== null && this.t['gender'] !== undefined) {

              const lowerAge = Math.min(...this.selectedAgeRanges.map(range => range.minAge));
              const upperAge = Math.max(...this.selectedAgeRanges.map(range => range.maxAge));
              this.targetForm.get('lowerAge')?.setValue(lowerAge);
              this.targetForm.get('upperAge')?.setValue(upperAge);

              payload.gender = this.t['gender'].value;
              payload.lower_age = this.t['lowerAge'].value !== "Infinity" ? this.t['lowerAge'].value : null;
              payload.upper_age = this.t['upperAge'].value !== "Infinity" ? this.t['upperAge'].value : null;
            } else {

              payload.gender = null;
              payload.lower_age = null;
              payload.upper_age = null;
            }

            payload.budget = this.b['budget'].value != undefined ? this.b['budget'].value : null;
            if(!this.editResponse){
              payload.total_spent = this.editResponse? this.editData.total_spent :null;
            }
            if(this.createFlash){
              payload.total_spent = !this.createFlash? this.editData.total_spent :null;
            }
            if(this.editResponse){
              payload.timezone = this.editData.timezone
            }
            const fromDateCompare = new Date(this.validityStatusForm.value.from )
              let fromCompare = moment(fromDateCompare, "YYYY-MM-DD hh:mm:ss").local();
              let FromValue = fromCompare.format("YYYY-MM-DD");
            if (FromValue> this.presentDate && this.isGoLive) {
              payload.status = "P"//U
              payload.paymentDone = true;
            } else if (this.v['status'].value === 'R') {
              payload.status = "P"
            }
            else {
              payload.status = this.v['status'].value != undefined ? this.v['status'].value : "D";
            }
            if (this.isFlash) {
              payload.available_from = this.combinedFromDateTime !== 'Invalid date' ? this.combinedFromDateTime : null;
              payload.available_to = this.combinedToDateTime !== 'Invalid date' ? this.combinedToDateTime : null;
              payload.flash = this.isFlash;
              payload.noOfHours = this.v['selectedHour'].value

              const fromDateCompare = new Date(this.combinedFromDateTime)
              let fromCompare = moment(fromDateCompare, "YYYY-MM-DD hh:mm:ss").local();
              let FromValue = fromCompare.format("YYYY-MM-DD hh:mm:ss");

              const dateCompare = this.compareDates(FromValue, this.presentDateTime);

              if (dateCompare === 1) {
                payload.status = "P"//U
                payload.paymentDone = true;
              } else {
                payload.status = "P";
                payload.paymentDone = true;//this.v['status'].value != undefined ? this.v['status'].value : "D";
              }
            }
            else {
              payload.available_from = this.validityStatusForm.value.from !== 'Invalid date' || undefined ? this.validityStatusForm.value.from : null;
              payload.available_to = this.validityStatusForm.value.to !== 'Invalid date' || undefined ? this.validityStatusForm.value.to : null;
            }

            if ((this.campaignBasicDetailForm.value.id !== null || this.f['id'].value !== null || undefined) && (this.editData.flash)) {
              this.campaignService.updateCampaign(payload).subscribe((res: any) => {
                if (res.body?.status === 'Error') {
                  this.notifyService.showError(
                    res.body?.message,
                    res.body?.status
                  );
                } else {
                  this.notifyService.showSuccess(
                    "Saved Successfully",
                    res.body?.status
                  );
                  // this.campaignBasicDetailForm.value.id = res.body?.data.product_id !== undefined ? res.body?.data.product_id : this.f['id'].value
                  // if (this.activeStep !== 4) {
                  //   ++this.activeStep
                  //   this.cdr.markForCheck();
                  // }
                  // else {
                  this.router.navigate(["/dashboard"]);
                  // }

                }
              })
            }
            else {
              this.campaignService.addCampaign(payload).subscribe((res: any) => {
                if (res.body?.status === 'Error') {
                  this.notifyService.showError(
                    res.body?.message,
                    res.body?.status
                  );
                } else {
                  this.notifyService.showSuccess(
                    "New Flash Deal Added Successfully",
                    res.body?.status
                  );
                  // if (this.activeStep !== 4) {
                  //   ++this.activeStep
                  //   this.cdr.markForCheck();
                  // }
                  // else {
                  this.router.navigate(["/dashboard"]);
                  // }
                  this.isSaveDraft = true;

                }
              });
            }
          }
        });
    }
    else {
      this.notifyService.showError("Please provide mandatory details", "Invalid Form");
      return;
    }

  }

  SetendTime() {
    this.validityStatusForm.value.to = this.incrementTime(this.validityStatusForm.value.from);
    this.validityStatusForm.get('to')?.setValue(this.validityStatusForm.value.to);
    this.v['to'].disable();
  }

  incrementTime(originalTime: string): string {
    const [hoursStr, minutesStr] = originalTime.split(':');
    let hours = parseInt(hoursStr, 10);
    let minutes = parseInt(minutesStr, 10);

    // Add 4 hours
    hours += parseInt(this.v["selectedHour"].value);

    // Handle hour overflow
    if (hours >= 24) {
      hours -= 24;
    }

    // Format hours and minutes back into "HH:mm"
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  }

  incrementDateTime(originalDateTime: string, incrementHours: number): string {

    // Parse the original date and time
    const date = new Date(originalDateTime);

    // Add the increment hours to the current time
    date.setHours(date.getHours() + incrementHours);

    // Format the new date as "yyyy-MM-dd"
    const newDate = date.toLocaleDateString().split('T')[0];
    const formatedDate = this.convertDateToISOFormat(newDate)
    // Format the new time as "HH:mm"
    const newTime = date.toTimeString().slice(0, 5);
    const combinedDateTime = `${formatedDate}T${newTime}`
    return combinedDateTime;
  }

  convertDateToISOFormat(dateStr: string): string {
    // Split the date string by '/'
    const [month, day, year] = dateStr.split('/');

    // Format the date to 'YYYY-MM-DD'
    const isoFormattedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;

    return isoFormattedDate;
  }


  compareDates(fromDateStr: string, toDateStr: string): number {
    const fromDate = new Date(fromDateStr);
    const toDate = new Date(toDateStr);

    // Compare the dates using getTime() method of Date
    if (fromDate.getTime() > toDate.getTime()) {
      return 1; // From date is greater than to date
    } else if (fromDate.getTime() < toDate.getTime()) {
      return -1; // From date is less than to date
    } else {
      return 0; // Dates are equal
    }
  }

  detectCategoryChange() {
    let res = this.editData
    let selectedCategories = this.allCategory.filter((value: any) => value.category_id !== 0);
    if (res.categories.length < selectedCategories.length && selectedCategories.length > 1) {
      this.categorySelect(0);
      if (this.v['status'].value === "D") {
        // this.isCategoriesChanged = true;
        this.isFormValid = true;
      }

    } else if (res.categories.length === selectedCategories.length && selectedCategories.length > 1) {
      this.categorySelect(0);
    }
    else {
      this.selectedCategory = res.categories[0]
    }
  }

  selectedCategories: number[] = []
  categorySelect(event: any) {
    if (event.value === 0 || event === 0) {
      let selectedCategories = this.allCategory.map((category: any) => category.category_id);
      this.selectedCategories = selectedCategories.filter((value: any) => value !== 0);
      this.selectedCategories.forEach(id => this.processCategories(id));
    } else {
      this.selectedCategories = event.value !== undefined ? [event.value] : [event];
      this.processCategories(this.selectedCategories[0])
    }

  }

  processCategories(categoryId: number) {
    this.allCategory.forEach((category: any) => {
        if (category.category_id === categoryId) {
            const categoryType = category.category_type;
            // Check if the tagsKeywords array already contains the categoryType
            if (!this.tagsKeywords.includes(categoryType)) {
                // If it doesn't exist, push the categoryType
                this.tagsKeywords.push(categoryType);
            }
        }
    });
}

  detectCategories() {
    let res = this.editData
    let selectedCategories = this.allCategory.filter((value: any) => value.category_id !== 0);
    if (res.categories.length > 1) {
      this.categorySelect(0);
      if (this.v['status'].value === "D") {
        // this.isCategoriesChanged = true;
        this.isFormValid = true;
        if (this.validityStatusForm.value.from !== 'Invalid date') {
          this.isValidityFormValid = this.validityStatusForm.valid;
        }
      }

    }
    else {
      this.selectedCategory = res.categories[0]
      this.categorySelect(this.selectedCategory);
      if (this.v['status'].value === "D") {
        // this.isCategoriesChanged = true;
        this.isFormValid = true;
        if (this.validityStatusForm.value.from !== 'Invalid date') {
          this.isValidityFormValid = this.validityStatusForm.valid;
        }
      }
    }
  }

  openImagePreview() {
    this.dialog.open(PreviewdealwidgetComponent, {
      // data: { imageSrc: this.imageSrc },
      position: { top: '10px', right: '10px' },
      maxWidth: '60vw',
      maxHeight: '100vh'
    });
  }

  atLeastOneRequiredValidator(
    controlName1: string,
    controlName2: string
  ): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const control1 = formGroup.get(controlName1);
      const control2 = formGroup.get(controlName2);
  
      if (!control1 || !control2) {
        return null; // Return null if controls are not found (shouldn't happen)
      }
  
      const isValid = control1.value || control2.value;
  
      if (!isValid) {
        control1.setErrors({ required: true });
        control2.setErrors({ required: true });
        return { atLeastOneRequired: true };
      } else {
        control1.setErrors(null);
        control2.setErrors(null);
      }
  
      return null;
    };
  }

}
