/// <reference types="@angular/localize" />
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import {botswanaConfig} from './config/botswana-config'
import {defaultConfig} from './config/default-config'


if (environment.production) {
  enableProdMode();

}

const hostname = window.location.hostname;
let countryConfig;

if (hostname.includes('zimpost')) {
  // countryConfig = zimpostConfig;
} else if (hostname.includes('botswana')) {
  countryConfig = botswanaConfig;
} else {
  countryConfig = defaultConfig;
}

platformBrowserDynamic([{ provide: 'COUNTRY_CONFIG', useValue: countryConfig }])
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
