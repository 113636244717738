<div class="modal-header" style="zoom:80%">
    <i class= "fa fa-exclamation-circle"></i>
    <h4 class="modal-title px-2">{{ title }}</h4>
    <!-- <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button> -->
</div>
<div class="modal-body">
    <div *ngIf="data">
        <h5>{{placeHolders['LBL_PLEASE_CONFIRM_DEATILS']}}</h5>
        <p class="m-0"><b>{{placeHolders['LBL_REG_BUSINESS_NAME']}}: </b>{{ data.marketplace_name }}</p>
        <p class="m-0"><b>{{placeHolders['LBL_PRIMARY_CONTACT']}}:</b>{{ data.firstName }} {{data.lastName}}</p>
        <p class="m-0"><b>{{placeHolders['LBL_REG_PRIMARY_EMAIL']}}:</b> {{ data.email_id }}</p>
        <p class="m-0"><b>{{placeHolders['LBL_REG_MOBILE_NUMBER']}}:</b> {{ data.mobile }}</p>
    </div>
    <p [innerHTML]="message"></p>
    <!-- {{ message }} -->
</div>
<div class="modal-footer">
    <button type="button" *ngIf="btnCancelText" style="color: white;" class="btn btn-danger" (click)="decline()">{{ btnCancelText }}</button>
    <button type="button" class="btn btn-primary" (click)="accept()">{{ btnOkText }}</button>
</div>