<div>
    <h5>Quick Links</h5>
</div>
<div>
    <mat-form-field appearance="outline">
        <mat-label>Search</mat-label>
        <input matInput [(ngModel)]="searchQuery" placeholder="Search questions" (ngModelChange)="filterQuestions()">
    </mat-form-field>
</div>

<mat-accordion>
    <div *ngFor="let faq of filteredFaqs" style="padding-bottom: 10px;">
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title style="color: rgb(7, 78, 15);">
                    {{ faq.category }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngFor="let question of faq.questions" style="padding-bottom: 5px;">
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title style="color: rgb(2, 86, 103);">
                            {{ question.question }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <p>{{ question.answer }}</p>
                </mat-expansion-panel>
            </div>
        </mat-expansion-panel>
    </div>
</mat-accordion>