import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { CommonUtilService } from 'src/app/services/common-util.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';
import Validation from 'src/app/utils/validation';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';

@Component({
  selector: 'app-administatordetails',
  templateUrl: './administatordetails.component.html',
  styleUrls: [
    './administatordetails.component.scss',
    '../../../../assets/bootstrap.min.css'
  ],
})
export class AdministatordetailsComponent {
  verifiedPhone = false;
  verifiedEmail = false;
  showCreatePass = false;
  showConfirmPass = false;
  disablePhone = false;
  disableEmail = false;
  countryData: any;
  country: any;
  macAddress: any;
  emailOtpField = false;
  phoneOtpField = false;
  verifyEmailField = true;
  verifyPhoneField = true;
  emailVerified = false;
  phoneVerified = true;
  resendOtpClicked = 1;
  resendOtpClickedPhone = 1;
  resendEmailOtp: boolean = false;
  resendMobileOtp: boolean = false;
  displayEmailTimer: boolean = false;
  displayMobileTimer: boolean = false;
  displayEmail: any;
  displayMobile: any;
  phoneNumberCode: any;
  // disable = false;
  administartiveForm: FormGroup = new FormGroup({
    primaryContact: new FormControl(''),
    country: new FormControl(''),
    email: new FormControl(''),
    phone: new FormControl(''),
    password: new FormControl(''),
    confirmPass: new FormControl(''),
  });

  constructor(private router: Router, private fb: FormBuilder, private notifyService: NotificationAlertService,public dialog: MatDialog,
    private confirmationDialogService: ConfirmationDialogService, private service: CommonUtilService, private cStorage: ClientSideStorageService,) {
    this.country = JSON.parse(this.cStorage.get("country"));
    this.macAddress = JSON.parse(this.cStorage.get("macAddress"));
    this.service.getCountry(this.country, this.macAddress).subscribe((res: any) => {
      this.countryData = res.body?.data;
    });
    this.createForm();
    
    this.administartiveForm.get('password')?.valueChanges.subscribe(value => {
      this.service.setPasswordRulesDataData(value);
    });
  }



  get f(): { [key: string]: AbstractControl } {
    return this.administartiveForm.controls;
  }

  createForm() {
    this.administartiveForm = this.fb.group({
      primaryContact: ['', [Validators.required,
      Validators.minLength(2),
      Validators.maxLength(30)]],
      country: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      emailotp: ['', [Validators.required]],
      phone: ['', Validators.required],
      phoneotp: [''],
      accept: ['', Validators.required],
      password: ['', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(30)
      ]],
      confirmPass: ['', Validators.required]
    },
      {
        validators: [Validation.match('password', 'confirmPass')]
      }
    );
  }

  sendEmailOtp() {
    let status: any = this.administartiveForm.controls['email'].status;
    if (status !== 'INVALID') {
      let payload = {
        email: this.administartiveForm.value.email
      }
      this.service.sendEmailOtp(payload, this.country, this.macAddress).subscribe((res: any) => {
        let value = res;
        if (res.body?.status === 'ERROR') {
          this.notifyService.showError(
            res.body?.message,
            res.body?.status
          );
        } else {
          this.emailOtpField = true;
          this.verifyEmailField = false;
          this.startEmailTimer(2);
        }
      });
    } else {
      this.notifyService.showError(
        "Invalid Email!!",
        "Error"
      );
    }
  }

  resendEmailOTP() {
    let clickedCOunter = this.resendOtpClicked++;
    if (clickedCOunter <= 3) {
      this.sendEmailOtp();
    } else {
      this.notifyService.showError("OTP resend limit exceeded !", "Error")
    }
  }

  startEmailTimer(minute: any) {
    this.displayEmailTimer = true;
    this.resendEmailOtp = false;
    // let minute = 1;
    let seconds = minute * 60;
    let textSec: any = '0';
    let statSec = 60;
    const prefix = minute < 10 ? '0' : '';
    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      // if (statSec < 10) textSec = "0" + statSec;
      // textSec = statSec;

      if (statSec < 10) {
        // console.log('inside', statSec);
        textSec = '0' + statSec;
      } else {
        // console.log('else', statSec);
        textSec = statSec;
      }

      // this.display = prefix + Math.floor(seconds / 60) + ":" + textSec;
      this.displayEmail = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        // console.log('finished');
        clearInterval(timer);
        this.resendEmailOtp = true;
        this.displayEmailTimer = false;
      }
    }, 1000);
  }

  validateOtpEmail() {
    let status: any = this.administartiveForm.controls['emailotp'].status;
    if (status !== 'INVALID') {
      let payload = {
        "email": this.administartiveForm.value.email,
        "type": "email",
        "validationCode": this.administartiveForm.value.emailotp
      }
      this.service.ValidateOtp(payload, this.country, this.macAddress).subscribe((res: any) => {
        if (res.body?.status === 'Error') {
          this.notifyService.showError(
            res.body?.message,
            res.body?.status
          );
        } else {
          this.verifiedEmail = true;
          this.notifyService.showSuccess(
            "Email Verified !!",
            "Success"
          );
          this.verifyEmailField = true;
          this.disableEmail = true;
          this.emailVerified = true;
        }
      });
    } else {
      this.notifyService.showError(
        "Enter OTP !!",
        "Error"
      );
    }

    //   if(this.administartiveForm.invalid) {
    //     return;
    //   } else{
    //     this.showPhone =true;
    //     this.disable = true;
    //   }
  }


  onOtpEmailChange(event: any) {
    let res = event;
    this.administartiveForm.value.emailotp = res;
  }

  sendMobileOtp() {
    let status: any = this.administartiveForm.controls['phone'].status;
    if (status !== 'INVALID') {
      let countryCode = this.phoneNumberCode;
      let payload = {

        mobileNumber: countryCode.toString() + this.administartiveForm.value?.phone.toString()
      }
      this.service.sendMobileOtp(payload, this.country, this.macAddress).subscribe((res: any) => {
        let value = res;
        if (res && res?.body?.status === "ERROR") {
          this.notifyService.showError(
            res?.body?.message,
            res?.body?.status
          );
        } else {
          this.phoneOtpField = true;
          this.verifyPhoneField = false;
          this.startMobileTimer(2);
        }
      });
    } else {
      this.notifyService.showError(
        "Invalid Phone Number !!",
        "Error"
      );
    }
  }

  resendMobileOTP() {
    let clickedCOunter = this.resendOtpClickedPhone++;
    if (clickedCOunter <= 3) {
      this.sendMobileOtp();
    } else {
      this.notifyService.showError("OTP resend limit exceeded !", "Error")
    }
  }

  startMobileTimer(minute: any) {
    this.displayMobileTimer = true;
    this.resendMobileOtp = false;
    // let minute = 1;
    let seconds = minute * 60;
    let textSec: any = '0';
    let statSec = 60;
    const prefix = minute < 10 ? '0' : '';
    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      // if (statSec < 10) textSec = "0" + statSec;
      // textSec = statSec;

      if (statSec < 10) {
        // console.log('inside', statSec);
        textSec = '0' + statSec;
      } else {
        // console.log('else', statSec);
        textSec = statSec;
      }

      // this.display = prefix + Math.floor(seconds / 60) + ":" + textSec;
      this.displayMobile = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        // console.log('finished');
        clearInterval(timer);
        this.resendMobileOtp = true;
        this.displayMobileTimer = false;
      }
    }, 1000);
  }

  onOtpPhoneChange(event: any) {
    let res = event;
    this.administartiveForm.value.phoneotp = res;
  }

  validateOtpPhone() {
    let status: any = this.administartiveForm.controls['phoneotp'].status;
    if (status !== 'INVALID') {

      let mobileNumber = this.phoneNumberCode.toString() + this.administartiveForm.value?.phone.toString()
      let payload = {
        "phoneNumber": mobileNumber,
        "type": "mobile",
        "validationCode": this.administartiveForm.value.phoneotp
      }
      this.service.ValidateOtp(payload, this.country, this.macAddress).subscribe((res: any) => {
        if (res.body?.status === 'Error') {
          this.notifyService.showError(
            res.body?.message,
            res.body?.status
          );
        } else {
          this.verifiedPhone = true;
          this.notifyService.showSuccess(
            "Phone Number Verified !!",
            "Success"
          );
          this.verifyPhoneField = true;
          this.disablePhone = true;
          this.phoneVerified = true;
        }
      });
    } else {
      this.notifyService.showError(
        "Enter OTP !!",
        "Error"
      );
    }
  }

  togglePasswordVisibility() {
    this.showCreatePass = !this.showCreatePass;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPass = !this.showConfirmPass;
  }

  reset() {
    this.administartiveForm.reset();
    this.verifiedEmail = false
    this.verifiedPhone = false
    this.emailVerified = false
    this.phoneVerified = false
    this.showCreatePass = false;
    this.showConfirmPass = false;
    this.disablePhone = false;
    this.disableEmail = false;
    this.emailOtpField = false;
    this.phoneOtpField = false;
    this.verifyEmailField = true;
    this.verifyPhoneField = true;
    this.emailVerified = false;
    this.phoneVerified = false;
    this.resendEmailOtp = false;
    this.resendMobileOtp = false;
    this.displayEmailTimer = false;
    this.displayMobileTimer = false;
  }


  save() {
    if (this.administartiveForm.status === "VALID") {
      const fullName = this.administartiveForm.value.primaryContact.split(' ');
      const lastName = fullName.pop(); // 'Panakkal'
      const firstName = fullName.join(' ')
      let payload = {
        "password": this.administartiveForm.value.password,
        "firstName": firstName,
        "lastName": lastName,
        "email_id": this.administartiveForm.value.email,
        "mobile": this.administartiveForm.value.phone.toString(),
        "roleId": 1
      }
      let adminData = {
        "Name": this.administartiveForm.value.primaryContact,
        "email_id": this.administartiveForm.value.email,
        "mobile": this.phoneNumberCode + this.administartiveForm.value.phone,
      }
      this.confirmationDialogService
        .confirm("Confirmation ", "Are you sure you want to save? ",payload)
        .then((confirmed) => {
          if (confirmed) {
            this.service.adminSignup(payload, this.country, this.macAddress).subscribe((res: any) => {
              if (res.body?.status === 'Error') {
                this.notifyService.showError(
                  res.body?.message,
                  res.body?.status
                );
              } else {
                const data = {
                  emailId: this.administartiveForm.value.email,
                  password: this.administartiveForm.value.password
                }
                this.notifyService.showSuccess(
                  "User Registered Successfully",
                  res.body?.status
                );
                this.service.setAdminData(adminData);
                this.service.setLoginData(data);
                this.cStorage.set('userId', JSON.stringify(res.body?.data.personId));
                this.cStorage.set('userName', JSON.stringify(res.body?.data.email_id));
                this.cStorage.set('roleId', JSON.stringify(res.body?.data.roleId));
                this.router.navigate(["/register"]);

              }

            });
          }
        });
    } else {
      this.notifyService.showError(
        "Please fill all the mandatory details !!",
        "Error"
      );
    }
  }

  mobileCode(event: any) {
    this.phoneNumberCode = event;
    // phone_country_prefix
  }

  termsAndCondition() {
    this.dialog.open(TermsAndConditionsComponent, {
      height: '500px',
      width: '600px',
      position: {
        top: '5vh',
        left: '30vw'
      },
      autoFocus: false,
      backdropClass: 'custom-dialog-overlay',  // mat-dialog css class
      disableClose: true  // If you click outside the mat-dialog box window, it will not close. 
    });
  }
}
