import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CampaignService } from 'src/app/services/campaign.service';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { CommonUtilService } from 'src/app/services/common-util.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';

@Component({
  selector: 'app-tragetaudience',
  templateUrl: './tragetaudience.component.html',
  styleUrl: './tragetaudience.component.scss'
})
export class TragetaudienceComponent {
  editData: any;
  dropdownSettings: IDropdownSettings = {};
  agedropdownSettings: IDropdownSettings = {};
  targetForm: FormGroup = new FormGroup({
    country: new FormControl(''),
    select: new FormControl('')
  });
  gender = [{ id: "male", name: "Male" }, { id: "female", name: "Female" }, { id: "all", name: "All" }];
  
  lowerAgeGroup = [{id:0, lower_age: "0" }, {id:10, lower_age: "10" }, {id:20, lower_age: "20" }, {id:30, lower_age: "30" }, {id:40, lower_age: "40" },
  {id:50, lower_age: "50" }, {id:60, lower_age: "60" }, {id:70, lower_age: "70" }, {id:80, lower_age: "80" }];
  
  upperAgeGroup = [{id:10, upper_age: "10" }, {id:20, upper_age: "20" }, {id:30, upper_age: "30" }, {id:40, upper_age: "40" },
  {id:50, upper_age: "50" }, {id:60, upper_age: "60" }, {id:70, upper_age: "70" }, {id:80, upper_age: "80" }];

  targetAudience = false;
  country: any;
  macAddress: any;
  personID: any;
  countryData: any;
  userData: any;
  editResponse: any;

  constructor(private router: Router, private fb: FormBuilder, private confirmationDialogService: ConfirmationDialogService,
    private campaignService: CampaignService, private service: CommonUtilService, private cStorage: ClientSideStorageService,
    private notifyService: NotificationAlertService) {
    this.targetForm.reset();

    this.personID = JSON.parse(this.cStorage.get("userId"));
    this.country = JSON.parse(this.cStorage.get("country"));
    this.macAddress = JSON.parse(this.cStorage.get("macAddress"));
    this.dropdownSettings = {
      idField: "name",
      textField: "name",
      enableCheckAll: true,
      // selectAllText: "Select All Users From List",
      // unSelectAllText: "Unselect All Users From List",
      noDataAvailablePlaceholderText: "There is no item availabale to show",
      allowSearchFilter: true,

      // defaultOpen: false
    };
    this.agedropdownSettings = {
      idField: "age",
      textField: "age",
      enableCheckAll: true,
      // selectAllText: "Select All Users From List",
      // unSelectAllText: "Unselect All Users From List",
      noDataAvailablePlaceholderText: "There is no item availabale to show",
      allowSearchFilter: true,
      itemsShowLimit: 3
      // defaultOpen: false
    };
    this.editData = this.campaignService.getData();
    this.editData = this.editData.source._value;
    this.editResponse = this.campaignService.returnCreateAndEditRes();

    this.userData = JSON.parse(this.cStorage.get("marketplaceData"));
    if (this.userData ) {
      this.service.getCountry(this.country, this.macAddress).subscribe((res: any) => {
        this.countryData = res.body?.data;
        if (this.countryData) {
          this.countryData.forEach((element: any) => {
            if ((element.country_id).toString() === this.userData.country) {
              this.country = element.country_name;
            }
          });
        }
        if (this.editResponse === false) {
          this.createForm();
        } else {
          this.editForm();
        }
      });
    }
  // });
}

createForm() {
  this.targetForm = this.fb.group({
    country: [this.country],
    select: ['EC'],
    // gender: [''],
    // age: ['']
  });
}

get f(): { [key: string]: AbstractControl } {
  return this.targetForm.controls;
}

editForm() {
  let res = this.editData;
  if (res.gender !== null && res.gender !== undefined) {
    this.targetAudience = true;
    this.targetForm = this.fb.group({
      //lower_age
      country: [this.country],
      select: ['C'],
      gender: [res?.gender,Validators.required],
      lowerAge: [res?.lower_age,Validators.required],
      upperAge: [res?.upper_age,Validators.required]
    });
  } else {
    this.targetForm = this.fb.group({
      country: [this.country],
      select: ['EC'],
      // gender: [res?.gender],
      // lowerAge: [res?.lower_age],
      // upperAge: [res?.upper_age]
    });
  }
}


selectClick() {
  this.targetAudience = false;
  this.targetForm = this.fb.group({
    country: [this.country],
    select: ['EC', Validators.required],
  });
}

customClick() {
  this.targetAudience = true;
  this.targetForm = this.fb.group({
    country: [this.country],
    select: ['C', Validators.required],
    gender: ['', Validators.required],
    lowerAge: ['', Validators.required],
    upperAge: ['', Validators.required]
  });
}

saveAndNext() {
  if (this.targetForm.invalid) {
    this.notifyService.showError("Please fill required details", "Invalid Form");
    return;
  } else {
    this.confirmationDialogService
      .confirm("Confirmation ", "Are you sure you want to Save? ")
      .then((confirmed) => {
        if (confirmed) {
          let values = this.targetForm.value;
          this.saveData(values);
          this.router.navigate(["/campaign/budget"]);
        }
      });
  }
}

saveData(data: any) {
  if (this.editResponse === true) {
    this.campaignService.setEditTargetDetails(data);
  } else {
    this.campaignService.setTargetDetails(data);
    // this.data.push(values);
  }
}

cancel() {
  this.confirmationDialogService
    .confirm("Confirmation ", "Are you sure you want to cancel this operation? ")
    .then((confirmed) => {
      if (confirmed) {
        this.router.navigate(["/dashboard"]);
      }
    });
}

campaignBasicDetailClick() {
  if (this.targetForm.invalid) {
    this.notifyService.showError("Please fill required details", "Invalid Form");
    return;
  } else {
    this.confirmationDialogService
      .navigateConfirm("Confirmation ", "Are you sure you want to save this operation? ")
      .then((confirmed) => {
        if (confirmed) {
          let values = this.targetForm.value;
          this.saveData(values);
          this.router.navigate(["/campaign"]);
        } else {
          this.router.navigate(["/campaign"]);
        }
      });
  }
}

budgetDetailClick() {
  if (this.targetForm.invalid) {
    this.notifyService.showError("Please fill required details", "Invalid Form");
    // return;
  } else {
    this.confirmationDialogService
      .navigateConfirm("Confirmation ", "Are you sure you want to save this operation? ")
      .then((confirmed) => {
        if (confirmed) {
          let values = this.targetForm.value;
          this.saveData(values);
          this.router.navigate(["/campaign/budget"]);
        } else {
          this.router.navigate(["/campaign/budget"]);
        }
      });
  }
}

ValidityClick() {
  if (this.targetForm.invalid) {
    this.notifyService.showError("Please fill required details", "Invalid Form");
    return;
  } else {
    this.confirmationDialogService
      .navigateConfirm("Confirmation ", "Are you sure you want to save this operation? ")
      .then((confirmed) => {
        if (confirmed) {
          let values = this.targetForm.value;
          this.saveData(values);
          this.router.navigate(["/campaign/validatstatus"]);
        } else {
          this.router.navigate(["/campaign/validatstatus"]);
        }
      });
  }
}
  }
