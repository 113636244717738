<!-- <div class="div">
    <div class="div-6">
      <div class="div-7"> -->
<!-- <div class="column-2">
          <div class="div-29"> -->
<div class="div-30">Puma Season Sale</div>
<div class="div-31">
  <nav>
    <button style="border: none;" (click)="campaignBasicDetailClick()" class="button">
      <div class="div-32">
        <div class="div-33" style="text-align: left;">Basic Details</div>
        <div class="div-34">Enter Campaign Details</div>
      </div>
    </button>
  </nav>
  <nav>
    <button style="border: none;" (click)="targetClick()" class="button">
      <div class="div-35">
        <div class="div-36" style="text-align: left;">Target Audience</div>
        <div class="div-37">Select the Target Audience</div>
      </div>
    </button>
  </nav>
  <div class="div-38">
    <div class="div-39">Budget Details</div>
    <div class="div-40">Set Budget</div>
  </div>
  <nav>
    <button class="button" style="border: none;" (click)="ValidityClick()">
      <div class="div-41">
        <div class="div-42">Validity & Status</div>
        <div class="div-43" style="text-align: left;">Enter Validity</div>
      </div>
    </button>
  </nav>

</div>

<form [formGroup]="BudgetDetailForm">
  <div class="div-44">
    <div class="div-45">Budget Details</div>
    <div class="div-46">
      <div class="div-47">
        <div class="div-48">
          <div class="div-49">CPC (Click per Cost)</div>
          <!-- <img loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/3475f441-6a1e-48dd-90ce-9b13c4458b25?"
            class="img-15" /> -->
        </div>
        <div class="div-50">
          <input type="text" style="background-color: transparent;" [readonly]="true" formControlName="cpc" class=" " />
        </div>
      </div>
      <div class="div-51">
        <div class="div-52">
          <div class="div-53">CPF (Click per Favourite)</div>
          <!-- <img loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b1ca74ac-bc6b-49b8-a2dd-8d3d6abfa0c2?"
            class="img-16" /> -->
        </div>
        <div class="div-54">
          <input type="text" style="background-color: transparent;" [readonly]="true" formControlName="cpf" class=" " />
        </div>
      </div>
    </div>
    <!-- <div class="div-55">
                <div class="div-56">
                  <div class="div-57">
                    <div class="div-58">CPM (Click per Message)</div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/e3023760-e3dc-418b-a518-c7683771bfb2?"
                      class="img-17"
                    />
                  </div>
                  <div class="div-59">22</div>
                </div>
                <div class="div-60">
                  <div class="div-61">
                    <div class="div-62">% of success</div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/86518b93-f9df-46ce-aa3f-694c5e1dedea?"
                      class="img-18"
                    />
                  </div>
                  <div class="div-63">22</div>
                </div>
              </div> -->
    <div class="div-64">
      <div class="div-65 asterix">Enter Budget</div>
      <!-- <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/0f14b5be-f7c9-4f93-9315-eecf4cf3e8ce?"
        class="img-19" /> -->
    </div>
    <div class="div-66">
      {{this.clickCost?.currency}}
      <input type="number" style="border:none;" min="0" formControlName="budget"
      [ngClass]="{ 'is-invalid':  f['budget'].errors  && (f['budget'].dirty || f['budget'].touched ) }"
       required />
      @if ( f['budget'].errors) {
      <div class="invalid-feedback">
        @if (f['budget'].errors['required']) {
        <div>Budget is required</div>
        }
      </div>
      }
    </div>
    <div class="div-67">
      <button class="div-68" (click)="cancel()">Cancel</button>
      <div class="div-69">
        <!-- <div class="div-70">Preview</div> -->
        <button class="div-71" (click)="saveAndNext()">Save & Next</button>
      </div>
    </div>
  </div>
</form>
<!-- </div>
        </div> -->
<!-- </div>
    </div>
  </div> -->