import { Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { ClientSideStorageService } from 'src/app/services/client-side-storage.service';
import { CommonUtilService } from 'src/app/services/common-util.service';
import { ConfirmationDialogService } from 'src/app/services/confirmation-dialog.service';
import { DashboardService } from 'src/app/services/dashboard.service';
import { LoginService } from 'src/app/services/login.service';
import { NotificationAlertService } from 'src/app/services/notification-alert.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent {
  allCategory: any;
  editData: any;
  showLink = false;
  submitted = false;
  countryData: any;
  stateData: any;
  cityData: any;
  dropdownSettings: IDropdownSettings = {};
  zipCode: any;
  zipID: any;
  country: any;
  macAddress: any;
  allBusinessTypes: any;
  personID: any;
  fileName: any = null;
  docName: any = null;
  fData = new FormData();
  docData = new FormData();
  documents3Path: any = null;
  images3Path: any = null;
  isImgUploaded = false;
  isFileUploaded = false;
  loginData: any;
  registerForm: FormGroup = new FormGroup({
    companyName: new FormControl(''),
    companyWebsite: new FormControl(''),
    companyLogo: new FormControl(''),
    registrationId: new FormControl(''),
    document: new FormControl(''),
    businessType: new FormControl(''),
    productCategories: new FormControl(''),
    addressLine: new FormControl(''),
    additionalInfo: new FormControl(''),
    country: new FormControl(''),
    state: new FormControl(''),
    city: new FormControl(''),
    zipCode: new FormControl(''),
  });
  categoryType:any =[];
  country_id:any;


  constructor(private fb: FormBuilder, private service: CommonUtilService, private notifyService: NotificationAlertService,
    private router: Router, private confirmationDialogService: ConfirmationDialogService, private loginService: LoginService,
    private dashboardService: DashboardService, private cStorage: ClientSideStorageService) {
    this.loginData = this.service.getLoginData();
    this.loginData = this.loginData.source._value;
    this.createRegisterForm();
    this.dropdownSettings = {
      idField: "category_id",
      textField: "category_type",
      enableCheckAll: true,
      // selectAllText: "Select All Users From List",
      // unSelectAllText: "Unselect All Users From List",
      noDataAvailablePlaceholderText: "There is no item availabale to show",
      allowSearchFilter: true,
      // itemsShowLimit: 3
      // defaultOpen: false
    };
    this.personID = JSON.parse(this.cStorage.get("userId"));
    this.country = JSON.parse(this.cStorage.get("country"));
    this.macAddress = JSON.parse(this.cStorage.get("macAddress"));
    this.service.getBusinessTypes(this.country, this.macAddress).subscribe((res: any) => {
      this.allBusinessTypes = res.body?.data;
    });
    this.editData = this.service.getData();
    this.editData = this.editData.source._value;
    this.dashboardService.getCategory().subscribe(response => {
      this.allCategory = response.body?.data;
      this.submitted = false;
      // if (this.editData !== null && this.editData !== undefined) {
      //   this.editRegisterForm();
      // } else {
      // }
    });
    this.service.getCountry(this.country, this.macAddress).subscribe((res: any) => {
      this.countryData = res.body?.data;
    });
  }

  websiteUrlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const url = control.value;
      if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
        // If URL doesn't start with 'http://' or 'https://', append 'https://'
        control.setValue('https://' + url);
      }
      return null;
    };
  }

  createRegisterForm() {
    this.showLink = false;
    this.registerForm = this.fb.group({
      companyName: ['', [Validators.required, Validators.minLength(2),
      Validators.maxLength(50)]],
      companyWebsite: ['', [Validators.required, this.websiteUrlValidator()]],
      companyLogo: ['', Validators.required],
      registrationId: ['', [Validators.required, Validators.minLength(5),
      Validators.maxLength(20)]],
      digitalId: [''],
      document: [''],
      businessType: ['', Validators.required],
      productCategories: ['', Validators.required],
      addressLine: ['', [Validators.required, Validators.minLength(5),
      Validators.maxLength(250)]],
      additionalInfo: [''],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      // zipCode: ['', Validators.required],
    });
  }

  get f(): { [key: string]: AbstractControl } {
    return this.registerForm.controls;
  }

  reset() {
    this.submitted = false;
    this.isImgUploaded = false;
    this.isFileUploaded = false;
    this.registerForm.reset();
  }

  next() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      this.notifyService.showError(
        "Please fill all the required Fields!!",
        "Error"
      );
    } else {
      this.categoryType = [];
      let res = this.registerForm.value;
      let categories: any = [];
      if (res.productCategories !== "") {
        res.productCategories.forEach((item: any) => {
          categories.push(item.category_id);
          this.categoryType.push(item.category_type)
        });
      }
      let data = {
        "categories": categories,
        "status": "A",
        // "banner": true,
        // "merchant_citrus_key": "",
        "marketplace_name": res.companyName,
        //"marketplace_description": "Company available in multiple categories like electonics, accessories",
        "marketplace_image": this.images3Path,
        "marketplace_link": res.companyWebsite,
        "marketplace_document": this.documents3Path,
        // "marketplace_gift_type": "Gift",
        "marketplace_type": res.businessType,
        "unique_id": res.digitalId,
        "registration_id": res.registrationId,
        // "shipping_cost_type": "flat",
        // "marketplace_from_email": "samsung@marketplace.com",
        // "merchant_vanity_url": "",
        // "reference_sampling": "Y",
        "addressLine1": res.addressLine,
        "addressLine2": res.additionalInfo,
        "country": res.country,
        "state": res.state,
        "city": res.city,
        "categoryType": "",
        "termsAndConditions": true,
        "zipcode": res.zipCode,
        // "zipcode": res.zipCode
      }
      this.confirmationDialogService
        .confirm("Confirmation ", "Are you sure you want to save? ")
        .then((confirmed) => {
          if (confirmed) {
            this.service.registerCompany(this.personID, data, this.country, this.macAddress).subscribe((res: any) => {
              if (res.body?.status === 'Error') {
                this.notifyService.showError(
                  res.body?.message,
                  res.body?.status
                );
              } else {
                data.categoryType = this.categoryType;

                this.notifyService.showSuccess(
                  "Brand Registered Successfully",
                  res.body?.status
                );
                // let data = {
                //   email_id: this.loginData.emailId,
                //   password: this.loginData.password
                // }
                this.service.setRegistrationData(data);
                this.router.navigate(["register/summary"]);          
                
              }
            });
          }
        });
    };
  }

  countrySelectionForState(id: number) {
    this.registerForm.controls["state"].reset();
    this.registerForm.controls["city"].reset();
    // this.registerForm.controls["zipCode"].reset();
    this.stateData = [];
    this.cityData = [];
    this.country_id = id;
    this.service.getState(id, this.country, this.macAddress).subscribe((res: any) => {
      this.stateData = res.data;
    });
  }

  stateSelectionForCity(state: any) {
    this.registerForm.controls["city"].reset();
    // this.registerForm.controls["zipCode"].reset();
    this.cityData = [];
    const selectedCountryCode = this.country_id;
    this.service.getCity(selectedCountryCode,state, this.country, this.macAddress).subscribe((res: any) => {
      this.cityData = res.data;
    });
  }

  citySelectionForZip(zip_code: any, zip_id: any) {
    // this.registerForm.controls["zipCode"].reset();
    // this.zipCode = zip_code;
    // this.zipID = zip_id;
  }

  uploadImage(event: any) {
    let files = event.target.files[0];
    let doctype = files.name.split('.');
    let doctypeName = doctype[doctype.length - 1];
    if (doctypeName && (doctypeName === "jpg" || doctypeName === "jpeg" || doctypeName === "png") &&
      files.size <= 2097152) {
      this.fileName = files.name;
      this.fData.append("file", files);
      this.notifyService.showInfo("Image selected please upload", "")
    } else {
      this.notifyService.showInfo("Only jpg, jpeg, png file formats are allowed in less than 2 MB", "Invalid File")
    }
    event.target.value = '';
  }

  removeImage() {
    this.registerForm.value.companyLogo = null;
    this.fileName = null;
    this.fData = new FormData();
    this.images3Path = null;
    this.isImgUploaded = false;
    this.isImgUploaded = false;
  }

  uploadDoc(event: any) {
    let files = event.target.files[0];
    // files.type = "";
    let doctype = files.name.split('.');
    let doctypeName = doctype[doctype.length - 1];
    if (files.size <= 2097152 ) {
    this.docName = files.name;
    this.docData.append("file", files);
    this.notifyService.showInfo("Document selected please upload", "")
    } else {
      this.notifyService.showInfo("Only less than 2 MB docs are allowed","Invalid File")
    }
    event.target.value = '';
  }

  removeDoc() {
    this.registerForm.value.document = null;
    this.docName = null;
    this.docData = new FormData();
    this.documents3Path = null;
    this.isFileUploaded = false;
    this.isFileUploaded = false;
  }

  imageUpload() {
    this.service.uploadImageRegister(this.fData).subscribe((res: any) => {
      if (res?.data?.s3Path) {
        this.images3Path = res?.data?.s3Path;
        this.isImgUploaded = true;
        this.notifyService.showSuccess(
          "Image Successfully Uploaded",
          res?.status
        );
      } else {
        this.notifyService.showSuccess(
          res?.message,
          res?.status
        );
      }
    });
  }

  documentUpload() {
    this.service.uploadDocRegister(this.docData).subscribe((res: any) => {
      if (res?.data?.s3Path) {
        this.documents3Path = res?.data?.s3Path;
        this.isFileUploaded = true;
        this.notifyService.showSuccess(
          "Document Successfully Uploaded",
          res?.status
        );
      } else {
        this.notifyService.showSuccess(
          res?.message,
          res?.status
        );
      }
    });
  }


}
