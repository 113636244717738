import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { CampaignService } from 'src/app/services/campaign.service';
import { MessagingService } from 'src/app/services/messaging.service';

@Component({
  selector: 'app-filter-favourite-campaigns',
  templateUrl: './filter-favourite-campaigns.component.html',
  styleUrl: './filter-favourite-campaigns.component.scss'
})
export class FilterFavouriteCampaignsComponent {
  addForm!: FormGroup;
  dropdownSettings: IDropdownSettings = {};
  tagsValue: any;
  tagsArray: any = [];
  allTagsKewords: any = [];
  todaysDate: any;

  constructor(public dialog: MatDialog, private formBuilder: FormBuilder, private campaignService: CampaignService,
    private mssgService: MessagingService) {
      const curDate = new Date();
      this.todaysDate =
      curDate.getFullYear() +
      "-" +
      ("0" + (curDate.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + curDate.getDate()).slice(-2);
    this.dropdownSettings = {
      idField: "tag",
      textField: "tag",
      enableCheckAll: true,
      // selectAllText: "Select All Users From List",
      // unSelectAllText: "Unselect All Users From List",
      noDataAvailablePlaceholderText: "There is no item availabale to show",
      allowSearchFilter: true,
      // itemsShowLimit: 3
      // defaultOpen: false
    };
    this.allTagsKewords = this.campaignService.getAllTagsKeyword().subscribe((res: any) => {
      if (res?.body?.data) {
        this.allTagsKewords = res.body.data;
      }

    });
    this.createForm();
  }

  createForm() {
    this.addForm = this.formBuilder.group({
      tag: [],
      fromDate: [''],
      toDate: [''],
      status: ['All'],
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  onSubmitFilter() {
    let value = this.addForm.value;

    if (value.fromDate !== '') {
      let fromDate = moment(value.fromDate, "YYYY-MM-DD");
      value.fromDate = fromDate.format("MM/DD/YYYY");
    }

    if (value.toDate !== '') {
      let toDate = moment(value.toDate, "YYYY-MM-DD");
      value.toDate = toDate.format("MM/DD/YYYY");
    }

    let data = {
      tag: [],
      fromDate: value.fromDate,
      toDate: value.toDate,
      status: '',
      chipData: {
        tag: [],
        status: '',
        fromDate: value.fromDate,
        toDate: value.toDate,
      }
    }
    if (value.tag !== "") {
      if (value.tag !== null) {
        value.tag.forEach((item: any) => {
          this.tagsArray.push(item.tag);
        });
      }
    }
    if (value.status === 'All') {
      data.status = '';
    } else {
      data.status = value.status;
    }

    data.chipData.tag = this.tagsArray;
    if (value.status === 'All') {
      data.chipData.status = 'All';
    } else if (value.status === 'A') {
      data.chipData.status = 'Active';
    } else if (value.status === 'P') {
      data.chipData.status = 'Paused';
    } else if (value.status === 'E') {
      data.chipData.status = 'Expired';
    }

    data.tag = this.tagsArray;
    this.mssgService.emit(data);
    this.dialog.closeAll();
    // let value = this.addForm.value;    
    // let parsedToDate = moment(value.toDate,"YYYY-MM-DD");
    // value.toDate = parsedToDate.format("DD/MM/YYYY");    
    // let parsedFromDate = moment(value.fromDate,"YYYY-MM-DD");
    // value.fromDate = parsedFromDate.format("DD/MM/YYYY");
    // // value.category = this.categoryValue;
    // this.dashboardService.emit(this.addForm.value);    
    // this.dialog.closeAll();
    //this.dashboardService.getBrandSpecificDeals(value.fromDate, value.toDate, "1", value.status)
  }


  onReset() {
    // this.categoryValue =  null;
    // this.addForm.reset();
    this.tagsArray = [];
    this.addForm.controls["fromDate"].reset();
    this.addForm.controls["toDate"].reset();
    this.addForm.controls["tag"].reset();
    this.createForm();
    this.addForm.value.status = "All";
  }
}
