<form [formGroup]="administartiveForm">
  <div class="div">
    <div class="div-2">
      <div class="div-3">
        <!-- <img
          loading="lazy"
          srcset="..."
          class="img"
        /> -->
        <img loading="lazy" style="cursor: pointer;" routerLink="/login"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/12819099-6939-4a45-a53d-6fada6f7c721?" class="img-2" />

      </div>
      <div class="div-4">
        <div class="div-5">
          <!-- <div class="div-6">Quick Help</div>
          <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/fc0670d5-2dde-4318-8acd-255e7b8c768f?"
            class="img-3" /> -->
        </div>
        <!-- <div class="div-7">
          <div class="div-8">
            <img loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/298675b0-c2d5-415d-bfe8-dc2a4931ccf7?"
              class="img-4" />
            <div class="div-9">Existing user?</div>
          </div>

          <nav>
            <a routerLink="/login" class="button" routerLinkActive="active" ariaCurrentWhenActive="page">
              <div class="div-10">Login here</div>
            </a>
          </nav>
        </div> -->
      </div>
    </div>
    <div class="div-11">
      <div class="div-12">Company Registration</div>
      <div class="div-13">
        Please provide your email address to begin the registration process.
        The email you provide will be used as your login username.
        <br />
        Creating an account through this form will assign you as the Primary
        Contact for your company.
      </div>
      <div class="div-14">
        <div class="div-15">Administrator Details</div>
        <div class="div-16">
          Enter the information about the administrator
        </div>
        <div class="div-17">
          <div class="div-18"></div>
        </div>
      </div>
      <div class="div-19">

        <div class="container mt-4">

          <div class="row">
            <div class="row col-12">
              <div class="col-4 form-group">
                <label class="asterix" for="PrimaryContactPerson">Administrator name</label>
                <input type="text" class="form-control" formControlName="primaryContact"
                  placeholder="Enter administrator name"
                  [ngClass]="{ 'is-invalid':  f['primaryContact'].errors && (f['primaryContact'].dirty || f['primaryContact'].touched )}"
                  pattern="^[a-zA-Z_ ]*$" required />
                @if ( f['primaryContact'].errors ) {
                <div class="invalid-feedback">
                  @if (f['primaryContact'].errors['required']) {
                  <div>Name is required</div>
                  }
                  @if (f['primaryContact'].errors['minlength']) {
                  <div>Name must be at least 2 characters</div>
                  }
                  @if (f['primaryContact'].errors['maxlength']) {
                  <div>Name must not exceed 30 characters</div>
                  }
                  @if (f['primaryContact'].errors['pattern']) {
                  <div>Only characters allowed</div>
                  }
                </div>
                }
              </div>


              <div class="col-1 mb-0 form-group">
                <label class="asterix" for="code">Code</label>
                <mat-form-field style="margin-top: -16px; margin-left:-16px;">
                  <mat-select formControlName="country" title="Country Code" placeholder="Code" class="form-control"
                    style="width: 77px;">
                    @for (country of countryData; track country) {
                    <mat-option (click)="mobileCode(country.phone_country_prefix)"
                      [value]="country.country_id">{{country.phone_country_prefix}}
                    </mat-option>
                    }
                  </mat-select>
                  <mat-error style="margin-top: -1px;
                  font-size: small;
                  margin-left: 78px;
                  width: 100%;"
                    *ngIf="{ 'is-invalid':  f['country'].errors && (f['country'].dirty || f['country'].touched )}">
                    Country code is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-3 form-group">
                <label for="password" class="asterix">Phone number</label>
                <input class="form-control" type="number" [readonly]="disablePhone" placeholder="Enter phone number"
                  formControlName="phone" pattern="^[0-9]{9}$"
                  [ngClass]="{ 'is-invalid': f['phone'].errors && (f['phone'].dirty || f['phone'].touched ) }"
                  required />
                @if ( f['phone'].errors) {
                <div class="invalid-feedback" style="width: 200px;">
                  @if (f['phone'].errors['required']) {
                  <div>Phone number is required</div>
                  }
                  @if (f['phone'].errors['pattern']) {
                  <div>Invalid number!!</div>
                  }
                </div>
                }
              </div>


              <!-- <div class="col-4 form-group">
                <label for="SelectCountry" class="asterix" style="margin-left: 6px;">Select Country</label>
                <mat-form-field style="margin-top: -16px; margin-left:-8px;">
                  <mat-select formControlName="country" placeholder="Select" class="form-control">
                    @for (country of countryData; track country) {
                    <mat-option (click)="mobileCode(country.phone_country_prefix)"
                      [value]="country.country_id">{{country.phone_country_prefix}}
                    </mat-option>
                    }
                  </mat-select>
                  <mat-error style="margin-top: -11px;
                  font-size: small"
                    *ngIf="{ 'is-invalid':  f['country'].errors && (f['country'].dirty || f['country'].touched )}">
                    Country is required
                  </mat-error>
                </mat-form-field>
              </div> -->
            </div>
            <div class="row col-12">
              <div class="col-4 form-group">
                <label for="email" class="asterix">Email</label>
                <input class="form-control" type="text" [readonly]="disableEmail"
                  placeholder="Please enter official email" autocomplete="email" formControlName="email"
                  [ngClass]="{ 'is-invalid':  f['email'].errors && (f['email'].dirty || f['email'].touched ) }"
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" required />
                @if ( f['email'].errors) {
                <div class="invalid-feedback">
                  @if (f['email'].errors['required']) {
                  <div>Email is required</div>
                  }
                  @if (f['email'].errors['pattern']) {
                  <div>Invalid email!!</div>
                  }
                </div>
                }
              </div>
              <img *ngIf="emailVerified===true" loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/9e77607a-df82-4ba3-a07e-abde78fac171?"
                class="img-succes-tick col-1" />
              <!-- <div class="col-4 form-group" *ngIf="phoneVerified===true">
                <label for="password" class="asterix">Phone number</label>
                <input class="form-control" type="text" [readonly]="disablePhone" placeholder="Enter phone number"
                  formControlName="phone" />
              </div>
              <img *ngIf="phoneVerified===true" loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/6e46e582-82fb-4f16-a811-9abe7d12918b?"
                class="img-succes-tick col-1" /> -->
              <div class="col-3">
                <button *ngIf="emailOtpField===false" class="div-37" (click)="sendEmailOtp()">Verify email</button>
              </div>
            </div>
            <div class="row col-12" *ngIf="verifyEmailField===false && resendEmailOtp !== true">
              <div class="col-5 form-group">
                <label>Enter email OTP</label>
                <ng-otp-input formControlName="emailotp" (onInputChange)="onOtpEmailChange($event)"
                  [config]="{length:6}" ngDefaultControl></ng-otp-input>
              </div>
              <div class="col-2">
                <button class="div-37" style="margin-top: 34px; margin-left: -60px;"
                  (click)="validateOtpEmail()">Submit</button>
              </div>
            </div>
            <div class="row col-12">
              <div *ngIf="verifyEmailField===false" class="px-2" style="text-align: left;color: gray;">
                <p *ngIf="displayEmailTimer">Expiring in <span style="color: red;">{{ displayEmail }}</span></p>
                <a *ngIf="resendEmailOtp" style="color:rgb(3 114 139);text-decoration: none;cursor: pointer;"
                  (click)="resendEmailOTP()">Resend email OTP</a>
              </div>
            </div>
            <!-- <div class="row col-12" >
              <div class="col-1 mb-0 form-group">
                <label class="asterix" for="code">Code</label> -->
            <!-- <div aria-disabled="true" class="form-control">
                  {{phoneNumberCode}} </div> -->
            <!-- <mat-form-field style="margin-top: -16px; margin-left:-16px;">
                  <mat-select formControlName="country" title="Country Code" placeholder="Code" class="form-control"
                    style="width: 77px;">
                    @for (country of countryData; track country) {
                    <mat-option (click)="mobileCode(country.phone_country_prefix)"
                      [value]="country.country_id">{{country.phone_country_prefix}}
                    </mat-option>
                    }
                  </mat-select>
                  <mat-error style="margin-top: -1px;
                  font-size: small;
                  margin-left: 78px;
                  width: 100%;"
                    *ngIf="{ 'is-invalid':  f['country'].errors && (f['country'].dirty || f['country'].touched )}">
                    Country code is required
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-3 form-group">
                <label for="password" class="asterix">Phone number</label>
                <input class="form-control" type="number" [readonly]="disablePhone" placeholder="Enter phone number"
                  formControlName="phone" pattern="^[0-9]{9}$"
                  [ngClass]="{ 'is-invalid': f['phone'].errors && (f['phone'].dirty || f['phone'].touched ) }"
                  required />
                @if ( f['phone'].errors) {
                <div class="invalid-feedback" style="width: 200px;">
                  @if (f['phone'].errors['required']) {
                  <div>Phone number is required</div>
                  }
                  @if (f['phone'].errors['pattern']) {
                  <div>Invalid number!!</div>
                  }
                </div>
                }
              </div> -->
            <!-- <div class="col-3">
                <button class="div-37" *ngIf="phoneOtpField===false" (click)="sendMobileOtp()">Verify phone</button>
              </div> -->
            <!-- </div> -->
            <!-- <div class="row col-12" *ngIf="verifyPhoneField===false && resendMobileOtp !== true">
              <div class="col-5 form-group">

                <label>Enter phone OTP</label>
                <ng-otp-input formControlName="phoneotp" (onInputChange)="onOtpPhoneChange($event)"
                  [config]="{length:6}" ngDefaultControl></ng-otp-input>
              </div>
              <div class="col-2">
                <button class="div-37" style="margin-top: 34px; margin-left: -60px;" (click)="validateOtpPhone()">Submit</button>
              </div>
            </div>
            <div class="row col-12">
              <div *ngIf="verifyPhoneField===false" class="px-2" style="text-align: left;color: gray;">
                <p *ngIf="displayMobileTimer">Expiring in <span style="color: red;">{{ displayMobile }}</span></p>
                <a *ngIf="resendMobileOtp" style="color:rgb(3 114 139);text-decoration: none;cursor: pointer;"
                  (click)="resendMobileOTP()">Resend mobile OTP</a>
              </div>
            </div> -->
            <div class="row col-12" *ngIf="emailVerified===true && phoneVerified===true">
              <div class="div-set35" *ngIf="emailVerified===true && phoneVerified===true">Set Password</div>
              <div class="div-set36" *ngIf="emailVerified===true && phoneVerified===true">
                Passwords must have at least 8 characters and contain at least
                two of the following: uppercase letters, lowercase letters,
                numbers, and symbols.
              </div>
              <div class="col-4 form-group">
                <label class="asterix" for="Password">Password</label>
                <input matInput [type]="showCreatePass ? 'text' : 'password'" class="form-control"
                  placeholder="Create password" formControlName="password"
                  pattern="^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,30}$"
                  [ngClass]="{ 'is-invalid': f['password'].errors && (f['password'].dirty || f['password'].touched )  }"
                  required />
                @if (f['password'].errors) {
                <div class="invalid-feedback">
                  @if (f['password'].errors['required']) {
                  <div>Password is required</div>
                  }
                  @if (f['password'].errors['minlength']) {
                  <div>Password must be at least 8 characters</div>
                  }
                  @if (f['password'].errors['maxlength']) {
                  <div>Password must not exceed 30 characters</div>
                  }
                  @if (f['password'].errors['pattern']) {
                  <div>
                    Enter correct pattern
                  </div>
                  }
                </div>
                }
              </div>
              <mat-icon matSuffix class="col-1 pt-1" style="overflow: visible;margin-top: 35px;margin-left: -85px"
                (click)="togglePasswordVisibility()">{{showCreatePass?'visibility_off':'visibility'}}</mat-icon>
              <div class="col-1"></div>
              <div class="col-7">
              <app-password-rules></app-password-rules>
            </div>
            </div>
            <div class="row col-12" *ngIf="emailVerified===true && phoneVerified===true">
              <div class="col-4 form-group" style="margin-top: -25px;">
                <label class="asterix" for="ConfirmPassword">Confirm password</label>
                <input matInput [type]="showConfirmPass ? 'text' : 'password'" class="form-control"
                  placeholder="Re-enter password" formControlName="confirmPass"
                  [ngClass]="{ 'is-invalid': !f['password'].errors && f['confirmPass'].errors && (f['confirmPass'].dirty || f['confirmPass'].touched ) }"
                  required />
                @if ( f['confirmPass'].errors) {
                <div class="invalid-feedback">
                  <!-- @if (f['password'].touched) {
                    <div>Create Password is required</div>
                    } -->
                  @if (f['confirmPass'].errors['required']) {
                  <div>Confirm password is required</div>
                  }
                  @if (f['confirmPass'].errors['matching']) {
                  <div>Confirm password does not match</div>
                  }
                </div>
                }
              </div>
              <mat-icon matSuffix class="col-1 pt-2" style="overflow: visible;margin-top: 6px;margin-left: -85px"
                (click)="toggleConfirmPasswordVisibility()">{{showConfirmPass?'visibility_off':'visibility'}}</mat-icon>
            </div>

            <div class="div-80" *ngIf="emailVerified===true && phoneVerified===true">
              <input type="checkbox" formControlName="accept" />
              <div class="div-81">
                <span style="color: rgba(49, 67, 69, 1)">I Accept</span>
                <span style="color: rgba(54, 132, 157, 1);cursor: pointer;" (click)="termsAndCondition()">
                  Terms & Conditions
                </span>
                <!-- <span style="color: rgba(49, 67, 69, 1)">& </span>
                <span style="color: rgba(54, 132, 157, 1)">Privacy policy</span> -->
              </div>
            </div>
            <div class="text-right">
              <button type="submit" (click)="save()" *ngIf="f['accept'].value===true"
                style="cursor: pointer;float: right;font-weight: 600;" class="btn div-63">Submit</button>
              <button type="reset" (click)="reset()"
                style="float: right;margin-right: 5px;color: black;background-color: #9da5b1;"
                class="btn btn-secondary">Reset</button>
            </div>
          </div>

        </div>


      </div>
    </div>
  </div>
</form>

<ngx-ui-loader fgsColor="#93d847" pbColor="#93d847" text="Please Wait..."></ngx-ui-loader>