<div class="div">
  <div class="div-2">
    <div class="div-3">Filter Campaigns</div>
    <mat-icon (click)="closeDialog()">close</mat-icon>
  </div>
  <form [formGroup]="addForm" class="">
    <!-- <div class="div-4">
      <div class="div-5">Select tags</div>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/a6651221268d26d5f7c2459d3d86471df8c55372891d73df0aac8e167e05b80a?"
        class="img-2"
      />
    </div>
    <div class="div-6">
      <div class="div-7">
        <div class="div-8">
          <div class="div-9">Puma</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/57f6728b9abb08ca48a3c5a44dfce8050541257cf72d76f742f37dba5d9c5bc3?"
            class="img-3"
          />
        </div>
        <div class="div-10">
          <div class="div-11">Season</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/57f6728b9abb08ca48a3c5a44dfce8050541257cf72d76f742f37dba5d9c5bc3?"
            class="img-4"
          />
        </div>
        <div class="div-12">
          <div class="div-13">Sale</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/57f6728b9abb08ca48a3c5a44dfce8050541257cf72d76f742f37dba5d9c5bc3?"
            class="img-5"
          />
        </div>
      </div>
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/f231dafc1b3fef336bbad007ade9614aba7f4b8bebd006e84c57ee6d4b1289a7?"
        class="img-6"
      />
    </div> -->
    
    
    <!-- <div class="div-22">
      <div class="div-23">
        <div class="div-24">
          <div class="div-25">From</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
            class="img-11"
          />
        </div>
        <div class="div-26">
          
          <Input type="date"
          formControlName="fromDate" onkeydown="return false" placeholder="Select Date"
          style="border: 0px solid transparent;" class="col-8" ngDefaultControl />
         
        </div>
      </div>
      <div class="div-28">
        <div class="div-29">
          <div class="div-30">To</div>
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/7ed5e5b41e4208efcad5291278fcc6a5d320da4b646dec769aa8dcc08b65947a?"
            class="img-13"
          />
        </div>
        <div class="div-31">
          <Input type="date" 
          formControlName="toDate" onkeydown="return false" placeholder="Select Date"
          style="border: 0px solid transparent;" class="col-8"  ngDefaultControl/>
         
        </div>
      </div>
    </div> -->
    <div class="div-33">
      <div class="div-34">Status</div>
      <!-- <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
        class="img-15"
      /> -->
    </div>
    <div class="div-35">

      <mat-radio-group aria-label="Select an option" formControlName="status">
        <mat-radio-button value="All">All</mat-radio-button>
        <mat-radio-button value="A">Active</mat-radio-button>
        <mat-radio-button value="P">Paused</mat-radio-button>
        <mat-radio-button value="E">Expired</mat-radio-button>
      </mat-radio-group>

      <!-- <div class="div-36">
        <img
          loading="lazy"
          srcset="..."
          class="img-16"
        />
        <div class="div-37">All</div>
      </div>
      <div class="div-38">
        <div class="div-39"></div>
        <div class="div-40">Active</div>
      </div>
      <div class="div-41">
        <div class="div-42"></div>
        <div class="div-43">Paused</div>
      </div>
      <div class="div-44">
        <div class="div-45"></div>
        <div class="div-46">Expired</div>
      </div> -->
    </div>
    <div class="div-47">
      <div class="div-48">Budget</div>
      <!-- <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
        class="img-17"
      /> -->
    </div>
    <div class="div-49">

      <!-- <section class="example-section" [formGroup]="budget"> -->
      <!-- <h4>Select your Budget:</h4> -->
      <mat-checkbox formControlName="budget1">$100-1000</mat-checkbox>
      <mat-checkbox formControlName="budget2">$10001-$9999</mat-checkbox>
      <mat-checkbox formControlName="budget3">$10001-$9999</mat-checkbox>
      <!-- </section> -->

      <!-- <div class="div-50">
        <div class="div-51"></div>
        <div class="div-52">$100-1000</div>
      </div>
      <div class="div-53">
        <img
          loading="lazy"
          srcset="..."
          class="img-18"
        />
        <div class="div-54">$10001-$9999</div>
      </div>
      <div class="div-55">
        <div class="div-56"></div>
        <div class="div-57">$10001-$9999</div>
      </div> -->
    </div>
    <div class="div-58">
      <div class="div-59">Actual Spent</div>
      <!-- <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bbf3f6be6b4a0c4435de1895467800e2ddd5d0433d0ed53d3fd76dd73608aab?"
        class="img-19"
      /> -->
    </div>
    <div class="div-60">


      <mat-checkbox formControlName="actualSpent1">$100-1000</mat-checkbox>
      <mat-checkbox formControlName="actualSpent2">$10001-$9999</mat-checkbox>
      <mat-checkbox formControlName="actualSpent3">$10001-$9999</mat-checkbox>

      <!-- <div class="div-61">
        <img
          loading="lazy"
          srcset="..."
          class="img-20"
        />
        <div class="div-62">$100-1000</div>
      </div>
      <div class="div-63">
        <div class="div-64"></div>
        <div class="div-65">$10001-$9999</div>
      </div>
      <div class="div-66">
        <div class="div-67"></div>
        <div class="div-68">$10001-$9999</div>
      </div> -->
    </div>
    <div class="div-69">
      <div class="col-10"></div>
      <button class="div-70" [disabled]="addForm.invalid" (click)="onReset()">Reset</button>
      <button class="div-71" [disabled]="addForm.invalid" (click)="onSubmitFilter()">Filter</button>
    </div>
  </form>
</div>