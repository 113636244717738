import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-select-users-filter',
  templateUrl: './select-users-filter.component.html',
  styleUrl: './select-users-filter.component.scss'
})
export class SelectUsersFilterComponent {

  constructor(public dialog: MatDialog ) { }

  closeDialog() {
    this.dialog.closeAll();
  }

}
