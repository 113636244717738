import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss'
})
export class CheckboxComponent {
  params: any;

  constructor() {
  }

  refresh(): boolean {
    return true;
  }

  agInit(params: any) {
    this.params = params;
  }

  edit() {
    return this.params.clicked(this.params.data);
  }

  checkedClicked(data:any) {
      return this.params.clicked(this.params.data, data.checked); 
  }
}
